// eslint-disable-next-line
// @ts-nocheck
import * as Frontegg from "@frontegg/nextjs";
import React from "react";

import { LANDING_URL } from "./AuthenticationProvider";

const FRONTEGG_HEADER_IMG = "/logo.svg";

export interface FronteggProviderProps {
  children: React.ReactNode;
}

export const FronteggProvider: React.FC<FronteggProviderProps> = ({
  children,
}) => {
  return (
    <Frontegg.FronteggProvider
      headerImage={FRONTEGG_HEADER_IMG}
      authOptions={{
        routes: {
          authenticatedUrl: LANDING_URL,
        },
      }}
      customLoader={true}
      contextOptions={{
        baseUrl: process.env.NEXT_PUBLIC_FRONTEGG_URL,
      }}
    >
      {children}
    </Frontegg.FronteggProvider>
  );
};
