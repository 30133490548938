import React from "react";
import { createRange, clamp } from "util/range";

export interface UseCursorProps {
  initial?: number;
  length: number;
}

export interface Cursor {
  decrement: () => void;
  increment: () => void;
  pointer: number;
  reset: () => void;
  set: (next: number) => void;
  length: number;
}

/**
 * Cursor is used to keep track of a location within a list.
 */
export function useCursor({ initial = 0, length }: UseCursorProps): Cursor {
  const [pointer, setPointer] = React.useState<number>(initial);

  const set = (next: number) => {
    const clamped: number = clamp(createRange(0, length - 1), next);

    setPointer(clamped);
  };

  const increment = React.useCallback(() => {
    set(pointer + 1);
  }, [pointer, length]);

  const decrement = React.useCallback(() => {
    set(pointer - 1);
  }, [pointer, length]);

  const reset = () => set(0);

  return {
    decrement,
    increment,
    pointer,
    reset,
    set,
    length,
  };
}
