/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LogicDefinition } from './LogicDefinition';
import {
    LogicDefinitionFromJSON,
    LogicDefinitionFromJSONTyped,
    LogicDefinitionToJSON,
} from './LogicDefinition';

/**
 * 
 * @export
 * @interface UpdatePolicyDto
 */
export interface UpdatePolicyDto {
    /**
     * 
     * @type {LogicDefinition}
     * @memberof UpdatePolicyDto
     */
    cohort?: LogicDefinition;
    /**
     * 
     * @type {string}
     * @memberof UpdatePolicyDto
     */
    type?: UpdatePolicyDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdatePolicyDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePolicyDto
     */
    id: string;
}


/**
 * @export
 */
export const UpdatePolicyDtoTypeEnum = {
    Employee: 'employee',
    Plan: 'plan'
} as const;
export type UpdatePolicyDtoTypeEnum = typeof UpdatePolicyDtoTypeEnum[keyof typeof UpdatePolicyDtoTypeEnum];


/**
 * Check if a given object implements the UpdatePolicyDto interface.
 */
export function instanceOfUpdatePolicyDto(value: object): value is UpdatePolicyDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    return true;
}

export function UpdatePolicyDtoFromJSON(json: any): UpdatePolicyDto {
    return UpdatePolicyDtoFromJSONTyped(json, false);
}

export function UpdatePolicyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePolicyDto {
    if (json == null) {
        return json;
    }
    return {
        
        'cohort': json['cohort'] == null ? undefined : LogicDefinitionFromJSON(json['cohort']),
        'type': json['type'] == null ? undefined : json['type'],
        'title': json['title'] == null ? undefined : json['title'],
        'id': json['id'],
    };
}

export function UpdatePolicyDtoToJSON(value?: UpdatePolicyDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cohort': LogicDefinitionToJSON(value['cohort']),
        'type': value['type'],
        'title': value['title'],
        'id': value['id'],
    };
}

