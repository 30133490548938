import * as R from "ramda";
import React from "react";
import styled from "styled-components";

import * as Style from "style";

export interface TabsChildrenProps<T> {
  setTab: (next: T) => void;
  tab: T;
}

export type TabsRenderer<T> = (props: TabsChildrenProps<T>) => React.ReactNode;

export interface TabsProps<T> {
  children: React.ReactNode | TabsRenderer<T>;
  defaultActive: T;
}

export const TabsContainer = styled.div`
  display: flex;
  column-gap: ${Style.Layout.Padding.Large};
`;

export function Tabs<T>({
  children,
  defaultActive,
}: TabsProps<T>): React.ReactElement {
  const [tab, setTab] = React.useState<T>(defaultActive);

  return (
    <TabsContainer>
      {R.is(Function, children) &&
        (children as TabsRenderer<T>)({ tab, setTab })}
      {!R.is(Function, children) && children}
    </TabsContainer>
  );
}
