import type { NumberLinePoint as INumberLinePoint } from "./type";
import type { Range } from "util/range";

import React from "react";

import * as Style from "style";
import { Alignment } from "../constant";
import { NumberLineContainer } from "./Container";
import { NumberLinePoint } from "./NumberLinePoint";

export interface MultiNumberLineProps {
  background?: string;
  points: INumberLinePoint[];
  range: Range;
  alignment?: Alignment;
}

export const MultiNumberLine: React.FC<MultiNumberLineProps> = ({
  alignment,
  background = Style.Color.Layout.Line,
  points,
  range,
}) => {
  return (
    <NumberLineContainer color={background}>
      {points.map((point: INumberLinePoint, index: number) => {
        return (
          <NumberLinePoint
            key={index}
            point={point}
            range={range}
            depth={index}
            alignment={alignment}
          />
        );
      })}
    </NumberLineContainer>
  );
};
