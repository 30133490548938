/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaginationResult
 */
export interface PaginationResult {
    /**
     * 
     * @type {Array<string>}
     * @memberof PaginationResult
     */
    results: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof PaginationResult
     */
    aggregations: object;
    /**
     * 
     * @type {number}
     * @memberof PaginationResult
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationResult
     */
    offset: number;
    /**
     * 
     * @type {object}
     * @memberof PaginationResult
     */
    search_after: object;
}

/**
 * Check if a given object implements the PaginationResult interface.
 */
export function instanceOfPaginationResult(value: object): value is PaginationResult {
    if (!('results' in value) || value['results'] === undefined) return false;
    if (!('aggregations' in value) || value['aggregations'] === undefined) return false;
    if (!('total' in value) || value['total'] === undefined) return false;
    if (!('offset' in value) || value['offset'] === undefined) return false;
    if (!('search_after' in value) || value['search_after'] === undefined) return false;
    return true;
}

export function PaginationResultFromJSON(json: any): PaginationResult {
    return PaginationResultFromJSONTyped(json, false);
}

export function PaginationResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginationResult {
    if (json == null) {
        return json;
    }
    return {
        
        'results': json['results'],
        'aggregations': json['aggregations'],
        'total': json['total'],
        'offset': json['offset'],
        'search_after': json['search_after'],
    };
}

export function PaginationResultToJSON(value?: PaginationResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'results': value['results'],
        'aggregations': value['aggregations'],
        'total': value['total'],
        'offset': value['offset'],
        'search_after': value['search_after'],
    };
}

