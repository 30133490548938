/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PerformanceCategory = {
    NoData: 'no-data',
    DoesNotMeet: 'does-not-meet',
    Meets: 'meets',
    Exceeds: 'exceeds',
    FarExceeds: 'far-exceeds'
} as const;
export type PerformanceCategory = typeof PerformanceCategory[keyof typeof PerformanceCategory];


export function instanceOfPerformanceCategory(value: any): boolean {
    for (const key in PerformanceCategory) {
        if (Object.prototype.hasOwnProperty.call(PerformanceCategory, key)) {
            if (PerformanceCategory[key as keyof typeof PerformanceCategory] === value) {
                return true;
            }
        }
    }
    return false;
}

export function PerformanceCategoryFromJSON(json: any): PerformanceCategory {
    return PerformanceCategoryFromJSONTyped(json, false);
}

export function PerformanceCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PerformanceCategory {
    return json as PerformanceCategory;
}

export function PerformanceCategoryToJSON(value?: PerformanceCategory | null): any {
    return value as any;
}

