/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const RoleType = {
    Admin: 'admin',
    Job: 'job',
    ViewOnly: 'view-only',
    Anonymous: 'anonymous'
} as const;
export type RoleType = typeof RoleType[keyof typeof RoleType];


export function instanceOfRoleType(value: any): boolean {
    for (const key in RoleType) {
        if (Object.prototype.hasOwnProperty.call(RoleType, key)) {
            if (RoleType[key as keyof typeof RoleType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function RoleTypeFromJSON(json: any): RoleType {
    return RoleTypeFromJSONTyped(json, false);
}

export function RoleTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleType {
    return json as RoleType;
}

export function RoleTypeToJSON(value?: RoleType | null): any {
    return value as any;
}

