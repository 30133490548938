/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateJobLevelGroupDto,
  JobLevelGroup,
  UpdateJobLevelGroupDto,
} from '../models/index';
import {
    CreateJobLevelGroupDtoFromJSON,
    CreateJobLevelGroupDtoToJSON,
    JobLevelGroupFromJSON,
    JobLevelGroupToJSON,
    UpdateJobLevelGroupDtoFromJSON,
    UpdateJobLevelGroupDtoToJSON,
} from '../models/index';

export interface CreateJobLevelGroupRequest {
    createJobLevelGroupDto: CreateJobLevelGroupDto;
}

export interface FindOneJobLevelGroupRequest {
    id: string;
}

export interface RemoveJobLevelGroupRequest {
    id: string;
}

export interface UpdateJobLevelGroupRequest {
    id: string;
    updateJobLevelGroupDto: UpdateJobLevelGroupDto;
}

/**
 * 
 */
export class JobLevelGroupApi extends runtime.BaseAPI {

    /**
     */
    async createJobLevelGroupRaw(requestParameters: CreateJobLevelGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<JobLevelGroup>> {
        if (requestParameters['createJobLevelGroupDto'] == null) {
            throw new runtime.RequiredError(
                'createJobLevelGroupDto',
                'Required parameter "createJobLevelGroupDto" was null or undefined when calling createJobLevelGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/job-level-groups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateJobLevelGroupDtoToJSON(requestParameters['createJobLevelGroupDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JobLevelGroupFromJSON(jsonValue));
    }

    /**
     */
    async createJobLevelGroup(requestParameters: CreateJobLevelGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<JobLevelGroup> {
        const response = await this.createJobLevelGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findAllJobLevelGroupsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<JobLevelGroup>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/job-level-groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(JobLevelGroupFromJSON));
    }

    /**
     */
    async findAllJobLevelGroups(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<JobLevelGroup>> {
        const response = await this.findAllJobLevelGroupsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async findOneJobLevelGroupRaw(requestParameters: FindOneJobLevelGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<JobLevelGroup>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling findOneJobLevelGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/job-level-groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JobLevelGroupFromJSON(jsonValue));
    }

    /**
     */
    async findOneJobLevelGroup(requestParameters: FindOneJobLevelGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<JobLevelGroup> {
        const response = await this.findOneJobLevelGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removeJobLevelGroupRaw(requestParameters: RemoveJobLevelGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling removeJobLevelGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/job-level-groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async removeJobLevelGroup(requestParameters: RemoveJobLevelGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeJobLevelGroupRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateJobLevelGroupRaw(requestParameters: UpdateJobLevelGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<JobLevelGroup>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateJobLevelGroup().'
            );
        }

        if (requestParameters['updateJobLevelGroupDto'] == null) {
            throw new runtime.RequiredError(
                'updateJobLevelGroupDto',
                'Required parameter "updateJobLevelGroupDto" was null or undefined when calling updateJobLevelGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/job-level-groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateJobLevelGroupDtoToJSON(requestParameters['updateJobLevelGroupDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JobLevelGroupFromJSON(jsonValue));
    }

    /**
     */
    async updateJobLevelGroup(requestParameters: UpdateJobLevelGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<JobLevelGroup> {
        const response = await this.updateJobLevelGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
