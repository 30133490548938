import React from "react";

import type { IconProps } from "./type";

export const BreadcrumbArrowRight: React.FC<IconProps> = ({
  className = "",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="5"
      height="8"
      viewBox="0 0 5 8"
      fill="none"
    >
      <path
        d="M0.828613 1L3.65704 3.82843L0.828613 6.65685"
        stroke="currentcolor"
      />
    </svg>
  );
};
