import React from "react";
import styled from "styled-components";

import * as Icons from "../../Icon";
import * as Style from "style";
import { SubTitle } from "../SubTitle";

export interface NoResultsProps {
  Icon?: React.VoidFunctionComponent;
  children?: React.ReactNode;
}

export const NoResultsContainer = styled.div`
  align-items: center;
  display: grid;
  justify-content: center;
  text-align: center;
  padding: ${Style.Layout.Padding.ExtraSmall};
  text-align: center;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  color: ${Style.Color.Font.Secondary};
  height: ${Style.Text.Size.Base};
  margin-bottom: ${Style.Layout.Padding.ExtraSmall};
`;

export const NoResults: React.FC<NoResultsProps> = ({
  Icon = Icons.MagnifyingGlass,
  children = "Search produced no results.",
}) => {
  return (
    <NoResultsContainer>
      <IconContainer>
        <Icon />
      </IconContainer>
      <SubTitle>{children}</SubTitle>
    </NoResultsContainer>
  );
};
