import type { LayoutProps } from "components/Layout";

import React from "react";

import { DashboardFrame } from "components/DashboardFrame";

export const BlankWhiteLayout: React.FC<LayoutProps> = ({
  children,
  title,
}) => {
  return <DashboardFrame title={title}>{children}</DashboardFrame>;
};
