import type { StandardComponent } from "components/type";
import type { Options } from "components/library/Select";

import React from "react";

import { SelectBuilder } from "components/library";

export interface SelectRowsPerPageProps extends StandardComponent {
  allowShowAll?: boolean;
  onChange: (number) => void;
  selected: number;
}

function toOptions({
  allowShowAll,
}: {
  allowShowAll: boolean;
}): Options<number> {
  const options: Options<number> = [
    {
      label: "10 rows per page",
      value: 10,
    },
    {
      label: "20 rows per page",
      value: 20,
    },
    {
      label: "50 rows per page",
      value: 50,
    },
  ];

  if (allowShowAll) {
    options.push({
      label: "Show all",
      value: -1,
    });
  }

  return options;
}

export const SelectRowsPerPage: React.FC<SelectRowsPerPageProps> = ({
  allowShowAll = true,
  onChange,
  selected = 20,
  isDisabled = false,
}) => {
  return (
    <SelectBuilder<number>
      options={toOptions({ allowShowAll })}
      value={selected}
      isDisabled={isDisabled}
      onChange={onChange}
    />
  );
};
