import type { BaseButtonProps } from "components/library/Button/Base";

import React from "react";
import styled from "styled-components";

import * as Style from "style";
import { Button } from "../Button";
import { Paragraph } from "../Paragraph";
import { Sentiment } from "../constant";
import { sentimentToModal } from "./util";

export interface NoticeModalProps {
  description: React.ReactNode;
  onAcknowledge: () => void;
  sentiment?: Sentiment;
  acknowledge?: string;
  title: string;
}

export const NoticeModalContentContainer = styled.div``;

export const NoticeModalActions = styled.div`
  column-gap: ${Style.Layout.Padding.ExtraSmall};
  display: flex;
  justify-content: end;
  margin-top: ${Style.Layout.Padding.ExtraSmall};
`;

/**
 * Given a sentiment get a button.
 */
function sentimentToButton({
  sentiment,
}: {
  sentiment: Sentiment;
}): React.FC<BaseButtonProps> {
  switch (sentiment) {
    case Sentiment.Error:
      return Button.Danger;
    case Sentiment.Info:
      return Button.Secondary;
    default:
      return Button.Primary;
  }
}

export const NoticeModal: React.FC<NoticeModalProps> = ({
  description,
  sentiment = Sentiment.Info,
  title,
  acknowledge = "Close",
  onAcknowledge,
  ...props
}) => {
  const Modal = sentimentToModal({ sentiment });
  const ActionButton = sentimentToButton({ sentiment });

  return (
    <Modal title={title} {...props}>
      {({ close, focusRef }) => {
        return (
          <NoticeModalContentContainer>
            <Paragraph>{description}</Paragraph>
            <NoticeModalActions>
              <ActionButton
                forwardRef={focusRef}
                onClick={() => {
                  close();
                  onAcknowledge();
                }}
              >
                {acknowledge}
              </ActionButton>
            </NoticeModalActions>
          </NoticeModalContentContainer>
        );
      }}
    </Modal>
  );
};
