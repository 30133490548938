import type { IDHandle, ItemRenderer, ItemHandle } from "./type";

import * as R from "ramda";
import React from "react";
import styled from "styled-components";
import {
  useDrag,
  useDrop,
  DragSourceMonitor,
  DropTargetMonitor,
} from "react-dnd";

import * as Style from "style";
import { GrabHandle, GrabHandleContainer } from "./GrabHandle";

export interface ItemProps<T extends IDHandle> {
  canDrop?: (
    handle: ItemHandle<T>,
    monitor: DropTargetMonitor<unknown, unknown>
  ) => boolean;
  canDropAtPosition?: boolean;
  index: number;
  isDisabled?: boolean;
  isSelected?: boolean;
  item: T;
  onDrop: (handle: ItemHandle<T>) => void;
  renderer: ItemRenderer<T>;
}

function toCursor({
  isDragging,
  isDisabled,
}: {
  isDisabled: boolean;
  isDragging: boolean;
}): string {
  if (isDisabled) {
    return "not-allowed";
  } else if (isDragging) {
    return "grabbing";
  }

  return "grab";
}

function toBackgroundHover({
  canAccept,
  canDropAtPosition,
  isDisabled,
  isOver,
}: {
  canAccept: boolean;
  canDropAtPosition: boolean;
  isDisabled: boolean;
  isOver: boolean;
}): string {
  if (isDisabled) {
    return "";
  }

  if (isOver && canAccept && !canDropAtPosition) {
    return "orange";
  }

  return Style.Color.Layout.Background.Secondary;
}

function toOpacity({ isDragging }: { isDragging: boolean }): number {
  return isDragging ? 0.5 : 1;
}

function toBackground({ isSelected }): string {
  return isSelected ? Style.Color.Sentiment.Info.Secondary : Style.Color.White;
}

export const ItemContainer = styled.li`
  background: ${toBackground};
  opacity: ${toOpacity};
  border: 1px solid ${Style.Color.Layout.Line};
  border-radius: ${Style.Design.Rounding.Primary};
`;

export const GrabHandlePlaceholder = styled(GrabHandleContainer)`
  visiblity: hidden;
`;

const ItemClickableArea = styled.a<{
  canAccept: boolean;
  canDropAtPosition: boolean;
  isDisabled: boolean;
  isDragging: boolean;
  isOver: boolean;
  isSelected: boolean;
}>`
  border-radius: ${Style.Design.Rounding.Primary};
  display: block;
  position: relative;

  &:hover {
    background: ${toBackgroundHover};
    cursor: ${toCursor};
  }
`;

const ItemContent = styled.div`
  padding: ${Style.Layout.Padding.ExtraSmall};
  display: flex;
  align-items: center;

  ${GrabHandleContainer} {
    margin-right: ${Style.Layout.Padding.ExtraSmall};
  }
`;
export const DropLine = styled.div`
  border-radius: ${Style.Design.Rounding.Primary};
  border-style: ${Style.Design.Border.Style};
  border-top-color: ${Style.Color.Brand.Primary};
  border-top-width: 2px;
  position: absolute;
  top: 0;
  width: 100%;
`;

export function Item<T extends IDHandle>({
  canDrop = R.always(true),
  canDropAtPosition = true,
  index,
  isDisabled = false,
  isSelected = false,
  item,
  onDrop,
  renderer = R.always(null),
}: ItemProps<T>) {
  const ref = React.useRef<HTMLLIElement>(null);

  const [{ handlerId, isOver, canAccept }, drop] = useDrop({
    accept: "item",
    collect: (monitor: DropTargetMonitor) => ({
      canAccept: monitor.canDrop(),
      handlerId: monitor.getHandlerId(),
      isOver: monitor.isOver(),
    }),
    canDrop,
    drop: (handle: ItemHandle<T>) => onDrop(handle),
  });

  const [{ isDragging }, drag] = useDrag({
    type: "item",
    item: () => {
      return { item, index };
    },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <ItemContainer ref={ref} key={item.id} data-handler-id={handlerId}>
      <ItemClickableArea
        href="#"
        canAccept={canAccept}
        canDropAtPosition={canDropAtPosition}
        isDisabled={isDisabled}
        isDragging={isDragging}
        isOver={isOver}
        isSelected={isSelected}
      >
        {!isDisabled && isOver && canAccept && canDropAtPosition && (
          <DropLine />
        )}
        <ItemContent>
          {!isDisabled && <GrabHandle />}
          {isDisabled && <GrabHandlePlaceholder />}
          <div className="flex-1 flex truncate">
            {renderer({ item, index })}
          </div>
        </ItemContent>
      </ItemClickableArea>
    </ItemContainer>
  );
}
