import React, { HTMLAttributes } from "react";
import styled from "styled-components";

import * as R from "ramda";
import * as Style from "style";
import { Tooltip } from "./Tooltip";

export interface SubTitleProps {
  children?: React.ReactNode;
}

export interface TooltipSubTitleProps extends SubTitleProps {
  tooltip: React.ReactNode;
}

export interface SubTitleSpecializations
  extends React.FC<SubTitleProps & HTMLAttributes<HTMLDivElement>> {
  Light: React.FC<SubTitleProps>;
  Tooltip: React.FC<TooltipSubTitleProps>;
}

const SubTitleContainer = styled.span`
  ${Style.Text.Definition.SubTitle};
  color: ${Style.Color.Font.Tertiary};
`;

/**
 * Used to denote sub sections.
 */
export const SubTitle: SubTitleSpecializations = ({ children }) => {
  return <SubTitleContainer>{children}</SubTitleContainer>;
};

const LightSubTitleContainer = styled.span`
  ${Style.Text.Definition.SubTitle};
  color: ${Style.Color.White};
`;

/**
 * Used to denote sub sections that have a dark background.
 */
const LightSubTitle: React.FC<SubTitleProps> = ({ children }) => {
  return <LightSubTitleContainer>{children}</LightSubTitleContainer>;
};

const TooltipSubTitleContainer = styled(SubTitleContainer)`
  align-items: center;
  column-gap: ${Style.Layout.Padding.Compact};
  display: flex;
`;

/**
 * Subtitle with a tooltip.
 */
export const TooltipSubTitle: React.FC<TooltipSubTitleProps> = ({
  tooltip,
  children,
  ...props
}) => {
  return (
    <TooltipSubTitleContainer {...props}>
      {children}
      {!R.isNil(tooltip) && <Tooltip.Info>{tooltip}</Tooltip.Info>}
    </TooltipSubTitleContainer>
  );
};

SubTitle.Light = LightSubTitle;
SubTitle.Tooltip = TooltipSubTitle;
