import type { PillProps } from "./type";

import * as R from "ramda";
import React from "react";
import styled from "styled-components";

import * as Style from "style";
import { Sentiment } from "../constant";
import { Tooltip } from "components/library/Tooltip";
import { sentimentToPrimaryColor } from "../util";

function toCursor({ onClick }: PillProps): string {
  return !R.isNil(onClick) ? "pointer" : "";
}

export const PillContentContainer = styled.span<PillProps>`
  ${Style.Text.Definition.Truncate};
`;

export const PillContainer = styled.span<PillProps>`
  background: ${sentimentToPrimaryColor};
  border-radius: ${Style.Design.Rounding.Tertiary};
  color: ${Style.Color.White};
  font-family: ${Style.Text.FontFamily.Primary};
  font-size: ${Style.Text.Size.Base};
  font-style: ${Style.Text.Style.Primary};
  font-weight: ${Style.Text.Weight.Secondary};
  padding: 0px 6px;
  cursor: ${toCursor};
  display: inline-flex;
`;

// TODO: Refactor this to only take a color not a sentiment then have specialized pass in the sentimentToPrimaryColor() value.
export const Pill: React.FC<PillProps> = ({
  children,
  sentiment = Sentiment.Neutral,
  onClick,
}) => {
  return (
    <PillContainer onClick={onClick} sentiment={sentiment}>
      <Tooltip.Inline tooltip={children}>{children}</Tooltip.Inline>
    </PillContainer>
  );
};
