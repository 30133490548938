import type { BaseButtonProps } from "./Base";

import { css } from "styled-components";

import * as Style from "style";

/**
 * Determine the primary button background color.
 */
function toPrimaryButtonBackgroundColor({
  isDisabled,
}: BaseButtonProps): string {
  return isDisabled ? Style.Color.Layout.Gray : Style.Color.Brand.Primary;
}

/**
 * Determine the primary button background hover color.
 */
function toPrimaryButtonBackgroundHoverColor({
  isDisabled,
}: BaseButtonProps): string {
  return isDisabled ? Style.Color.Layout.Gray : Style.Color.Brand.Secondary;
}

/**
 * Determine the secondary button color.
 */
function toSecondaryButtonColor({ isDisabled }: BaseButtonProps): string {
  return isDisabled ? Style.Color.Layout.Gray : Style.Color.Brand.Primary;
}

/**
 * Determine the danger button color.
 */
function toDangerButtonColor({ isDisabled }: BaseButtonProps): string {
  return isDisabled
    ? Style.Color.Layout.Gray
    : Style.Color.Sentiment.Error.Primary;
}

/**
 * Determine the secondary button hover color.
 */
function toSecondaryButtonHoverColor({ isDisabled }: BaseButtonProps): string {
  return isDisabled ? Style.Color.Layout.Gray : Style.Color.Brand.Secondary;
}

/**
 * Determine the button cursor.
 */
function toCursor({ isDisabled }: BaseButtonProps): string {
  return isDisabled ? "not-allowed" : "pointer";
}

export const BaseButtonStyle = css<{ isDisabled: boolean }>`
  border-radius: ${Style.Design.Rounding.Secondary};
  font-family: ${Style.Text.FontFamily.Primary};
  font-size: ${Style.Text.Size.Base};
  font-style: ${Style.Text.Style.Primary};
  font-weight: ${Style.Text.Weight.Secondary};
  cursor: ${toCursor};
`;

export const PrimaryButtonStyle = css<{ isDisabled: boolean }>`
  background: ${toPrimaryButtonBackgroundColor};
  color: ${Style.Color.White};

  &:hover {
    background: ${toPrimaryButtonBackgroundHoverColor};
  }
`;

export const SecondaryButtonStyle = css<{ isDisabled: boolean }>`
  border-color: ${toSecondaryButtonColor};
  border-style: ${Style.Design.Border.Style};
  border-width: ${Style.Design.Border.Size};
  color: ${toSecondaryButtonColor};

  &:hover {
    border-color: ${toSecondaryButtonHoverColor};
    color: ${toSecondaryButtonHoverColor};
  }
`;

export const DangerButtonStyle = css`
  border-color: ${toDangerButtonColor};
  border-style: ${Style.Design.Border.Style};
  border-width: ${Style.Design.Border.Size};
  color: ${toDangerButtonColor};

  &:hover {
    border-color: ${Style.Color.Sentiment.Error.Secondary};
    color: ${Style.Color.Sentiment.Error.Secondary};
  }
`;

export const FixedButtonStyle = css`
  height: ${Style.Layout.Input.Height};
  padding: 0px ${Style.Layout.Padding.Small};
  min-width: ${Style.Layout.Input.MinWidth};
`;

export const LeftButtonGroupStyle = css`
  border-bottom-right-radius: 0px;
  border-right-width: 0px;
  border-top-right-radius: 0px;
`;

export const CenterButtonGroupStyle = css`
  border-radius: 0px;
`;

export const RightButtonGroupStyle = css`
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
`;
