/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SpendByTitleDatum } from './SpendByTitleDatum';
import {
    SpendByTitleDatumFromJSON,
    SpendByTitleDatumFromJSONTyped,
    SpendByTitleDatumToJSON,
} from './SpendByTitleDatum';
import type { SpendByGeographyDatum } from './SpendByGeographyDatum';
import {
    SpendByGeographyDatumFromJSON,
    SpendByGeographyDatumFromJSONTyped,
    SpendByGeographyDatumToJSON,
} from './SpendByGeographyDatum';

/**
 * 
 * @export
 * @interface SpendData
 */
export interface SpendData {
    /**
     * 
     * @type {Array<SpendByGeographyDatum>}
     * @memberof SpendData
     */
    by_geo: Array<SpendByGeographyDatum>;
    /**
     * 
     * @type {Array<SpendByTitleDatum>}
     * @memberof SpendData
     */
    by_title: Array<SpendByTitleDatum>;
    /**
     * 
     * @type {Array<SpendByTitleDatum>}
     * @memberof SpendData
     */
    by_count: Array<SpendByTitleDatum>;
}

/**
 * Check if a given object implements the SpendData interface.
 */
export function instanceOfSpendData(value: object): value is SpendData {
    if (!('by_geo' in value) || value['by_geo'] === undefined) return false;
    if (!('by_title' in value) || value['by_title'] === undefined) return false;
    if (!('by_count' in value) || value['by_count'] === undefined) return false;
    return true;
}

export function SpendDataFromJSON(json: any): SpendData {
    return SpendDataFromJSONTyped(json, false);
}

export function SpendDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpendData {
    if (json == null) {
        return json;
    }
    return {
        
        'by_geo': ((json['by_geo'] as Array<any>).map(SpendByGeographyDatumFromJSON)),
        'by_title': ((json['by_title'] as Array<any>).map(SpendByTitleDatumFromJSON)),
        'by_count': ((json['by_count'] as Array<any>).map(SpendByTitleDatumFromJSON)),
    };
}

export function SpendDataToJSON(value?: SpendData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'by_geo': ((value['by_geo'] as Array<any>).map(SpendByGeographyDatumToJSON)),
        'by_title': ((value['by_title'] as Array<any>).map(SpendByTitleDatumToJSON)),
        'by_count': ((value['by_count'] as Array<any>).map(SpendByTitleDatumToJSON)),
    };
}

