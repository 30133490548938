import React from "react";

import type { IconProps } from "./type";

export const InformationCircle: React.FC<IconProps> = ({
  className = "",
  ...rest
}) => {
  return (
    <svg
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="100%"
      height="100%"
      viewBox="0 0 256 256"
      stroke="currentColor"
      fill="none"
    >
      <circle
        cx="128"
        cy="128"
        r="96"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      ></circle>
      <polyline
        points="120 120 128 120 128 176 136 176"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      ></polyline>
      <circle cx="126" cy="84" r="12"></circle>
    </svg>
  );
};
