import type { SpecializedPillProps } from "./type";

import React from "react";

import { Pill } from "./Pill";
import { Sentiment } from "../constant";

export const SuccessPill: React.FC<SpecializedPillProps> = (props) => {
  return <Pill sentiment={Sentiment.Success} {...props} />;
};
