import type { ColorSwatchProps } from "./Color";
import type { ContainedSwatchProps } from "./Contained";

import React from "react";

import { ColorSwatch } from "./Color";
import { ContainedSwatch } from "./Contained";
import { SwatchContainer } from "./Base";

export interface SwatchSpecializations extends React.VoidFunctionComponent {
  Color: React.FC<ColorSwatchProps>;
  Contained: React.FC<ContainedSwatchProps>;
}

export const Swatch: SwatchSpecializations = (props) => {
  return <SwatchContainer {...props} />;
};

Swatch.Color = ColorSwatch;
Swatch.Contained = ContainedSwatch;
