/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FilterValue
 */
export interface FilterValue {
    /**
     * 
     * @type {string}
     * @memberof FilterValue
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof FilterValue
     */
    field: string;
    /**
     * 
     * @type {string}
     * @memberof FilterValue
     */
    operator: FilterValueOperatorEnum;
}


/**
 * @export
 */
export const FilterValueOperatorEnum = {
    Between: 'Between',
    Contains: 'Contains',
    DoesNotContain: 'DoesNotContain',
    Equals: 'Equals',
    Excludes: 'Excludes',
    GreaterThan: 'GreaterThan',
    GreaterThanOrEqualTo: 'GreaterThanOrEqualTo',
    Includes: 'Includes',
    LessThan: 'LessThan',
    LessThanOrEqualTo: 'LessThanOrEqualTo',
    NotBetween: 'NotBetween',
    NotEquals: 'NotEquals'
} as const;
export type FilterValueOperatorEnum = typeof FilterValueOperatorEnum[keyof typeof FilterValueOperatorEnum];


/**
 * Check if a given object implements the FilterValue interface.
 */
export function instanceOfFilterValue(value: object): value is FilterValue {
    if (!('value' in value) || value['value'] === undefined) return false;
    if (!('field' in value) || value['field'] === undefined) return false;
    if (!('operator' in value) || value['operator'] === undefined) return false;
    return true;
}

export function FilterValueFromJSON(json: any): FilterValue {
    return FilterValueFromJSONTyped(json, false);
}

export function FilterValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterValue {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'],
        'field': json['field'],
        'operator': json['operator'],
    };
}

export function FilterValueToJSON(value?: FilterValue | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'value': value['value'],
        'field': value['field'],
        'operator': value['operator'],
    };
}

