/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { JobLevelGroup } from './JobLevelGroup';
import {
    JobLevelGroupFromJSON,
    JobLevelGroupFromJSONTyped,
    JobLevelGroupToJSON,
} from './JobLevelGroup';

/**
 * 
 * @export
 * @interface HydratedJobLevel
 */
export interface HydratedJobLevel {
    /**
     * 
     * @type {string}
     * @memberof HydratedJobLevel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof HydratedJobLevel
     */
    job_level_group_id: string;
    /**
     * 
     * @type {string}
     * @memberof HydratedJobLevel
     */
    label: string;
    /**
     * 
     * @type {number}
     * @memberof HydratedJobLevel
     */
    ordinal: number;
    /**
     * 
     * @type {JobLevelGroup}
     * @memberof HydratedJobLevel
     */
    job_level_group: JobLevelGroup;
    /**
     * 
     * @type {string}
     * @memberof HydratedJobLevel
     */
    full_ordinal: string;
}

/**
 * Check if a given object implements the HydratedJobLevel interface.
 */
export function instanceOfHydratedJobLevel(value: object): value is HydratedJobLevel {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('job_level_group_id' in value) || value['job_level_group_id'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('ordinal' in value) || value['ordinal'] === undefined) return false;
    if (!('job_level_group' in value) || value['job_level_group'] === undefined) return false;
    if (!('full_ordinal' in value) || value['full_ordinal'] === undefined) return false;
    return true;
}

export function HydratedJobLevelFromJSON(json: any): HydratedJobLevel {
    return HydratedJobLevelFromJSONTyped(json, false);
}

export function HydratedJobLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): HydratedJobLevel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'job_level_group_id': json['job_level_group_id'],
        'label': json['label'],
        'ordinal': json['ordinal'],
        'job_level_group': JobLevelGroupFromJSON(json['job_level_group']),
        'full_ordinal': json['full_ordinal'],
    };
}

export function HydratedJobLevelToJSON(value?: HydratedJobLevel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'job_level_group_id': value['job_level_group_id'],
        'label': value['label'],
        'ordinal': value['ordinal'],
        'job_level_group': JobLevelGroupToJSON(value['job_level_group']),
        'full_ordinal': value['full_ordinal'],
    };
}

