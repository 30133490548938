import type { CardContentSpecializations } from "./Content";
import type { CardFooterSpecializations } from "./Footer";
import type { CardHeaderProps } from "./Header";
import type { CollapsableCardProps } from "./Collapsable";

import React from "react";
import cn from "lib/cn";

import { CardContent } from "./Content";
import { CardFooter } from "./Footer";
import { CardHeader } from "./Header";
import { CollapsableCard } from "./Collapsable";

interface CardProps extends React.HTMLProps<HTMLDivElement> {
  children?: React.ReactNode;
  className?: string;
}

export interface CardSpecializations extends React.FC<CardProps> {
  Header: React.FC<CardHeaderProps>;
  Content: CardContentSpecializations;
  Footer: CardFooterSpecializations;
  Collapsable: React.FC<CollapsableCardProps>;
}

export const Card: CardSpecializations = ({ children, className, ...rest }) => {
  return (
    <div
      className={cn(
        className,
        "flex flex-col shadow h-full bg-white rounded-md relative"
      )}
      {...rest}
    >
      {children}
    </div>
  );
};

Card.Content = CardContent;
Card.Footer = CardFooter;
Card.Header = CardHeader;
Card.Collapsable = CollapsableCard;
