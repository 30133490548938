import * as R from "ramda";
import React from "react";
import styled from "styled-components";

import * as Style from "style";
import { SwatchContainer } from "./Base";

export interface ContainedSwatchProps {
  children: React.ReactNode;
  color?: string;
  width?: number;
  rounding?: number;
}

export const ContainedSwatchContainer = styled.div<{
  color: string;
  width: number;
  rounding: number;
}>`
  border-color: ${R.prop("color")};
  border-width: ${R.prop("width")}px;
  border-style: ${Style.Design.Border.Style};
  border-radius: ${R.prop("rounding")}px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  > div {
    border-radius: ${R.prop("rounding")}px;
  }
`;

export const ContainedSwatch: React.FC<ContainedSwatchProps> = ({
  color = Style.Color.Layout.Line,
  width = Style.Design.Border.Size,
  rounding = Style.Design.Rounding.Secondary,
  children,
}) => {
  return (
    <SwatchContainer>
      <ContainedSwatchContainer color={color} width={width} rounding={rounding}>
        {children}
      </ContainedSwatchContainer>
    </SwatchContainer>
  );
};
