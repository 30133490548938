import type { SpecializedModalProps } from "./Modal";

import * as R from "ramda";

import { ErrorModal } from "./Error";
import { InfoModal } from "./Info";
import { Sentiment } from "../constant";
import { SuccessModal } from "./Success";
import { WarningModal } from "./Warning";

/**
 * Given sentiment get a modal component.
 */
export function sentimentToModal({
  sentiment = Sentiment.Info,
}: {
  sentiment: Sentiment;
}): React.FC<SpecializedModalProps> {
  return R.propOr(InfoModal, sentiment, {
    [Sentiment.Error]: ErrorModal,
    [Sentiment.Info]: InfoModal,
    [Sentiment.Success]: SuccessModal,
    [Sentiment.Warning]: WarningModal,
  });
}
