import React from "react";
import styled from "styled-components";

import * as R from "ramda";

import * as Style from "style";
import { Tooltip } from "./Tooltip";

export interface TitleProps {
  children: React.ReactNode;
  className?: string;
}

export interface TooltipTitleProps extends TitleProps {
  tooltip: React.ReactNode;
}

export interface TitleSpecializations extends React.FC<TitleProps> {
  Light: React.FC<TitleProps>;
  Tooltip: React.FC<TooltipTitleProps>;
}

const TitleContainer = styled.span`
  ${Style.Text.Definition.Title};
  color: ${Style.Color.Font.Primary};
`;

/**
 * Used to title sections.
 */
export const Title: TitleSpecializations = ({ children, className }) => {
  return <TitleContainer className={className}>{children}</TitleContainer>;
};

const LightTitleContainer = styled.span`
  ${Style.Text.Definition.Title};
  color: ${Style.Color.White};
`;

/**
 * Used to title sections that have a dark background.
 */
const LightTitle: React.FC<TitleProps> = ({ children }) => {
  return <LightTitleContainer>{children}</LightTitleContainer>;
};

const TooltipTitleContainer = styled(TitleContainer)`
  align-items: center;
  display: flex;
  align-items: center;
  column-gap: ${Style.Layout.Padding.Compact};
`;

/**
 * Title with a tooltip.
 */
export const TooltipTitle: React.FC<TooltipTitleProps> = ({
  tooltip,
  children,
  ...props
}) => {
  return (
    <TooltipTitleContainer {...props}>
      {children}
      {!R.isNil(tooltip) && <Tooltip.Info>{tooltip}</Tooltip.Info>}
    </TooltipTitleContainer>
  );
};

Title.Light = LightTitle;
Title.Tooltip = TooltipTitle;
