import * as Style from "style";
import React from "react";
import styled from "styled-components";
import cn from "lib/cn";

export interface BaseCardFooterProps {
  children?: React.ReactNode;
  className?: string;
}

export interface CardFooterProps {
  children?: React.ReactNode;
}

export interface CardFooterSpecializations extends React.FC<CardFooterProps> {
  Base: React.FC<BaseCardFooterProps>;
}

export const CardFooterContainer = styled.div`
  background: ${Style.Color.Layout.Background.Secondary};
  border-bottom-left-radius: ${Style.Design.Rounding.Primary};
  border-bottom-right-radius: ${Style.Design.Rounding.Primary};
  border-top: 1px solid ${Style.Color.Layout.Line};
`;

export const BaseCardFooter: React.FC<BaseCardFooterProps> = ({
  children,
  className,
}) => {
  return (
    <div
      className={cn(
        className,
        "bg-layout-background-secondary border-t rounded-b-md"
      )}
    >
      {children}
    </div>
  );
};

export const CardFooter: CardFooterSpecializations = ({ children }) => {
  return (
    <BaseCardFooter className="p-4 mt-4 space-x-4 flex justify-end">
      {children}
    </BaseCardFooter>
  );
};

CardFooter.Base = BaseCardFooter;
