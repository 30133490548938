import type { Column } from "./type";
import type { CellCSSProps } from "./style";

import * as R from "ramda";
import React from "react";
import styled from "styled-components";

import * as Style from "style";
import { AlignmentContainer } from "components/Plan/Report/Builder/PreviewCell/Container";
import { CellContainer, CellCSS, TruncationWrapper } from "./style";

export interface HeaderCellCSSProps extends CellCSSProps {
  isSortable?: boolean;
}

function toCursor({ isSortable }: HeaderCellCSSProps): string {
  return isSortable ? "pointer" : "auto";
}

const HeaderCellContainer = styled.th<HeaderCellCSSProps>`
  ${CellCSS};
  background: ${Style.Color.Layout.Background.Primary};
  cursor: ${toCursor};
  z-index: 2;
`;

export interface HeaderCellProps {
  children: React.ReactNode;
  column: Column;
}

export const HeaderCell: React.FC<HeaderCellProps> = ({ children, column }) => {
  return (
    <HeaderCellContainer isSortable={column.sortable}>
      <CellContainer column={column}>
        <AlignmentContainer type={R.path(["props", "schema", "type"], column)}>
          <TruncationWrapper>{children}</TruncationWrapper>
        </AlignmentContainer>
      </CellContainer>
    </HeaderCellContainer>
  );
};
