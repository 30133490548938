import type { TabsRenderer } from "./Tabs";

import * as R from "ramda";
import React from "react";

import { TabsContainer } from "./Tabs";

export interface ActionTabsProps<T> {
  children: React.ReactNode | TabsRenderer<T>;
  defaultActive: T;
  actions: React.ReactNode;
}

export function ActionTabs<T>({
  actions,
  children,
  defaultActive,
}: ActionTabsProps<T>): React.ReactElement {
  const [tab, setTab] = React.useState<T>(defaultActive);

  return (
    <TabsContainer className="flex">
      <nav
        className="-mb-px flex w-full justify-between gap-x-6 items-center"
        aria-label="Tabs"
      >
        {R.is(Function, children) &&
          (children as TabsRenderer<T>)({ tab, setTab })}
        {actions}
      </nav>
    </TabsContainer>
  );
}
