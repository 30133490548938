/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CountryDetails } from './CountryDetails';
import {
    CountryDetailsFromJSON,
    CountryDetailsFromJSONTyped,
    CountryDetailsToJSON,
} from './CountryDetails';

/**
 * 
 * @export
 * @interface HydratedZone
 */
export interface HydratedZone {
    /**
     * 
     * @type {string}
     * @memberof HydratedZone
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof HydratedZone
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof HydratedZone
     */
    country_label: string;
    /**
     * 
     * @type {string}
     * @memberof HydratedZone
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof HydratedZone
     */
    multiplier: number;
    /**
     * 
     * @type {CountryDetails}
     * @memberof HydratedZone
     */
    country_details: CountryDetails;
}

/**
 * Check if a given object implements the HydratedZone interface.
 */
export function instanceOfHydratedZone(value: object): value is HydratedZone {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('country' in value) || value['country'] === undefined) return false;
    if (!('country_label' in value) || value['country_label'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('multiplier' in value) || value['multiplier'] === undefined) return false;
    if (!('country_details' in value) || value['country_details'] === undefined) return false;
    return true;
}

export function HydratedZoneFromJSON(json: any): HydratedZone {
    return HydratedZoneFromJSONTyped(json, false);
}

export function HydratedZoneFromJSONTyped(json: any, ignoreDiscriminator: boolean): HydratedZone {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'country': json['country'],
        'country_label': json['country_label'],
        'description': json['description'],
        'multiplier': json['multiplier'],
        'country_details': CountryDetailsFromJSON(json['country_details']),
    };
}

export function HydratedZoneToJSON(value?: HydratedZone | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'country': value['country'],
        'country_label': value['country_label'],
        'description': value['description'],
        'multiplier': value['multiplier'],
        'country_details': CountryDetailsToJSON(value['country_details']),
    };
}

