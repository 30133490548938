/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateReportDto,
  PaginationResult,
  QueryDTO,
  Report,
  UpdateReportDto,
} from '../models/index';
import {
    CreateReportDtoFromJSON,
    CreateReportDtoToJSON,
    PaginationResultFromJSON,
    PaginationResultToJSON,
    QueryDTOFromJSON,
    QueryDTOToJSON,
    ReportFromJSON,
    ReportToJSON,
    UpdateReportDtoFromJSON,
    UpdateReportDtoToJSON,
} from '../models/index';

export interface CreateReportRequest {
    createReportDto: CreateReportDto;
}

export interface FindOneReportRequest {
    id: string;
}

export interface RemoveReportRequest {
    id: string;
}

export interface RunReportRequest {
    id: string;
    queryDTO: QueryDTO;
}

export interface UpdateReportRequest {
    id: string;
    updateReportDto: UpdateReportDto;
}

/**
 * 
 */
export class ReportApi extends runtime.BaseAPI {

    /**
     */
    async createReportRaw(requestParameters: CreateReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Report>> {
        if (requestParameters['createReportDto'] == null) {
            throw new runtime.RequiredError(
                'createReportDto',
                'Required parameter "createReportDto" was null or undefined when calling createReport().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/reports`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateReportDtoToJSON(requestParameters['createReportDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportFromJSON(jsonValue));
    }

    /**
     */
    async createReport(requestParameters: CreateReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Report> {
        const response = await this.createReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findAllReportsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Report>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/reports`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReportFromJSON));
    }

    /**
     */
    async findAllReports(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Report>> {
        const response = await this.findAllReportsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async findOneReportRaw(requestParameters: FindOneReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Report>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling findOneReport().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/reports/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportFromJSON(jsonValue));
    }

    /**
     */
    async findOneReport(requestParameters: FindOneReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Report> {
        const response = await this.findOneReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removeReportRaw(requestParameters: RemoveReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling removeReport().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/reports/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async removeReport(requestParameters: RemoveReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeReportRaw(requestParameters, initOverrides);
    }

    /**
     * Run report
     */
    async runReportRaw(requestParameters: RunReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginationResult>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling runReport().'
            );
        }

        if (requestParameters['queryDTO'] == null) {
            throw new runtime.RequiredError(
                'queryDTO',
                'Required parameter "queryDTO" was null or undefined when calling runReport().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/reports/run/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QueryDTOToJSON(requestParameters['queryDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginationResultFromJSON(jsonValue));
    }

    /**
     * Run report
     */
    async runReport(requestParameters: RunReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginationResult> {
        const response = await this.runReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateReportRaw(requestParameters: UpdateReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Report>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateReport().'
            );
        }

        if (requestParameters['updateReportDto'] == null) {
            throw new runtime.RequiredError(
                'updateReportDto',
                'Required parameter "updateReportDto" was null or undefined when calling updateReport().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/reports/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateReportDtoToJSON(requestParameters['updateReportDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportFromJSON(jsonValue));
    }

    /**
     */
    async updateReport(requestParameters: UpdateReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Report> {
        const response = await this.updateReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
