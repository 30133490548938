import type { BaseButtonProps } from "./Base";

import React from "react";
import styled from "styled-components";

import { BaseButton } from "./Base";
import { SecondaryButtonStyle, FixedButtonStyle } from "./style";

export const SecondaryButtonContainer = styled.div<{
  isDisabled: boolean;
}>`
  display: inline-block;

  > button {
    ${FixedButtonStyle};
    ${SecondaryButtonStyle};
  }
`;

/**
 * Button used for secondary actions.
 */
export const SecondaryButton: React.FC<BaseButtonProps> = (props) => {
  const { isDisabled } = props;

  return (
    <SecondaryButtonContainer isDisabled={isDisabled}>
      <BaseButton {...props} />
    </SecondaryButtonContainer>
  );
};
