import type { MultiNumberLineProps } from "./Multi";
import type { NumberLineProps } from "./NumberLine";

import React from "react";

import { MultiNumberLine } from "./Multi";
import { NumberLine as DefaultNumberLine } from "./NumberLine";

export interface NumberLineSpecializations extends React.FC<NumberLineProps> {
  Multi: React.FC<MultiNumberLineProps>;
}

export const NumberLine: NumberLineSpecializations = (props) => {
  return <DefaultNumberLine {...props} />;
};

NumberLine.Multi = MultiNumberLine;
