import type { Column } from "./type";
import type { CellCSSProps } from "./style";

import * as R from "ramda";
import React from "react";
import styled from "styled-components";

import * as Style from "style";
import { AlignmentContainer } from "components/Plan/Report/Builder/PreviewCell/Container";
import { CellContainer, CellCSS, TruncationWrapper } from "./style";

const FooterCellContainer = styled.td<CellCSSProps>`
  ${CellCSS};
  background: ${Style.Color.White};
  z-index: 2;
`;

export interface FooterCellProps {
  children?: React.ReactNode;
  column: Column;
}

export const FooterCell: React.FC<FooterCellProps> = ({ children, column }) => {
  return (
    <FooterCellContainer>
      <CellContainer column={column}>
        <AlignmentContainer type={R.path(["props", "schema", "type"], column)}>
          <TruncationWrapper>{children}</TruncationWrapper>
        </AlignmentContainer>
      </CellContainer>
    </FooterCellContainer>
  );
};
