import React from "react";
import * as R from "ramda";
import { PaginationResult, QueryDTO } from ".generated/models";

interface FetchProps {
  tag: string;
  initialQuery: QueryDTO;
}

export interface KeyMethodParams extends FetchProps {
  pageIndex: number;
  previousPageData?: PaginationResult;
}

export type KeyMethod = (params: KeyMethodParams) => QueryDTO;
export type Fetcher<T> = (query: QueryDTO) => Promise<T>;

const getTotal = R.pipe(
  R.defaultTo({}),
  R.view(R.lensIndex(0)),
  R.propOr(null, "total")
) as (p: Record<string, unknown>) => number;

export const useDataPaginated = <T>(
  params: FetchProps,
  keyMethod: KeyMethod,
  apiMethod: Fetcher<PaginationResult>
) => {
  const [pageIndex, setPageIndex] = React.useState<number>(0);
  const [error, setError] = React.useState<string>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [pageData, setPageData] = React.useState<
    Record<number, PaginationResult>
  >({});
  const [versionMap, setVersionMap] = React.useState<Record<string, number>>(
    {}
  );

  const paramHash = React.useMemo(() => {
    return JSON.stringify(params);
  }, [params]);

  const currentRequest = { tag: params.tag, pageIndex };

  const latestRequestRef = React.useRef<{
    tag: string;
    pageIndex: number;
    version: number;
  }>(null);

  // Check if there is already a request in flight for the same tag and pageIndex
  const isRequestInFlight = React.useMemo(() => {
    return (
      latestRequestRef.current &&
      latestRequestRef.current.tag === currentRequest.tag &&
      latestRequestRef.current.pageIndex === currentRequest.pageIndex
    );
  }, [currentRequest]);

  const fetchData = React.useCallback(async () => {
    if (isRequestInFlight) {
      latestRequestRef.current.version += 1;
    } else {
      latestRequestRef.current = { ...currentRequest, version: 0 };
    }
    const nextVersionMap = {
      ...versionMap,
      [params.tag]: latestRequestRef.current.version,
    };
    setVersionMap(nextVersionMap);

    try {
      setLoading(true);
      const query = keyMethod({
        tag: params.tag,
        initialQuery: params.initialQuery,
        pageIndex,
        previousPageData: pageData[pageIndex - 1] || null,
      });

      const result = await apiMethod(query);
      setError(undefined);

      // only write to state if this is the latest request
      if (
        latestRequestRef.current.version === R.prop(params.tag, nextVersionMap)
      ) {
        setPageData((prevPageData) => ({
          ...prevPageData,
          [pageIndex]: result,
        }));
      }
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }, [keyMethod, params.tag, paramHash, pageIndex]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const d: T[] = React.useMemo(
    () =>
      R.pipe(
        R.defaultTo([]),
        R.map(R.prop("results")),
        R.flatten,
        R.reject(R.isNil) as (v: (T | undefined)[]) => T[]
      )(R.values(pageData)),
    [pageData]
  );

  const total = React.useMemo(() => getTotal(pageData), [pageData]);

  const progress = React.useMemo(() => {
    return { current: total, total };
  }, [d, total]);

  const isReady = React.useMemo(() => {
    return !R.isNil(progress.current);
  }, [progress]);

  const isLoading = React.useMemo(() => {
    return R.isNil(total);
  }, [total]);

  const loadMore = React.useCallback(
    () => setPageIndex(R.add(1)),
    [setPageIndex]
  );

  return {
    data: d,
    aggregations: R.pathOr({}, [0, "aggregations"], pageData),
    loading,
    mutate: R.always(undefined),
    error,
    total,
    isReady,
    isLoading,
    progress,
    loadMore,
    pageData,
  };
};
