// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import PropTypes from "prop-types";
import React from "react";

import styled from "styled-components";

import { ChevronLeftIcon } from "@heroicons/react/solid";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { CompactButtonContainer } from "components/library/Button/Compact";
import { SecondaryButtonStyle } from "components/library/Button/style";
import { SelectRowsPerPage } from "./SelectRowsPerPage";
import { Title, Paragraph } from "components/library";

export const PaginateLeftButton = styled(CompactButtonContainer)`
  ${SecondaryButtonStyle};
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right-width: 0px;
`;

export const PageButton = styled(CompactButtonContainer)`
  ${SecondaryButtonStyle};
  border-right-width: 0px;
  border-radius: 0px;
`;

export const PaginateRightButton = styled(CompactButtonContainer)`
  ${SecondaryButtonStyle};
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
`;

const Pagination = ({
  onChange,
  page,
  pageCount,
  pageSize,
  total,
  allowShowAll = true,
}) => {
  const pages = [];
  pages.push(0);
  if (page <= 6) {
    for (let i = 1; i <= page; i++) {
      pages.push(i);
    }
  } else {
    pages.push("...");
    pages.push(page - 4);
    pages.push(page - 3);
    pages.push(page - 2);
    pages.push(page - 1);
    pages.push(page);
  }

  if (pageCount - page <= 7) {
    for (let i = page + 1; i < pageCount; i++) {
      pages.push(i);
    }
  } else {
    pages.push(page + 1);
    pages.push(page + 2);
    pages.push(page + 3);
    pages.push(page + 4);
    pages.push("...");
    pages.push(pageCount - 1);
  }

  return (
    <div className="flex-1 flex items-center justify-between">
      <div className="flex items-center gap-x-4">
        <span className="flex-shrink-0 whitespace-nowrap">
          <Paragraph>
            {total === 0 && <span>Showing </span>}
            {total !== 0 && (
              <span>
                Showing <Title>{page * pageSize + 1}</Title> to{" "}
              </span>
            )}
            <Title>{Math.min((page + 1) * pageSize, total)}</Title> of{" "}
            <Title>{total}</Title> results
          </Paragraph>
        </span>
        <SelectRowsPerPage
          selected={pageSize}
          allowShowAll={allowShowAll}
          onChange={(next) => {
            onChange({ page, pageSize: next });
          }}
        />
      </div>
      <div>
        <nav className="relative z-0 inline-flex" aria-label="Pagination">
          <PaginateLeftButton
            disabled={page - 1 < 0}
            isDisabled={page - 1 < 0}
            onClick={() => {
              onChange({ page: page - 1, pageSize });
            }}
            className="relative inline-flex items-center"
          >
            <span className="sr-only">Previous</span>

            <ChevronLeftIcon className="h-5 w-5" />
          </PaginateLeftButton>
          {pages.map((i) =>
            i === "..." ? (
              <PageButton
                type="button"
                key={i}
                disabled
                className="relative inline-flex items-center"
              >
                ...
              </PageButton>
            ) : (
              <PageButton
                type="button"
                key={i}
                disabled={page === i}
                isDisabled={page === i}
                onClick={() => {
                  onChange({ page: i, pageSize });
                }}
                className="relative inline-flex items-center"
              >
                {i + 1}
              </PageButton>
            )
          )}
          <PaginateRightButton
            type="button"
            onClick={() => {
              onChange({ page: page + 1, pageSize });
            }}
            disabled={page === pageCount - 1}
            isDisabled={page === pageCount - 1}
            className="relative inline-flex items-center"
          >
            <span className="sr-only">Next</span>

            <ChevronRightIcon className="h-5 w-5" />
          </PaginateRightButton>
        </nav>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  page: PropTypes.number,
  pageCount: PropTypes.number,
  total: PropTypes.number,
  onChange: PropTypes.func,
};

export default Pagination;
