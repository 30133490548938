import type { SpecializedModalProps } from "./Modal";

import React from "react";
import { CheckCircleIcon } from "@heroicons/react/solid";

import { Modal } from "./Modal";
import { Sentiment } from "../constant";
import { SentimentIcon } from "./SentimentIcon";

export const SuccessModal: React.FC<SpecializedModalProps> = (props) => {
  const sentiment = Sentiment.Success;

  const icon = (
    <SentimentIcon sentiment={sentiment}>
      <CheckCircleIcon className="h-5 w-5 text-white" />
    </SentimentIcon>
  );

  return <Modal title="Success" {...props} sentiment={sentiment} icon={icon} />;
};
