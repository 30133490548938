import type { BaseInputProps } from "./type";

import React from "react";

import { BaseInput } from "./Base";
import { isNaturalNumber } from "data/report/util";

export interface NaturalNumberInputProps
  extends Omit<BaseInputProps, "onChange" | "value"> {
  onChange?: (next: number, event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: number | undefined;
}

export const NaturalNumberInput: React.FC<NaturalNumberInputProps> = (
  props
) => {
  return (
    <BaseInput
      {...props}
      value={props.value === undefined ? "" : props.value}
      onChange={(next: string, event: React.ChangeEvent<HTMLInputElement>) => {
        if (next === "") {
          props.onChange(undefined, event);
        } else if (isNaturalNumber(Number(next))) {
          props.onChange(Number(next), event);
        }
      }}
    />
  );
};
