import * as R from "ramda";

export function arrayToString<T = unknown>(value: T[]): string {
  return `(${value.join(", ")})`;
}

export function arrayize<T = unknown>(input: T | T[]): T[] {
  return R.is(Array, input) ? (input as T[]) : [input as T];
}

export function stringsToObject(values: string[]): { [key: string]: string } {
  return R.reduce(
    (acc: { [key: string]: string }, key: string) => {
      return R.set(R.lensProp(key), key, acc);
    },
    {},
    values
  );
}

export const groupByUnique = R.curry(
  <T>(field: string | string[], records: T[]) => {
    const path: string[] = arrayize(field);
    const grouped = R.groupBy<T>(R.path(path), records);
    return R.mapObjIndexed(R.head, grouped);
  }
);

export const groupById = groupByUnique("id");
