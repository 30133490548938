/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Region } from './Region';
import {
    RegionFromJSON,
    RegionFromJSONTyped,
    RegionToJSON,
} from './Region';

/**
 * 
 * @export
 * @interface PaybandMetrics
 */
export interface PaybandMetrics {
    /**
     * 
     * @type {Array<Region>}
     * @memberof PaybandMetrics
     */
    _1: Array<Region>;
    /**
     * 
     * @type {Array<Region>}
     * @memberof PaybandMetrics
     */
    _2: Array<Region>;
    /**
     * 
     * @type {Array<Region>}
     * @memberof PaybandMetrics
     */
    _4: Array<Region>;
}

/**
 * Check if a given object implements the PaybandMetrics interface.
 */
export function instanceOfPaybandMetrics(value: object): value is PaybandMetrics {
    if (!('_1' in value) || value['_1'] === undefined) return false;
    if (!('_2' in value) || value['_2'] === undefined) return false;
    if (!('_4' in value) || value['_4'] === undefined) return false;
    return true;
}

export function PaybandMetricsFromJSON(json: any): PaybandMetrics {
    return PaybandMetricsFromJSONTyped(json, false);
}

export function PaybandMetricsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaybandMetrics {
    if (json == null) {
        return json;
    }
    return {
        
        '_1': ((json['1'] as Array<any>).map(RegionFromJSON)),
        '_2': ((json['2'] as Array<any>).map(RegionFromJSON)),
        '_4': ((json['4'] as Array<any>).map(RegionFromJSON)),
    };
}

export function PaybandMetricsToJSON(value?: PaybandMetrics | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        '1': ((value['_1'] as Array<any>).map(RegionToJSON)),
        '2': ((value['_2'] as Array<any>).map(RegionToJSON)),
        '4': ((value['_4'] as Array<any>).map(RegionToJSON)),
    };
}

