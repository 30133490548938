/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateEmployeeDto,
  HydratedEmployee,
  PaginationResult,
  QueryDTO,
} from '../models/index';
import {
    CreateEmployeeDtoFromJSON,
    CreateEmployeeDtoToJSON,
    HydratedEmployeeFromJSON,
    HydratedEmployeeToJSON,
    PaginationResultFromJSON,
    PaginationResultToJSON,
    QueryDTOFromJSON,
    QueryDTOToJSON,
} from '../models/index';

export interface CreateEmployeeRequest {
    createEmployeeDto: CreateEmployeeDto;
}

export interface CreateEmployeeBatchRequest {
    requestBody: Array<string>;
}

export interface ExportAsCsvRequest {
    queryDTO: QueryDTO;
}

export interface ExportRawRequest {
    queryDTO: QueryDTO;
}

export interface FindActiveRequest {
    queryDTO: QueryDTO;
}

export interface FindOneEmployeeRequest {
    id: string;
}

export interface GetEmployeeCountRequest {
    queryDTO: QueryDTO;
}

/**
 * 
 */
export class EmployeeApi extends runtime.BaseAPI {

    /**
     * Create Employee
     */
    async createEmployeeRaw(requestParameters: CreateEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HydratedEmployee>> {
        if (requestParameters['createEmployeeDto'] == null) {
            throw new runtime.RequiredError(
                'createEmployeeDto',
                'Required parameter "createEmployeeDto" was null or undefined when calling createEmployee().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/employees`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateEmployeeDtoToJSON(requestParameters['createEmployeeDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HydratedEmployeeFromJSON(jsonValue));
    }

    /**
     * Create Employee
     */
    async createEmployee(requestParameters: CreateEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HydratedEmployee> {
        const response = await this.createEmployeeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createEmployeeBatchRaw(requestParameters: CreateEmployeeBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['requestBody'] == null) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter "requestBody" was null or undefined when calling createEmployeeBatch().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/employees/batch`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createEmployeeBatch(requestParameters: CreateEmployeeBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createEmployeeBatchRaw(requestParameters, initOverrides);
    }

    /**
     */
    async exportAsCsvRaw(requestParameters: ExportAsCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['queryDTO'] == null) {
            throw new runtime.RequiredError(
                'queryDTO',
                'Required parameter "queryDTO" was null or undefined when calling exportAsCsv().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/employees/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QueryDTOToJSON(requestParameters['queryDTO']),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async exportAsCsv(requestParameters: ExportAsCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.exportAsCsvRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exportRawRaw(requestParameters: ExportRawRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['queryDTO'] == null) {
            throw new runtime.RequiredError(
                'queryDTO',
                'Required parameter "queryDTO" was null or undefined when calling exportRaw().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/employees/export/raw`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QueryDTOToJSON(requestParameters['queryDTO']),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async exportRaw(requestParameters: ExportRawRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.exportRawRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for employees
     */
    async findActiveRaw(requestParameters: FindActiveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginationResult>> {
        if (requestParameters['queryDTO'] == null) {
            throw new runtime.RequiredError(
                'queryDTO',
                'Required parameter "queryDTO" was null or undefined when calling findActive().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/employees/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QueryDTOToJSON(requestParameters['queryDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginationResultFromJSON(jsonValue));
    }

    /**
     * Search for employees
     */
    async findActive(requestParameters: FindActiveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginationResult> {
        const response = await this.findActiveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findOneEmployeeRaw(requestParameters: FindOneEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HydratedEmployee>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling findOneEmployee().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/employees/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HydratedEmployeeFromJSON(jsonValue));
    }

    /**
     */
    async findOneEmployee(requestParameters: FindOneEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HydratedEmployee> {
        const response = await this.findOneEmployeeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getEmployeeCountRaw(requestParameters: GetEmployeeCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['queryDTO'] == null) {
            throw new runtime.RequiredError(
                'queryDTO',
                'Required parameter "queryDTO" was null or undefined when calling getEmployeeCount().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/employees/count`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QueryDTOToJSON(requestParameters['queryDTO']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async getEmployeeCount(requestParameters: GetEmployeeCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.getEmployeeCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getEmployeeCountPgRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/employees/countpg`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async getEmployeeCountPg(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getEmployeeCountPgRaw(initOverrides);
    }

    /**
     */
    async getMetadataKeysRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/employees/metadata-keys`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getMetadataKeys(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.getMetadataKeysRaw(initOverrides);
        return await response.value();
    }

}
