import type { Action as ReduxAction } from "redux";

import * as R from "ramda";
import React from "react";
import { isNilOrEmpty } from "@milio/lib/util/nil/util";

export interface Metadata {
  title: string;
  subtitle: string;
}

export interface MetadataManager {
  clear: () => void;
  update: (updates: Partial<Metadata>) => void;
  metadata: Metadata;
}

export interface Action<T> extends ReduxAction<T> {
  payload?: Record<string, unknown>;
}

export const PAGE_VIEW_QUERY_KEY = "view";
const SEGMENT_VALUE_KEY = "segmentValues";

function toSegmentString(segmentValues: string[]) {
  return segmentValues.join(" > ");
}

export function resolvePageSubtitle(query: Record<string, string>): string {
  const view = R.prop(PAGE_VIEW_QUERY_KEY, query);

  const rawSegmentValues = R.propOr([], SEGMENT_VALUE_KEY, query);

  const segmentValues = Array.isArray(rawSegmentValues)
    ? rawSegmentValues
    : [rawSegmentValues];

  if (!isNilOrEmpty(segmentValues)) {
    const segmentString = `Segment - ${toSegmentString(segmentValues)}`;
    return isNilOrEmpty(view) ? segmentString : view + ", " + segmentString;
  }

  return view;
}

const MetadataInitialState = {
  title: "",
  subtitle: "",
};

enum MetadataAction {
  Clear = "metadata.clear",
  Update = "metadata.update",
}

const reducer = (state: Metadata, action: Action<MetadataAction>): Metadata => {
  const { payload } = action;

  switch (action.type) {
    case MetadataAction.Update: {
      return R.mergeRight(state, payload) as Metadata;
    }
    case MetadataAction.Clear: {
      return MetadataInitialState;
    }
    default: {
      return state;
    }
  }
};

export const MetadataContext = React.createContext<{
  dispatch: (action: Action<MetadataAction>) => void;
  state: Metadata;
}>({
  dispatch: () => null,
  state: MetadataInitialState,
});

export const MetadataProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}): React.ReactElement => {
  const [state, dispatch] = React.useReducer(reducer, MetadataInitialState);

  const context = { dispatch, state };

  return (
    <MetadataContext.Provider value={context}>
      {children}
    </MetadataContext.Provider>
  );
};

export const useMetadata = (): MetadataManager => {
  const { state, dispatch } = React.useContext(MetadataContext);

  function update(updates: Partial<Metadata>) {
    dispatch({ type: MetadataAction.Update, payload: updates });
  }

  function clear() {
    dispatch({ type: MetadataAction.Clear });
  }

  return { metadata: state, clear, update };
};
