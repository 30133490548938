import React from "react";
import styled from "styled-components";

import * as R from "ramda";
import * as Style from "style";

export interface HeadingProps {
  children: React.ReactNode;
  color?: string;
}

export interface HeadingSpecializations extends React.FC<HeadingProps> {
  Light: React.FC<HeadingProps>;
  Metric: React.FC<HeadingProps>;
}

export const HeadingContainer = styled.span`
  ${Style.Text.Definition.Heading};
  color: ${Style.Color.Font.Primary};
  line-height: normal;
`;

/**
 * Used for the most important title on a page.
 */
export const Heading: HeadingSpecializations = ({ children }) => {
  return <HeadingContainer>{children}</HeadingContainer>;
};

const LightHeadingContainer = styled.span`
  ${Style.Text.Definition.Heading};
  color: ${Style.Color.White};
`;

/**
 * Used for headings that have dark backgrounds.
 */
const LightHeading: React.FC<HeadingProps> = ({ children }) => {
  return <LightHeadingContainer>{children}</LightHeadingContainer>;
};

const MetricHeadingContainer = styled.span`
  ${Style.Text.Definition.SubHeading};
  color: ${R.prop("color")};
  word-wrap: break-word;
`;

/**
 * Used for metric headings -- used for numbers like KPIs.
 */
const MetricHeading: React.FC<HeadingProps> = ({
  children,
  color = Style.Color.Chart.CornflowerBlue,
}) => {
  return (
    <MetricHeadingContainer color={color}>{children}</MetricHeadingContainer>
  );
};

Heading.Light = LightHeading;
Heading.Metric = MetricHeading;
