/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PeerGroupMetric,
} from '../models/index';
import {
    PeerGroupMetricFromJSON,
    PeerGroupMetricToJSON,
} from '../models/index';

export interface FindByEmployeeRequest {
    id: string;
}

export interface FindByPeerGroupRequest {
    planId: string;
    zoneId: string;
}

/**
 * 
 */
export class PeerGroupMetricApi extends runtime.BaseAPI {

    /**
     */
    async findByEmployeeRaw(requestParameters: FindByEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PeerGroupMetric>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling findByEmployee().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/peer-group-metrics/by-employee/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PeerGroupMetricFromJSON(jsonValue));
    }

    /**
     */
    async findByEmployee(requestParameters: FindByEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PeerGroupMetric> {
        const response = await this.findByEmployeeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findByPeerGroupRaw(requestParameters: FindByPeerGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PeerGroupMetric>>> {
        if (requestParameters['planId'] == null) {
            throw new runtime.RequiredError(
                'planId',
                'Required parameter "planId" was null or undefined when calling findByPeerGroup().'
            );
        }

        if (requestParameters['zoneId'] == null) {
            throw new runtime.RequiredError(
                'zoneId',
                'Required parameter "zoneId" was null or undefined when calling findByPeerGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/peer-group-metrics/by-peer-group/{plan_id}/{zone_id}`.replace(`{${"plan_id"}}`, encodeURIComponent(String(requestParameters['planId']))).replace(`{${"zone_id"}}`, encodeURIComponent(String(requestParameters['zoneId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PeerGroupMetricFromJSON));
    }

    /**
     */
    async findByPeerGroup(requestParameters: FindByPeerGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PeerGroupMetric>> {
        const response = await this.findByPeerGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
