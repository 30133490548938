import React from "react";

import type { IconProps } from "./type";

export const Employee: React.FC<IconProps> = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="100%"
      height="100%"
      viewBox="0 0 256 256"
      stroke="currentColor"
      fill="none"
    >
      <circle
        cx="128"
        cy="96"
        r="64"
        fill="none"
        stroke="currentcolor"
        strokeMiterlimit="10"
        strokeWidth="16"
      ></circle>
      <path
        d="M30.989,215.99064a112.03731,112.03731,0,0,1,194.02311.002"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      ></path>
    </svg>
  );
};
