import type { IconProps } from "components/Icon/type";

import * as R from "ramda";
import React from "react";
import styled from "styled-components";

import * as Style from "style";
import { Sentiment } from "./constant";
import { sentimentToPrimaryColor, sentimentToIcon } from "./util";

export interface SentimentProps {
  sentiment: Sentiment;
}

export interface SentimentTextProps extends SentimentProps {
  children?: React.ReactNode;
}

const SentimentIndicatorContainer = styled.div<{ color: string }>`
  width: ${Style.Text.Size.Base};
  height: ${Style.Text.Size.Base};
  display: inline-block;

  > svg {
    color: ${R.prop("color")};
  }
`;

export const SentimentIndicator: React.FC<SentimentProps> = (props) => {
  const color: string = sentimentToPrimaryColor(props);
  const Icon: React.FC<IconProps> = sentimentToIcon(props);

  return (
    <SentimentIndicatorContainer color={color}>
      <Icon />
    </SentimentIndicatorContainer>
  );
};

const SentimentTextContainer = styled.span<{ color: string }>`
  > * {
    color: ${R.prop("color")} !important;
  }
`;

export const SentimentText: React.FC<SentimentTextProps> = (props) => {
  const color: string =
    props.sentiment === Sentiment.Neutral
      ? "auto"
      : sentimentToPrimaryColor(props);

  return (
    <SentimentTextContainer color={color}>
      {props.children}
    </SentimentTextContainer>
  );
};
