/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmployeeMetric } from './EmployeeMetric';
import {
    EmployeeMetricFromJSON,
    EmployeeMetricFromJSONTyped,
    EmployeeMetricToJSON,
} from './EmployeeMetric';
import type { EmployeeStatus } from './EmployeeStatus';
import {
    EmployeeStatusFromJSON,
    EmployeeStatusFromJSONTyped,
    EmployeeStatusToJSON,
} from './EmployeeStatus';
import type { HydratedPlan } from './HydratedPlan';
import {
    HydratedPlanFromJSON,
    HydratedPlanFromJSONTyped,
    HydratedPlanToJSON,
} from './HydratedPlan';
import type { Benchmark } from './Benchmark';
import {
    BenchmarkFromJSON,
    BenchmarkFromJSONTyped,
    BenchmarkToJSON,
} from './Benchmark';
import type { PerformanceDetails } from './PerformanceDetails';
import {
    PerformanceDetailsFromJSON,
    PerformanceDetailsFromJSONTyped,
    PerformanceDetailsToJSON,
} from './PerformanceDetails';
import type { ManagerDetails } from './ManagerDetails';
import {
    ManagerDetailsFromJSON,
    ManagerDetailsFromJSONTyped,
    ManagerDetailsToJSON,
} from './ManagerDetails';
import type { HydratedZone } from './HydratedZone';
import {
    HydratedZoneFromJSON,
    HydratedZoneFromJSONTyped,
    HydratedZoneToJSON,
} from './HydratedZone';

/**
 * 
 * @export
 * @interface HydratedEmployee
 */
export interface HydratedEmployee {
    /**
     * 
     * @type {number}
     * @memberof HydratedEmployee
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof HydratedEmployee
     */
    employee_id: string;
    /**
     * 
     * @type {string}
     * @memberof HydratedEmployee
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof HydratedEmployee
     */
    email: string;
    /**
     * 
     * @type {number}
     * @memberof HydratedEmployee
     */
    pay: number;
    /**
     * 
     * @type {string}
     * @memberof HydratedEmployee
     */
    pay_rate: HydratedEmployeePayRateEnum;
    /**
     * 
     * @type {number}
     * @memberof HydratedEmployee
     */
    pay_hourly: number;
    /**
     * 
     * @type {string}
     * @memberof HydratedEmployee
     */
    currency: string;
    /**
     * 
     * @type {string}
     * @memberof HydratedEmployee
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof HydratedEmployee
     */
    location: string;
    /**
     * 
     * @type {boolean}
     * @memberof HydratedEmployee
     */
    is_active: boolean;
    /**
     * 
     * @type {string}
     * @memberof HydratedEmployee
     */
    gender: HydratedEmployeeGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof HydratedEmployee
     */
    join_date: string;
    /**
     * 
     * @type {string}
     * @memberof HydratedEmployee
     */
    termination_date: string;
    /**
     * 
     * @type {string}
     * @memberof HydratedEmployee
     */
    image_url: string;
    /**
     * 
     * @type {string}
     * @memberof HydratedEmployee
     */
    job_title_id: string;
    /**
     * 
     * @type {string}
     * @memberof HydratedEmployee
     */
    manager_id: string;
    /**
     * 
     * @type {string}
     * @memberof HydratedEmployee
     */
    department: string;
    /**
     * 
     * @type {string}
     * @memberof HydratedEmployee
     */
    department_id: string;
    /**
     * 
     * @type {string}
     * @memberof HydratedEmployee
     */
    exemption_status: HydratedEmployeeExemptionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof HydratedEmployee
     */
    zone_id: string;
    /**
     * 
     * @type {EmployeeStatus}
     * @memberof HydratedEmployee
     */
    status: EmployeeStatus;
    /**
     * 
     * @type {string}
     * @memberof HydratedEmployee
     */
    remote_id: string;
    /**
     * 
     * @type {string}
     * @memberof HydratedEmployee
     */
    effective_from: string;
    /**
     * 
     * @type {string}
     * @memberof HydratedEmployee
     */
    effective_to: string;
    /**
     * 
     * @type {object}
     * @memberof HydratedEmployee
     */
    metadata: object;
    /**
     * 
     * @type {number}
     * @memberof HydratedEmployee
     */
    comparatio: number;
    /**
     * 
     * @type {string}
     * @memberof HydratedEmployee
     */
    payband_status: HydratedEmployeePaybandStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof HydratedEmployee
     */
    zone_label: string;
    /**
     * 
     * @type {Benchmark}
     * @memberof HydratedEmployee
     */
    benchmark: Benchmark;
    /**
     * 
     * @type {ManagerDetails}
     * @memberof HydratedEmployee
     */
    manager: ManagerDetails;
    /**
     * 
     * @type {EmployeeMetric}
     * @memberof HydratedEmployee
     */
    metrics: EmployeeMetric;
    /**
     * 
     * @type {HydratedPlan}
     * @memberof HydratedEmployee
     */
    plan: HydratedPlan;
    /**
     * 
     * @type {HydratedPlan}
     * @memberof HydratedEmployee
     */
    original_plan: HydratedPlan;
    /**
     * 
     * @type {HydratedZone}
     * @memberof HydratedEmployee
     */
    zone: HydratedZone;
    /**
     * 
     * @type {PerformanceDetails}
     * @memberof HydratedEmployee
     */
    performance: PerformanceDetails;
    /**
     * 
     * @type {number}
     * @memberof HydratedEmployee
     */
    pay_base: number;
    /**
     * 
     * @type {number}
     * @memberof HydratedEmployee
     */
    pay_base_hourly: number;
    /**
     * 
     * @type {number}
     * @memberof HydratedEmployee
     */
    pct_25_base: number;
    /**
     * 
     * @type {number}
     * @memberof HydratedEmployee
     */
    pct_50_base: number;
    /**
     * 
     * @type {number}
     * @memberof HydratedEmployee
     */
    pct_75_base: number;
    /**
     * 
     * @type {number}
     * @memberof HydratedEmployee
     */
    pay_band_min_base: number;
    /**
     * 
     * @type {number}
     * @memberof HydratedEmployee
     */
    pay_band_max_base: number;
    /**
     * 
     * @type {number}
     * @memberof HydratedEmployee
     */
    pay_band_min_base_hourly: number;
    /**
     * 
     * @type {number}
     * @memberof HydratedEmployee
     */
    pay_band_max_base_hourly: number;
}


/**
 * @export
 */
export const HydratedEmployeePayRateEnum = {
    Salary: 'salary',
    Hourly: 'hourly',
    Daily: 'daily'
} as const;
export type HydratedEmployeePayRateEnum = typeof HydratedEmployeePayRateEnum[keyof typeof HydratedEmployeePayRateEnum];

/**
 * @export
 */
export const HydratedEmployeeGenderEnum = {
    Female: 'female',
    Male: 'male',
    NotSpecified: 'not-specified',
    Other: 'other'
} as const;
export type HydratedEmployeeGenderEnum = typeof HydratedEmployeeGenderEnum[keyof typeof HydratedEmployeeGenderEnum];

/**
 * @export
 */
export const HydratedEmployeeExemptionStatusEnum = {
    Exempt: 'exempt',
    NonExempt: 'non-exempt'
} as const;
export type HydratedEmployeeExemptionStatusEnum = typeof HydratedEmployeeExemptionStatusEnum[keyof typeof HydratedEmployeeExemptionStatusEnum];

/**
 * @export
 */
export const HydratedEmployeePaybandStatusEnum = {
    InBand: 'In Band',
    AboveBand: 'Above Band',
    BelowBand: 'Below Band',
    NoPayband: 'No Payband'
} as const;
export type HydratedEmployeePaybandStatusEnum = typeof HydratedEmployeePaybandStatusEnum[keyof typeof HydratedEmployeePaybandStatusEnum];


/**
 * Check if a given object implements the HydratedEmployee interface.
 */
export function instanceOfHydratedEmployee(value: object): value is HydratedEmployee {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('employee_id' in value) || value['employee_id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('pay' in value) || value['pay'] === undefined) return false;
    if (!('pay_rate' in value) || value['pay_rate'] === undefined) return false;
    if (!('pay_hourly' in value) || value['pay_hourly'] === undefined) return false;
    if (!('currency' in value) || value['currency'] === undefined) return false;
    if (!('country' in value) || value['country'] === undefined) return false;
    if (!('location' in value) || value['location'] === undefined) return false;
    if (!('is_active' in value) || value['is_active'] === undefined) return false;
    if (!('gender' in value) || value['gender'] === undefined) return false;
    if (!('join_date' in value) || value['join_date'] === undefined) return false;
    if (!('termination_date' in value) || value['termination_date'] === undefined) return false;
    if (!('image_url' in value) || value['image_url'] === undefined) return false;
    if (!('job_title_id' in value) || value['job_title_id'] === undefined) return false;
    if (!('manager_id' in value) || value['manager_id'] === undefined) return false;
    if (!('department' in value) || value['department'] === undefined) return false;
    if (!('department_id' in value) || value['department_id'] === undefined) return false;
    if (!('exemption_status' in value) || value['exemption_status'] === undefined) return false;
    if (!('zone_id' in value) || value['zone_id'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('remote_id' in value) || value['remote_id'] === undefined) return false;
    if (!('effective_from' in value) || value['effective_from'] === undefined) return false;
    if (!('effective_to' in value) || value['effective_to'] === undefined) return false;
    if (!('metadata' in value) || value['metadata'] === undefined) return false;
    if (!('comparatio' in value) || value['comparatio'] === undefined) return false;
    if (!('payband_status' in value) || value['payband_status'] === undefined) return false;
    if (!('zone_label' in value) || value['zone_label'] === undefined) return false;
    if (!('benchmark' in value) || value['benchmark'] === undefined) return false;
    if (!('manager' in value) || value['manager'] === undefined) return false;
    if (!('metrics' in value) || value['metrics'] === undefined) return false;
    if (!('plan' in value) || value['plan'] === undefined) return false;
    if (!('original_plan' in value) || value['original_plan'] === undefined) return false;
    if (!('zone' in value) || value['zone'] === undefined) return false;
    if (!('performance' in value) || value['performance'] === undefined) return false;
    if (!('pay_base' in value) || value['pay_base'] === undefined) return false;
    if (!('pay_base_hourly' in value) || value['pay_base_hourly'] === undefined) return false;
    if (!('pct_25_base' in value) || value['pct_25_base'] === undefined) return false;
    if (!('pct_50_base' in value) || value['pct_50_base'] === undefined) return false;
    if (!('pct_75_base' in value) || value['pct_75_base'] === undefined) return false;
    if (!('pay_band_min_base' in value) || value['pay_band_min_base'] === undefined) return false;
    if (!('pay_band_max_base' in value) || value['pay_band_max_base'] === undefined) return false;
    if (!('pay_band_min_base_hourly' in value) || value['pay_band_min_base_hourly'] === undefined) return false;
    if (!('pay_band_max_base_hourly' in value) || value['pay_band_max_base_hourly'] === undefined) return false;
    return true;
}

export function HydratedEmployeeFromJSON(json: any): HydratedEmployee {
    return HydratedEmployeeFromJSONTyped(json, false);
}

export function HydratedEmployeeFromJSONTyped(json: any, ignoreDiscriminator: boolean): HydratedEmployee {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'employee_id': json['employee_id'],
        'name': json['name'],
        'email': json['email'],
        'pay': json['pay'],
        'pay_rate': json['pay_rate'],
        'pay_hourly': json['pay_hourly'],
        'currency': json['currency'],
        'country': json['country'],
        'location': json['location'],
        'is_active': json['is_active'],
        'gender': json['gender'],
        'join_date': json['join_date'],
        'termination_date': json['termination_date'],
        'image_url': json['image_url'],
        'job_title_id': json['job_title_id'],
        'manager_id': json['manager_id'],
        'department': json['department'],
        'department_id': json['department_id'],
        'exemption_status': json['exemption_status'],
        'zone_id': json['zone_id'],
        'status': EmployeeStatusFromJSON(json['status']),
        'remote_id': json['remote_id'],
        'effective_from': json['effective_from'],
        'effective_to': json['effective_to'],
        'metadata': json['metadata'],
        'comparatio': json['comparatio'],
        'payband_status': json['payband_status'],
        'zone_label': json['zone_label'],
        'benchmark': BenchmarkFromJSON(json['benchmark']),
        'manager': ManagerDetailsFromJSON(json['manager']),
        'metrics': EmployeeMetricFromJSON(json['metrics']),
        'plan': HydratedPlanFromJSON(json['plan']),
        'original_plan': HydratedPlanFromJSON(json['original_plan']),
        'zone': HydratedZoneFromJSON(json['zone']),
        'performance': PerformanceDetailsFromJSON(json['performance']),
        'pay_base': json['pay_base'],
        'pay_base_hourly': json['pay_base_hourly'],
        'pct_25_base': json['pct_25_base'],
        'pct_50_base': json['pct_50_base'],
        'pct_75_base': json['pct_75_base'],
        'pay_band_min_base': json['pay_band_min_base'],
        'pay_band_max_base': json['pay_band_max_base'],
        'pay_band_min_base_hourly': json['pay_band_min_base_hourly'],
        'pay_band_max_base_hourly': json['pay_band_max_base_hourly'],
    };
}

export function HydratedEmployeeToJSON(value?: HydratedEmployee | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'employee_id': value['employee_id'],
        'name': value['name'],
        'email': value['email'],
        'pay': value['pay'],
        'pay_rate': value['pay_rate'],
        'pay_hourly': value['pay_hourly'],
        'currency': value['currency'],
        'country': value['country'],
        'location': value['location'],
        'is_active': value['is_active'],
        'gender': value['gender'],
        'join_date': value['join_date'],
        'termination_date': value['termination_date'],
        'image_url': value['image_url'],
        'job_title_id': value['job_title_id'],
        'manager_id': value['manager_id'],
        'department': value['department'],
        'department_id': value['department_id'],
        'exemption_status': value['exemption_status'],
        'zone_id': value['zone_id'],
        'status': EmployeeStatusToJSON(value['status']),
        'remote_id': value['remote_id'],
        'effective_from': value['effective_from'],
        'effective_to': value['effective_to'],
        'metadata': value['metadata'],
        'comparatio': value['comparatio'],
        'payband_status': value['payband_status'],
        'zone_label': value['zone_label'],
        'benchmark': BenchmarkToJSON(value['benchmark']),
        'manager': ManagerDetailsToJSON(value['manager']),
        'metrics': EmployeeMetricToJSON(value['metrics']),
        'plan': HydratedPlanToJSON(value['plan']),
        'original_plan': HydratedPlanToJSON(value['original_plan']),
        'zone': HydratedZoneToJSON(value['zone']),
        'performance': PerformanceDetailsToJSON(value['performance']),
        'pay_base': value['pay_base'],
        'pay_base_hourly': value['pay_base_hourly'],
        'pct_25_base': value['pct_25_base'],
        'pct_50_base': value['pct_50_base'],
        'pct_75_base': value['pct_75_base'],
        'pay_band_min_base': value['pay_band_min_base'],
        'pay_band_max_base': value['pay_band_max_base'],
        'pay_band_min_base_hourly': value['pay_band_min_base_hourly'],
        'pay_band_max_base_hourly': value['pay_band_max_base_hourly'],
    };
}

