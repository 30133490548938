import { Combobox } from "@headlessui/react";
import * as R from "ramda";
import { MagnifyingGlass } from "components/Icon";
import React from "react";
import cn from "lib/cn";
import { HydratedEmployee, HydratedPlan } from ".generated/models";
import { EmployeeCardInner } from "components/Employee/EmployeeCardSmall";
import { useEmployees } from "hooks/useEmployeeRows";
import { getHydratedEmployeeLink, getPlanLink, getQueryLink } from "util/link";
import { NextRouter, useRouter } from "next/router";
import { usePlans } from "hooks/usePlans";
import debounce from "just-debounce-it";
import { Link, SubTitle, Title } from "components/library";
import { useClientCohortProvider } from "hooks/useClientCohortProvider";
import { DataKey } from "hooks/keys";
import { ResourceType } from "@milio/lib/data/constant";
import { getZoneLabel } from "@milio/lib/schema/employee/util";

export interface SearchBarProps {
  employeeResults?: HydratedEmployee[];
  planResults?: HydratedPlan[];
  query: string;
  setQuery: React.Dispatch<string>;
  router: NextRouter;
}

export const SearchBar: React.FC<SearchBarProps> = ({
  router,
  query,
  setQuery,
  employeeResults = [],
  planResults = [],
}) => {
  const { createSerializedQuery } = useClientCohortProvider();

  const viewAllEmployees = React.useCallback(async () => {
    const id = await createSerializedQuery({ text_query: query, limit: 100 });
    router.push(getQueryLink(ResourceType.Employee, id));
  }, [query, router]);

  const viewAllPlans = React.useCallback(async () => {
    const id = await createSerializedQuery({ text_query: query, limit: 100 });
    router.push(getQueryLink(ResourceType.Plan, id));
  }, [query, router]);

  return (
    <Combobox
      as="div"
      value={null}
      onChange={(next: HydratedEmployee | HydratedPlan) => {
        if ((next as HydratedEmployee).employee_id !== undefined) {
          router.push(
            getHydratedEmployeeLink((next as HydratedEmployee).employee_id)
          );
        } else {
          router.push(getPlanLink((next as HydratedPlan).plan_id));
        }
      }}
    >
      <div className="md:w-96 relative mt-2">
        <Combobox.Input
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={debounce(
            (event: React.ChangeEvent<HTMLInputElement>) =>
              setQuery(event.target.value),
            300
          )}
          displayValue={(result: HydratedEmployee) => result?.name}
          placeholder="Search"
          type="search"
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <MagnifyingGlass
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {employeeResults.length > 0 && (
          <Combobox.Options className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm divide-gray-100 divide-y">
            <div className="py-1">
              <div className="flex justify-between">
                {" "}
                <span className="px-2 py-1 text-gray-400 text-sm">
                  Employees
                </span>
                <span className="px-2 py-1 text-sm">
                  <Link onClick={viewAllEmployees}>View All</Link>
                </span>
              </div>
              {employeeResults.map((result) => (
                <Combobox.Option
                  key={result.id}
                  value={result}
                  className={({ active }) =>
                    cn(
                      "relative cursor-default select-none py-2 pl-3 pr-9",
                      active ? "bg-indigo-600 text-white" : "text-gray-900"
                    )
                  }
                >
                  {({ active }) => (
                    <EmployeeCardInner
                      employee={result}
                      displayPay={false}
                      active={active}
                    />
                  )}
                </Combobox.Option>
              ))}
            </div>

            {planResults.length > 0 && (
              <div className="py-1">
                <div className="flex justify-between">
                  <span className="px-2 py-1 text-gray-400 text-sm">Plans</span>

                  <span className="px-2 py-1 text-sm">
                    <Link onClick={viewAllPlans}>View All</Link>
                  </span>
                </div>
                {planResults.map((result) => (
                  <Combobox.Option
                    key={result.id}
                    value={result}
                    className={({ active }) =>
                      cn(
                        "relative cursor-default select-none py-2 pl-3 pr-9",
                        active ? "bg-indigo-600 text-white" : "text-gray-900"
                      )
                    }
                  >
                    {() => {
                      return (
                        <div className="flex flex-col">
                          <Title>{result.title}</Title>
                          <SubTitle> {getZoneLabel(result as any)}</SubTitle>
                        </div>
                      );
                    }}
                  </Combobox.Option>
                ))}
              </div>
            )}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};

export const SearchBarContainer: React.FC = () => {
  const router = useRouter();
  const [query, setQuery] = React.useState<string>();
  const { active, setQuery: setEmployeeQuery } = useEmployees(
    {
      limit: 5,
      text_query: query,
      fields: ["name", "plan.title", "image_url", "employee_id"],
    },
    `search`
  );

  const { active: plans, setQuery: setPlanQuery } = usePlans(
    {
      limit: 5,
      text_query: query,
      fields: ["title", "plan_id"],
    },
    DataKey.PlanSearch
  );

  React.useEffect(() => {
    setEmployeeQuery({
      limit: 5,
      text_query: query,
      fields: ["name", "plan.title", "image_url", "employee_id"],
    });
    setPlanQuery({ limit: 5, text_query: query, fields: ["title", "plan_id"] });
  }, [query]);

  return (
    <SearchBar
      planResults={R.take(5, plans)}
      employeeResults={active}
      setQuery={setQuery}
      query={query}
      router={router}
    />
  );
};
