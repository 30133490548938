import React from "react";
import * as R from "ramda";
import type { User } from "@frontegg/redux-store";
import { useAuth } from "@frontegg/nextjs";

import { Configuration } from "@api/milio";

export const useApi = <T>(
  ApiClass: new (configuration: Configuration) => T
) => {
  const auth = useAuth();

  const user: User = R.propOr({}, "user", auth);

  const configuration = React.useMemo(
    () =>
      new Configuration({
        basePath: process.env.NEXT_PUBLIC_API_URI,
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      }),
    [user]
  );

  const api = React.useMemo(() => new ApiClass(configuration), []);
  return {
    api,
  };
};
