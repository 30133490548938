import type { Authenticate, AuthenticateOptions } from "data/user/util";
import type { User } from "data/user";

import React from "react";

import { isAuthenticated } from "data/user/util";
import { useAuthentication } from "./AuthenticationProvider";

export interface Auth {
  authenticate: Authenticate;
  user: User;
  isAuthorized: boolean;
  isInitialized: boolean;
  require: (options: AuthenticateOptions) => void;
}

export function useRequireAuth(): Auth {
  const user = useAuthentication();

  const [authenticate] = React.useState<
    (options: AuthenticateOptions) => boolean
  >(isAuthenticated(user));
  const [isAuthorized, setIsAuthorized] = React.useState(false);
  const [isInitialized, setIsInitialized] = React.useState(false);

  const require = React.useCallback((options: AuthenticateOptions) => {
    setIsAuthorized(authenticate(options));
    // We don't want to say someone is unauthorized if authenticate hasn't been called yet.
    setIsInitialized(true);
  }, []);

  return {
    authenticate,
    user,
    isAuthorized,
    isInitialized: user !== null && isInitialized,
    require,
  };
}
