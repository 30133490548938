/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateBenchmarkDto
 */
export interface CreateBenchmarkDto {
    /**
     * 
     * @type {string}
     * @memberof CreateBenchmarkDto
     */
    job_title_id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBenchmarkDto
     */
    zone_id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBenchmarkDto
     */
    survey_date: string;
    /**
     * 
     * @type {number}
     * @memberof CreateBenchmarkDto
     */
    pct_25?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateBenchmarkDto
     */
    pct_50?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateBenchmarkDto
     */
    pct_75?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateBenchmarkDto
     */
    notes?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateBenchmarkDto
     */
    aging_factor?: number;
}

/**
 * Check if a given object implements the CreateBenchmarkDto interface.
 */
export function instanceOfCreateBenchmarkDto(value: object): value is CreateBenchmarkDto {
    if (!('job_title_id' in value) || value['job_title_id'] === undefined) return false;
    if (!('zone_id' in value) || value['zone_id'] === undefined) return false;
    if (!('survey_date' in value) || value['survey_date'] === undefined) return false;
    return true;
}

export function CreateBenchmarkDtoFromJSON(json: any): CreateBenchmarkDto {
    return CreateBenchmarkDtoFromJSONTyped(json, false);
}

export function CreateBenchmarkDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateBenchmarkDto {
    if (json == null) {
        return json;
    }
    return {
        
        'job_title_id': json['job_title_id'],
        'zone_id': json['zone_id'],
        'survey_date': json['survey_date'],
        'pct_25': json['pct_25'] == null ? undefined : json['pct_25'],
        'pct_50': json['pct_50'] == null ? undefined : json['pct_50'],
        'pct_75': json['pct_75'] == null ? undefined : json['pct_75'],
        'notes': json['notes'] == null ? undefined : json['notes'],
        'aging_factor': json['aging_factor'] == null ? undefined : json['aging_factor'],
    };
}

export function CreateBenchmarkDtoToJSON(value?: CreateBenchmarkDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'job_title_id': value['job_title_id'],
        'zone_id': value['zone_id'],
        'survey_date': value['survey_date'],
        'pct_25': value['pct_25'],
        'pct_50': value['pct_50'],
        'pct_75': value['pct_75'],
        'notes': value['notes'],
        'aging_factor': value['aging_factor'],
    };
}

