/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateZoneDto
 */
export interface UpdateZoneDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateZoneDto
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateZoneDto
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateZoneDto
     */
    multiplier?: number;
}

/**
 * Check if a given object implements the UpdateZoneDto interface.
 */
export function instanceOfUpdateZoneDto(value: object): value is UpdateZoneDto {
    return true;
}

export function UpdateZoneDtoFromJSON(json: any): UpdateZoneDto {
    return UpdateZoneDtoFromJSONTyped(json, false);
}

export function UpdateZoneDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateZoneDto {
    if (json == null) {
        return json;
    }
    return {
        
        'country': json['country'] == null ? undefined : json['country'],
        'description': json['description'] == null ? undefined : json['description'],
        'multiplier': json['multiplier'] == null ? undefined : json['multiplier'],
    };
}

export function UpdateZoneDtoToJSON(value?: UpdateZoneDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'country': value['country'],
        'description': value['description'],
        'multiplier': value['multiplier'],
    };
}

