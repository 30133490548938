import type { InlineTooltipProps } from "./Inline";
import type { SpecializedTooltipProps } from "./type";
import type { TooltipProps } from "./Tooltip";
import type { TruncatedTooltipProps } from "./Truncated";
import type { ValidationTooltipProps } from "./Validation";

import React from "react";

import { InfoTooltip } from "./Info";
import { InlineTooltip } from "./Inline";
import { Tooltip as BaseTooltip } from "./Tooltip";
import { TooltipPlacement } from "./constant";
import { TruncatedTooltip } from "./Truncated";
import { ValidationTooltip } from "./Validation";
import { WarningTooltip } from "./Warning";

export interface TooltipSpecializations extends React.FC<TooltipProps> {
  Info: React.FC<SpecializedTooltipProps>;
  Inline: React.FC<InlineTooltipProps>;
  Validation: React.FC<ValidationTooltipProps>;
  Truncated: React.FC<TruncatedTooltipProps>;
  Warning: React.FC<SpecializedTooltipProps>;
  Placement: typeof TooltipPlacement;
}

export const Tooltip: TooltipSpecializations = (props) => {
  return <BaseTooltip {...props} />;
};

Tooltip.Info = InfoTooltip;
Tooltip.Inline = InlineTooltip;
Tooltip.Placement = TooltipPlacement;
Tooltip.Truncated = TruncatedTooltip;
Tooltip.Validation = ValidationTooltip;
Tooltip.Warning = WarningTooltip;
