/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PlanAnalyticsDto,
  PlanMetricsDto,
  PlanOverviewDatum,
  PlanOverviewQueryDto,
  RecentHireDto,
} from '../models/index';
import {
    PlanAnalyticsDtoFromJSON,
    PlanAnalyticsDtoToJSON,
    PlanMetricsDtoFromJSON,
    PlanMetricsDtoToJSON,
    PlanOverviewDatumFromJSON,
    PlanOverviewDatumToJSON,
    PlanOverviewQueryDtoFromJSON,
    PlanOverviewQueryDtoToJSON,
    RecentHireDtoFromJSON,
    RecentHireDtoToJSON,
} from '../models/index';

export interface GetPlanAnalyticsRequest {
    planId: string;
}

export interface GetPlanMetricsRequest {
    planId: string;
}

export interface GetPlanOverviewRequest {
    planOverviewQueryDto: PlanOverviewQueryDto;
}

export interface GetRecentHiresRequest {
    planId: string;
    zoneId: string;
}

/**
 * 
 */
export class MetricsApi extends runtime.BaseAPI {

    /**
     */
    async getPlanAnalyticsRaw(requestParameters: GetPlanAnalyticsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PlanAnalyticsDto>> {
        if (requestParameters['planId'] == null) {
            throw new runtime.RequiredError(
                'planId',
                'Required parameter "planId" was null or undefined when calling getPlanAnalytics().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['planId'] != null) {
            queryParameters['plan_id'] = requestParameters['planId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/metrics/plan-analytics`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlanAnalyticsDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPlanAnalytics(requestParameters: GetPlanAnalyticsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PlanAnalyticsDto> {
        const response = await this.getPlanAnalyticsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPlanMetricsRaw(requestParameters: GetPlanMetricsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PlanMetricsDto>>> {
        if (requestParameters['planId'] == null) {
            throw new runtime.RequiredError(
                'planId',
                'Required parameter "planId" was null or undefined when calling getPlanMetrics().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['planId'] != null) {
            queryParameters['plan_id'] = requestParameters['planId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/metrics/plan-metrics`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PlanMetricsDtoFromJSON));
    }

    /**
     */
    async getPlanMetrics(requestParameters: GetPlanMetricsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PlanMetricsDto>> {
        const response = await this.getPlanMetricsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPlanOverviewRaw(requestParameters: GetPlanOverviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PlanOverviewDatum>>> {
        if (requestParameters['planOverviewQueryDto'] == null) {
            throw new runtime.RequiredError(
                'planOverviewQueryDto',
                'Required parameter "planOverviewQueryDto" was null or undefined when calling getPlanOverview().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/metrics/plan-overview`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PlanOverviewQueryDtoToJSON(requestParameters['planOverviewQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PlanOverviewDatumFromJSON));
    }

    /**
     */
    async getPlanOverview(requestParameters: GetPlanOverviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PlanOverviewDatum>> {
        const response = await this.getPlanOverviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getRecentHiresRaw(requestParameters: GetRecentHiresRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RecentHireDto>> {
        if (requestParameters['planId'] == null) {
            throw new runtime.RequiredError(
                'planId',
                'Required parameter "planId" was null or undefined when calling getRecentHires().'
            );
        }

        if (requestParameters['zoneId'] == null) {
            throw new runtime.RequiredError(
                'zoneId',
                'Required parameter "zoneId" was null or undefined when calling getRecentHires().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['planId'] != null) {
            queryParameters['plan_id'] = requestParameters['planId'];
        }

        if (requestParameters['zoneId'] != null) {
            queryParameters['zone_id'] = requestParameters['zoneId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/metrics/recent-hires`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecentHireDtoFromJSON(jsonValue));
    }

    /**
     */
    async getRecentHires(requestParameters: GetRecentHiresRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RecentHireDto> {
        const response = await this.getRecentHiresRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
