import React from "react";
import cn from "lib/cn";
import styled from "styled-components";
import { useRouter } from "next/router";

import { Anchor, NotificationDot } from "components/library";

import * as Style from "style";

export interface NavigationProps {
  activeMenuIndex?: number;
  className?: string;
  items?: any[];
}

export interface NavigationItemProps {
  Icon: React.VoidFunctionComponent;
  href: string;
  isActive: boolean;
  label: string;
  testID: string;
  hasNotifications: boolean;
}

function toNavigationIconBackgroundColor(): string {
  return "transparent";
}

function toNavigationIconHoverColor(): string {
  return Style.Color.Brand.Primary;
}

function toNavigationIconColor({ isActive }): string {
  return isActive ? Style.Color.Brand.Primary : Style.Color.Font.Secondary;
}

function toNavigationLabelColor({ isActive }): string {
  return isActive ? Style.Color.Brand.Primary : Style.Color.Font.Secondary;
}

function toNavigationItemRowBackground(): string {
  return Style.Color.White;
}

function toNavigationLabelHoverColor(): string {
  return Style.Color.Brand.Primary;
}

export const NavigationIcon = styled.div<{ isActive: boolean }>`
  background: ${toNavigationIconBackgroundColor};
  position: relative;
  border-radius: 5px;
  display: inline-block;
  color: ${toNavigationIconColor};
  margin-right: ${Style.Layout.Padding.ExtraSmall};
  width: 30px;
  height: 30px;
  padding: 6px;
  cursor: pointer;
  flex: none;
`;

export const NavigationLabel = styled.div<{ isActive: boolean }>`
  ${Style.Text.Definition.SubTitle};
  color: ${toNavigationLabelColor};
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const NavigationItemRow = styled.div`
  display: flex;
  align-items: center;
  background: ${toNavigationItemRowBackground};
  border-radius: ${Style.Design.Rounding.Secondary};
  padding: 8px 8px;
  justify-content: start;
  text-align: center;
`;

export const NavigationItemContainer = styled(NavigationItemRow)<{
  isActive: boolean;
}>`
  cursor: pointer;

  &:hover {
    ${NavigationLabel} {
      color: ${toNavigationLabelHoverColor};
    }

    ${NavigationIcon} {
      color: ${toNavigationIconHoverColor};
    }
  }
`;

export const NavigationContainer = styled.nav`
  ${NavigationItemContainer} {
    :last-child {
      margin-bottom: none;
    }
  }
`;

export const NavigationItem: React.FC<NavigationItemProps> = ({
  isActive,
  hasNotifications,
  href,
  label,
  Icon,
  testID,
}) => {
  const router = useRouter();

  return (
    <NavigationItemContainer
      data-test-id={`navigation-${testID}`}
      isActive={isActive}
      onClick={() => router.push(href)}
    >
      <NavigationIcon isActive={isActive}>
        <Icon />
        {hasNotifications && (
          <Anchor.TopRight>
            <NotificationDot.Error />
          </Anchor.TopRight>
        )}
      </NavigationIcon>
      <NavigationLabel isActive={isActive}>{label}</NavigationLabel>
    </NavigationItemContainer>
  );
};

export const Navigation: React.FC<NavigationProps> = ({
  className,
  activeMenuIndex,
  items = [],
}) => (
  <NavigationContainer className={cn(className)}>
    {items.map(
      ({ hasNotifications, href, label, Icon, testID }, index: number) => {
        const isActive: boolean = index === activeMenuIndex;

        return (
          <NavigationItem
            Icon={Icon}
            hasNotifications={hasNotifications}
            href={href}
            isActive={isActive}
            key={index.toString()}
            label={label}
            testID={testID}
          />
        );
      }
    )}
  </NavigationContainer>
);
