/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Benchmark } from './Benchmark';
import {
    BenchmarkFromJSON,
    BenchmarkFromJSONTyped,
    BenchmarkToJSON,
} from './Benchmark';
import type { HydratedJobLevel } from './HydratedJobLevel';
import {
    HydratedJobLevelFromJSON,
    HydratedJobLevelFromJSONTyped,
    HydratedJobLevelToJSON,
} from './HydratedJobLevel';
import type { JobFamily } from './JobFamily';
import {
    JobFamilyFromJSON,
    JobFamilyFromJSONTyped,
    JobFamilyToJSON,
} from './JobFamily';
import type { PlanMetrics } from './PlanMetrics';
import {
    PlanMetricsFromJSON,
    PlanMetricsFromJSONTyped,
    PlanMetricsToJSON,
} from './PlanMetrics';
import type { HydratedZone } from './HydratedZone';
import {
    HydratedZoneFromJSON,
    HydratedZoneFromJSONTyped,
    HydratedZoneToJSON,
} from './HydratedZone';

/**
 * 
 * @export
 * @interface HydratedPlan
 */
export interface HydratedPlan {
    /**
     * 
     * @type {string}
     * @memberof HydratedPlan
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof HydratedPlan
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof HydratedPlan
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof HydratedPlan
     */
    deleted_at: string;
    /**
     * 
     * @type {string}
     * @memberof HydratedPlan
     */
    created_by: string;
    /**
     * 
     * @type {string}
     * @memberof HydratedPlan
     */
    updated_by: string;
    /**
     * 
     * @type {string}
     * @memberof HydratedPlan
     */
    deleted_by: string;
    /**
     * 
     * @type {string}
     * @memberof HydratedPlan
     */
    plan_id: string;
    /**
     * 
     * @type {string}
     * @memberof HydratedPlan
     */
    plan_group_id: string;
    /**
     * 
     * @type {string}
     * @memberof HydratedPlan
     */
    zone_id: string;
    /**
     * 
     * @type {number}
     * @memberof HydratedPlan
     */
    pay_band_min: number;
    /**
     * 
     * @type {number}
     * @memberof HydratedPlan
     */
    pay_band_max: number;
    /**
     * 
     * @type {string}
     * @memberof HydratedPlan
     */
    currency: string;
    /**
     * 
     * @type {string}
     * @memberof HydratedPlan
     */
    job_level_label: string;
    /**
     * 
     * @type {string}
     * @memberof HydratedPlan
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof HydratedPlan
     */
    remote_id: string;
    /**
     * 
     * @type {HydratedJobLevel}
     * @memberof HydratedPlan
     */
    job_level: HydratedJobLevel;
    /**
     * 
     * @type {string}
     * @memberof HydratedPlan
     */
    job_level_id: string;
    /**
     * 
     * @type {JobFamily}
     * @memberof HydratedPlan
     */
    job_family: JobFamily;
    /**
     * 
     * @type {string}
     * @memberof HydratedPlan
     */
    job_family_id: string;
    /**
     * 
     * @type {HydratedZone}
     * @memberof HydratedPlan
     */
    zone: HydratedZone;
    /**
     * 
     * @type {PlanMetrics}
     * @memberof HydratedPlan
     */
    metrics: PlanMetrics;
    /**
     * 
     * @type {number}
     * @memberof HydratedPlan
     */
    pay_band_midpoint: number;
    /**
     * 
     * @type {boolean}
     * @memberof HydratedPlan
     */
    has_payband: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HydratedPlan
     */
    has_benchmark: boolean;
    /**
     * 
     * @type {string}
     * @memberof HydratedPlan
     */
    mapped_plan_id: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof HydratedPlan
     */
    plan_ids: Array<string>;
    /**
     * 
     * @type {Benchmark}
     * @memberof HydratedPlan
     */
    benchmark: Benchmark;
}

/**
 * Check if a given object implements the HydratedPlan interface.
 */
export function instanceOfHydratedPlan(value: object): value is HydratedPlan {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('created_at' in value) || value['created_at'] === undefined) return false;
    if (!('updated_at' in value) || value['updated_at'] === undefined) return false;
    if (!('deleted_at' in value) || value['deleted_at'] === undefined) return false;
    if (!('created_by' in value) || value['created_by'] === undefined) return false;
    if (!('updated_by' in value) || value['updated_by'] === undefined) return false;
    if (!('deleted_by' in value) || value['deleted_by'] === undefined) return false;
    if (!('plan_id' in value) || value['plan_id'] === undefined) return false;
    if (!('plan_group_id' in value) || value['plan_group_id'] === undefined) return false;
    if (!('zone_id' in value) || value['zone_id'] === undefined) return false;
    if (!('pay_band_min' in value) || value['pay_band_min'] === undefined) return false;
    if (!('pay_band_max' in value) || value['pay_band_max'] === undefined) return false;
    if (!('currency' in value) || value['currency'] === undefined) return false;
    if (!('job_level_label' in value) || value['job_level_label'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('remote_id' in value) || value['remote_id'] === undefined) return false;
    if (!('job_level' in value) || value['job_level'] === undefined) return false;
    if (!('job_level_id' in value) || value['job_level_id'] === undefined) return false;
    if (!('job_family' in value) || value['job_family'] === undefined) return false;
    if (!('job_family_id' in value) || value['job_family_id'] === undefined) return false;
    if (!('zone' in value) || value['zone'] === undefined) return false;
    if (!('metrics' in value) || value['metrics'] === undefined) return false;
    if (!('pay_band_midpoint' in value) || value['pay_band_midpoint'] === undefined) return false;
    if (!('has_payband' in value) || value['has_payband'] === undefined) return false;
    if (!('has_benchmark' in value) || value['has_benchmark'] === undefined) return false;
    if (!('mapped_plan_id' in value) || value['mapped_plan_id'] === undefined) return false;
    if (!('plan_ids' in value) || value['plan_ids'] === undefined) return false;
    if (!('benchmark' in value) || value['benchmark'] === undefined) return false;
    return true;
}

export function HydratedPlanFromJSON(json: any): HydratedPlan {
    return HydratedPlanFromJSONTyped(json, false);
}

export function HydratedPlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): HydratedPlan {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created_at': json['created_at'],
        'updated_at': json['updated_at'],
        'deleted_at': json['deleted_at'],
        'created_by': json['created_by'],
        'updated_by': json['updated_by'],
        'deleted_by': json['deleted_by'],
        'plan_id': json['plan_id'],
        'plan_group_id': json['plan_group_id'],
        'zone_id': json['zone_id'],
        'pay_band_min': json['pay_band_min'],
        'pay_band_max': json['pay_band_max'],
        'currency': json['currency'],
        'job_level_label': json['job_level_label'],
        'title': json['title'],
        'remote_id': json['remote_id'],
        'job_level': HydratedJobLevelFromJSON(json['job_level']),
        'job_level_id': json['job_level_id'],
        'job_family': JobFamilyFromJSON(json['job_family']),
        'job_family_id': json['job_family_id'],
        'zone': HydratedZoneFromJSON(json['zone']),
        'metrics': PlanMetricsFromJSON(json['metrics']),
        'pay_band_midpoint': json['pay_band_midpoint'],
        'has_payband': json['has_payband'],
        'has_benchmark': json['has_benchmark'],
        'mapped_plan_id': json['mapped_plan_id'],
        'plan_ids': json['plan_ids'],
        'benchmark': BenchmarkFromJSON(json['benchmark']),
    };
}

export function HydratedPlanToJSON(value?: HydratedPlan | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'created_at': value['created_at'],
        'updated_at': value['updated_at'],
        'deleted_at': value['deleted_at'],
        'created_by': value['created_by'],
        'updated_by': value['updated_by'],
        'deleted_by': value['deleted_by'],
        'plan_id': value['plan_id'],
        'plan_group_id': value['plan_group_id'],
        'zone_id': value['zone_id'],
        'pay_band_min': value['pay_band_min'],
        'pay_band_max': value['pay_band_max'],
        'currency': value['currency'],
        'job_level_label': value['job_level_label'],
        'title': value['title'],
        'remote_id': value['remote_id'],
        'job_level': HydratedJobLevelToJSON(value['job_level']),
        'job_level_id': value['job_level_id'],
        'job_family': JobFamilyToJSON(value['job_family']),
        'job_family_id': value['job_family_id'],
        'zone': HydratedZoneToJSON(value['zone']),
        'metrics': PlanMetricsToJSON(value['metrics']),
        'pay_band_midpoint': value['pay_band_midpoint'],
        'has_payband': value['has_payband'],
        'has_benchmark': value['has_benchmark'],
        'mapped_plan_id': value['mapped_plan_id'],
        'plan_ids': value['plan_ids'],
        'benchmark': BenchmarkToJSON(value['benchmark']),
    };
}

