import type { StandardComponent } from "components/type";

import * as R from "ramda";
import React from "react";
import styled from "styled-components";

import * as Style from "style";

export interface CheckboxProps extends StandardComponent {
  checked?: boolean;
  id?: string;
  isIndeterminate?: boolean;
  onChange: (next: boolean) => void;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

function toCursor({ isDisabled }: CheckboxProps): string {
  return isDisabled ? "not-allowed" : "pointer";
}

function toColor({ isDisabled }: CheckboxProps): string {
  return isDisabled ? Style.Color.Layout.Line : Style.Color.Brand.Primary;
}

export const CheckboxContainer = styled.input<CheckboxProps>`
  border-color: ${Style.Color.Layout.Line};
  border-radius: ${Style.Design.Rounding.Tertiary};
  color: ${toColor};
  cursor: ${toCursor};
  display: flex;
`;

// TODO: rename checked -> value
export const Checkbox: React.FC<CheckboxProps> = ({
  checked = false,
  id,
  isDisabled = false,
  isIndeterminate = false,
  onChange,
  onClick = R.always(undefined),
}) => {
  const ref: React.RefObject<HTMLInputElement> = React.useRef();

  React.useEffect(() => {
    ref.current.indeterminate = isIndeterminate;
  }, [isIndeterminate]);

  return (
    <CheckboxContainer
      ref={ref}
      isDisabled={isDisabled}
      id={id}
      onClick={onClick}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        if (!isDisabled) {
          onChange(event.target.checked);
        }
      }}
      checked={checked}
      type="checkbox"
      className="h-4 w-4"
    />
  );
};
