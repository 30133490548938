import cn from "lib/cn";

import React from "react";

export interface BaseCardContentProps<P = unknown> {
  children?: React.ReactNode | ((props: P) => React.ReactNode);
  className?: string;
}

export interface CardContentProps<P = unknown> {
  className?: string;
  children?: React.ReactNode | ((props: P) => React.ReactNode);
}

export interface ScrollableCardContentProps extends CardContentProps {
  header?: React.ReactNode;
  action?: React.ReactNode;
}

export interface CardContentSpecializations extends React.FC<CardContentProps> {
  Base: React.FC<BaseCardContentProps>;
  Scrollable: React.FC<ScrollableCardContentProps>;
}

export const ScrollableCardContent: React.FC<ScrollableCardContentProps> = ({
  children,
  header,
  action,
}) => {
  return (
    <div className="flex flex-col h-full">
      <div>
        {header && (
          <div
            className={cn(
              "flex items-center p-4",
              action ? "justify-between" : ""
            )}
          >
            {header}
            {action}
          </div>
        )}
      </div>
      <div className="flex-1 p-4 bg-white overflow-auto">{children as any}</div>
    </div>
  );
};

export const CardContent: CardContentSpecializations = ({
  className,
  children,
}) => {
  return <div className={cn("p-4 bg-white", className)}>{children as any}</div>;
};

CardContent.Base = ScrollableCardContent;
CardContent.Scrollable = ScrollableCardContent;
