import type { StandardComponent } from "components/type";
import type { MenuChildrenProps } from "components/library/Menu";

import * as R from "ramda";
import React from "react";
import styled from "styled-components";

import * as Style from "style";
import { InputStyle } from "components/library/Input/style";
import { Menu } from "components/library";
import { RightAlignedDropdownChevron } from "./DropdownChevron";

export interface CurrentSelectionProps extends StandardComponent {
  children: React.ReactNode | ((props: MenuChildrenProps) => React.ReactNode);
  label: string;
  placeholder?: React.ReactNode;
  onToggle: (next: boolean) => void;
  onKeyDown: (event: KeyboardEvent) => void;
}

export const CurrentSelectionContainer = styled.div<{ isActive: boolean }>`
  ${InputStyle};
  display: flex;
  height: ${Style.Layout.Input.Height};
  width: 100%;
  overflow: hidden;
  position: relative;
`;

export const LabelContainer = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: ${Style.Layout.Padding.Medium};
`;

export const PlaceholderContainer = styled.span`
  color: ${Style.Color.Layout.Gray};
`;

export const CurrentSelection = (
  props: CurrentSelectionProps
): React.ReactElement => {
  const {
    onKeyDown,
    onToggle,
    children,
    label,
    testID,
    placeholder,
    isDisabled = false,
  } = props;

  return (
    <Menu
      testID={testID}
      onKeyDown={onKeyDown}
      content={children}
      onToggle={onToggle}
      isDisabled={isDisabled}
    >
      {({ isActive }: MenuChildrenProps) => (
        <div className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6">
          <RightAlignedDropdownChevron />
          <LabelContainer>
            {R.isNil(label) && (
              <PlaceholderContainer>{placeholder}</PlaceholderContainer>
            )}
            {!R.isNil(label) && <>{label}</>}
          </LabelContainer>
        </div>
      )}
    </Menu>
  );
};
