import React from "react";
import { UserApi } from ".generated/apis";
import { DataKey } from "hooks/keys";
import { useData } from "hooks/useData";
import { useApi } from "milio-api/context";

export const useUserContext = () => {
  const { api } = useApi(UserApi);
  const fetcher = React.useCallback(() => api.findMe(), []);
  const { data } = useData(DataKey.UserContext, fetcher);

  return data;
};
