import * as R from "ramda";
import React from "react";
import { useRouter } from "next/router";

import cn from "lib/cn";

export interface LinkProps {
  children: React.ReactNode;
  className?: string;
  href?: string;
  onClick?: () => void;
  target?: string;
  testID?: string;
}

export interface LinkSpecializations extends React.FC<LinkProps> {}

export const Link: LinkSpecializations = ({
  children,
  testID,
  href,
  target,
  onClick = R.always(undefined),
  className = "",
}) => {
  const router = useRouter();

  return (
    <a
      data-test-id={testID}
      target={target}
      className={cn(
        "text-primary rounded-md text-md cursor-pointer hover:text-secondary",
        className
      )}
      href={R.isNil(target) ? undefined : href}
      onClick={() => {
        if (target === "_blank") {
          return;
        }

        !R.isNil(href) ? router.push(href) : onClick();
      }}
    >
      {children}
    </a>
  );
};
