/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateJobLevelDto
 */
export interface CreateJobLevelDto {
    /**
     * 
     * @type {string}
     * @memberof CreateJobLevelDto
     */
    job_level_group_id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateJobLevelDto
     */
    label: string;
    /**
     * 
     * @type {number}
     * @memberof CreateJobLevelDto
     */
    ordinal: number;
}

/**
 * Check if a given object implements the CreateJobLevelDto interface.
 */
export function instanceOfCreateJobLevelDto(value: object): value is CreateJobLevelDto {
    if (!('job_level_group_id' in value) || value['job_level_group_id'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('ordinal' in value) || value['ordinal'] === undefined) return false;
    return true;
}

export function CreateJobLevelDtoFromJSON(json: any): CreateJobLevelDto {
    return CreateJobLevelDtoFromJSONTyped(json, false);
}

export function CreateJobLevelDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateJobLevelDto {
    if (json == null) {
        return json;
    }
    return {
        
        'job_level_group_id': json['job_level_group_id'],
        'label': json['label'],
        'ordinal': json['ordinal'],
    };
}

export function CreateJobLevelDtoToJSON(value?: CreateJobLevelDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'job_level_group_id': value['job_level_group_id'],
        'label': value['label'],
        'ordinal': value['ordinal'],
    };
}

