/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlanMetrics
 */
export interface PlanMetrics {
    /**
     * 
     * @type {number}
     * @memberof PlanMetrics
     */
    employee_count: number;
    /**
     * 
     * @type {number}
     * @memberof PlanMetrics
     */
    total_count: number;
    /**
     * 
     * @type {number}
     * @memberof PlanMetrics
     */
    above_band_count: number;
    /**
     * 
     * @type {number}
     * @memberof PlanMetrics
     */
    below_band_count: number;
    /**
     * 
     * @type {number}
     * @memberof PlanMetrics
     */
    in_band_count: number;
    /**
     * 
     * @type {number}
     * @memberof PlanMetrics
     */
    pay_avg: number;
}

/**
 * Check if a given object implements the PlanMetrics interface.
 */
export function instanceOfPlanMetrics(value: object): value is PlanMetrics {
    if (!('employee_count' in value) || value['employee_count'] === undefined) return false;
    if (!('total_count' in value) || value['total_count'] === undefined) return false;
    if (!('above_band_count' in value) || value['above_band_count'] === undefined) return false;
    if (!('below_band_count' in value) || value['below_band_count'] === undefined) return false;
    if (!('in_band_count' in value) || value['in_band_count'] === undefined) return false;
    if (!('pay_avg' in value) || value['pay_avg'] === undefined) return false;
    return true;
}

export function PlanMetricsFromJSON(json: any): PlanMetrics {
    return PlanMetricsFromJSONTyped(json, false);
}

export function PlanMetricsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanMetrics {
    if (json == null) {
        return json;
    }
    return {
        
        'employee_count': json['employee_count'],
        'total_count': json['total_count'],
        'above_band_count': json['above_band_count'],
        'below_band_count': json['below_band_count'],
        'in_band_count': json['in_band_count'],
        'pay_avg': json['pay_avg'],
    };
}

export function PlanMetricsToJSON(value?: PlanMetrics | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'employee_count': value['employee_count'],
        'total_count': value['total_count'],
        'above_band_count': value['above_band_count'],
        'below_band_count': value['below_band_count'],
        'in_band_count': value['in_band_count'],
        'pay_avg': value['pay_avg'],
    };
}

