import type { SelectPillProps } from "./Select";
import type { SpecializedPillProps, PillProps } from "./type";

import React from "react";

import { ErrorPill } from "./Error";
import { InfoPill } from "./Info";
import { NeutralPill } from "./Neutral";
import { Pill as BasePill } from "./Pill";
import { SelectPill } from "./Select";
import { Sentiment } from "../constant";
import { SuccessPill } from "./Success";
import { WarningPill } from "./Warning";

export * from "./type";

export interface PillSpecializations extends React.FC<PillProps> {
  Error: React.FC<SpecializedPillProps>;
  Info: React.FC<SpecializedPillProps>;
  Neutral: React.FC<SpecializedPillProps>;
  Select: <T>(props: SelectPillProps<T>) => React.ReactElement;
  Success: React.FC<SpecializedPillProps>;
  Warning: React.FC<SpecializedPillProps>;
  Sentiment: typeof Sentiment;
}

export const Pill: PillSpecializations = (props) => {
  return <BasePill {...props} />;
};

Pill.Error = ErrorPill;
Pill.Info = InfoPill;
Pill.Neutral = NeutralPill;
Pill.Success = SuccessPill;
Pill.Warning = WarningPill;
Pill.Select = SelectPill;
Pill.Sentiment = Sentiment;
