export const createFormatter: ({
  style,
  locale,
  currency,
  digits,
}: {
  style: string;
  locale?: string;
  currency?: string;
  digits?: number;
}) => Intl.NumberFormat = ({
  style,
  locale,
  currency = "USD",
  digits = 0,
}): Intl.NumberFormat => {
  locale =
    locale || (typeof window !== "undefined" && navigator?.language) || "en-US";
  return new Intl.NumberFormat(locale, {
    style,
    currency,
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
  });
};
