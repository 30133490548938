export enum Sentiment {
  Error = "error",
  Info = "info",
  Neutral = "neutral",
  Success = "success",
  Warning = "warning",
}

export enum Orientation {
  Horizontal = "horizontal",
  Vertical = "vertical",
}

export enum Alignment {
  Bottom = "bottom",
  Left = "left",
  Right = "right",
  Top = "top",
}

export enum KeyEvent {
  ArrowDown = "ArrowDown",
  ArrowUp = "ArrowUp",
  Enter = "Enter",
}
