import type { StandardComponent } from "components/type";

import React from "react";
import styled from "styled-components";

import * as Style from "style";
import { getUICursorDataProps } from "./useUICursor";

export interface OptionProps extends StandardComponent {
  children: React.ReactNode;
  isActive?: boolean;
  isSelected?: boolean;
  onClick: () => void;
}

function toColor({ isSelected, isActive, isDisabled }: OptionProps): string {
  if (isDisabled) {
    return isSelected ? Style.Color.Brand.Primary : Style.Color.Layout.Line;
  }

  if (isSelected) {
    return Style.Color.Brand.Primary;
  }

  if (isActive) {
    return Style.Color.Font.Primary;
  }

  return Style.Color.Font.Secondary;
}

function toWeight({ isSelected }: OptionProps): string {
  return isSelected ? Style.Text.Weight.Secondary : Style.Text.Weight.Primary;
}

function toBackgroundColor({
  isSelected,
  isActive,
  isDisabled,
}: OptionProps): string {
  if (isDisabled && isActive) {
    return Style.Color.Layout.Background.Secondary;
  }

  if (isDisabled) {
    return Style.Color.White;
  }

  if (isSelected && isActive) {
    return Style.Color.Layout.Background.Secondary;
  }

  if (isSelected) {
    return Style.Color.White;
  }

  if (isActive) {
    return Style.Color.Layout.Background.Primary;
  }

  return Style.Color.White;
}

function toCursor({ isDisabled }): string {
  return isDisabled ? "not-allowed" : "pointer";
}

export const SelectOptionContainer = styled.div<OptionProps>`
  ${Style.Text.Definition.Paragraph};
  color: ${toColor};
  background: ${toBackgroundColor};
  cursor: ${toCursor};
  font-family: ${Style.Text.FontFamily.Primary};
  font-style: ${Style.Text.Style.Primary};
  padding: 4px 6px;
  font-weight: ${toWeight};
  border-radius: ${Style.Design.Rounding.Tertiary};
  margin-bottom: 5px;

  &:hover {
    background: ${Style.Color.Layout.Background.Secondary};
  }
`;

export const Option: React.FC<OptionProps> = ({
  children,
  isSelected = false,
  isActive = false,
  isDisabled = false,
  onClick,
  testID,
}) => {
  return (
    <SelectOptionContainer
      data-test-id={testID}
      isActive={isActive}
      isDisabled={isDisabled}
      isSelected={isSelected}
      onClick={() => {
        if (!isDisabled) {
          onClick();
        }
      }}
      {...getUICursorDataProps({ isActive, isSelected })}
    >
      {children}
    </SelectOptionContainer>
  );
};
