// cache keys for stale-while-revalidate
export enum DataKey {
  Alert = "alert",
  Alerts = "alerts",
  Benchmarks = "benchmarks",
  Customer = "customer",
  Employee = "employee",
  EmployeeCount = "employee-count",
  EmployeeCountByJobFamilyId = "employee-count-by-job-family-id",
  EmployeeHistory = "employee-history",
  EmployeeOptions = "employee-options",
  EmployeeMetadata = "employee-metadata",
  ExchangeRates = "exchange-rates",
  FixedExchangeRates = "fixed-exchange-rates",
  HealthcheckTemplate = "healthcheck-template",
  HealthcheckRun = "healthcheck-run",
  HealthcheckRule = "healthcheck-rule",
  HistoricalEmployees = "historical-employees",
  HistoricalPlans = "historical-plans",
  HistoryDashboard = "history-dashboard",
  JobFamily = "job-family",
  JobGroup = "job-group",
  JobLevel = "job-level",
  JobLevelGroup = "job-level-group",
  Model = "model",
  KPI = "kpi",
  KPISpend = "kpi-spend",
  PeerGroupMetric = "peer-group-metric",
  Performance = "performance",
  Plan = "plan",
  PlanAnalytics = "plan-analytics",
  PlanCount = "payband-count",
  PlanSearch = "plan-search",
  PlanDetail = "plan-detail",
  PlanHistory = "plan-history",
  PlanMetrics = "plan-metrics",
  PlanOverview = "plan-overview",
  Policy = "policy",
  RecentHires = "recent-hires",
  Report = "report",
  ReportNew = "report-new",
  Settings = "settings",
  Task = "task",
  User = "user",
  UserPolicy = "user-policy",
  UserContext = "user-context",
  UserSettings = "user-settings",
  Zone = "zone",
}
