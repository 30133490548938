import type { NotificationDotProps } from "./type";

import * as R from "ramda";
import React from "react";
import styled from "styled-components";

import * as Style from "style";

export const NotificationDotContainer = styled.div<NotificationDotProps>`
  display: inline-block;
  background-color: ${R.prop("color")};
  border: 1px solid ${Style.Color.White};
  height: 10px;
  width: 10px;
  border-radius: 50%;
`;

export const NotificationDot: React.FC<NotificationDotProps> = (props) => {
  return <NotificationDotContainer {...props} />;
};
