/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SpendByGeographyDatum
 */
export interface SpendByGeographyDatum {
    /**
     * 
     * @type {string}
     * @memberof SpendByGeographyDatum
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SpendByGeographyDatum
     */
    color: string;
    /**
     * 
     * @type {number}
     * @memberof SpendByGeographyDatum
     */
    total: number;
}

/**
 * Check if a given object implements the SpendByGeographyDatum interface.
 */
export function instanceOfSpendByGeographyDatum(value: object): value is SpendByGeographyDatum {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('color' in value) || value['color'] === undefined) return false;
    if (!('total' in value) || value['total'] === undefined) return false;
    return true;
}

export function SpendByGeographyDatumFromJSON(json: any): SpendByGeographyDatum {
    return SpendByGeographyDatumFromJSONTyped(json, false);
}

export function SpendByGeographyDatumFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpendByGeographyDatum {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'color': json['color'],
        'total': json['total'],
    };
}

export function SpendByGeographyDatumToJSON(value?: SpendByGeographyDatum | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'color': value['color'],
        'total': value['total'],
    };
}

