/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ResolutionMethod = {
    BuiltIn: 'built-in',
    Scripted: 'scripted'
} as const;
export type ResolutionMethod = typeof ResolutionMethod[keyof typeof ResolutionMethod];


export function instanceOfResolutionMethod(value: any): boolean {
    for (const key in ResolutionMethod) {
        if (Object.prototype.hasOwnProperty.call(ResolutionMethod, key)) {
            if (ResolutionMethod[key as keyof typeof ResolutionMethod] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ResolutionMethodFromJSON(json: any): ResolutionMethod {
    return ResolutionMethodFromJSONTyped(json, false);
}

export function ResolutionMethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResolutionMethod {
    return json as ResolutionMethod;
}

export function ResolutionMethodToJSON(value?: ResolutionMethod | null): any {
    return value as any;
}

