import React from "react";
import * as R from "ramda";

import { Avatar, SubTitle } from "components/library";
import { createFormatter } from "lib/numberFormatter";
import { DECIMAL_PRECISION } from "util/format";
import { HydratedEmployee } from ".generated/models";
import cn from "lib/cn";

export interface EmployeeCardProps {
  displayPay?: boolean;
  employee: HydratedEmployee;
  hourly?: boolean;
  settings?: HasBaseCurrency;
  active?: boolean;
}

interface HasBaseCurrency {
  base_currency: string;
}

export const EmployeeCardInner: React.FC<EmployeeCardProps> = ({
  displayPay = true,
  employee,
  hourly = false,
  settings = {},
  active = false,
}) => {
  const currencyFormatter = React.useMemo(() => {
    return createFormatter({
      style: "currency",
      currency: employee.currency,
      digits: DECIMAL_PRECISION,
    });
  }, [employee.currency]);

  const baseCurrencyFormatter = React.useMemo(() => {
    return createFormatter({
      style: "currency",
      currency: settings?.base_currency,
      digits: DECIMAL_PRECISION,
    });
  }, [settings?.base_currency]);
  return (
    <div className="relative flex items-center gap-x-2">
      <div className="flex-shrink-0">
        <Avatar src={employee.image_url} name={employee.name} />
      </div>
      <div className="min-w-0 flex-1">
        <a href="#" className="focus:outline-none">
          <span className="absolute inset-0" aria-hidden="true" />
          <p
            className={cn(
              "text-sm font-medium",
              active ? "text-white" : "text-gray-900"
            )}
          >
            {employee.name}
          </p>
          <p
            className={cn(
              "truncate text-sm",
              active ? "text-white" : "text-gray-500"
            )}
          >
            {employee.plan.title}
          </p>
          {displayPay && (
            <>
              <p>
                <SubTitle>
                  {currencyFormatter.format(
                    R.prop(hourly ? "pay_hourly" : "pay", employee)
                  )}
                </SubTitle>
              </p>
              {settings && settings.base_currency !== employee.currency && (
                <p>
                  <SubTitle>
                    {baseCurrencyFormatter.format(
                      R.prop(hourly ? "pay_base_hourly" : "pay_base", employee)
                    )}
                  </SubTitle>
                </p>
              )}
            </>
          )}
        </a>
      </div>
    </div>
  );
};

export const EmployeeCardSmall: React.FC<EmployeeCardProps> = (props) => {
  return (
    <div className="grid grid-cols-1 gap-4">
      <EmployeeCardInner {...props} />
    </div>
  );
};
