import * as R from "ramda";
import Head from "next/head";
import React from "react";
import { AppProps } from "next/app";
import { NextPage } from "next";
import "tailwind/tailwind.css";

import { StandardLayout, StandardProvider } from "components/Layout/index";
import { ErrorBoundary } from "components/ErrorBoundary";

type NextPageWithLayout = NextPage & {
  Layout?: React.FC<{ children: React.ReactNode }>;
  title: string;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const MilioApp: React.FC<AppPropsWithLayout> = ({ Component, pageProps }) => {
  const Layout = R.propOr(
    StandardLayout,
    "Layout",
    Component
  ) as React.JSXElementConstructor<React.PropsWithChildren>;

  return (
    <ErrorBoundary>
      <Head>
        <link rel="shortcut icon" href="/favicon.png" />
        <meta charSet="utf-8" />
      </Head>
      <div id="portal-root" />
      <StandardProvider>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </StandardProvider>
    </ErrorBoundary>
  );
};

export default MilioApp;
