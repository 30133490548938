/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Sample
 */
export interface Sample {
    /**
     * 
     * @type {string}
     * @memberof Sample
     */
    date: string;
    /**
     * 
     * @type {number}
     * @memberof Sample
     */
    value: number;
}

/**
 * Check if a given object implements the Sample interface.
 */
export function instanceOfSample(value: object): value is Sample {
    if (!('date' in value) || value['date'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function SampleFromJSON(json: any): Sample {
    return SampleFromJSONTyped(json, false);
}

export function SampleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Sample {
    if (json == null) {
        return json;
    }
    return {
        
        'date': json['date'],
        'value': json['value'],
    };
}

export function SampleToJSON(value?: Sample | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'date': value['date'],
        'value': value['value'],
    };
}

