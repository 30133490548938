/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreatePolicyDto,
  Policy,
  UpdatePolicyDto,
} from '../models/index';
import {
    CreatePolicyDtoFromJSON,
    CreatePolicyDtoToJSON,
    PolicyFromJSON,
    PolicyToJSON,
    UpdatePolicyDtoFromJSON,
    UpdatePolicyDtoToJSON,
} from '../models/index';

export interface CreatePolicyRequest {
    createPolicyDto: CreatePolicyDto;
}

export interface FindOnePolicyRequest {
    id: string;
}

export interface RemovePolicyRequest {
    id: string;
}

export interface UpdatePolicyRequest {
    id: string;
    updatePolicyDto: UpdatePolicyDto;
}

/**
 * 
 */
export class PoliciesApi extends runtime.BaseAPI {

    /**
     */
    async createPolicyRaw(requestParameters: CreatePolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Policy>> {
        if (requestParameters['createPolicyDto'] == null) {
            throw new runtime.RequiredError(
                'createPolicyDto',
                'Required parameter "createPolicyDto" was null or undefined when calling createPolicy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/policies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePolicyDtoToJSON(requestParameters['createPolicyDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PolicyFromJSON(jsonValue));
    }

    /**
     */
    async createPolicy(requestParameters: CreatePolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Policy> {
        const response = await this.createPolicyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findAllPoliciesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Policy>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/policies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PolicyFromJSON));
    }

    /**
     */
    async findAllPolicies(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Policy>> {
        const response = await this.findAllPoliciesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async findOnePolicyRaw(requestParameters: FindOnePolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Policy>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling findOnePolicy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/policies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PolicyFromJSON(jsonValue));
    }

    /**
     */
    async findOnePolicy(requestParameters: FindOnePolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Policy> {
        const response = await this.findOnePolicyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removePolicyRaw(requestParameters: RemovePolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling removePolicy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/policies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async removePolicy(requestParameters: RemovePolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removePolicyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updatePolicyRaw(requestParameters: UpdatePolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Policy>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updatePolicy().'
            );
        }

        if (requestParameters['updatePolicyDto'] == null) {
            throw new runtime.RequiredError(
                'updatePolicyDto',
                'Required parameter "updatePolicyDto" was null or undefined when calling updatePolicy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/policies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePolicyDtoToJSON(requestParameters['updatePolicyDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PolicyFromJSON(jsonValue));
    }

    /**
     */
    async updatePolicy(requestParameters: UpdatePolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Policy> {
        const response = await this.updatePolicyRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
