import type { NumberLinePoint as INumberLinePoint } from "./type";
import type { Range } from "util/range";

import * as R from "ramda";
import React from "react";
import styled from "styled-components";

import * as Style from "style";
import { Alignment } from "../constant";
import { SubTitle } from "../SubTitle";
import { Tooltip } from "../Tooltip";
import { percentageOfRange, clamp } from "util/range";

export interface NumberLinePointProps {
  depth?: number;
  point: INumberLinePoint;
  range: Range;
  alignment?: Alignment;
}

export interface NumberLinePointContainerProps {
  color: string;
  depth: number;
  offset: number;
}

export const NumberLinePointContainer = styled.div<NumberLinePointContainerProps>`
  background: ${R.prop("color")};
  bottom: 0;
  display: flex;
  left: calc(${R.prop("offset")}% - 4px);
  position: absolute;
  z-index: ${R.prop("depth")};
  border-radius: 50%;
  height: 8px;
  width: 8px;
`;

export interface NumberLinePointLabelProps {
  overflow: number;
  alignment: Alignment;
}

export function toBottom({ alignment }: NumberLinePointLabelProps): string {
  return alignment === Alignment.Bottom ? "-20px" : "6px";
}

export const NumberLinePointLabel = styled.div<NumberLinePointLabelProps>`
  bottom: ${toBottom};
  left: ${R.prop("overflow")}px;
  position: absolute;
  white-space: nowrap;

  span {
    color: ${Style.Color.Layout.Gray};
    font-size: 12px;
  }
`;

function getOverflow({ label, offset }) {
  const parentRect = label.parentElement.parentElement.getBoundingClientRect();
  const labelRect = label.getBoundingClientRect();
  const percent = offset / 100;

  const { width: parentWidth } = parentRect;
  const { width: labelWidth } = labelRect;
  const halfLabelWidth = labelWidth / 2;

  const base = parentWidth * percent - halfLabelWidth;
  const baseWithLabel = base + labelWidth;

  // Overflow right side.
  if (baseWithLabel > parentWidth) {
    return base - halfLabelWidth + 4;
    // Overflow on left side.
  } else if (base < 0) {
    return 0 - 4;
  }

  return base;
}

export const NumberLinePoint: React.FC<NumberLinePointProps> = ({
  point,
  range,
  depth = 0,
  alignment = Alignment.Top,
}) => {
  const ref = React.useRef<HTMLDivElement>();
  const [overflow, setOverflow] = React.useState<number>(0);

  const offset: number =
    percentageOfRange(range, clamp(range, point.value)) * 100;

  const updateOverflow = React.useCallback(() => {
    if (!R.isNil(ref.current)) {
      setOverflow(getOverflow({ label: ref.current, offset }));
    }
  }, [setOverflow, offset, ref]);

  React.useEffect(() => {
    window.addEventListener("resize", updateOverflow);

    updateOverflow();

    return () => {
      window.removeEventListener("resize", updateOverflow);
    };
  }, [updateOverflow, ref]);

  const { label } = point;

  return (
    <Tooltip.Inline tooltip={point.tooltip}>
      {!R.isNil(label) && (
        <NumberLinePointLabel
          ref={ref}
          overflow={overflow}
          alignment={alignment}
        >
          <SubTitle>{label}</SubTitle>
        </NumberLinePointLabel>
      )}
      <NumberLinePointContainer
        depth={depth}
        offset={offset}
        color={point.color}
      />
    </Tooltip.Inline>
  );
};
