export const createNumberFormatter: ({
  style,
  locale,
  currency,
  digits,
}: {
  style: string;
  locale?: string;
  currency?: string;
  digits?: number;
}) => Intl.NumberFormat = ({
  style,
  locale = navigator?.language || "en-US",
  currency = "USD",
  digits = 0,
}): Intl.NumberFormat =>
  new Intl.NumberFormat(locale, {
    style,
    currency,
    maximumFractionDigits: digits,
    minimumFractionDigits: 0,
  });

/**
 * Get the sign symbol for a given value.
 */
export function getSignSymbol(value: number): string {
  if (value === 0) {
    return "";
  }

  return value <= 0 ? "-" : "+";
}

/**
 * Determine the number of decimal places in a string.
 */
export function getDecimalPlaces(value: number | string): number {
  const asString: string = typeof value === "string" ? value : value.toString();

  return (asString.split(".")[1] || []).length;
}
