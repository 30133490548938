import type { BaseButtonProps } from "components/library/Button/Base";

import * as R from "ramda";
import React from "react";
import styled from "styled-components";

import * as Style from "style";
import { Button } from "../Button";
import { Paragraph } from "../Paragraph";
import { Sentiment } from "../constant";
import { sentimentToModal } from "./util";

export interface ConfirmationModalProps {
  action: string;
  description: React.ReactNode;
  onConfirm: () => Promise<void>;
  sentiment?: Sentiment;
  title: string;
}

export const ConfirmationModalContentContainer = styled.div``;

export const ConfirmationModalActions = styled.div`
  column-gap: ${Style.Layout.Padding.ExtraSmall};
  display: flex;
  justify-content: end;
  margin-top: ${Style.Layout.Padding.ExtraSmall};
`;

/**
 * Given a sentiment get a button.
 */
function sentimentToButton(sentiment): React.FC<BaseButtonProps> {
  switch (sentiment) {
    case Sentiment.Error:
      return Button.Danger;
    case Sentiment.Info:
      return Button.Secondary;
    default:
      return Button.Primary;
  }
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  action,
  description,
  onConfirm,
  sentiment = Sentiment.Info,
  title,
  ...props
}) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const Modal = sentimentToModal({ sentiment });
  const ActionButton = sentimentToButton({ sentiment });

  const primaryAction = React.useCallback(async () => {
    setIsLoading(true);

    await onConfirm();

    setIsLoading(false);
  }, [onConfirm, setIsLoading]);

  return (
    <Modal title={title} {...props}>
      {({ close, focusRef }) => {
        return (
          <ConfirmationModalContentContainer>
            <Paragraph>{description}</Paragraph>
            <ConfirmationModalActions>
              <Button.Secondary
                isLoading={isLoading}
                isDisabled={isLoading}
                forwardRef={focusRef}
                onClick={() => close()}
              >
                Cancel
              </Button.Secondary>
              <ActionButton
                isLoading={isLoading}
                isDisabled={isLoading}
                onClick={() => {
                  primaryAction()
                    .then(() => close())
                    .catch(R.identity);
                }}
              >
                {action}
              </ActionButton>
            </ConfirmationModalActions>
          </ConfirmationModalContentContainer>
        );
      }}
    </Modal>
  );
};
