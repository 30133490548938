/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PlanMetadata } from './PlanMetadata';
import {
    PlanMetadataFromJSON,
    PlanMetadataFromJSONTyped,
    PlanMetadataToJSON,
} from './PlanMetadata';

/**
 * 
 * @export
 * @interface PlanMetadataContainer
 */
export interface PlanMetadataContainer {
    /**
     * 
     * @type {PlanMetadata}
     * @memberof PlanMetadataContainer
     */
    plan: PlanMetadata;
}

/**
 * Check if a given object implements the PlanMetadataContainer interface.
 */
export function instanceOfPlanMetadataContainer(value: object): value is PlanMetadataContainer {
    if (!('plan' in value) || value['plan'] === undefined) return false;
    return true;
}

export function PlanMetadataContainerFromJSON(json: any): PlanMetadataContainer {
    return PlanMetadataContainerFromJSONTyped(json, false);
}

export function PlanMetadataContainerFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanMetadataContainer {
    if (json == null) {
        return json;
    }
    return {
        
        'plan': PlanMetadataFromJSON(json['plan']),
    };
}

export function PlanMetadataContainerToJSON(value?: PlanMetadataContainer | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'plan': PlanMetadataToJSON(value['plan']),
    };
}

