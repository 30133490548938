/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MetricValue } from './MetricValue';
import {
    MetricValueFromJSON,
    MetricValueFromJSONTyped,
    MetricValueToJSON,
} from './MetricValue';
import type { AggregationResult } from './AggregationResult';
import {
    AggregationResultFromJSON,
    AggregationResultFromJSONTyped,
    AggregationResultToJSON,
} from './AggregationResult';

/**
 * 
 * @export
 * @interface RegionStatDatum
 */
export interface RegionStatDatum {
    /**
     * 
     * @type {MetricValue}
     * @memberof RegionStatDatum
     */
    avg_pay: MetricValue;
    /**
     * 
     * @type {MetricValue}
     * @memberof RegionStatDatum
     */
    avg_last_promotion_months: MetricValue;
    /**
     * 
     * @type {MetricValue}
     * @memberof RegionStatDatum
     */
    avg_tenure: MetricValue;
    /**
     * 
     * @type {MetricValue}
     * @memberof RegionStatDatum
     */
    min_pay: MetricValue;
    /**
     * 
     * @type {MetricValue}
     * @memberof RegionStatDatum
     */
    max_pay: MetricValue;
    /**
     * 
     * @type {object}
     * @memberof RegionStatDatum
     */
    med_pay: object;
    /**
     * 
     * @type {MetricValue}
     * @memberof RegionStatDatum
     */
    avg_comparatio: MetricValue;
    /**
     * 
     * @type {AggregationResult}
     * @memberof RegionStatDatum
     */
    gender_breakdown: AggregationResult;
    /**
     * 
     * @type {AggregationResult}
     * @memberof RegionStatDatum
     */
    age_group_breakdown: AggregationResult;
    /**
     * 
     * @type {AggregationResult}
     * @memberof RegionStatDatum
     */
    performance_breakdown: AggregationResult;
    /**
     * 
     * @type {number}
     * @memberof RegionStatDatum
     */
    doc_count: number;
}

/**
 * Check if a given object implements the RegionStatDatum interface.
 */
export function instanceOfRegionStatDatum(value: object): value is RegionStatDatum {
    if (!('avg_pay' in value) || value['avg_pay'] === undefined) return false;
    if (!('avg_last_promotion_months' in value) || value['avg_last_promotion_months'] === undefined) return false;
    if (!('avg_tenure' in value) || value['avg_tenure'] === undefined) return false;
    if (!('min_pay' in value) || value['min_pay'] === undefined) return false;
    if (!('max_pay' in value) || value['max_pay'] === undefined) return false;
    if (!('med_pay' in value) || value['med_pay'] === undefined) return false;
    if (!('avg_comparatio' in value) || value['avg_comparatio'] === undefined) return false;
    if (!('gender_breakdown' in value) || value['gender_breakdown'] === undefined) return false;
    if (!('age_group_breakdown' in value) || value['age_group_breakdown'] === undefined) return false;
    if (!('performance_breakdown' in value) || value['performance_breakdown'] === undefined) return false;
    if (!('doc_count' in value) || value['doc_count'] === undefined) return false;
    return true;
}

export function RegionStatDatumFromJSON(json: any): RegionStatDatum {
    return RegionStatDatumFromJSONTyped(json, false);
}

export function RegionStatDatumFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegionStatDatum {
    if (json == null) {
        return json;
    }
    return {
        
        'avg_pay': MetricValueFromJSON(json['avg_pay']),
        'avg_last_promotion_months': MetricValueFromJSON(json['avg_last_promotion_months']),
        'avg_tenure': MetricValueFromJSON(json['avg_tenure']),
        'min_pay': MetricValueFromJSON(json['min_pay']),
        'max_pay': MetricValueFromJSON(json['max_pay']),
        'med_pay': json['med_pay'],
        'avg_comparatio': MetricValueFromJSON(json['avg_comparatio']),
        'gender_breakdown': AggregationResultFromJSON(json['gender_breakdown']),
        'age_group_breakdown': AggregationResultFromJSON(json['age_group_breakdown']),
        'performance_breakdown': AggregationResultFromJSON(json['performance_breakdown']),
        'doc_count': json['doc_count'],
    };
}

export function RegionStatDatumToJSON(value?: RegionStatDatum | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'avg_pay': MetricValueToJSON(value['avg_pay']),
        'avg_last_promotion_months': MetricValueToJSON(value['avg_last_promotion_months']),
        'avg_tenure': MetricValueToJSON(value['avg_tenure']),
        'min_pay': MetricValueToJSON(value['min_pay']),
        'max_pay': MetricValueToJSON(value['max_pay']),
        'med_pay': value['med_pay'],
        'avg_comparatio': MetricValueToJSON(value['avg_comparatio']),
        'gender_breakdown': AggregationResultToJSON(value['gender_breakdown']),
        'age_group_breakdown': AggregationResultToJSON(value['age_group_breakdown']),
        'performance_breakdown': AggregationResultToJSON(value['performance_breakdown']),
        'doc_count': value['doc_count'],
    };
}

