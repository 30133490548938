import type {
  NotificationDotProps,
  SpecializedNotificationDotProps,
} from "./type";

import React from "react";

import { ErrorNotificationDot } from "./Error";
import { InfoNotificationDot } from "./Info";
import { NeutralNotificationDot } from "./Neutral";
import { NotificationDot as BaseNotificationDot } from "./NotificationDot";
import { Sentiment } from "../constant";
import { SuccessNotificationDot } from "./Success";
import { WarningNotificationDot } from "./Warning";

export interface NotificationDotNamespace
  extends React.FC<NotificationDotProps> {
  Error: React.FC<SpecializedNotificationDotProps>;
  Info: React.FC<SpecializedNotificationDotProps>;
  Neutral: React.FC<SpecializedNotificationDotProps>;
  Sentiment: typeof Sentiment;
  Success: React.FC<SpecializedNotificationDotProps>;
  Warning: React.FC<SpecializedNotificationDotProps>;
}

export const NotificationDot: NotificationDotNamespace = (props) => {
  return <BaseNotificationDot {...props} />;
};

NotificationDot.Error = ErrorNotificationDot;
NotificationDot.Info = InfoNotificationDot;
NotificationDot.Neutral = NeutralNotificationDot;
NotificationDot.Sentiment = Sentiment;
NotificationDot.Success = SuccessNotificationDot;
NotificationDot.Warning = WarningNotificationDot;
