import * as R from "ramda";
import React from "react";

import { useApi } from "milio-api/context";
import { EmployeeApi } from ".generated/apis";
import {
  QueryDTO,
  FilterValue,
  HydratedEmployee,
  LogicDefinition,
} from ".generated/models";
import { KeyMethod, useDataPaginated } from "./useDataPaginated";
import { Combinator } from "data/report";
import { ElasticBackedDataHook } from "./type";

const PAGE_SIZE = 100;

export interface EmployeeElasticData
  extends ElasticBackedDataHook<HydratedEmployee> {
  api: EmployeeApi;
  mutate: () => void;
}

export const filterValuesToLogicDefinition = (
  filters: FilterValue[],
  combinator: Combinator = Combinator.And
): LogicDefinition => {
  return {
    groups: [{ filters, combinator }],
    combinators: [],
  };
};

export const getPaginationKey: KeyMethod = (params) => {
  const { pageIndex, previousPageData, initialQuery, tag } = params;

  // reached the end
  if (previousPageData && previousPageData.offset === previousPageData.total)
    return null;

  // first page, we don't have `previousPageData`
  if (pageIndex === 0)
    return R.mergeRight(initialQuery, {
      tag,
      limit: initialQuery.limit || PAGE_SIZE,
    });

  return R.mergeRight(initialQuery, {
    tag,
    limit: initialQuery.limit || PAGE_SIZE,
    offset: previousPageData.offset,
  });
};

export const useEmployees = (
  initialQuery: QueryDTO = {} as QueryDTO,
  tag = "active"
): EmployeeElasticData => {
  const { api: employeeApi } = useApi(EmployeeApi);
  const [query, setQuery] = React.useState<QueryDTO>(initialQuery);

  const {
    data: employees,
    aggregations,
    mutate,
    error,
    progress,
    isReady,
    isLoading,
    loadMore,
  } = useDataPaginated<HydratedEmployee>(
    { tag, initialQuery: query },
    getPaginationKey,
    (params: QueryDTO) => {
      const withoutTag = R.omit(["tag"], params);
      return employeeApi.findActive({ queryDTO: withoutTag });
    }
  );

  const loggedSetQuery = React.useMemo(() => {
    return (next: QueryDTO) => {
      // console.trace(tag, next);
      return setQuery(next);
    };
  }, [setQuery]);

  return {
    api: employeeApi,
    progress,
    aggregations,
    error,
    active: employees,
    mutate,
    isReady,
    isLoading,
    loadMore,
    query,
    setQuery: loggedSetQuery,
  };
};
