import * as R from "ramda";
import React from "react";
import styled from "styled-components";

import * as Style from "style";
import { Title } from "components/library";

export interface AvatarProps {
  src: string;
  name: string;
}

const MAX_INITIALS = 4;

function toBackgroundColor({
  src,
  color,
  imageFailedToLoad,
}: {
  src: string;
  color: string;
  imageFailedToLoad: boolean;
}): string {
  return imageFailedToLoad || R.isNil(src) ? color : "transparent";
}

export const AvatarContainer = styled.div<{
  fontSize: number;
  color: string;
  imageFailedToLoad: boolean;
}>`
  align-items: center;
  background: ${toBackgroundColor};
  border-radius: 50%;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  min-width: 1.5rem;
  min-height: 1.5rem;

  > span {
    font-size: ${R.prop("fontSize")}px;
  }
`;

export const ImageContainer = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

function getInitials(name: string): string {
  return name
    .split(/\s/)
    .map((part) => part.substring(0, 1).toUpperCase())
    .filter((v) => !!v)
    .slice(0, MAX_INITIALS)
    .join("")
    .toUpperCase();
}

// https://en.wikipedia.org/wiki/Linear_congruential_generator
function stringPRNG(input: string, m: number): number {
  const charCodes = Array.from(input).map((letter) => letter.charCodeAt(0));
  const len = charCodes.length;

  const a = (len % (m - 1)) + 1;
  const c = charCodes.reduce((current, next) => current + next) % m;

  let random = charCodes[0] % m;
  for (let i = 0; i < len; i++) random = (a * random + c) % m;

  return random;
}

function getColor(name: string): string {
  const index: number = stringPRNG(name, Style.ChartColors.length);

  return Style.ChartColors[index];
}

function calculateFontSize({
  width,
  characters,
}: {
  width: number;
  characters: number;
}) {
  return width / characters / 1.5;
}

export const Avatar: React.FC<AvatarProps> = ({ src, name }) => {
  const ref = React.useRef<HTMLDivElement>();

  const [imageFailedToLoad, setImageFailedToLoad] =
    React.useState<boolean>(false);
  const [fontSize, setFontSize] = React.useState<number>(10);
  const initials: string = getInitials(name);

  React.useEffect(() => {
    if (ref.current) {
      setFontSize(
        calculateFontSize({
          width: ref.current.clientWidth,
          characters: initials.length,
        })
      );
    }
  }, [ref.current, initials]);

  return (
    <AvatarContainer
      ref={ref}
      src={src}
      color={getColor(name)}
      imageFailedToLoad={imageFailedToLoad}
      fontSize={fontSize}
    >
      <Title.Light>{initials}</Title.Light>
      {!R.isNil(src) && !imageFailedToLoad && (
        <ImageContainer>
          <img
            alt={initials}
            src={src}
            onError={() => setImageFailedToLoad(true)}
          />
        </ImageContainer>
      )}
    </AvatarContainer>
  );
};
