/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TaskState } from './TaskState';
import {
    TaskStateFromJSON,
    TaskStateFromJSONTyped,
    TaskStateToJSON,
} from './TaskState';

/**
 * 
 * @export
 * @interface Task
 */
export interface Task {
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    resource_id: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    resource_type: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    owner_id: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    description: string;
    /**
     * 
     * @type {TaskState}
     * @memberof Task
     */
    state: TaskState;
    /**
     * 
     * @type {Array<string>}
     * @memberof Task
     */
    tags: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    deleted_at: string | null;
}



/**
 * Check if a given object implements the Task interface.
 */
export function instanceOfTask(value: object): value is Task {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('resource_id' in value) || value['resource_id'] === undefined) return false;
    if (!('resource_type' in value) || value['resource_type'] === undefined) return false;
    if (!('owner_id' in value) || value['owner_id'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('state' in value) || value['state'] === undefined) return false;
    if (!('tags' in value) || value['tags'] === undefined) return false;
    if (!('created_at' in value) || value['created_at'] === undefined) return false;
    if (!('updated_at' in value) || value['updated_at'] === undefined) return false;
    if (!('deleted_at' in value) || value['deleted_at'] === undefined) return false;
    return true;
}

export function TaskFromJSON(json: any): Task {
    return TaskFromJSONTyped(json, false);
}

export function TaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): Task {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'resource_id': json['resource_id'],
        'resource_type': json['resource_type'],
        'owner_id': json['owner_id'],
        'title': json['title'],
        'description': json['description'],
        'state': TaskStateFromJSON(json['state']),
        'tags': json['tags'],
        'created_at': json['created_at'],
        'updated_at': json['updated_at'],
        'deleted_at': json['deleted_at'],
    };
}

export function TaskToJSON(value?: Task | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'resource_id': value['resource_id'],
        'resource_type': value['resource_type'],
        'owner_id': value['owner_id'],
        'title': value['title'],
        'description': value['description'],
        'state': TaskStateToJSON(value['state']),
        'tags': value['tags'],
        'created_at': value['created_at'],
        'updated_at': value['updated_at'],
        'deleted_at': value['deleted_at'],
    };
}

