import type { LegendItemProps } from "./Item";
import type { LegendListProps } from "./List";

import React from "react";

import { LegendItem } from "./Item";
import { LegendList } from "./List";

export interface LegendNamespace {
  Item: React.FC<LegendItemProps>;
  List: React.FC<LegendListProps>;
}

export const Legend: LegendNamespace = (props) => {
  return <LegendList {...props} />;
};

Legend.Item = LegendItem;
Legend.List = LegendList;
