/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PeerGroupMetric
 */
export interface PeerGroupMetric {
    /**
     * 
     * @type {number}
     * @memberof PeerGroupMetric
     */
    number_of_employees_in_role_and_country: number;
    /**
     * 
     * @type {number}
     * @memberof PeerGroupMetric
     */
    average_base_comp_for_role_and_country: number;
    /**
     * 
     * @type {number}
     * @memberof PeerGroupMetric
     */
    average_base_comp_for_role_and_country_female: number;
    /**
     * 
     * @type {number}
     * @memberof PeerGroupMetric
     */
    average_base_comp_for_role_and_country_male: number;
    /**
     * 
     * @type {number}
     * @memberof PeerGroupMetric
     */
    average_base_comp_for_role_and_country_not_specified: number;
    /**
     * 
     * @type {number}
     * @memberof PeerGroupMetric
     */
    average_base_comp_for_role_and_country_other: number;
    /**
     * 
     * @type {number}
     * @memberof PeerGroupMetric
     */
    average_compa_ratio_for_role_and_country: number;
    /**
     * 
     * @type {number}
     * @memberof PeerGroupMetric
     */
    average_compa_ratio_for_role_and_country_historical: number;
    /**
     * 
     * @type {number}
     * @memberof PeerGroupMetric
     */
    average_performance_for_role_and_country: number;
    /**
     * 
     * @type {number}
     * @memberof PeerGroupMetric
     */
    average_performance_for_role_and_country_historical: number;
    /**
     * 
     * @type {number}
     * @memberof PeerGroupMetric
     */
    average_tenure_for_role_and_country: number;
    /**
     * 
     * @type {number}
     * @memberof PeerGroupMetric
     */
    average_tenure_for_role_and_country_historical: number;
    /**
     * 
     * @type {number}
     * @memberof PeerGroupMetric
     */
    average_time_till_comp_increase_for_role_and_country: number;
    /**
     * 
     * @type {number}
     * @memberof PeerGroupMetric
     */
    average_time_till_comp_increase_for_role_and_country_historical: number;
    /**
     * 
     * @type {number}
     * @memberof PeerGroupMetric
     */
    max_base_comp_for_role_and_country: number;
    /**
     * 
     * @type {number}
     * @memberof PeerGroupMetric
     */
    max_compa_ratio_for_role_and_country: number;
    /**
     * 
     * @type {number}
     * @memberof PeerGroupMetric
     */
    max_performance_for_role_and_country: number;
    /**
     * 
     * @type {number}
     * @memberof PeerGroupMetric
     */
    max_tenure_for_role_and_country: number;
    /**
     * 
     * @type {number}
     * @memberof PeerGroupMetric
     */
    max_time_till_comp_increase_for_role_and_country: number;
    /**
     * 
     * @type {number}
     * @memberof PeerGroupMetric
     */
    min_base_comp_for_role_and_country: number;
    /**
     * 
     * @type {number}
     * @memberof PeerGroupMetric
     */
    min_compa_ratio_for_role_and_country: number;
    /**
     * 
     * @type {number}
     * @memberof PeerGroupMetric
     */
    min_tenure_for_role_and_country: number;
    /**
     * 
     * @type {number}
     * @memberof PeerGroupMetric
     */
    min_time_till_comp_increase_for_role_and_country: number;
}

/**
 * Check if a given object implements the PeerGroupMetric interface.
 */
export function instanceOfPeerGroupMetric(value: object): value is PeerGroupMetric {
    if (!('number_of_employees_in_role_and_country' in value) || value['number_of_employees_in_role_and_country'] === undefined) return false;
    if (!('average_base_comp_for_role_and_country' in value) || value['average_base_comp_for_role_and_country'] === undefined) return false;
    if (!('average_base_comp_for_role_and_country_female' in value) || value['average_base_comp_for_role_and_country_female'] === undefined) return false;
    if (!('average_base_comp_for_role_and_country_male' in value) || value['average_base_comp_for_role_and_country_male'] === undefined) return false;
    if (!('average_base_comp_for_role_and_country_not_specified' in value) || value['average_base_comp_for_role_and_country_not_specified'] === undefined) return false;
    if (!('average_base_comp_for_role_and_country_other' in value) || value['average_base_comp_for_role_and_country_other'] === undefined) return false;
    if (!('average_compa_ratio_for_role_and_country' in value) || value['average_compa_ratio_for_role_and_country'] === undefined) return false;
    if (!('average_compa_ratio_for_role_and_country_historical' in value) || value['average_compa_ratio_for_role_and_country_historical'] === undefined) return false;
    if (!('average_performance_for_role_and_country' in value) || value['average_performance_for_role_and_country'] === undefined) return false;
    if (!('average_performance_for_role_and_country_historical' in value) || value['average_performance_for_role_and_country_historical'] === undefined) return false;
    if (!('average_tenure_for_role_and_country' in value) || value['average_tenure_for_role_and_country'] === undefined) return false;
    if (!('average_tenure_for_role_and_country_historical' in value) || value['average_tenure_for_role_and_country_historical'] === undefined) return false;
    if (!('average_time_till_comp_increase_for_role_and_country' in value) || value['average_time_till_comp_increase_for_role_and_country'] === undefined) return false;
    if (!('average_time_till_comp_increase_for_role_and_country_historical' in value) || value['average_time_till_comp_increase_for_role_and_country_historical'] === undefined) return false;
    if (!('max_base_comp_for_role_and_country' in value) || value['max_base_comp_for_role_and_country'] === undefined) return false;
    if (!('max_compa_ratio_for_role_and_country' in value) || value['max_compa_ratio_for_role_and_country'] === undefined) return false;
    if (!('max_performance_for_role_and_country' in value) || value['max_performance_for_role_and_country'] === undefined) return false;
    if (!('max_tenure_for_role_and_country' in value) || value['max_tenure_for_role_and_country'] === undefined) return false;
    if (!('max_time_till_comp_increase_for_role_and_country' in value) || value['max_time_till_comp_increase_for_role_and_country'] === undefined) return false;
    if (!('min_base_comp_for_role_and_country' in value) || value['min_base_comp_for_role_and_country'] === undefined) return false;
    if (!('min_compa_ratio_for_role_and_country' in value) || value['min_compa_ratio_for_role_and_country'] === undefined) return false;
    if (!('min_tenure_for_role_and_country' in value) || value['min_tenure_for_role_and_country'] === undefined) return false;
    if (!('min_time_till_comp_increase_for_role_and_country' in value) || value['min_time_till_comp_increase_for_role_and_country'] === undefined) return false;
    return true;
}

export function PeerGroupMetricFromJSON(json: any): PeerGroupMetric {
    return PeerGroupMetricFromJSONTyped(json, false);
}

export function PeerGroupMetricFromJSONTyped(json: any, ignoreDiscriminator: boolean): PeerGroupMetric {
    if (json == null) {
        return json;
    }
    return {
        
        'number_of_employees_in_role_and_country': json['number_of_employees_in_role_and_country'],
        'average_base_comp_for_role_and_country': json['average_base_comp_for_role_and_country'],
        'average_base_comp_for_role_and_country_female': json['average_base_comp_for_role_and_country_female'],
        'average_base_comp_for_role_and_country_male': json['average_base_comp_for_role_and_country_male'],
        'average_base_comp_for_role_and_country_not_specified': json['average_base_comp_for_role_and_country_not_specified'],
        'average_base_comp_for_role_and_country_other': json['average_base_comp_for_role_and_country_other'],
        'average_compa_ratio_for_role_and_country': json['average_compa_ratio_for_role_and_country'],
        'average_compa_ratio_for_role_and_country_historical': json['average_compa_ratio_for_role_and_country_historical'],
        'average_performance_for_role_and_country': json['average_performance_for_role_and_country'],
        'average_performance_for_role_and_country_historical': json['average_performance_for_role_and_country_historical'],
        'average_tenure_for_role_and_country': json['average_tenure_for_role_and_country'],
        'average_tenure_for_role_and_country_historical': json['average_tenure_for_role_and_country_historical'],
        'average_time_till_comp_increase_for_role_and_country': json['average_time_till_comp_increase_for_role_and_country'],
        'average_time_till_comp_increase_for_role_and_country_historical': json['average_time_till_comp_increase_for_role_and_country_historical'],
        'max_base_comp_for_role_and_country': json['max_base_comp_for_role_and_country'],
        'max_compa_ratio_for_role_and_country': json['max_compa_ratio_for_role_and_country'],
        'max_performance_for_role_and_country': json['max_performance_for_role_and_country'],
        'max_tenure_for_role_and_country': json['max_tenure_for_role_and_country'],
        'max_time_till_comp_increase_for_role_and_country': json['max_time_till_comp_increase_for_role_and_country'],
        'min_base_comp_for_role_and_country': json['min_base_comp_for_role_and_country'],
        'min_compa_ratio_for_role_and_country': json['min_compa_ratio_for_role_and_country'],
        'min_tenure_for_role_and_country': json['min_tenure_for_role_and_country'],
        'min_time_till_comp_increase_for_role_and_country': json['min_time_till_comp_increase_for_role_and_country'],
    };
}

export function PeerGroupMetricToJSON(value?: PeerGroupMetric | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'number_of_employees_in_role_and_country': value['number_of_employees_in_role_and_country'],
        'average_base_comp_for_role_and_country': value['average_base_comp_for_role_and_country'],
        'average_base_comp_for_role_and_country_female': value['average_base_comp_for_role_and_country_female'],
        'average_base_comp_for_role_and_country_male': value['average_base_comp_for_role_and_country_male'],
        'average_base_comp_for_role_and_country_not_specified': value['average_base_comp_for_role_and_country_not_specified'],
        'average_base_comp_for_role_and_country_other': value['average_base_comp_for_role_and_country_other'],
        'average_compa_ratio_for_role_and_country': value['average_compa_ratio_for_role_and_country'],
        'average_compa_ratio_for_role_and_country_historical': value['average_compa_ratio_for_role_and_country_historical'],
        'average_performance_for_role_and_country': value['average_performance_for_role_and_country'],
        'average_performance_for_role_and_country_historical': value['average_performance_for_role_and_country_historical'],
        'average_tenure_for_role_and_country': value['average_tenure_for_role_and_country'],
        'average_tenure_for_role_and_country_historical': value['average_tenure_for_role_and_country_historical'],
        'average_time_till_comp_increase_for_role_and_country': value['average_time_till_comp_increase_for_role_and_country'],
        'average_time_till_comp_increase_for_role_and_country_historical': value['average_time_till_comp_increase_for_role_and_country_historical'],
        'max_base_comp_for_role_and_country': value['max_base_comp_for_role_and_country'],
        'max_compa_ratio_for_role_and_country': value['max_compa_ratio_for_role_and_country'],
        'max_performance_for_role_and_country': value['max_performance_for_role_and_country'],
        'max_tenure_for_role_and_country': value['max_tenure_for_role_and_country'],
        'max_time_till_comp_increase_for_role_and_country': value['max_time_till_comp_increase_for_role_and_country'],
        'min_base_comp_for_role_and_country': value['min_base_comp_for_role_and_country'],
        'min_compa_ratio_for_role_and_country': value['min_compa_ratio_for_role_and_country'],
        'min_tenure_for_role_and_country': value['min_tenure_for_role_and_country'],
        'min_time_till_comp_increase_for_role_and_country': value['min_time_till_comp_increase_for_role_and_country'],
    };
}

