import type { MenuChildrenProps } from "components/library/Menu";
import type { Option } from "components/library/Select/type";
import type { SpecializedPillProps } from "./type";
import type { StandardComponent } from "components/type";

import * as R from "ramda";
import React from "react";
import styled from "styled-components";

import * as Style from "style";
import { ExpandHandle } from "./ExpandHandle";
import { Menu } from "components/library/Menu";
import { NeutralPill } from "./Neutral";
import { Tooltip } from "components/library/Tooltip";

export interface PillOption {
  Pill: React.FC<SpecializedPillProps>;
  label: string;
}

export type PillOptionMap = { [key: string]: PillOption };

export interface SelectPillProps<T> extends StandardComponent {
  onChange: (next: T) => void;
  options: Option<T>[];
  placeholder?: string;
  value: string;
  pills: PillOptionMap;
}

export const ExpandHandleContainer = styled.span`
  height: 15px;
  margin-left: 5px;
  width: 15px;

  svg {
    color: ${Style.Color.White};
    width: 15px;
    height: 15px;
  }
`;

export const PillLabelContainer = styled.span`
  ${Style.Text.Definition.Truncate};
  display: flex;
  flex: 1;
`;

export const PillContainer = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export function SelectPill<T>({
  isDisabled = false,
  onChange,
  options,
  placeholder = "Select...",
  value,
  pills,
}: SelectPillProps<T>): React.ReactElement {
  const pillOption = R.propOr(
    {
      Pill: NeutralPill,
      label: placeholder,
    },
    value,
    pills
  ) as PillOption;

  return (
    <Menu
      isDisabled={isDisabled}
      content={({ close }) => (
        <Menu.Items>
          {R.map((option: Option<T>) => {
            return (
              <Menu.Item
                key={option.value.toString()}
                isDisabled={isDisabled}
                onClick={() => {
                  onChange(option.value);

                  close();
                }}
              >
                {option.label}
              </Menu.Item>
            );
          }, options)}
        </Menu.Items>
      )}
    >
      {({ isActive, close }: MenuChildrenProps) => (
        <pillOption.Pill>
          <PillContainer>
            <PillLabelContainer>
              <Tooltip.Truncated tooltip={pillOption.label}>
                {pillOption.label}
              </Tooltip.Truncated>
            </PillLabelContainer>
            <ExpandHandleContainer>
              <ExpandHandle
                isOpen={isActive}
                isDisabled={isDisabled}
                onClick={() => {
                  if (isActive) {
                    close();
                  }
                }}
              />
            </ExpandHandleContainer>
          </PillContainer>
        </pillOption.Pill>
      )}
    </Menu>
  );
}
