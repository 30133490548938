/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateImportResultDto,
} from '../models/index';
import {
    CreateImportResultDtoFromJSON,
    CreateImportResultDtoToJSON,
} from '../models/index';

export interface CreateImportResultRequest {
    createImportResultDto: CreateImportResultDto;
}

export interface FindOneImportResultRequest {
    id: string;
}

export interface RemoveImportResultRequest {
    id: string;
}

/**
 * 
 */
export class ImportResultApi extends runtime.BaseAPI {

    /**
     */
    async createImportResultRaw(requestParameters: CreateImportResultRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['createImportResultDto'] == null) {
            throw new runtime.RequiredError(
                'createImportResultDto',
                'Required parameter "createImportResultDto" was null or undefined when calling createImportResult().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/import-results`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateImportResultDtoToJSON(requestParameters['createImportResultDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createImportResult(requestParameters: CreateImportResultRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createImportResultRaw(requestParameters, initOverrides);
    }

    /**
     */
    async findAllImportResultsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/import-results`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async findAllImportResults(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.findAllImportResultsRaw(initOverrides);
    }

    /**
     */
    async findOneImportResultRaw(requestParameters: FindOneImportResultRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling findOneImportResult().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/import-results/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async findOneImportResult(requestParameters: FindOneImportResultRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.findOneImportResultRaw(requestParameters, initOverrides);
    }

    /**
     */
    async removeImportResultRaw(requestParameters: RemoveImportResultRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling removeImportResult().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/import-results/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async removeImportResult(requestParameters: RemoveImportResultRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeImportResultRaw(requestParameters, initOverrides);
    }

}
