import React from "react";
import LoaderIcon from "react-spinners/PuffLoader";

import * as Style from "style";

interface LoaderProps {
  color?: string;
  size?: number;
  occupy?: React.ReactNode;
}

export const Loader: React.FC<LoaderProps> = ({
  color = Style.Color.Brand.Primary,
  size = 10,
}) => {
  return <LoaderIcon speedMultiplier={2} color={color} loading size={size} />;
};

export const ComponentLoader: React.FC<LoaderProps> = ({
  occupy,
  ...props
}) => {
  if (occupy) {
    return (
      <div className="relative">
        <div className="invisible">{occupy}</div>
        <div className="absolute left-0 top-0 right-0 bottom-0 flex items-center justify-center">
          <Loader {...props} />
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center">
      <Loader {...props} />
    </div>
  );
};
