/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HealthcheckType } from './HealthcheckType';
import {
    HealthcheckTypeFromJSON,
    HealthcheckTypeFromJSONTyped,
    HealthcheckTypeToJSON,
} from './HealthcheckType';
import type { HealthcheckRunResult } from './HealthcheckRunResult';
import {
    HealthcheckRunResultFromJSON,
    HealthcheckRunResultFromJSONTyped,
    HealthcheckRunResultToJSON,
} from './HealthcheckRunResult';
import type { HealthcheckRule } from './HealthcheckRule';
import {
    HealthcheckRuleFromJSON,
    HealthcheckRuleFromJSONTyped,
    HealthcheckRuleToJSON,
} from './HealthcheckRule';
import type { LogicDefinition } from './LogicDefinition';
import {
    LogicDefinitionFromJSON,
    LogicDefinitionFromJSONTyped,
    LogicDefinitionToJSON,
} from './LogicDefinition';

/**
 * 
 * @export
 * @interface HealthcheckTemplate
 */
export interface HealthcheckTemplate {
    /**
     * 
     * @type {string}
     * @memberof HealthcheckTemplate
     */
    id: string;
    /**
     * 
     * @type {LogicDefinition}
     * @memberof HealthcheckTemplate
     */
    cohort?: LogicDefinition;
    /**
     * 
     * @type {HealthcheckType}
     * @memberof HealthcheckTemplate
     */
    type?: HealthcheckType;
    /**
     * 
     * @type {string}
     * @memberof HealthcheckTemplate
     */
    title: string;
    /**
     * 
     * @type {Array<HealthcheckRule>}
     * @memberof HealthcheckTemplate
     */
    rules: Array<HealthcheckRule>;
    /**
     * 
     * @type {Array<HealthcheckRunResult>}
     * @memberof HealthcheckTemplate
     */
    results: Array<HealthcheckRunResult>;
}



/**
 * Check if a given object implements the HealthcheckTemplate interface.
 */
export function instanceOfHealthcheckTemplate(value: object): value is HealthcheckTemplate {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('rules' in value) || value['rules'] === undefined) return false;
    if (!('results' in value) || value['results'] === undefined) return false;
    return true;
}

export function HealthcheckTemplateFromJSON(json: any): HealthcheckTemplate {
    return HealthcheckTemplateFromJSONTyped(json, false);
}

export function HealthcheckTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): HealthcheckTemplate {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'cohort': json['cohort'] == null ? undefined : LogicDefinitionFromJSON(json['cohort']),
        'type': json['type'] == null ? undefined : HealthcheckTypeFromJSON(json['type']),
        'title': json['title'],
        'rules': ((json['rules'] as Array<any>).map(HealthcheckRuleFromJSON)),
        'results': ((json['results'] as Array<any>).map(HealthcheckRunResultFromJSON)),
    };
}

export function HealthcheckTemplateToJSON(value?: HealthcheckTemplate | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'cohort': LogicDefinitionToJSON(value['cohort']),
        'type': HealthcheckTypeToJSON(value['type']),
        'title': value['title'],
        'rules': ((value['rules'] as Array<any>).map(HealthcheckRuleToJSON)),
        'results': ((value['results'] as Array<any>).map(HealthcheckRunResultToJSON)),
    };
}

