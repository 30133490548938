import React from "react";

import type { IconProps } from "./type";

export const CaretCircleUp: React.FC<IconProps> = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="100%"
      height="100%"
      viewBox="0 0 256 256"
      stroke="currentColor"
      fill="none"
    >
      <circle
        cx="128"
        cy="128"
        r="96"
        fill="none"
        stroke="currentcolor"
        strokeMiterlimit="10"
        strokeWidth="16"
      ></circle>
      <polyline
        points="164 144 128 104 92 144"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      ></polyline>
    </svg>
  );
};
