import type { BaseInputProps } from "../Input/type";
import type { CurrencyInputProps } from "../Input/Currency";
import type { FormikComponentProps } from "./type";
import type { LabelProps } from "../Label";
import type { LabelledSliderProps } from "../Slider";
import type { NaturalNumberInputProps } from "../Input/NaturalNumber";
import type { Options } from "../Select/type";
import type { PercentInputProps } from "../Input/Percent";
import type { RationalNumberInputProps } from "../Input/RationalNumber";
import type { TextareaProps } from "../Textarea";

import React from "react";

import { BaseInput } from "../Input/Base";
import { CurrencyInput as BaseCurrencyInput } from "../Input/Currency";
import { ErrorMessage as BaseErrorMessage } from "../ErrorMessage";
import { Label as BaseLabel } from "../Label";
import { LabelledSlider as BaseLabelledSlider } from "../Slider";
import { NaturalNumberInput as BaseNaturalNumberInput } from "../Input/NaturalNumber";
import { PercentInput as BasePercentInput } from "../Input/Percent";
import { RationalNumberInput as BaseRationalNumberInput } from "../Input/RationalNumber";
import { SelectBuilder as BaseSelectBuilder } from "../Select/SelectBuilder";
import { SelectRadio as BaseSelectRadio } from "../RadioButton";
import { Textarea as BaseTextarea } from "../Textarea";
import { toFormikComponent } from "./util";

export const ErrorMessage =
  toFormikComponent<React.VoidFunctionComponent>(BaseErrorMessage);
export const SelectBuilder = toFormikComponent<any>(BaseSelectBuilder);
export const SelectRadio = toFormikComponent<{ options: Options<unknown> }>(
  BaseSelectRadio
);
export const Textarea = toFormikComponent<TextareaProps>(BaseTextarea);
export const LabelledSlider =
  toFormikComponent<LabelledSliderProps>(BaseLabelledSlider);
export const Label = toFormikComponent<LabelProps>(BaseLabel);

export interface InputSpecializations
  extends React.FC<FormikComponentProps & BaseInputProps> {
  Currency: React.FC<CurrencyInputProps & FormikComponentProps>;
  NaturalNumber: React.FC<NaturalNumberInputProps & FormikComponentProps>;
  Percent: React.FC<PercentInputProps & FormikComponentProps>;
  RationalNumber: React.FC<RationalNumberInputProps & FormikComponentProps>;
}

const BaseFormikInput = toFormikComponent<BaseInputProps>(BaseInput);
export const Input: InputSpecializations = (props) => {
  return <BaseFormikInput {...props} />;
};
Input.Percent = toFormikComponent<PercentInputProps>(BasePercentInput);
Input.Currency = toFormikComponent<CurrencyInputProps>(BaseCurrencyInput);
Input.NaturalNumber = toFormikComponent<NaturalNumberInputProps>(
  BaseNaturalNumberInput
);
Input.RationalNumber = toFormikComponent<RationalNumberInputProps>(
  BaseRationalNumberInput
);
