/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CurrencyContext } from './CurrencyContext';
import {
    CurrencyContextFromJSON,
    CurrencyContextFromJSONTyped,
    CurrencyContextToJSON,
} from './CurrencyContext';
import type { TotalContext } from './TotalContext';
import {
    TotalContextFromJSON,
    TotalContextFromJSONTyped,
    TotalContextToJSON,
} from './TotalContext';

/**
 * 
 * @export
 * @interface Context
 */
export interface Context {
    /**
     * 
     * @type {CurrencyContext}
     * @memberof Context
     */
    currency?: CurrencyContext;
    /**
     * 
     * @type {TotalContext}
     * @memberof Context
     */
    total?: TotalContext;
}

/**
 * Check if a given object implements the Context interface.
 */
export function instanceOfContext(value: object): value is Context {
    return true;
}

export function ContextFromJSON(json: any): Context {
    return ContextFromJSONTyped(json, false);
}

export function ContextFromJSONTyped(json: any, ignoreDiscriminator: boolean): Context {
    if (json == null) {
        return json;
    }
    return {
        
        'currency': json['currency'] == null ? undefined : CurrencyContextFromJSON(json['currency']),
        'total': json['total'] == null ? undefined : TotalContextFromJSON(json['total']),
    };
}

export function ContextToJSON(value?: Context | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'currency': CurrencyContextToJSON(value['currency']),
        'total': TotalContextToJSON(value['total']),
    };
}

