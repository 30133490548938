import * as R from "ramda";
import React from "react";
import cn from "lib/cn";
import styled from "styled-components";
import { useRouter } from "next/router";

import { Icon } from "components/library";
import { Link } from "./Link";
import { SubTitle } from "./SubTitle";

import * as Style from "style";

export interface Breadcrumb {
  href?: string;
  title: string;
}
export type Breadcrumbs = Breadcrumb[];

export interface BreadcrumbListProps {
  children: React.ReactNode;
}

export interface BreadcrumbListItemProps {
  children: React.ReactNode;
  isFirst: boolean;
  onClick?: () => void;
}

export interface BreadcrumbBuilderProps {
  breadcrumbs: Breadcrumbs;
}

export interface BreadcrumbBuilderSpecialization
  extends React.FC<BreadcrumbBuilderProps> {
  Placeholder: React.FC<Record<string, unknown>>;
}

export const BreadcrumbDividerContainer = styled.div`
  padding: 0px ${Style.Layout.Padding.ExtraSmall};
  color: ${Style.Color.Font.Tertiary};
`;

export const BreadcrumbListItem: React.FC<BreadcrumbListItemProps> = ({
  children,
  onClick,
  isFirst,
}) => {
  const Container = !R.isNil(onClick)
    ? ({ children: content }) => <Link onClick={onClick}>{content}</Link>
    : ({ children: content }) => <SubTitle>{content}</SubTitle>;

  return (
    <li>
      <div className={cn(isFirst ? "" : "items-center", "flex")}>
        {!isFirst && (
          <BreadcrumbDividerContainer>
            <Icon.BreadcrumbArrowRight className="flex-shrink-0 h-2 w-2" />
          </BreadcrumbDividerContainer>
        )}
        <Container>{children}</Container>
      </div>
    </li>
  );
};

export const BreadcrumbListContainer = styled.ol`
  padding: 0px;
`;

export const BreadcrumbList: React.FC<BreadcrumbListProps> = ({ children }) => {
  return (
    <BreadcrumbListContainer role="list" className="flex items-center">
      {children}
    </BreadcrumbListContainer>
  );
};

export const BreadcrumbBuilderPlaceholder: React.FC<
  Record<string, unknown>
> = () => {
  return (
    <div className="invisible">
      <BreadcrumbList>
        <BreadcrumbListItem key={0} isFirst>
          {" "}
        </BreadcrumbListItem>
        <BreadcrumbListItem key={1} isFirst={false}>
          {" "}
        </BreadcrumbListItem>
      </BreadcrumbList>
    </div>
  );
};

export const BreadcrumbBuilder: BreadcrumbBuilderSpecialization = ({
  breadcrumbs,
}) => {
  const router = useRouter();

  return (
    <BreadcrumbList>
      {breadcrumbs.map((breadcrumb: Breadcrumb, index: number) => {
        const onClick = R.has("href", breadcrumb)
          ? () => router.push(breadcrumb.href)
          : undefined;

        return (
          <BreadcrumbListItem
            key={index}
            isFirst={index === 0}
            onClick={onClick}
          >
            {breadcrumb.title}
          </BreadcrumbListItem>
        );
      })}
    </BreadcrumbList>
  );
};

BreadcrumbBuilder.Placeholder = BreadcrumbBuilderPlaceholder;
