import * as Style from "style";
import styled from "styled-components";

export interface MiniTabProps {
  isActive: boolean;
}

export interface MiniTabsNamespace extends React.VoidFunctionComponent {
  Tab: React.FC<MiniTabProps>;
}

function toBorderBottom({ isActive }: MiniTabProps): string {
  return isActive ? `2px solid ${Style.Color.Brand.Primary}` : "";
}

function toMarginBottom({ isActive }: MiniTabProps): string {
  return isActive ? "-2px" : "auto";
}

export const MiniTabs = styled.div`
  column-gap: ${Style.Layout.Padding.Small};
  align-items: center;
  display: flex;
`;

const MiniTab = styled.div<MiniTabProps>`
  border-bottom: ${toBorderBottom};
  margin-bottom: ${toMarginBottom};
`;

MiniTabs.Tab = MiniTab;
