/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateFixedExchangeRateDto,
  FixedExchangeRate,
  UpdateFixedExchangeRateDto,
} from '../models/index';
import {
    CreateFixedExchangeRateDtoFromJSON,
    CreateFixedExchangeRateDtoToJSON,
    FixedExchangeRateFromJSON,
    FixedExchangeRateToJSON,
    UpdateFixedExchangeRateDtoFromJSON,
    UpdateFixedExchangeRateDtoToJSON,
} from '../models/index';

export interface CreateFixedExchangeRateRequest {
    createFixedExchangeRateDto: CreateFixedExchangeRateDto;
}

export interface FindOneFixedExchangeRateRequest {
    id: string;
}

export interface RemoveFixedExchangeRateRequest {
    id: string;
}

export interface UpdateFixedExchangeRateRequest {
    id: string;
    updateFixedExchangeRateDto: UpdateFixedExchangeRateDto;
}

/**
 * 
 */
export class FixedExchangeRateApi extends runtime.BaseAPI {

    /**
     */
    async createFixedExchangeRateRaw(requestParameters: CreateFixedExchangeRateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FixedExchangeRate>> {
        if (requestParameters['createFixedExchangeRateDto'] == null) {
            throw new runtime.RequiredError(
                'createFixedExchangeRateDto',
                'Required parameter "createFixedExchangeRateDto" was null or undefined when calling createFixedExchangeRate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/fixed-exchange-rates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateFixedExchangeRateDtoToJSON(requestParameters['createFixedExchangeRateDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FixedExchangeRateFromJSON(jsonValue));
    }

    /**
     */
    async createFixedExchangeRate(requestParameters: CreateFixedExchangeRateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FixedExchangeRate> {
        const response = await this.createFixedExchangeRateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findAllFixedExchangeRatesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FixedExchangeRate>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/fixed-exchange-rates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FixedExchangeRateFromJSON));
    }

    /**
     */
    async findAllFixedExchangeRates(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FixedExchangeRate>> {
        const response = await this.findAllFixedExchangeRatesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async findOneFixedExchangeRateRaw(requestParameters: FindOneFixedExchangeRateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FixedExchangeRate>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling findOneFixedExchangeRate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/fixed-exchange-rates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FixedExchangeRateFromJSON(jsonValue));
    }

    /**
     */
    async findOneFixedExchangeRate(requestParameters: FindOneFixedExchangeRateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FixedExchangeRate> {
        const response = await this.findOneFixedExchangeRateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removeFixedExchangeRateRaw(requestParameters: RemoveFixedExchangeRateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling removeFixedExchangeRate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/fixed-exchange-rates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async removeFixedExchangeRate(requestParameters: RemoveFixedExchangeRateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeFixedExchangeRateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateFixedExchangeRateRaw(requestParameters: UpdateFixedExchangeRateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FixedExchangeRate>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateFixedExchangeRate().'
            );
        }

        if (requestParameters['updateFixedExchangeRateDto'] == null) {
            throw new runtime.RequiredError(
                'updateFixedExchangeRateDto',
                'Required parameter "updateFixedExchangeRateDto" was null or undefined when calling updateFixedExchangeRate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/fixed-exchange-rates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateFixedExchangeRateDtoToJSON(requestParameters['updateFixedExchangeRateDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FixedExchangeRateFromJSON(jsonValue));
    }

    /**
     */
    async updateFixedExchangeRate(requestParameters: UpdateFixedExchangeRateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FixedExchangeRate> {
        const response = await this.updateFixedExchangeRateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
