/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateJobFamilyDto,
  JobFamily,
  UpdateJobFamilyDto,
} from '../models/index';
import {
    CreateJobFamilyDtoFromJSON,
    CreateJobFamilyDtoToJSON,
    JobFamilyFromJSON,
    JobFamilyToJSON,
    UpdateJobFamilyDtoFromJSON,
    UpdateJobFamilyDtoToJSON,
} from '../models/index';

export interface CreateJobFamilyRequest {
    createJobFamilyDto: CreateJobFamilyDto;
}

export interface CreateJobFamilyBatchRequest {
    createJobFamilyDto: Array<CreateJobFamilyDto>;
}

export interface FindOneJobFamilyRequest {
    id: string;
}

export interface RemoveJobFamilyRequest {
    id: string;
}

export interface UpdateJobFamilyRequest {
    id: string;
    updateJobFamilyDto: UpdateJobFamilyDto;
}

/**
 * 
 */
export class JobFamilyApi extends runtime.BaseAPI {

    /**
     */
    async createJobFamilyRaw(requestParameters: CreateJobFamilyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<JobFamily>> {
        if (requestParameters['createJobFamilyDto'] == null) {
            throw new runtime.RequiredError(
                'createJobFamilyDto',
                'Required parameter "createJobFamilyDto" was null or undefined when calling createJobFamily().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/job-families`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateJobFamilyDtoToJSON(requestParameters['createJobFamilyDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JobFamilyFromJSON(jsonValue));
    }

    /**
     */
    async createJobFamily(requestParameters: CreateJobFamilyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<JobFamily> {
        const response = await this.createJobFamilyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createJobFamilyBatchRaw(requestParameters: CreateJobFamilyBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<JobFamily>>> {
        if (requestParameters['createJobFamilyDto'] == null) {
            throw new runtime.RequiredError(
                'createJobFamilyDto',
                'Required parameter "createJobFamilyDto" was null or undefined when calling createJobFamilyBatch().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/job-families/batch`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['createJobFamilyDto']!.map(CreateJobFamilyDtoToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(JobFamilyFromJSON));
    }

    /**
     */
    async createJobFamilyBatch(requestParameters: CreateJobFamilyBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<JobFamily>> {
        const response = await this.createJobFamilyBatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findAllJobFamiliesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<JobFamily>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/job-families`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(JobFamilyFromJSON));
    }

    /**
     */
    async findAllJobFamilies(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<JobFamily>> {
        const response = await this.findAllJobFamiliesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async findOneJobFamilyRaw(requestParameters: FindOneJobFamilyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<JobFamily>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling findOneJobFamily().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/job-families/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JobFamilyFromJSON(jsonValue));
    }

    /**
     */
    async findOneJobFamily(requestParameters: FindOneJobFamilyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<JobFamily> {
        const response = await this.findOneJobFamilyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getEmployeeCountByJobFamilyIdRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/job-families/employee-count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getEmployeeCountByJobFamilyId(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.getEmployeeCountByJobFamilyIdRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async removeJobFamilyRaw(requestParameters: RemoveJobFamilyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling removeJobFamily().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/job-families/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async removeJobFamily(requestParameters: RemoveJobFamilyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeJobFamilyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateJobFamilyRaw(requestParameters: UpdateJobFamilyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<JobFamily>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateJobFamily().'
            );
        }

        if (requestParameters['updateJobFamilyDto'] == null) {
            throw new runtime.RequiredError(
                'updateJobFamilyDto',
                'Required parameter "updateJobFamilyDto" was null or undefined when calling updateJobFamily().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/job-families/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateJobFamilyDtoToJSON(requestParameters['updateJobFamilyDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JobFamilyFromJSON(jsonValue));
    }

    /**
     */
    async updateJobFamily(requestParameters: UpdateJobFamilyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<JobFamily> {
        const response = await this.updateJobFamilyRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
