/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateMappedPlanDto
 */
export interface CreateMappedPlanDto {
    /**
     * 
     * @type {string}
     * @memberof CreateMappedPlanDto
     */
    job_family_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateMappedPlanDto
     */
    job_level_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateMappedPlanDto
     */
    remote_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateMappedPlanDto
     */
    title: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateMappedPlanDto
     */
    plan_group_ids: Array<string>;
}

/**
 * Check if a given object implements the CreateMappedPlanDto interface.
 */
export function instanceOfCreateMappedPlanDto(value: object): value is CreateMappedPlanDto {
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('plan_group_ids' in value) || value['plan_group_ids'] === undefined) return false;
    return true;
}

export function CreateMappedPlanDtoFromJSON(json: any): CreateMappedPlanDto {
    return CreateMappedPlanDtoFromJSONTyped(json, false);
}

export function CreateMappedPlanDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateMappedPlanDto {
    if (json == null) {
        return json;
    }
    return {
        
        'job_family_id': json['job_family_id'] == null ? undefined : json['job_family_id'],
        'job_level_id': json['job_level_id'] == null ? undefined : json['job_level_id'],
        'remote_id': json['remote_id'] == null ? undefined : json['remote_id'],
        'title': json['title'],
        'plan_group_ids': json['plan_group_ids'],
    };
}

export function CreateMappedPlanDtoToJSON(value?: CreateMappedPlanDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'job_family_id': value['job_family_id'],
        'job_level_id': value['job_level_id'],
        'remote_id': value['remote_id'],
        'title': value['title'],
        'plan_group_ids': value['plan_group_ids'],
    };
}

