// Brand colors.
const Brand = {
  // Main brand color.
  Primary: "#6098EC",

  // Darket tone of the brand color.
  Secondary: "#436AA5",
};

// Action colors.
const Action = {
  // Used to denote an action can be taken.
  Primary: Brand.Secondary,

  Secondary: Brand.Secondary,
};

// Font colors.
const Font = {
  // Used on important texts such as titles and charts.
  Primary: "#27272A",

  // Used on descriptions and general text blocks.
  Secondary: "#838383",

  // Dork
  Tertiary: "#939393",
};

// Colors used on charts and infographics.
const Chart = {
  /** Medium value blue. */
  CornflowerBlue: "#6098EC",

  /** Medium value green. */
  Fern: "#59BD75",

  /** Medium value orange. */
  Saffron: "#F3A93C",

  /** Medium value red. */
  Mandy: "#E94F4F",

  /** Medium value purple. */
  Studio: "#8A50AA",

  /** Medium value yellow. */
  EnergyYellow: "#F9D35D",

  /** Medium value Turquiose. */
  PuertoRico: "#5CCAA6",

  /** Medium value pink. */
  PersianPink: "#F26CAB",

  /** Low value blue. */
  CeruleanBlue: "#275DAE",

  /** Another medium value yellow slightly higher than EnergyYellow. */
  BrightSun: "#FECA46",

  /** Low value purple. */
  MulledWine: "#60466F",

  /** Light parchment-like color. */
  Chino: "#D6CEB2",

  /** High value purple. */
  Lavender: "#A68CDC",

  /** High value green. */
  DeYork: "#8AC189",

  /** High value pink. */
  TonysPink: "#E89393",
};

// Variants of the chart colors, 15% lighter and darker. Including the HSL if we need to tweak or add more tints.
const ChartHighlights = {
  CornflowerBlue: {
    HSL: "hsl(216, 78.7%, 65.1%)",
    Darker: "hsl(216, 78.7%, 50.1%)",
    Lighter: "hsl(216, 78.7%, 80.1%)",
  },

  Fern: {
    HSL: "hsl(137, 43%, 55%)",
    Darker: "hsl(137, 43%, 40%)",
    Lighter: "hsl(137, 43%, 70%)",
  },

  Saffron: {
    HSL: "hsl(36, 88%, 59%)",
    Darker: "hsl(36, 88%, 44%)",
    Lighter: "hsl(36, 88%, 74%)",
  },

  Mandy: {
    HSL: "hsl(0, 78%, 61%)",
    Darker: "hsl(0, 78%, 46%)",
    Lighter: "hsl(0, 78%, 76%)",
  },

  Studio: {
    HSL: "hsl(279, 36%, 49%)",
    Darker: "hsl(279, 36%, 34%)",
    Lighter: "hsl(279, 36%, 34%)",
  },

  EnergyYellow: {
    HSL: "hsl(45, 93%, 67%)",
    Darker: "hsl(45, 93%, 52%)",
    Lighter: "hsl(45, 93%, 82%)",
  },

  PuertoRico: {
    HSL: "hsl(160, 51%, 58%)",
    Darker: "hsl(160, 51%, 43%)",
    Lighter: "hsl(160, 51%, 73%)",
  },

  PersianPink: {
    HSL: "hsl(332, 84%, 69%)",
    Darker: "hsl(332, 84%, 54%)",
    Lighter: "hsl(332, 84%, 84%)",
  },

  CeruleanBlue: {
    HSL: "hsl(216, 63%, 42%)",
    Darker: "hsl(216, 63%, 27%)",
    Lighter: "hsl(216, 63%, 57%)",
  },

  BrightSun: {
    HSL: "hsl(43, 99%, 64%)",
    Darker: "hsl(43, 99%, 49%)",
    Lighter: "hsl(43, 99%, 79%)",
  },

  MulledWine: {
    HSL: "hsl(278, 23%, 35%)",
    Darker: "hsl(278, 23%, 20%)",
    Lighter: "hsl(278, 23%, 50%)",
  },

  Chino: {
    HSL: "hsl(47, 31%, 77%)",
    Darker: "hsl(47, 31%, 62%)",
    Lighter: "hsl(47, 31%, 92%)",
  },

  Lavender: {
    HSL: "hsl(260, 53%, 71%)",
    Darker: "hsl(260, 53%, 56%)",
    Lighter: "hsl(260, 53%, 86%)",
  },

  DeYork: {
    HSL: "hsl(119, 31%, 65%)",
    Darker: "hsl(119, 31%, 50%)",
    Lighter: "hsl(119, 31%, 80%)",
  },

  TonysPink: {
    HSL: "hsl(0, 65%, 74%)",
    Darker: "hsl(0, 65%, 59%)",
    Lighter: "hsl(0, 65%, 89%)",
  },
};

// Used to color layout elements.
const Layout = {
  // Used on box outlines and seperation lines.
  Line: "#E5E5E5",

  // Used on the background of the application as well box headers.
  Background: {
    Primary: "#FBFBFB",

    // Used as an alternative background such as on table rows.
    Secondary: "#F5F5F5",
  },

  // Used on secondary elements.
  Gray: "#C4C4C4",
};

const Gray = {
  G50: "#FAFBFF",
  G75: "#F9FAFC",
  G90: "#F4F6FA",
  G100: "#F4F5F9",
  G200: "#EDEFF5",
  G300: "#E6E8F0",
  G400: "#D8DAE5",
  G500: "#C1C4D6",
  G600: "#8F95B2",
  G700: "#696F8C",
  G800: "#474D66",
  G900: "#101840",
};

// Colors that help user's understand the sentiment of actions.
const Sentiment = {
  // Used to denote that something is wrong.
  Error: {
    Primary: "#E94F4F",
    Secondary: "#ED7272",
  },

  // Used to denote that something should be seriously considered.
  Warning: {
    Primary: Chart.Saffron,
    Secondary: "#F5BA63",
  },

  // Used to denote that something worked.
  Success: {
    Primary: "#52BE71",
    Secondary: "#75CB8D",
  },

  // Used to provide additional details about something.
  Info: {
    Primary: Chart.CornflowerBlue,
    Secondary: "#90b7f2",
    Tertiary: "#ebf1fa",
  },

  // Used to denote a lack of action or activity.
  Neutral: {
    Primary: Layout.Gray,
    Secondary: Layout.Background.Secondary,
  },
};

export const Color = {
  Action,
  Brand,
  Chart,
  ChartHighlights,
  Font,
  Layout,
  Sentiment,
  White: "#FFFFFF",
  Gray,
};

/**
 * Gray scale.
 */
export const GrayScale = [
  Gray.G90,
  Gray.G100,
  Gray.G200,
  Gray.G300,
  Gray.G400,
  Gray.G500,
  Gray.G600,
  Gray.G700,
  Gray.G800,
  Gray.G900,
];

/**
 * Chart colors in the order that they should be used.
 */
export const ChartColors = [
  Color.Chart.CornflowerBlue,
  Color.Chart.Fern,
  Color.Chart.Saffron,
  Color.Chart.Mandy,
  Color.Chart.Studio,
  Color.Chart.EnergyYellow,
  Color.Chart.PuertoRico,
  Color.Chart.PersianPink,
  Color.Chart.CeruleanBlue,
  Color.Chart.BrightSun,
  Color.Chart.MulledWine,
  Color.Chart.Chino,
  Color.Chart.Lavender,
  Color.Chart.DeYork,
  Color.Chart.TonysPink,
];

export const ChartHighlightsLight = [
  Color.ChartHighlights.CornflowerBlue.Lighter,
  Color.ChartHighlights.Fern.Lighter,
  Color.ChartHighlights.Saffron.Lighter,
  Color.ChartHighlights.Mandy.Lighter,
  Color.ChartHighlights.Studio.Lighter,
  Color.ChartHighlights.EnergyYellow.Lighter,
  Color.ChartHighlights.PuertoRico.Lighter,
  Color.ChartHighlights.PersianPink.Lighter,
  Color.ChartHighlights.CeruleanBlue.Lighter,
  Color.ChartHighlights.BrightSun.Lighter,
  Color.ChartHighlights.MulledWine.Lighter,
  Color.ChartHighlights.Chino.Lighter,
  Color.ChartHighlights.Lavender.Lighter,
  Color.ChartHighlights.DeYork.Lighter,
  Color.ChartHighlights.TonysPink.Lighter,
];
