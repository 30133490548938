import styled from "styled-components";

import * as Style from "style";
import { SelectOptionContainer } from "./Option";

export const OptionMenu = styled.div`
  background: ${Style.Color.White};
  border-color: ${Style.Color.Layout.Line};
  border-radius: ${Style.Design.Rounding.Secondary};
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  padding: ${Style.Layout.Padding.ExtraSmall};
  max-height: ${Style.Layout.Select.MaxHeight};
  overflow-y: auto;

  ${SelectOptionContainer} {
    margin-bottom: ${Style.Layout.Padding.ExtraSmall};

    :last-child {
      margin-bottom: 0px;
    }
  }
`;
