/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EmployeeStatus = {
    Active: 'Active',
    Inactive: 'Inactive',
    Leave: 'Leave',
    Pending: 'Pending'
} as const;
export type EmployeeStatus = typeof EmployeeStatus[keyof typeof EmployeeStatus];


export function instanceOfEmployeeStatus(value: any): boolean {
    for (const key in EmployeeStatus) {
        if (Object.prototype.hasOwnProperty.call(EmployeeStatus, key)) {
            if (EmployeeStatus[key as keyof typeof EmployeeStatus] === value) {
                return true;
            }
        }
    }
    return false;
}

export function EmployeeStatusFromJSON(json: any): EmployeeStatus {
    return EmployeeStatusFromJSONTyped(json, false);
}

export function EmployeeStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeStatus {
    return json as EmployeeStatus;
}

export function EmployeeStatusToJSON(value?: EmployeeStatus | null): any {
    return value as any;
}

