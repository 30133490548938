/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Aggregation } from './Aggregation';
import {
    AggregationFromJSON,
    AggregationFromJSONTyped,
    AggregationToJSON,
} from './Aggregation';
import type { ColumnSort } from './ColumnSort';
import {
    ColumnSortFromJSON,
    ColumnSortFromJSONTyped,
    ColumnSortToJSON,
} from './ColumnSort';
import type { LogicDefinition } from './LogicDefinition';
import {
    LogicDefinitionFromJSON,
    LogicDefinitionFromJSONTyped,
    LogicDefinitionToJSON,
} from './LogicDefinition';

/**
 * 
 * @export
 * @interface QueryDTO
 */
export interface QueryDTO {
    /**
     * 
     * @type {number}
     * @memberof QueryDTO
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryDTO
     */
    offset?: number;
    /**
     * 
     * @type {Array<ColumnSort>}
     * @memberof QueryDTO
     */
    sort?: Array<ColumnSort>;
    /**
     * 
     * @type {LogicDefinition}
     * @memberof QueryDTO
     */
    filters?: LogicDefinition;
    /**
     * 
     * @type {Array<Aggregation>}
     * @memberof QueryDTO
     */
    aggregations?: Array<Aggregation>;
    /**
     * 
     * @type {Array<string>}
     * @memberof QueryDTO
     */
    group_by?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof QueryDTO
     */
    activeOnly?: boolean;
    /**
     * 
     * @type {object}
     * @memberof QueryDTO
     */
    pit?: object;
    /**
     * 
     * @type {object}
     * @memberof QueryDTO
     */
    search_after?: object;
    /**
     * 
     * @type {string}
     * @memberof QueryDTO
     */
    text_query?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof QueryDTO
     */
    fields?: Array<string>;
}

/**
 * Check if a given object implements the QueryDTO interface.
 */
export function instanceOfQueryDTO(value: object): value is QueryDTO {
    return true;
}

export function QueryDTOFromJSON(json: any): QueryDTO {
    return QueryDTOFromJSONTyped(json, false);
}

export function QueryDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'limit': json['limit'] == null ? undefined : json['limit'],
        'offset': json['offset'] == null ? undefined : json['offset'],
        'sort': json['sort'] == null ? undefined : ((json['sort'] as Array<any>).map(ColumnSortFromJSON)),
        'filters': json['filters'] == null ? undefined : LogicDefinitionFromJSON(json['filters']),
        'aggregations': json['aggregations'] == null ? undefined : ((json['aggregations'] as Array<any>).map(AggregationFromJSON)),
        'group_by': json['group_by'] == null ? undefined : json['group_by'],
        'activeOnly': json['activeOnly'] == null ? undefined : json['activeOnly'],
        'pit': json['pit'] == null ? undefined : json['pit'],
        'search_after': json['search_after'] == null ? undefined : json['search_after'],
        'text_query': json['text_query'] == null ? undefined : json['text_query'],
        'fields': json['fields'] == null ? undefined : json['fields'],
    };
}

export function QueryDTOToJSON(value?: QueryDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'limit': value['limit'],
        'offset': value['offset'],
        'sort': value['sort'] == null ? undefined : ((value['sort'] as Array<any>).map(ColumnSortToJSON)),
        'filters': LogicDefinitionToJSON(value['filters']),
        'aggregations': value['aggregations'] == null ? undefined : ((value['aggregations'] as Array<any>).map(AggregationToJSON)),
        'group_by': value['group_by'],
        'activeOnly': value['activeOnly'],
        'pit': value['pit'],
        'search_after': value['search_after'],
        'text_query': value['text_query'],
        'fields': value['fields'],
    };
}

