/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlanOverviewKey
 */
export interface PlanOverviewKey {
    /**
     * 
     * @type {string}
     * @memberof PlanOverviewKey
     */
    level_id: string;
    /**
     * 
     * @type {string}
     * @memberof PlanOverviewKey
     */
    family_id: string;
    /**
     * 
     * @type {number}
     * @memberof PlanOverviewKey
     */
    level_group_ord: number;
    /**
     * 
     * @type {number}
     * @memberof PlanOverviewKey
     */
    level_ord: number;
    /**
     * 
     * @type {string}
     * @memberof PlanOverviewKey
     */
    level: string;
    /**
     * 
     * @type {string}
     * @memberof PlanOverviewKey
     */
    family: string;
}

/**
 * Check if a given object implements the PlanOverviewKey interface.
 */
export function instanceOfPlanOverviewKey(value: object): value is PlanOverviewKey {
    if (!('level_id' in value) || value['level_id'] === undefined) return false;
    if (!('family_id' in value) || value['family_id'] === undefined) return false;
    if (!('level_group_ord' in value) || value['level_group_ord'] === undefined) return false;
    if (!('level_ord' in value) || value['level_ord'] === undefined) return false;
    if (!('level' in value) || value['level'] === undefined) return false;
    if (!('family' in value) || value['family'] === undefined) return false;
    return true;
}

export function PlanOverviewKeyFromJSON(json: any): PlanOverviewKey {
    return PlanOverviewKeyFromJSONTyped(json, false);
}

export function PlanOverviewKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanOverviewKey {
    if (json == null) {
        return json;
    }
    return {
        
        'level_id': json['level_id'],
        'family_id': json['family_id'],
        'level_group_ord': json['level_group_ord'],
        'level_ord': json['level_ord'],
        'level': json['level'],
        'family': json['family'],
    };
}

export function PlanOverviewKeyToJSON(value?: PlanOverviewKey | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'level_id': value['level_id'],
        'family_id': value['family_id'],
        'level_group_ord': value['level_group_ord'],
        'level_ord': value['level_ord'],
        'level': value['level'],
        'family': value['family'],
    };
}

