import type { SwitchProps } from "./Switch";
import type { LabelledSwitchProps } from "./Labelled";

import React from "react";

import { LabelledSwitch } from "./Labelled";
import { Switch as BaseSwitch } from "./Switch";

export interface SwitchNamespace extends React.FC<SwitchProps> {
  Labelled: React.FC<LabelledSwitchProps>;
}

export const Switch: SwitchNamespace = (props: SwitchProps) => {
  return <BaseSwitch {...props} />;
};

Switch.Labelled = LabelledSwitch;
