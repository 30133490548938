import type { Option } from "./type";

import * as R from "ramda";

/**
 * Convert a string into a select option.
 */
export function stringToOption(value: string): Option<string> {
  return { label: value, value };
}

/**
 * Convert strings into a select options.
 */
export function stringsToOptions(values: string[]): Option<string>[] {
  return R.map(stringToOption, values);
}

/**
 * Sort options.
 */
export function sortOptions(options: Option<string>[]): Option<string>[] {
  return R.sortBy(R.prop("label"), options);
}

/**
 * Find the index based on an option's label.
 */
export function findOptionLabelIndex(
  label: string,
  data: Option<any>[]
): number {
  return R.findIndex(R.propEq("label", label), data);
}
