/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ResolutionMethod } from './ResolutionMethod';
import {
    ResolutionMethodFromJSON,
    ResolutionMethodFromJSONTyped,
    ResolutionMethodToJSON,
} from './ResolutionMethod';

/**
 * 
 * @export
 * @interface HealthcheckRule
 */
export interface HealthcheckRule {
    /**
     * 
     * @type {string}
     * @memberof HealthcheckRule
     */
    id: HealthcheckRuleIdEnum;
    /**
     * 
     * @type {string}
     * @memberof HealthcheckRule
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof HealthcheckRule
     */
    type: HealthcheckRuleTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof HealthcheckRule
     */
    description: string;
    /**
     * 
     * @type {object}
     * @memberof HealthcheckRule
     */
    config: object;
    /**
     * 
     * @type {ResolutionMethod}
     * @memberof HealthcheckRule
     */
    resolutionMethod: ResolutionMethod;
}


/**
 * @export
 */
export const HealthcheckRuleIdEnum = {
    AboveBand: 'above-band',
    BelowBand: 'below-band',
    LowEmployeeCount: 'low-employee-count',
    StalePay: 'stale-pay',
    StalePayband: 'stale-payband',
    StaleBenchmark: 'stale-benchmark',
    NoPayband: 'no-payband',
    NoBenchmark: 'no-benchmark',
    NoJobFamily: 'no-job-family',
    NoJobLevel: 'no-job-level',
    HighPaybandSpread: 'high-payband-spread',
    AboveBandPct: 'above-band-pct',
    BelowBandPct: 'below-band-pct'
} as const;
export type HealthcheckRuleIdEnum = typeof HealthcheckRuleIdEnum[keyof typeof HealthcheckRuleIdEnum];

/**
 * @export
 */
export const HealthcheckRuleTypeEnum = {
    Employee: 'employee',
    Plan: 'plan'
} as const;
export type HealthcheckRuleTypeEnum = typeof HealthcheckRuleTypeEnum[keyof typeof HealthcheckRuleTypeEnum];


/**
 * Check if a given object implements the HealthcheckRule interface.
 */
export function instanceOfHealthcheckRule(value: object): value is HealthcheckRule {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('config' in value) || value['config'] === undefined) return false;
    if (!('resolutionMethod' in value) || value['resolutionMethod'] === undefined) return false;
    return true;
}

export function HealthcheckRuleFromJSON(json: any): HealthcheckRule {
    return HealthcheckRuleFromJSONTyped(json, false);
}

export function HealthcheckRuleFromJSONTyped(json: any, ignoreDiscriminator: boolean): HealthcheckRule {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'type': json['type'],
        'description': json['description'],
        'config': json['config'],
        'resolutionMethod': ResolutionMethodFromJSON(json['resolutionMethod']),
    };
}

export function HealthcheckRuleToJSON(value?: HealthcheckRule | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'title': value['title'],
        'type': value['type'],
        'description': value['description'],
        'config': value['config'],
        'resolutionMethod': ResolutionMethodToJSON(value['resolutionMethod']),
    };
}

