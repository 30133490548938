/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  UpdateUserSettingDto,
  UserSetting,
} from '../models/index';
import {
    UpdateUserSettingDtoFromJSON,
    UpdateUserSettingDtoToJSON,
    UserSettingFromJSON,
    UserSettingToJSON,
} from '../models/index';

export interface UpdateUserSettingsRequest {
    updateUserSettingDto: UpdateUserSettingDto;
}

/**
 * 
 */
export class UserSettingApi extends runtime.BaseAPI {

    /**
     */
    async findUserSettingsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserSetting>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user-settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserSettingFromJSON(jsonValue));
    }

    /**
     */
    async findUserSettings(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserSetting> {
        const response = await this.findUserSettingsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async updateUserSettingsRaw(requestParameters: UpdateUserSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserSetting>> {
        if (requestParameters['updateUserSettingDto'] == null) {
            throw new runtime.RequiredError(
                'updateUserSettingDto',
                'Required parameter "updateUserSettingDto" was null or undefined when calling updateUserSettings().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user-settings`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserSettingDtoToJSON(requestParameters['updateUserSettingDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserSettingFromJSON(jsonValue));
    }

    /**
     */
    async updateUserSettings(requestParameters: UpdateUserSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserSetting> {
        const response = await this.updateUserSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
