/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TotalContext
 */
export interface TotalContext {
    /**
     * 
     * @type {number}
     * @memberof TotalContext
     */
    total: number;
}

/**
 * Check if a given object implements the TotalContext interface.
 */
export function instanceOfTotalContext(value: object): value is TotalContext {
    if (!('total' in value) || value['total'] === undefined) return false;
    return true;
}

export function TotalContextFromJSON(json: any): TotalContext {
    return TotalContextFromJSONTyped(json, false);
}

export function TotalContextFromJSONTyped(json: any, ignoreDiscriminator: boolean): TotalContext {
    if (json == null) {
        return json;
    }
    return {
        
        'total': json['total'],
    };
}

export function TotalContextToJSON(value?: TotalContext | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'total': value['total'],
    };
}

