/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Zone
 */
export interface Zone {
    /**
     * 
     * @type {string}
     * @memberof Zone
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Zone
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof Zone
     */
    country_label: string;
    /**
     * 
     * @type {string}
     * @memberof Zone
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof Zone
     */
    multiplier: number;
}

/**
 * Check if a given object implements the Zone interface.
 */
export function instanceOfZone(value: object): value is Zone {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('country' in value) || value['country'] === undefined) return false;
    if (!('country_label' in value) || value['country_label'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('multiplier' in value) || value['multiplier'] === undefined) return false;
    return true;
}

export function ZoneFromJSON(json: any): Zone {
    return ZoneFromJSONTyped(json, false);
}

export function ZoneFromJSONTyped(json: any, ignoreDiscriminator: boolean): Zone {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'country': json['country'],
        'country_label': json['country_label'],
        'description': json['description'],
        'multiplier': json['multiplier'],
    };
}

export function ZoneToJSON(value?: Zone | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'country': value['country'],
        'country_label': value['country_label'],
        'description': value['description'],
        'multiplier': value['multiplier'],
    };
}

