import * as R from "ramda";
import numeral from "numeral";

import { titleCase } from "@milio/lib/util/string";

export const DECIMAL_PRECISION = 2;

export const Abbreviations = [
  "id",
  "usd",
  "fx",
  "ceo",
  "ii",
  "iii",
  "vp",
  "svp",
  "ic1",
  "ic2",
  "ic3",
  "ic4",
  "ic5",
  "ic6",
  "ic7",
  "ic8",
  "ic9",
];

export enum PercentFormat {
  Decimal = "decimal",
  Percentage = "percentage",
}

export function displayPercent(percent: number): string {
  return numeral(percent).format("0.[0]%");
}

export function displayNumber(value: number): string {
  return numeral(value).format("0,0.[00]");
}

export function displayWholeNumber(value: number): string {
  return numeral(Math.round(value)).format("0,0");
}

export function labelize(input: string): string {
  if (R.isNil(input)) {
    return "";
  }

  return titleCase(input, {
    threshold: 3,
    abbreviations: Abbreviations,
  });
}

export function examplize(example: string): string {
  return `e.g. ${example}`;
}

export function percentize(
  value: number,
  {
    input,
    output,
  }: {
    input: PercentFormat;
    output: PercentFormat;
  }
): number {
  switch (input) {
    case PercentFormat.Percentage:
      switch (output) {
        case PercentFormat.Decimal:
          return value / 100;
        case PercentFormat.Percentage:
        default:
          return value;
      }
    case PercentFormat.Decimal:
    default:
      switch (output) {
        case PercentFormat.Decimal:
          return value;
        case PercentFormat.Percentage:
        default:
          return Math.round(value * 100);
      }
  }
}
