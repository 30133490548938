/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PermissionType = {
    EmployeesView: 'milio.resource.employees.view',
    EmployeesWrite: 'milio.resource.employees.write',
    ExchangeRatesView: 'milio.resource.exchange-rates.view',
    ExchangeRatesWrite: 'milio.resource.exchange-rates.write',
    PoliciesView: 'milio.resource.policies.view',
    PoliciesWrite: 'milio.resource.policies.write',
    PoliciesAssign: 'milio.resource.policies.assign',
    ReportsView: 'milio.resource.reports.view',
    ReportsWrite: 'milio.resource.reports.write',
    PaybandsView: 'milio.resource.paybands.view',
    PaybandsWrite: 'milio.resource.paybands.write',
    PlansView: 'milio.resource.plans.view',
    PlansWrite: 'milio.resource.plans.write',
    TasksView: 'milio.resource.tasks.view',
    TasksWrite: 'milio.resource.tasks.write',
    ModelsView: 'milio.resource.models.view',
    ModelsWrite: 'milio.resource.models.write',
    HealthchecksView: 'milio.resource.healthchecks.view',
    HealthchecksWrite: 'milio.resource.healthchecks.write'
} as const;
export type PermissionType = typeof PermissionType[keyof typeof PermissionType];


export function instanceOfPermissionType(value: any): boolean {
    for (const key in PermissionType) {
        if (Object.prototype.hasOwnProperty.call(PermissionType, key)) {
            if (PermissionType[key as keyof typeof PermissionType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function PermissionTypeFromJSON(json: any): PermissionType {
    return PermissionTypeFromJSONTyped(json, false);
}

export function PermissionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PermissionType {
    return json as PermissionType;
}

export function PermissionTypeToJSON(value?: PermissionType | null): any {
    return value as any;
}

