import * as Style from "style";

export const DEFAULT_BACKGROUND = Style.Color.Layout.Gray;
export const DEFAULT_FOREGROUND = Style.Color.White;
export const DEFAULT_NETURAL = Style.Color.Layout.Line;
export const STROKE_WIDTH = 2;
export const CIRCLE_SIZE = 25;
export const CONNECTOR_LENGTH = CIRCLE_SIZE;
export const DOT_SIZE = Math.floor(CIRCLE_SIZE / 4);
export const ICON_SIZE = Math.floor(CIRCLE_SIZE / 2);
