import type { StandardComponent } from "components/type";

import styled from "styled-components";
import React from "react";

import * as Style from "style";
import { Orientation } from "../constant";

export interface LegendListProps extends StandardComponent {
  children: React.ReactNode;
  orientation?: Orientation;
}

export interface LegendListContainerProps {
  orientation?: LegendListProps["orientation"];
}

function toDisplay({ orientation }: LegendListContainerProps): string {
  return orientation === Orientation.Horizontal ? "flex" : "grid";
}

function toColumnGap({ orientation }: LegendListContainerProps): string {
  return orientation === Orientation.Horizontal
    ? Style.Layout.Padding.ExtraSmall
    : "auto";
}

function toRowGap({ orientation }: LegendListContainerProps): string {
  return orientation === Orientation.Horizontal
    ? "auto"
    : Style.Layout.Padding.ExtraSmall;
}

export const LegendListContainer = styled.div<LegendListContainerProps>`
  column-gap: ${toColumnGap};
  display: ${toDisplay};
  justify-content: end;
  row-gap: ${toRowGap};
`;

export const LegendList: React.FC<LegendListProps> = ({
  children,
  orientation = Orientation.Vertical,
}) => {
  return (
    <LegendListContainer orientation={orientation}>
      {children}
    </LegendListContainer>
  );
};
