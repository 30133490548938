/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserSetting
 */
export interface UserSetting {
    /**
     * 
     * @type {string}
     * @memberof UserSetting
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserSetting
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof UserSetting
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof UserSetting
     */
    deleted_at: string;
    /**
     * 
     * @type {string}
     * @memberof UserSetting
     */
    base_currency: string;
}

/**
 * Check if a given object implements the UserSetting interface.
 */
export function instanceOfUserSetting(value: object): value is UserSetting {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('created_at' in value) || value['created_at'] === undefined) return false;
    if (!('updated_at' in value) || value['updated_at'] === undefined) return false;
    if (!('deleted_at' in value) || value['deleted_at'] === undefined) return false;
    if (!('base_currency' in value) || value['base_currency'] === undefined) return false;
    return true;
}

export function UserSettingFromJSON(json: any): UserSetting {
    return UserSettingFromJSONTyped(json, false);
}

export function UserSettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserSetting {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created_at': json['created_at'],
        'updated_at': json['updated_at'],
        'deleted_at': json['deleted_at'],
        'base_currency': json['base_currency'],
    };
}

export function UserSettingToJSON(value?: UserSetting | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'created_at': value['created_at'],
        'updated_at': value['updated_at'],
        'deleted_at': value['deleted_at'],
        'base_currency': value['base_currency'],
    };
}

