import type { Action as ReduxAction } from "redux";

import React from "react";
import { v4 as uuid } from "uuid";

interface OpenAction extends ReduxAction<ModalAction.Open> {
  payload: {
    modal: React.ReactElement;
    id: string;
  };
}

interface CloseAction extends ReduxAction<ModalAction.Close> {
  payload: string;
}

interface CloseAllAction extends ReduxAction<ModalAction.CloseAll> {}

type Action = OpenAction | CloseAction | CloseAllAction;

export interface ModalManager {
  close: (id: string) => void;
  closeAll: () => void;
  open: (modal: React.ReactElement) => void;
  modals: Map<string, React.ReactElement>;
}

export interface ModalStore {
  modals: Map<string, React.ReactElement>;
}

const initialState = {
  modals: new Map(),
};

export interface ModalContainerProps {}

export enum ModalAction {
  Close = "modal.close",
  CloseAll = "modal.close-all",
  Open = "modal.open",
}

const reducer = (state: ModalStore, action: Action): ModalStore => {
  switch (action.type) {
    case ModalAction.Open: {
      const next = new Map(state.modals);

      next.set(action.payload.id, action.payload.modal);

      return { ...state, modals: next };
    }
    case ModalAction.Close: {
      if (state.modals.has(action.payload)) {
        const next = new Map(state.modals);

        next.delete(action.payload);

        return { ...state, modals: next };
      }

      return state;
    }
    case ModalAction.CloseAll: {
      return {
        ...state,
        modals: new Map(),
      };
    }
    default: {
      return state;
    }
  }
};

export const ModalContext = React.createContext<{
  dispatch: (action: Action) => void;
  state: ModalStore;
}>({
  dispatch: () => null,
  state: initialState,
});

export const ModalProvider: React.FC<{ children: React.ReactElement }> = ({
  children,
}): React.ReactElement => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const context = { dispatch, state };

  return (
    <ModalContext.Provider value={context}>{children}</ModalContext.Provider>
  );
};

export const useModal = (): ModalManager => {
  const { state, dispatch } = React.useContext(ModalContext);

  function open(modal: React.ReactElement) {
    const id: string = uuid();

    dispatch({ type: ModalAction.Open, payload: { id, modal } });
  }

  function close(id: string) {
    dispatch({ type: ModalAction.Close, payload: id });
  }

  function closeAll() {
    dispatch({ type: ModalAction.CloseAll });
  }

  return { modals: state.modals, close, open, closeAll };
};

export const ModalContainer: React.FC<ModalContainerProps> = () => {
  const { modals, close } = useModal();

  return (
    <div>
      {Array.from(modals, ([id, modal]: [string, React.ReactElement]) => {
        return (
          <modal.type
            {...modal.props}
            key={id}
            isOpen={true}
            onClose={() => close(id)}
          />
        );
      })}
    </div>
  );
};
