import type { Auth } from "./AuthenticationContext";
import type { AuthenticateOptions } from "data/user/util";
import type { Breadcrumbs } from "components/library/Breadcrumbs";
import type { BreadcrumbsManager } from "./BreadcrumbsContext";
import type { MetadataManager } from "./MetadataContext";
import type { ModalManager } from "./ModalContext";
import type { NextRouter } from "next/router";
import type { ToastManager } from "./ToastContext";
import { User } from "data/user";

import * as R from "ramda";
import React from "react";
import { useRouter } from "next/router";

import { ToastNamespace } from "components/config";
import { useBreadcrumbs } from "./BreadcrumbsContext";
import { useModal } from "./ModalContext";
import { useRequireAuth } from "./AuthenticationContext";
import { useToast } from "./ToastContext";
import { resolvePageSubtitle, useMetadata } from "./MetadataContext";
import { Unauthorized } from "components/library";

export interface PageSetupOptions {
  breadcrumbs: Breadcrumbs;
  title: string;
  subtitle?: string;
  toastNamespace: string;
  authenticate: AuthenticateOptions;
}

export interface ApplicationContext {
  auth: Auth;
  breadcrumbs: BreadcrumbsManager;
  metadata: MetadataManager;
  modal: ModalManager;
  router: NextRouter;
  setup: (options?: Partial<PageSetupOptions>) => void;
  toast: ToastManager;
  user: User;
}

// HOC that automatically injects the application context as props, and shows the Unauthorized screen
export const withApplicationContext = <
  T extends ApplicationContext = ApplicationContext
>(
  WrappedComponent: React.FC<T>,
  params?: Partial<PageSetupOptions>
): React.FC<T> => {
  const ComponentWithApplicationContext = (
    props: Omit<T, keyof ApplicationContext>
  ) => {
    const context = useApplicationContext();

    React.useEffect(
      () => !R.isNil(params) && context.setup(params),
      [context.router.query]
    );

    if (!context.auth.isInitialized) {
      // Don't render anything until the initial `setup` call.
      // This way we don't flash the page before showing Unauthorized,
      // and if you forget to add a call to authenticate your page won't render
      return null;
    }

    if (context.auth.isInitialized && !context.auth.isAuthorized) {
      return <Unauthorized />;
    }

    return <WrappedComponent {...context} {...(props as T)} />;
  };

  ComponentWithApplicationContext.Layout = R.prop("Layout", WrappedComponent);

  return ComponentWithApplicationContext;
};

export const useApplicationContext = (): ApplicationContext => {
  const auth: Auth = useRequireAuth();
  const breadcrumbs: BreadcrumbsManager = useBreadcrumbs();
  const metadata: MetadataManager = useMetadata();
  const modal: ModalManager = useModal();
  const router = useRouter();
  const toast: ToastManager = useToast();

  React.useEffect(() => {
    return () => {
      modal.closeAll();
      metadata.clear();
      breadcrumbs.clear();
    };
  }, []);

  function setup(opts: Partial<PageSetupOptions>) {
    const options: PageSetupOptions = R.mergeRight(
      {
        breadcrumbs: [],
        title: "",
        subtitle: "",
        authenticate: {},
        toastNamespace: ToastNamespace.Global,
      },
      opts
    );

    metadata.update({
      title: options.title,
      subtitle: R.or(
        resolvePageSubtitle(router.query as Record<string, string>),
        options.subtitle
      ),
    });
    breadcrumbs.set(options.breadcrumbs);
    toast.setCurrentNamespace(options.toastNamespace);
    auth.require(options.authenticate);
    toast.keepCurrentNamespace();
  }

  return {
    auth,
    breadcrumbs,
    metadata,
    modal,
    router,
    setup,
    toast,
    user: auth.user,
  };
};
