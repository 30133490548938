import type { ActionTabsProps } from "./Action";
import type { MiniTabsNamespace } from "./Mini";
import type { TabProps } from "./Tab";
import type { TabsProps } from "./Tabs";

import React from "react";

import { ActionTabs } from "./Action";
import { CompactTabs } from "./Compact";
import { EmptyTabs } from "./Empty";
import { MiniTabs } from "./Mini";
import { Tab } from "./Tab";
import { Tabs as BaseTabs } from "./Tabs";

export interface TabsNamespace<T> extends React.FC<TabsProps<T>> {
  Action: React.FC<ActionTabsProps<unknown>>;
  Compact: React.FC<TabsProps<T>>;
  Empty: React.VoidFunctionComponent;
  Mini: MiniTabsNamespace;
  Tab: React.FC<TabProps>;
}

export const Tabs: any = (props) => {
  return <BaseTabs {...props} />;
};

Tabs.Action = ActionTabs;
Tabs.Empty = EmptyTabs;
Tabs.Mini = MiniTabs;
Tabs.Tab = Tab;
Tabs.Compact = CompactTabs;
