import React from "react";
import styled from "styled-components";

import * as Style from "style";

export interface ParagraphProps {
  children: React.ReactNode;
  className?: string;
}

export interface ParagraphSpecializations extends React.FC<ParagraphProps> {
  Light: React.FC<ParagraphProps>;
}

const ParagraphContainer = styled.span`
  ${Style.Text.Definition.Paragraph};
  word-break: keep-all;
  white-space: normal;
`;

/**
 * Used for paragraphs and subtitles.
 */
export const Paragraph: ParagraphSpecializations = ({
  children,
  className,
}) => {
  return (
    <ParagraphContainer className={`text-font-secondary ${className}`}>
      {children}
    </ParagraphContainer>
  );
};

const LightParagraphContainer = styled.span`
  ${Style.Text.Definition.Paragraph};
  color: ${Style.Color.White};
`;

/**
 * Used for text blocks that have a dark background.
 */
const LightParagraph: React.FC<ParagraphProps> = ({ children }) => {
  return <LightParagraphContainer>{children}</LightParagraphContainer>;
};

Paragraph.Light = LightParagraph;
