import React from "react";

import Logo from "components/Logo.svg";
import { Paragraph } from "components/library";

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

/** Catches unhandled errors at the top level of the app.
 * Using a component class is required.
 * TODO: Incorporate the Sentry ErrorBoundary component here.
 * */
export class ErrorBoundary extends React.Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className="flex min-h-screen justify-center items-center">
          <div className="h-16 w-64">
            <div className="text-blue-600 w-24">
              <Logo />
            </div>
            <div className="mt-4 flex items-center">
              <Paragraph>An error occurred.</Paragraph>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
