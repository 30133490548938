import type { SpecializedTooltipProps } from "./type";

import * as R from "ramda";
import React from "react";
import { v4 as uuid } from "uuid";

import { Tooltip } from "./Tooltip";
import { TooltipPlacement } from "./constant";

export interface InlineTooltipProps extends SpecializedTooltipProps {
  isDisabled?: boolean;
  place?: TooltipPlacement;
  tooltip: React.ReactNode | (() => React.ReactNode);
  onHover?: () => void;
}

export const InlineTooltip: React.FC<InlineTooltipProps> = ({
  children,
  tooltip,
  isDisabled = false,
  place = TooltipPlacement.Bottom,
  onHover = R.always(undefined),
}) => {
  const [id] = React.useState<string>(uuid());
  const [isHovered, setIsHovered] = React.useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
    onHover();
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  React.useEffect(() => {
    return () => setIsHovered(false);
  }, []);

  const memoizedTooltip = React.useMemo(() => {
    if (isHovered && typeof tooltip === "function") {
      return tooltip();
    }
    return tooltip as React.ReactNode;
  }, [tooltip, isHovered]);

  return (
    <>
      {!isDisabled && !R.isNil(tooltip) && (
        <Tooltip id={id} place={place}>
          {memoizedTooltip}
        </Tooltip>
      )}
      <span
        data-for={id}
        data-tip
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </span>
    </>
  );
};
