import type { SpecializedAnchorProps } from "./type";

import React from "react";

import { Anchor } from "./Anchor";

export const TopRightAnchor: React.FC<SpecializedAnchorProps> = ({
  children,
}) => {
  return (
    <Anchor top={-5} right={0}>
      {children}
    </Anchor>
  );
};
