/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FilterGroup } from './FilterGroup';
import {
    FilterGroupFromJSON,
    FilterGroupFromJSONTyped,
    FilterGroupToJSON,
} from './FilterGroup';

/**
 * 
 * @export
 * @interface LogicDefinition
 */
export interface LogicDefinition {
    /**
     * 
     * @type {Array<string>}
     * @memberof LogicDefinition
     */
    combinators: Array<LogicDefinitionCombinatorsEnum>;
    /**
     * 
     * @type {Array<FilterGroup>}
     * @memberof LogicDefinition
     */
    groups: Array<FilterGroup>;
}


/**
 * @export
 */
export const LogicDefinitionCombinatorsEnum = {
    And: 'And',
    Or: 'Or'
} as const;
export type LogicDefinitionCombinatorsEnum = typeof LogicDefinitionCombinatorsEnum[keyof typeof LogicDefinitionCombinatorsEnum];


/**
 * Check if a given object implements the LogicDefinition interface.
 */
export function instanceOfLogicDefinition(value: object): value is LogicDefinition {
    if (!('combinators' in value) || value['combinators'] === undefined) return false;
    if (!('groups' in value) || value['groups'] === undefined) return false;
    return true;
}

export function LogicDefinitionFromJSON(json: any): LogicDefinition {
    return LogicDefinitionFromJSONTyped(json, false);
}

export function LogicDefinitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): LogicDefinition {
    if (json == null) {
        return json;
    }
    return {
        
        'combinators': json['combinators'],
        'groups': ((json['groups'] as Array<any>).map(FilterGroupFromJSON)),
    };
}

export function LogicDefinitionToJSON(value?: LogicDefinition | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'combinators': value['combinators'],
        'groups': ((value['groups'] as Array<any>).map(FilterGroupToJSON)),
    };
}

