import type { StandardComponent } from "components/type";

import * as R from "ramda";
import React from "react";
import styled from "styled-components";

import * as Style from "style";
import { Label } from "components/library/Label";
import { LabelContainer } from "components/library/Label/style";
import { Tooltip, ErrorMessage } from "components/library";
import debounce from "just-debounce-it";

export type FormInputComponent<S, R> = StandardComponent &
  R & {
    onChange: (next: S, event?: any) => void;
    value: S;
  };

export interface FormInputProps<T, S extends R.Prop<T, keyof T>, R = {}>
  extends StandardComponent {
  Component: React.JSXElementConstructor<FormInputComponent<S, R>>;
  debounceInterval?: number;
  errors?: Record<string, unknown>;
  field: keyof T;
  form: T;
  label?: string;
  onChange: (next: T) => void;
  props?: R;
  testID?: string;
  tooltip?: React.ReactNode;
  onTouch?: (field: string, touched?: boolean) => void;
}

function toLabelContainerMarginRight({ tooltip }): string {
  return R.isNil(tooltip) ? "0px" : Style.Layout.Padding.ExtraSmall;
}

export const LabelHeader = styled.div<{ tooltip: React.ReactNode }>`
  align-items: center;
  display: flex;
  justify-content: justify-start;
  padding-bottom: ${Style.Layout.Padding.ExtraSmall};

  ${LabelContainer} {
    padding: 0px;
    margin-right: ${toLabelContainerMarginRight};
  }
`;

export function FormInput<T, S extends R.Prop<T, keyof T>, R = {}>({
  Component,
  className,
  debounceInterval = 0,
  errors = {},
  field,
  form,
  isDisabled = false,
  label,
  onChange,
  props,
  testID,
  tooltip,
  onTouch = R.always(undefined),
}: FormInputProps<T, S, R> & { className?: string }) {
  return (
    <div className={className}>
      {!R.isNil(label) && (
        <LabelHeader tooltip={tooltip}>
          <Label error={R.prop<string>(field, errors)}>{label}</Label>
          {!R.isNil(tooltip) && (
            <Tooltip.Info place={Tooltip.Placement.Right}>
              {tooltip}
            </Tooltip.Info>
          )}
        </LabelHeader>
      )}
      <Component
        {...props}
        testID={testID}
        value={R.prop(field, form) as S}
        isDisabled={isDisabled}
        onChange={debounce((next: R.Prop<T, keyof T>) => {
          onTouch(field as string, true);
          onChange(R.set(R.lensProp(field as keyof T), next, form));
        }, debounceInterval)}
      />
      <ErrorMessage error={R.prop<string>(field, errors)} />
    </div>
  );
}
