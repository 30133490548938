import type { BaseInputProps } from "./type";

import * as R from "ramda";
import React from "react";
import styled from "styled-components";

import { PercentFormat } from "@milio/lib/util/percent/constant";
import { percentize } from "@milio/lib/util/percent/util";
import { getDecimalPlaces } from "@milio/lib/util/number/util";

import * as Style from "style";
import { BaseInput } from "./Base";
import { InputType } from "./constant";
import { Paragraph } from "../Paragraph";

export interface PercentInputProps
  extends Omit<BaseInputProps, "onChange" | "value"> {
  display?: PercentFormat;
  input?: PercentFormat;
  onChange?: (next: number, event: React.ChangeEvent<HTMLInputElement>) => void;
  output?: PercentFormat;
  value?: number | undefined;
  max?: number;
  min?: number;
  precision?: number;
}

function determineNext(
  raw: string,
  current: number,
  output: PercentFormat,
  display: PercentFormat
): number | undefined {
  if (raw === "") {
    return undefined;
  }

  const value: number = isNaN(Number(raw)) ? current : Number(raw);

  switch (output) {
    case PercentFormat.Percentage:
      switch (display) {
        case PercentFormat.Decimal:
          return value * 100;
        case PercentFormat.Percentage:
        default:
          return value;
      }
    case PercentFormat.Decimal:
    default:
      switch (display) {
        case PercentFormat.Decimal:
          return value;
        case PercentFormat.Percentage:
        default:
          return value / 100;
      }
  }
}

function determineValue(
  value: number | undefined,
  input: PercentFormat,
  display: PercentFormat,
  precision: number
): string {
  if (R.isNil(value)) {
    return "";
  }

  return percentize(value, { input, output: display, precision }).toString();
}

export const PercentInputContainer = styled.div`
  position: relative;

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

export const SymbolContainer = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  padding-right: ${Style.Layout.Padding.ExtraSmall};
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
`;

export const PercentInput: React.FC<PercentInputProps> = ({
  display = PercentFormat.Percentage,
  input = PercentFormat.Decimal,
  output = PercentFormat.Decimal,
  max = Infinity,
  min = 0,
  precision = 2,
  ...props
}) => {
  const value: string | undefined = determineValue(
    props.value,
    input,
    display,
    precision
  );

  // Parse the input event.
  const onParse = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value: raw } = event.target;

      // Filter out values that would be less or greater than the min or max.
      const next: number | undefined = determineNext(
        raw,
        props.value,
        output,
        display
      );
      if ((isFinite(min) && next < min) || (isFinite(max) && next < max)) {
        return value;
      }

      // Filter out values that are greater than the allowed precision.
      const places: number = getDecimalPlaces(raw);
      if (places > precision) {
        return value;
      }

      return raw;
    },
    [props.value, value, output, display, precision, max, min]
  );

  // Trigger an on change of the underlying perent value.
  const onChange = React.useCallback(
    (raw: string, event: React.ChangeEvent<HTMLInputElement>) => {
      props.onChange(determineNext(raw, props.value, output, display), event);
    },
    [props.onChange, props.value, output, display]
  );

  return (
    <PercentInputContainer>
      <BaseInput
        {...props}
        onChange={onChange}
        placeholder={props.placeholder || "e.g. 25"}
        type={InputType.Number}
        value={value}
        onParse={onParse}
      />
      <SymbolContainer>
        <Paragraph>%</Paragraph>
      </SymbolContainer>
    </PercentInputContainer>
  );
};
