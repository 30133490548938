import React from "react";
import styled from "styled-components";

import { Sentiment } from "../constant";
import { sentimentToPrimaryColor } from "../util";

export interface SentimentIconProps {
  sentiment: Sentiment;
  children: React.ReactNode;
}

export const SentimentIconContainer = styled.div<{ sentiment: Sentiment }>`
  background: ${sentimentToPrimaryColor};
`;

export const SentimentIcon: React.FC<SentimentIconProps> = ({
  sentiment,
  children,
}) => {
  return (
    <SentimentIconContainer
      sentiment={sentiment}
      className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10"
    >
      {children}
    </SentimentIconContainer>
  );
};
