/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CreateFixedExchangeRateItemDto } from './CreateFixedExchangeRateItemDto';
import {
    CreateFixedExchangeRateItemDtoFromJSON,
    CreateFixedExchangeRateItemDtoFromJSONTyped,
    CreateFixedExchangeRateItemDtoToJSON,
} from './CreateFixedExchangeRateItemDto';

/**
 * 
 * @export
 * @interface UpdateFixedExchangeRateDto
 */
export interface UpdateFixedExchangeRateDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateFixedExchangeRateDto
     */
    from_currency?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFixedExchangeRateDto
     */
    to_currency?: string;
    /**
     * 
     * @type {Array<CreateFixedExchangeRateItemDto>}
     * @memberof UpdateFixedExchangeRateDto
     */
    rates?: Array<CreateFixedExchangeRateItemDto>;
}

/**
 * Check if a given object implements the UpdateFixedExchangeRateDto interface.
 */
export function instanceOfUpdateFixedExchangeRateDto(value: object): value is UpdateFixedExchangeRateDto {
    return true;
}

export function UpdateFixedExchangeRateDtoFromJSON(json: any): UpdateFixedExchangeRateDto {
    return UpdateFixedExchangeRateDtoFromJSONTyped(json, false);
}

export function UpdateFixedExchangeRateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateFixedExchangeRateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'from_currency': json['from_currency'] == null ? undefined : json['from_currency'],
        'to_currency': json['to_currency'] == null ? undefined : json['to_currency'],
        'rates': json['rates'] == null ? undefined : ((json['rates'] as Array<any>).map(CreateFixedExchangeRateItemDtoFromJSON)),
    };
}

export function UpdateFixedExchangeRateDtoToJSON(value?: UpdateFixedExchangeRateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'from_currency': value['from_currency'],
        'to_currency': value['to_currency'],
        'rates': value['rates'] == null ? undefined : ((value['rates'] as Array<any>).map(CreateFixedExchangeRateItemDtoToJSON)),
    };
}

