export enum OperatorFamily {
  RangeBased = "RangeBased",
  ValueBased = "ValueBased",
}

export enum SortDirection {
  Ascend = "ascend",
  Descend = "descend",
}

export enum Operator {
  Between = "Between",
  Contains = "Contains",
  DoesNotContain = "DoesNotContain",
  Equals = "Equals",
  Excludes = "Excludes",
  GreaterThan = "GreaterThan",
  GreaterThanOrEqualTo = "GreaterThanOrEqualTo",
  Includes = "Includes",
  LessThan = "LessThan",
  LessThanOrEqualTo = "LessThanOrEqualTo",
  NotBetween = "NotBetween",
  NotEquals = "NotEquals",
}

export const OperatorFamilyMap = {
  [Operator.Between]: OperatorFamily.RangeBased,
  [Operator.Contains]: OperatorFamily.ValueBased,
  [Operator.DoesNotContain]: OperatorFamily.ValueBased,
  [Operator.Equals]: OperatorFamily.ValueBased,
  [Operator.Excludes]: OperatorFamily.ValueBased,
  [Operator.GreaterThan]: OperatorFamily.ValueBased,
  [Operator.GreaterThanOrEqualTo]: OperatorFamily.ValueBased,
  [Operator.Includes]: OperatorFamily.ValueBased,
  [Operator.LessThan]: OperatorFamily.ValueBased,
  [Operator.LessThanOrEqualTo]: OperatorFamily.ValueBased,
  [Operator.NotBetween]: OperatorFamily.RangeBased,
  [Operator.NotEquals]: OperatorFamily.ValueBased,
};

export enum Combinator {
  And = "And",
  Or = "Or",
}

export enum FilterSequenceKind {
  Combinator = "combinators",
  FilterGroup = "filter-group",
}

export enum DataType {
  Boolean = "boolean",
  Currency = "currency",
  Custom = "custom",
  DateTime = "date-time",
  Identifier = "identifier",
  NaturalNumber = "natural-number",
  Percent = "percent",
  Percentile = "percentile",
  RationalNumber = "rational-number",
  String = "string",
  WholeNumber = "whole-number",
}

export enum DataContainer {
  Array = "array",
}

export enum ReportType {
  Employee = "employee",
  Plan = "plan",
}

export const ReportTypeLabel = {
  [ReportType.Employee]: "Employee",
  [ReportType.Plan]: "Plan",
};

export enum AggregationType {
  Average = "average",
  Count = "count",
  DistinctCount = "distinct-count",
  Max = "max",
  Median = "median",
  Min = "min",
  Sum = "sum",
}

export enum GraphType {
  BarGraph = "bar-graph",
  Metrics = "metrics",
  LineGraph = "line-graph",
  PaybandAdheranceGraph = "payband-adherance-graph",
  PieGraph = "pie-graph",
  ScatterPlot = "scatter-plot",
  SegmentedTable = "segmented-table",
  StackedBarGraph = "stacked-bar-graph",
}

/** Describes the type of format function that should be used for this value. */
export enum FormatFunction {
  WholeNumber = "whole-number",
  Decimal = "decimal",
  Percent = "percent",
  PercentDecimal = "percent-decimal",
  CurrencySI = "currency-si",
  CurrencyRounded = "currency-rounded",
  CurrencyDecimal = "currency-decimal",
}
