import React from "react";

import type { IconProps } from "./type";

export const Func: React.FC<IconProps> = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="100%"
      height="100%"
      viewBox="0 0 256 256"
      stroke="currentColor"
      fill="none"
    >
      <line
        x1="72"
        y1="128"
        x2="184"
        y2="128"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></line>
      <path
        d="M56,216H85.3a32.1,32.1,0,0,0,31.5-26.3L139.2,66.3A32.1,32.1,0,0,1,170.7,40H200"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
};
