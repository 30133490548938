import type { BaseInputProps } from "./type";

import * as R from "ramda";
import { css } from "styled-components";

import * as Style from "style";

export const ACTIVE_BORDER_COLOR = Style.Color.Brand.Primary;

function toCursor({ isDisabled }: BaseInputProps): string {
  if (isDisabled) {
    return "not-allowed";
  }

  return "text";
}

function toColor({ isDisabled }: BaseInputProps): string {
  if (isDisabled) {
    return Style.Color.Font.Secondary;
  }

  return Style.Color.Font.Secondary;
}

function toBackground({ isDisabled }): string {
  if (isDisabled) {
    return Style.Color.Layout.Background.Secondary;
  }

  return Style.Color.White;
}

function toBorderColor({
  error,
  isDisabled = false,
  isActive = false,
}: BaseInputProps): string {
  if (isDisabled) {
    return Style.Color.Layout.Line;
  }

  if (!R.isNil(error) && !R.isEmpty(error)) {
    return Style.Color.Sentiment.Error.Primary;
  }

  if (isActive) {
    return ACTIVE_BORDER_COLOR;
  }

  return Style.Color.Layout.Line;
}

export const BaseInputPlaceholderStyle = css`
  ::placeholder {
    color: ${Style.Color.Layout.Gray};
  }
`;

export const BaseInputFocusStyle = css`
  &:focus {
    outline: none !important;
    border-width: ${Style.Design.Border.Size};
    border-style: ${Style.Design.Border.Style};
    border-color: ${ACTIVE_BORDER_COLOR};
    box-shadow: none;
  }
`;

export const BaseInputStyle = css`
  ${BaseInputFocusStyle};
  ${BaseInputPlaceholderStyle};

  text-overflow: ellipsis;
  align-items: center;
  cursor: ${toCursor};
  border-color: ${toBorderColor};
  border-radius: ${Style.Design.Rounding.Secondary};
  border-style: ${Style.Design.Border.Style};
  border-width: ${Style.Design.Border.Size};
  color: ${toColor};
  font-family: ${Style.Text.FontFamily.Primary};
  font-size: ${Style.Text.Size.Base};
  font-style: ${Style.Text.Style.Primary};
  font-weight: ${Style.Text.Weight.Primary};
  background: ${toBackground};
  padding: ${Style.Layout.Padding.ExtraSmall};
`;

export const InputStyle = css`
  ${BaseInputStyle};
  height: ${Style.Layout.Input.Height};
  width: 100%;
`;
