/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FilterValue } from './FilterValue';
import {
    FilterValueFromJSON,
    FilterValueFromJSONTyped,
    FilterValueToJSON,
} from './FilterValue';

/**
 * 
 * @export
 * @interface FilterGroup
 */
export interface FilterGroup {
    /**
     * 
     * @type {string}
     * @memberof FilterGroup
     */
    combinator: FilterGroupCombinatorEnum;
    /**
     * 
     * @type {Array<FilterValue>}
     * @memberof FilterGroup
     */
    filters: Array<FilterValue>;
}


/**
 * @export
 */
export const FilterGroupCombinatorEnum = {
    And: 'And',
    Or: 'Or'
} as const;
export type FilterGroupCombinatorEnum = typeof FilterGroupCombinatorEnum[keyof typeof FilterGroupCombinatorEnum];


/**
 * Check if a given object implements the FilterGroup interface.
 */
export function instanceOfFilterGroup(value: object): value is FilterGroup {
    if (!('combinator' in value) || value['combinator'] === undefined) return false;
    if (!('filters' in value) || value['filters'] === undefined) return false;
    return true;
}

export function FilterGroupFromJSON(json: any): FilterGroup {
    return FilterGroupFromJSONTyped(json, false);
}

export function FilterGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterGroup {
    if (json == null) {
        return json;
    }
    return {
        
        'combinator': json['combinator'],
        'filters': ((json['filters'] as Array<any>).map(FilterValueFromJSON)),
    };
}

export function FilterGroupToJSON(value?: FilterGroup | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'combinator': value['combinator'],
        'filters': ((value['filters'] as Array<any>).map(FilterValueToJSON)),
    };
}

