import React from "react";
import styled from "styled-components";

import * as Style from "style";
import { ItemContainer } from "./Item";

export interface ListProps {
  children: React.ReactNode;
}

export const ListContainer = styled.ul`
  background: ${Style.Color.White};

  ${ItemContainer} {
    margin-bottom: ${Style.Layout.Padding.ExtraSmall};
  }
`;

export const List: React.FC<ListProps> = ({ children }) => {
  return <ListContainer role="list">{children}</ListContainer>;
};
