/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BulkUpdateBenchmarkDto,
  BulkUpdatePlanDto,
  CreateMappedPlanDto,
  CreatePlanDto,
  HydratedPlan,
  MappedPlan,
  PlanIdentifier,
  QueryDTO,
  UpdateMappedPlanDto,
  UpdatePlanDto,
} from '../models/index';
import {
    BulkUpdateBenchmarkDtoFromJSON,
    BulkUpdateBenchmarkDtoToJSON,
    BulkUpdatePlanDtoFromJSON,
    BulkUpdatePlanDtoToJSON,
    CreateMappedPlanDtoFromJSON,
    CreateMappedPlanDtoToJSON,
    CreatePlanDtoFromJSON,
    CreatePlanDtoToJSON,
    HydratedPlanFromJSON,
    HydratedPlanToJSON,
    MappedPlanFromJSON,
    MappedPlanToJSON,
    PlanIdentifierFromJSON,
    PlanIdentifierToJSON,
    QueryDTOFromJSON,
    QueryDTOToJSON,
    UpdateMappedPlanDtoFromJSON,
    UpdateMappedPlanDtoToJSON,
    UpdatePlanDtoFromJSON,
    UpdatePlanDtoToJSON,
} from '../models/index';

export interface BulkUpdateBenchmarksRequest {
    bulkUpdateBenchmarkDto: BulkUpdateBenchmarkDto;
}

export interface BulkUpdatePaybandsRequest {
    bulkUpdatePlanDto: BulkUpdatePlanDto;
}

export interface CreatePlanRequest {
    createPlanDto: CreatePlanDto;
}

export interface CreatePlanBatchRequest {
    createPlanDto: Array<CreatePlanDto>;
}

export interface CreatePlanMappingRequest {
    createMappedPlanDto: CreateMappedPlanDto;
}

export interface ExportPlansAsCsvRequest {
    queryDTO: QueryDTO;
}

export interface FindOneMappedTitleRequest {
    id: string;
}

export interface FindOnePlanRequest {
    id: string;
}

export interface GetPlanCountRequest {
    queryDTO: QueryDTO;
}

export interface RemovePlanMappingRequest {
    id: string;
}

export interface SearchPlansRequest {
    queryDTO: QueryDTO;
}

export interface UpdatePlanRequest {
    id: string;
    updatePlanDto: UpdatePlanDto;
}

export interface UpdatePlanMappingRequest {
    id: string;
    updateMappedPlanDto: UpdateMappedPlanDto;
}

/**
 * 
 */
export class PlanApi extends runtime.BaseAPI {

    /**
     */
    async bulkUpdateBenchmarksRaw(requestParameters: BulkUpdateBenchmarksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['bulkUpdateBenchmarkDto'] == null) {
            throw new runtime.RequiredError(
                'bulkUpdateBenchmarkDto',
                'Required parameter "bulkUpdateBenchmarkDto" was null or undefined when calling bulkUpdateBenchmarks().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/plans/bulk-update/benchmarks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BulkUpdateBenchmarkDtoToJSON(requestParameters['bulkUpdateBenchmarkDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async bulkUpdateBenchmarks(requestParameters: BulkUpdateBenchmarksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.bulkUpdateBenchmarksRaw(requestParameters, initOverrides);
    }

    /**
     */
    async bulkUpdatePaybandsRaw(requestParameters: BulkUpdatePaybandsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['bulkUpdatePlanDto'] == null) {
            throw new runtime.RequiredError(
                'bulkUpdatePlanDto',
                'Required parameter "bulkUpdatePlanDto" was null or undefined when calling bulkUpdatePaybands().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/plans/bulk-update/paybands`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BulkUpdatePlanDtoToJSON(requestParameters['bulkUpdatePlanDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async bulkUpdatePaybands(requestParameters: BulkUpdatePaybandsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.bulkUpdatePaybandsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async createPlanRaw(requestParameters: CreatePlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PlanIdentifier>> {
        if (requestParameters['createPlanDto'] == null) {
            throw new runtime.RequiredError(
                'createPlanDto',
                'Required parameter "createPlanDto" was null or undefined when calling createPlan().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/plans`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePlanDtoToJSON(requestParameters['createPlanDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlanIdentifierFromJSON(jsonValue));
    }

    /**
     */
    async createPlan(requestParameters: CreatePlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PlanIdentifier> {
        const response = await this.createPlanRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createPlanBatchRaw(requestParameters: CreatePlanBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PlanIdentifier>>> {
        if (requestParameters['createPlanDto'] == null) {
            throw new runtime.RequiredError(
                'createPlanDto',
                'Required parameter "createPlanDto" was null or undefined when calling createPlanBatch().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/plans/batch`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['createPlanDto']!.map(CreatePlanDtoToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PlanIdentifierFromJSON));
    }

    /**
     */
    async createPlanBatch(requestParameters: CreatePlanBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PlanIdentifier>> {
        const response = await this.createPlanBatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createPlanMappingRaw(requestParameters: CreatePlanMappingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['createMappedPlanDto'] == null) {
            throw new runtime.RequiredError(
                'createMappedPlanDto',
                'Required parameter "createMappedPlanDto" was null or undefined when calling createPlanMapping().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/plans/mapping`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateMappedPlanDtoToJSON(requestParameters['createMappedPlanDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async createPlanMapping(requestParameters: CreatePlanMappingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createPlanMappingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exportPlansAsCsvRaw(requestParameters: ExportPlansAsCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['queryDTO'] == null) {
            throw new runtime.RequiredError(
                'queryDTO',
                'Required parameter "queryDTO" was null or undefined when calling exportPlansAsCsv().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/plans/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QueryDTOToJSON(requestParameters['queryDTO']),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async exportPlansAsCsv(requestParameters: ExportPlansAsCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.exportPlansAsCsvRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findAllMappedTitlesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MappedPlan>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/plans/mapping`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MappedPlanFromJSON));
    }

    /**
     */
    async findAllMappedTitles(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MappedPlan>> {
        const response = await this.findAllMappedTitlesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async findAllPlansRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<HydratedPlan>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/plans`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HydratedPlanFromJSON));
    }

    /**
     */
    async findAllPlans(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<HydratedPlan>> {
        const response = await this.findAllPlansRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async findOneMappedTitleRaw(requestParameters: FindOneMappedTitleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MappedPlan>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling findOneMappedTitle().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/plans/mapping/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MappedPlanFromJSON(jsonValue));
    }

    /**
     */
    async findOneMappedTitle(requestParameters: FindOneMappedTitleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MappedPlan> {
        const response = await this.findOneMappedTitleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findOnePlanRaw(requestParameters: FindOnePlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HydratedPlan>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling findOnePlan().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/plans/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HydratedPlanFromJSON(jsonValue));
    }

    /**
     */
    async findOnePlan(requestParameters: FindOnePlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HydratedPlan> {
        const response = await this.findOnePlanRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPlanCountRaw(requestParameters: GetPlanCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['queryDTO'] == null) {
            throw new runtime.RequiredError(
                'queryDTO',
                'Required parameter "queryDTO" was null or undefined when calling getPlanCount().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/plans/count`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QueryDTOToJSON(requestParameters['queryDTO']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async getPlanCount(requestParameters: GetPlanCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.getPlanCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removePlanMappingRaw(requestParameters: RemovePlanMappingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling removePlanMapping().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/plans/mapping/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async removePlanMapping(requestParameters: RemovePlanMappingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removePlanMappingRaw(requestParameters, initOverrides);
    }

    /**
     */
    async searchPlansRaw(requestParameters: SearchPlansRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<HydratedPlan>>> {
        if (requestParameters['queryDTO'] == null) {
            throw new runtime.RequiredError(
                'queryDTO',
                'Required parameter "queryDTO" was null or undefined when calling searchPlans().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/plans/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QueryDTOToJSON(requestParameters['queryDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HydratedPlanFromJSON));
    }

    /**
     */
    async searchPlans(requestParameters: SearchPlansRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<HydratedPlan>> {
        const response = await this.searchPlansRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updatePlanRaw(requestParameters: UpdatePlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updatePlan().'
            );
        }

        if (requestParameters['updatePlanDto'] == null) {
            throw new runtime.RequiredError(
                'updatePlanDto',
                'Required parameter "updatePlanDto" was null or undefined when calling updatePlan().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/plans/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePlanDtoToJSON(requestParameters['updatePlanDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updatePlan(requestParameters: UpdatePlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updatePlanRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updatePlanMappingRaw(requestParameters: UpdatePlanMappingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updatePlanMapping().'
            );
        }

        if (requestParameters['updateMappedPlanDto'] == null) {
            throw new runtime.RequiredError(
                'updateMappedPlanDto',
                'Required parameter "updateMappedPlanDto" was null or undefined when calling updatePlanMapping().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/plans/mapping/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateMappedPlanDtoToJSON(requestParameters['updateMappedPlanDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updatePlanMapping(requestParameters: UpdatePlanMappingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updatePlanMappingRaw(requestParameters, initOverrides);
    }

}
