import type { BaseInputProps } from "./type";
import type { CurrencyInputProps } from "./Currency";
import type { NaturalNumberInputProps } from "./NaturalNumber";
import type { PercentInputProps } from "./Percent";
import type { RationalNumberInputProps } from "./RationalNumber";

import React from "react";

import { Alignment } from "../constant";
import { BaseInput } from "./Base";
import { CurrencyInput } from "./Currency";
import { NaturalNumberInput } from "./NaturalNumber";
import { PercentInput } from "./Percent";
import { RationalNumberInput } from "./RationalNumber";

export interface InputSpecializations extends React.FC<BaseInputProps> {
  Currency: React.FC<CurrencyInputProps>;
  NaturalNumber: React.FC<NaturalNumberInputProps>;
  Percent: React.FC<PercentInputProps>;
  RationalNumber: React.FC<RationalNumberInputProps>;
  Alignment: typeof Alignment;
}

/**
 * Default input field.
 *
 * This is the LEAST specialized input.
 */
export const Input: InputSpecializations = (props) => {
  return <BaseInput {...props} />;
};

Input.Alignment = Alignment;
Input.Currency = CurrencyInput;
Input.NaturalNumber = NaturalNumberInput;
Input.Percent = PercentInput;
Input.RationalNumber = RationalNumberInput;
