import type { D3SVG } from "data/d3";

import { css } from "styled-components";

import { Color } from "./color";

// Application font size range.
const Size = {
  ExtraLarge: "28px",
  Large: "22px",
  Medium: "18px",
  Base: "14px",
  Small: "12px",
};

const Style = {
  Primary: "normal",
};

const FontFamily = {
  Primary: "Work Sans",
  Monospace: "monospace",
};

const Weight = {
  // Primary font weight.
  Primary: "300",

  // Secondary font weight.
  Secondary: "400",

  // Tertiary font weight.
  Tertiary: "500",
};

const Base = css`
  font-family: ${FontFamily.Primary};
  font-style: ${Style.Primary};
`;

const Heading = css`
  ${Base};
  font-size: ${Size.ExtraLarge};
  font-weight: ${Weight.Tertiary};
`;

const SubHeading = css`
  ${Base};
  font-size: ${Size.Medium};
  font-weight: ${Weight.Tertiary};
`;

const SubTitle = css`
  ${Base};
  font-size: ${Size.Base};
  font-weight: ${Weight.Secondary};
`;

const Title = css`
  ${Base};
  font-size: ${Size.Base};
  font-weight: ${Weight.Tertiary};
`;

const Paragraph = css`
  ${Base};
  font-size: ${Size.Base};
  font-weight: ${Weight.Secondary};
`;

const Link = css`
  ${Base};
  font-size: ${Size.Base};
  font-weight: ${Weight.Tertiary};
`;

const Truncate = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Definition = {
  Base,
  Heading,
  Link,
  Paragraph,
  Title,
  Truncate,
  SubHeading,
  SubTitle,
};

export const Text = {
  Definition,
  FontFamily,
  Size,
  Style,
  Weight,
};

export const D3Text = {
  Title: (svg: D3SVG): D3SVG => {
    return svg
      .style("color", Color.Font.Secondary)
      .style("font-family", FontFamily.Primary)
      .style("font-size", Size.Base)
      .style("font-style", Style.Primary)
      .style("font-weight", Weight.Secondary);
  },

  Paragraph: (svg: D3SVG): D3SVG => {
    return svg
      .style("color", Color.Font.Secondary)
      .style("font-family", FontFamily.Primary)
      .style("font-size", Size.Base)
      .style("font-style", Style.Primary)
      .style("font-weight", Weight.Primary);
  },
};
