/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExchangeRate
 */
export interface ExchangeRate {
    /**
     * 
     * @type {string}
     * @memberof ExchangeRate
     */
    base: string;
    /**
     * 
     * @type {object}
     * @memberof ExchangeRate
     */
    rates: object;
}

/**
 * Check if a given object implements the ExchangeRate interface.
 */
export function instanceOfExchangeRate(value: object): value is ExchangeRate {
    if (!('base' in value) || value['base'] === undefined) return false;
    if (!('rates' in value) || value['rates'] === undefined) return false;
    return true;
}

export function ExchangeRateFromJSON(json: any): ExchangeRate {
    return ExchangeRateFromJSONTyped(json, false);
}

export function ExchangeRateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExchangeRate {
    if (json == null) {
        return json;
    }
    return {
        
        'base': json['base'],
        'rates': json['rates'],
    };
}

export function ExchangeRateToJSON(value?: ExchangeRate | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'base': value['base'],
        'rates': value['rates'],
    };
}

