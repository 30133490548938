import type { AnchorProps } from "./type";

import * as R from "ramda";
import React from "react";
import styled from "styled-components";

const toPosition = R.curry((prop: string, props: AnchorProps): string => {
  const value: number | undefined = R.prop(prop, props);

  return R.isNil(value) ? "auto" : `${value}px`;
});

export const AnchorContainer = styled.div<AnchorProps>`
  bottom: ${toPosition("bottom")};
  left: ${toPosition("left")};
  position: absolute;
  right: ${toPosition("right")};
  top: ${toPosition("top")};
`;

export const Anchor: React.FC<AnchorProps> = (props) => {
  const { children } = props;

  return <AnchorContainer {...props}>{children}</AnchorContainer>;
};
