import * as R from "ramda";

function escapeRegExp(value: string) {
  return value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export function toSearchTokens(query: string): RegExp[] {
  return query
    .split(/\s+/)
    .filter(R.complement(R.isNil))
    .map((term: string) => new RegExp(escapeRegExp(term), "i"));
}

export function isMatch(tokens: RegExp[], value: string): boolean {
  return tokens.every((token: RegExp): boolean => {
    return token.test(value);
  });
}

export function queryHasMatch(query: string, value: string): boolean {
  const tokens = toSearchTokens(query);

  return isMatch(tokens, value);
}
