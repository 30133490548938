import React from "react";

import type { IconProps } from "./type";

export const Calendar: React.FC<IconProps> = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="100%"
      height="100%"
      viewBox="0 0 256 256"
      stroke="currentColor"
      fill="none"
    >
      <rect
        x="40"
        y="40"
        width="176"
        height="176"
        rx="8"
        strokeWidth="16"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      ></rect>
      <line
        x1="176"
        y1="24"
        x2="176"
        y2="56"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      ></line>
      <line
        x1="80"
        y1="24"
        x2="80"
        y2="56"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      ></line>
      <line
        x1="40"
        y1="88"
        x2="216"
        y2="88"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      ></line>
    </svg>
  );
};
