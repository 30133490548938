/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdatePlanDto
 */
export interface UpdatePlanDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePlanDto
     */
    plan_group_id?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePlanDto
     */
    zone_id?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdatePlanDto
     */
    pay_band_min?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdatePlanDto
     */
    pay_band_max?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdatePlanDto
     */
    currency?: string;
}

/**
 * Check if a given object implements the UpdatePlanDto interface.
 */
export function instanceOfUpdatePlanDto(value: object): value is UpdatePlanDto {
    return true;
}

export function UpdatePlanDtoFromJSON(json: any): UpdatePlanDto {
    return UpdatePlanDtoFromJSONTyped(json, false);
}

export function UpdatePlanDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePlanDto {
    if (json == null) {
        return json;
    }
    return {
        
        'plan_group_id': json['plan_group_id'] == null ? undefined : json['plan_group_id'],
        'zone_id': json['zone_id'] == null ? undefined : json['zone_id'],
        'pay_band_min': json['pay_band_min'] == null ? undefined : json['pay_band_min'],
        'pay_band_max': json['pay_band_max'] == null ? undefined : json['pay_band_max'],
        'currency': json['currency'] == null ? undefined : json['currency'],
    };
}

export function UpdatePlanDtoToJSON(value?: UpdatePlanDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'plan_group_id': value['plan_group_id'],
        'zone_id': value['zone_id'],
        'pay_band_min': value['pay_band_min'],
        'pay_band_max': value['pay_band_max'],
        'currency': value['currency'],
    };
}

