import * as R from "ramda";

export const PATH_DELIMITER = ".";

/**
 * Extract a parenthesized value.
 */
export const ParenthesesRegex = /\(([^)]+)\)/;
export function getParenthesizedContent(input: string): string | undefined {
  const matches = ParenthesesRegex.exec(input);

  return R.isNil(matches) ? undefined : matches[1];
}

/**
 * Convert an input string into a path.
 */
export function keyToPath(input: string): string[] {
  return input.split(PATH_DELIMITER);
}

/**
 * Capitalize a single word.
 */
export function capitalize(input: string): string {
  const firstLetter = R.head(input);
  const capitalizeFirst = R.toUpper(firstLetter);
  const rest = R.tail(input);
  return capitalizeFirst + rest;
}

/**
 * Title case a sentence.
 */
export function titleCase(
  value: string,
  options: {
    threshold?: number;
    abbreviations?: string[];
  } = {}
): string {
  const { threshold, abbreviations = [] } = options;
  const lower = R.toLower(value);
  const split = R.split(/(?!\(.*)\s(?![^(]*?\))/g, lower);

  const titleCased = R.addIndex(R.map)(
    (word: string, index: number): string => {
      const parenthesizedContent: string | undefined =
        getParenthesizedContent(word);

      if (!R.isNil(parenthesizedContent)) {
        return `(${titleCase(parenthesizedContent, options)})`;
      }

      if (R.includes(word, abbreviations)) {
        return R.toUpper(word);
      }

      if (!R.isNil(threshold) && index !== 0 && word.length <= threshold) {
        return word;
      }

      return capitalize(word);
    },
    split
  );

  const joined = R.join(" ", titleCased);
  return joined;
}

/**
 * Format an array of items to use the oxford comma.
 */
export function oxfordComma(items: string[]): string {
  return items.length > 2
    ? items
        .slice(0, items.length - 1)
        .concat(`and ${items.slice(-1)}`)
        .join(", ")
    : items.join(" and ");
}
