import React from "react";
import * as R from "ramda";

import { DataKey } from "./keys";
import { useData } from "./useData";
import { useApi } from "milio-api/context";
import { PlanApi, PlanHistoryApi } from ".generated/apis";
import { HydratedPlan, QueryDTO } from ".generated/models";
import { ElasticBackedDataHook } from "./type";

export interface PlanElasticData extends ElasticBackedDataHook<HydratedPlan> {
  api: PlanApi;
  mutate: () => void;
}

export const usePlan = (id: string) => {
  const { api } = useApi(PlanApi);

  const fetcher = React.useCallback(() => {
    return api.findOnePlan({ id });
  }, [id]);

  const { data, error, mutate, loading } = useData(
    [DataKey.PlanDetail, id],
    fetcher
  );

  return {
    api,
    plan: data,
    loading,
    error,
    mutate,
  };
};

export const usePlans = (
  initialQuery: QueryDTO = {},
  tag = DataKey.Plan
): PlanElasticData => {
  const { api: planApi } = useApi(PlanApi);
  const [query, setQuery] = React.useState<QueryDTO>(initialQuery);

  const fetcher = React.useCallback(() => {
    return planApi.searchPlans({ queryDTO: query });
  }, [query]);

  const { error, loading, data: plans } = useData(tag, fetcher);

  return {
    api: planApi,
    error,
    loadMore: R.always(undefined),
    isLoading: loading,
    active: plans || [],
    progress: {
      total: (plans || []).length,
      current: (plans || []).length,
    },
    aggregations: {},
    isReady: true,
    mutate: R.always(undefined),
    query,
    setQuery,
  };
};

export const usePlanHistory = (id: string) => {
  const { api } = useApi(PlanHistoryApi);

  const fetcher = React.useCallback(() => {
    return api.getPlanHistory({ id });
  }, [id]);

  const { data, error, mutate, loading } = useData(
    [DataKey.PlanHistory, id],
    fetcher
  );

  return {
    api,
    planHistory: data || [],
    loading,
    error,
    mutate,
  };
};
