import type { User } from "data/user";

import Link from "next/link";
import React from "react";

import Logo from "../Logo.svg";
import { LOGOUT_URL } from "context";
import { Navigation } from "./Navigation";

interface DesktopNavigationMenuProps {
  activeMenuIndex: number;
  menu: any;
  user: User;
}

interface UserMenuProps {
  user: User;
}

export const UserMenu: React.FC<UserMenuProps> = ({ user }) => {
  return (
    <div className="flex flex-shrink-0 borter-t border-layout-line p-4">
      <div className="flex items-center">
        <div>
          <p className="text-sm font-medium text-font-secondary select-none">
            {user?.email}
          </p>
          <p className="text-xs font-medium text-font-tertiary hover:text-primary">
            <Link href={LOGOUT_URL}>Logout</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export const DesktopNavigationMenu: React.FC<DesktopNavigationMenuProps> = ({
  menu,
  activeMenuIndex,
  user,
}) => (
  <div className="hidden md:flex md:flex-shrink-0">
    <div className="flex flex-col w-52 bg-white border-r border-r-layout-line px-4">
      <div className="flex flex-col flex-grow overflow-y-auto bg-white">
        <div className="text-primary mt-7 mb-4 flex justify-center">
          <Logo style={{ height: "48px" }} />
        </div>
        <div className="flex-grow flex flex-col">
          <Navigation
            items={menu}
            activeMenuIndex={activeMenuIndex}
            className="flex-1"
          />
        </div>
        <UserMenu user={user} />
      </div>
    </div>
  </div>
);
