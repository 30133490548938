/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RegionStatDatum } from './RegionStatDatum';
import {
    RegionStatDatumFromJSON,
    RegionStatDatumFromJSONTyped,
    RegionStatDatumToJSON,
} from './RegionStatDatum';

/**
 * 
 * @export
 * @interface RegionStats
 */
export interface RegionStats {
    /**
     * 
     * @type {RegionStatDatum}
     * @memberof RegionStats
     */
    _1: RegionStatDatum;
    /**
     * 
     * @type {RegionStatDatum}
     * @memberof RegionStats
     */
    _2: RegionStatDatum;
    /**
     * 
     * @type {RegionStatDatum}
     * @memberof RegionStats
     */
    _4: RegionStatDatum;
    /**
     * 
     * @type {RegionStatDatum}
     * @memberof RegionStats
     */
    _8: RegionStatDatum;
    /**
     * 
     * @type {RegionStatDatum}
     * @memberof RegionStats
     */
    _9: RegionStatDatum;
    /**
     * 
     * @type {RegionStatDatum}
     * @memberof RegionStats
     */
    _10: RegionStatDatum;
    /**
     * 
     * @type {RegionStatDatum}
     * @memberof RegionStats
     */
    _12: RegionStatDatum;
    /**
     * 
     * @type {RegionStatDatum}
     * @memberof RegionStats
     */
    _16: RegionStatDatum;
    /**
     * 
     * @type {RegionStatDatum}
     * @memberof RegionStats
     */
    _17: RegionStatDatum;
    /**
     * 
     * @type {RegionStatDatum}
     * @memberof RegionStats
     */
    _18: RegionStatDatum;
    /**
     * 
     * @type {RegionStatDatum}
     * @memberof RegionStats
     */
    _20: RegionStatDatum;
    /**
     * 
     * @type {RegionStatDatum}
     * @memberof RegionStats
     */
    _32: RegionStatDatum;
    /**
     * 
     * @type {RegionStatDatum}
     * @memberof RegionStats
     */
    _33: RegionStatDatum;
    /**
     * 
     * @type {RegionStatDatum}
     * @memberof RegionStats
     */
    _34: RegionStatDatum;
    /**
     * 
     * @type {RegionStatDatum}
     * @memberof RegionStats
     */
    _36: RegionStatDatum;
    /**
     * 
     * @type {RegionStatDatum}
     * @memberof RegionStats
     */
    _64: RegionStatDatum;
    /**
     * 
     * @type {RegionStatDatum}
     * @memberof RegionStats
     */
    _65: RegionStatDatum;
    /**
     * 
     * @type {RegionStatDatum}
     * @memberof RegionStats
     */
    _66: RegionStatDatum;
    /**
     * 
     * @type {RegionStatDatum}
     * @memberof RegionStats
     */
    _68: RegionStatDatum;
}

/**
 * Check if a given object implements the RegionStats interface.
 */
export function instanceOfRegionStats(value: object): value is RegionStats {
    if (!('_1' in value) || value['_1'] === undefined) return false;
    if (!('_2' in value) || value['_2'] === undefined) return false;
    if (!('_4' in value) || value['_4'] === undefined) return false;
    if (!('_8' in value) || value['_8'] === undefined) return false;
    if (!('_9' in value) || value['_9'] === undefined) return false;
    if (!('_10' in value) || value['_10'] === undefined) return false;
    if (!('_12' in value) || value['_12'] === undefined) return false;
    if (!('_16' in value) || value['_16'] === undefined) return false;
    if (!('_17' in value) || value['_17'] === undefined) return false;
    if (!('_18' in value) || value['_18'] === undefined) return false;
    if (!('_20' in value) || value['_20'] === undefined) return false;
    if (!('_32' in value) || value['_32'] === undefined) return false;
    if (!('_33' in value) || value['_33'] === undefined) return false;
    if (!('_34' in value) || value['_34'] === undefined) return false;
    if (!('_36' in value) || value['_36'] === undefined) return false;
    if (!('_64' in value) || value['_64'] === undefined) return false;
    if (!('_65' in value) || value['_65'] === undefined) return false;
    if (!('_66' in value) || value['_66'] === undefined) return false;
    if (!('_68' in value) || value['_68'] === undefined) return false;
    return true;
}

export function RegionStatsFromJSON(json: any): RegionStats {
    return RegionStatsFromJSONTyped(json, false);
}

export function RegionStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegionStats {
    if (json == null) {
        return json;
    }
    return {
        
        '_1': RegionStatDatumFromJSON(json['1']),
        '_2': RegionStatDatumFromJSON(json['2']),
        '_4': RegionStatDatumFromJSON(json['4']),
        '_8': RegionStatDatumFromJSON(json['8']),
        '_9': RegionStatDatumFromJSON(json['9']),
        '_10': RegionStatDatumFromJSON(json['10']),
        '_12': RegionStatDatumFromJSON(json['12']),
        '_16': RegionStatDatumFromJSON(json['16']),
        '_17': RegionStatDatumFromJSON(json['17']),
        '_18': RegionStatDatumFromJSON(json['18']),
        '_20': RegionStatDatumFromJSON(json['20']),
        '_32': RegionStatDatumFromJSON(json['32']),
        '_33': RegionStatDatumFromJSON(json['33']),
        '_34': RegionStatDatumFromJSON(json['34']),
        '_36': RegionStatDatumFromJSON(json['36']),
        '_64': RegionStatDatumFromJSON(json['64']),
        '_65': RegionStatDatumFromJSON(json['65']),
        '_66': RegionStatDatumFromJSON(json['66']),
        '_68': RegionStatDatumFromJSON(json['68']),
    };
}

export function RegionStatsToJSON(value?: RegionStats | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        '1': RegionStatDatumToJSON(value['_1']),
        '2': RegionStatDatumToJSON(value['_2']),
        '4': RegionStatDatumToJSON(value['_4']),
        '8': RegionStatDatumToJSON(value['_8']),
        '9': RegionStatDatumToJSON(value['_9']),
        '10': RegionStatDatumToJSON(value['_10']),
        '12': RegionStatDatumToJSON(value['_12']),
        '16': RegionStatDatumToJSON(value['_16']),
        '17': RegionStatDatumToJSON(value['_17']),
        '18': RegionStatDatumToJSON(value['_18']),
        '20': RegionStatDatumToJSON(value['_20']),
        '32': RegionStatDatumToJSON(value['_32']),
        '33': RegionStatDatumToJSON(value['_33']),
        '34': RegionStatDatumToJSON(value['_34']),
        '36': RegionStatDatumToJSON(value['_36']),
        '64': RegionStatDatumToJSON(value['_64']),
        '65': RegionStatDatumToJSON(value['_65']),
        '66': RegionStatDatumToJSON(value['_66']),
        '68': RegionStatDatumToJSON(value['_68']),
    };
}

