import type { LabelProps } from "./type";
import type { TooltipLabelProps } from "./Tooltip";

import React from "react";

import { Label as BaseLabel } from "./Label";
import { TinyLabel } from "./Tiny";
import { TooltipLabel } from "./Tooltip";

export * from "./type";

export interface LabelNamespace extends React.FC<LabelProps> {
  Tiny: React.FC<LabelProps>;
  Tooltip: React.FC<TooltipLabelProps>;
}

export const Label: LabelNamespace = (props) => {
  return <BaseLabel {...props} />;
};

Label.Tiny = TinyLabel;
Label.Tooltip = TooltipLabel;
