/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateFixedExchangeRateItemDto
 */
export interface CreateFixedExchangeRateItemDto {
    /**
     * 
     * @type {string}
     * @memberof CreateFixedExchangeRateItemDto
     */
    period_start: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFixedExchangeRateItemDto
     */
    period_end: string;
    /**
     * 
     * @type {number}
     * @memberof CreateFixedExchangeRateItemDto
     */
    rate: number;
}

/**
 * Check if a given object implements the CreateFixedExchangeRateItemDto interface.
 */
export function instanceOfCreateFixedExchangeRateItemDto(value: object): value is CreateFixedExchangeRateItemDto {
    if (!('period_start' in value) || value['period_start'] === undefined) return false;
    if (!('period_end' in value) || value['period_end'] === undefined) return false;
    if (!('rate' in value) || value['rate'] === undefined) return false;
    return true;
}

export function CreateFixedExchangeRateItemDtoFromJSON(json: any): CreateFixedExchangeRateItemDto {
    return CreateFixedExchangeRateItemDtoFromJSONTyped(json, false);
}

export function CreateFixedExchangeRateItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateFixedExchangeRateItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'period_start': json['period_start'],
        'period_end': json['period_end'],
        'rate': json['rate'],
    };
}

export function CreateFixedExchangeRateItemDtoToJSON(value?: CreateFixedExchangeRateItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'period_start': value['period_start'],
        'period_end': value['period_end'],
        'rate': value['rate'],
    };
}

