import React from "react";

export interface DataHookProps<T> {
  data: T;
  error: string;
  loading: boolean;
  mutate: () => void;
}

export type KeyPart = string | Record<string, unknown>;
export type KeyType = KeyPart | KeyPart[];

export const useData = <T>(
  key: KeyType,
  fetcher: (...args: any[]) => Promise<T>
): DataHookProps<T> => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<T>();
  const [error, setError] = React.useState<string>();
  const [versionMap, setVersionMap] = React.useState<Record<string, number>>(
    {}
  );
  const timeRef = React.useRef<number>(new Date().getTime());

  const keyHash = React.useMemo(() => JSON.stringify(key), [key]);

  const latestRequestRef = React.useRef<{
    keyHash: string;
    version: number;
  }>(null);

  // Check if there is already a request in flight for the same tag and pageIndex
  const isRequestInFlight = React.useMemo(() => {
    return (
      latestRequestRef.current && latestRequestRef.current.keyHash === keyHash
    );
  }, [keyHash, latestRequestRef.current]);

  React.useEffect(() => {
    setLoading(true);

    if (isRequestInFlight) {
      latestRequestRef.current.version += 1;
    } else {
      latestRequestRef.current = { keyHash, version: 0 };
    }
    const nextVersionMap = {
      ...versionMap,
      [keyHash]: latestRequestRef.current.version,
    };
    setVersionMap(nextVersionMap);

    fetcher(key)
      .then((data) => {
        if (nextVersionMap[keyHash] === latestRequestRef.current.version) {
          setData(data);
          setError(undefined);
        }
      })
      .catch((e) => {
        setError(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [keyHash, timeRef.current, fetcher]);

  return {
    data,
    error,
    loading,
    mutate: () => {
      timeRef.current = new Date().getTime();
    },
  };
};
