import type { StandardComponent } from "../type";

import * as R from "ramda";
import React from "react";
import cn from "lib/cn";
import styled from "styled-components";
import { debounce } from "lodash";

import { Alignment } from "./constant";
import { OptionMenu } from "components/library/Select/OptionMenu";
import { Portal } from "components/Portal";
import { SelectOptionContainer } from "components/library/Select/Option";
import { useDetectOutsideClick } from "hooks";
import { getUICursorDataProps } from "components/library/Select/useUICursor";

export interface MenuChildrenProps {
  close: () => void;
  isActive: boolean;
  isDisabled: boolean;
}

export interface MenuProps extends StandardComponent {
  alignment?: Alignment;
  children: React.ReactNode | ((props: any) => React.ReactNode);
  className?: string;
  content: React.ReactNode | ((props: any) => React.ReactNode);
  depth?: number;
  forwardRef?: React.MutableRefObject<HTMLDivElement>;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onKeyDown?: (event: KeyboardEvent) => void;
  onToggle?: (next: boolean) => void;
}

export interface MenuItemsProps extends StandardComponent {
  children: React.ReactNode;
  className?: string;
}

export interface MenuItemProps extends StandardComponent {
  children: React.ReactNode;
  onClick?: () => void;
  selected?: boolean;
  active?: boolean;
}

export interface MenuSpecializations extends React.FC<MenuProps> {
  Items: React.FC<MenuItemsProps>;
  Item: React.FC<MenuItemProps>;
}

const ContentDropdownPadding = 4;

function toMenuContainerCursor({
  isDisabled = false,
}: {
  isDisabled: boolean;
}): string {
  return isDisabled ? "not-allowed" : "auto";
}

function determineVerticalAlignment({ dropdownRect, contentRect }): Alignment {
  const availableBelow =
    window.innerHeight - (contentRect.y + contentRect.height);
  const availableAbove = contentRect.y;

  const needed = dropdownRect.height + ContentDropdownPadding;

  // The space required by the content can fit below.
  if (needed <= availableBelow) {
    return Alignment.Bottom;
    // The space required by the content can fit above.
  } else if (needed <= availableAbove) {
    return Alignment.Top;
  }

  // Otherwise we will overlay on the bottom.
  return Alignment.Bottom;
}

function toPosition({ alignment, contentRef, dropdownRef }) {
  const contentRect = contentRef.current.getBoundingClientRect();
  const dropdownRect = dropdownRef.current.getBoundingClientRect();

  const vAlign: Alignment = determineVerticalAlignment({
    dropdownRect,
    contentRect,
  });
  const top: number =
    vAlign === Alignment.Top
      ? contentRect.y - dropdownRect.height - ContentDropdownPadding
      : contentRect.y +
        contentRef.current.clientHeight +
        ContentDropdownPadding;

  switch (alignment) {
    case Alignment.Right:
      return {
        right: window.innerWidth - contentRect.right,
        top,
      };
    case Alignment.Left:
    default:
      return {
        left: contentRect.x,
        top,
      };
  }
}

const MenuContainer = styled.div<{ isDisabled?: boolean }>`
  width: 100%;

  > div {
    cursor: ${toMenuContainerCursor};
  }
`;

const MenuItem: React.FC<MenuItemProps> = ({
  children,
  onClick = R.always(undefined),
  selected = false,
  active = false,
  isDisabled = false,
}) => {
  return (
    <SelectOptionContainer
      isDisabled={isDisabled}
      isSelected={selected}
      onClick={() => {
        if (!isDisabled) {
          onClick();
        }
      }}
      isActive={active}
      {...getUICursorDataProps({ isActive: active, isSelected: selected })}
    >
      {children}
    </SelectOptionContainer>
  );
};

const MenuItems: React.FC<MenuItemsProps> = ({ children, className = "" }) => {
  return (
    <OptionMenu style={{ minWidth: "14rem" }} className={className}>
      {children}
    </OptionMenu>
  );
};

const Menu: MenuSpecializations = ({
  children,
  className = "",
  content,
  depth = 50,
  forwardRef,
  isDisabled = false,
  onClick = R.always(undefined),
  onKeyDown = R.always(undefined),
  onToggle = R.always(undefined),
  testID,
  alignment = Alignment.Left,
}) => {
  const dropdownRef: React.MutableRefObject<HTMLDivElement> = React.useRef();
  const contentRef: React.RefObject<HTMLDivElement> = React.useRef();
  const [isActive, setIsActive] = useDetectOutsideClick({
    ref: dropdownRef,
    initial: false,
    excludes: [contentRef.current],
    excludePortal: true,
  });
  const [coordinates, setCoordinates] = React.useState({});

  const updateCoordinates = React.useCallback(
    (event) => {
      if (R.isNil(contentRef?.current)) {
        return;
      }

      // Check to see if we are scrolling inside the menu.
      if (event.target.parentElement?.isSameNode(dropdownRef.current)) {
        return;
      }

      const position = toPosition({ contentRef, dropdownRef, alignment });

      setCoordinates(position);
    },
    [contentRef, setCoordinates, dropdownRef]
  );

  React.useEffect(() => {
    if (R.isNil(contentRef?.current) || R.isNil(dropdownRef?.current)) {
      return;
    }

    const position = toPosition({ contentRef, dropdownRef, alignment });

    setCoordinates(position);
  }, [isActive, dropdownRef, contentRef.current]);

  const close = React.useCallback(() => {
    setIsActive(false);
  }, []);

  const debouncedUpdateCoordinates = React.useCallback(
    debounce(updateCoordinates, 10),
    [updateCoordinates]
  );
  const handleKeyDown = (event) => {
    if (isActive) {
      onKeyDown(event);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", debouncedUpdateCoordinates, true);

    return () => {
      window.removeEventListener("scroll", debouncedUpdateCoordinates, true);
    };
  });

  React.useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  React.useEffect(() => {
    onToggle(isActive);
  }, [isActive]);

  const childrenProps: MenuChildrenProps = { close, isActive, isDisabled };

  return (
    <MenuContainer
      data-test-id={testID}
      isDisabled={isDisabled}
      className={cn("relative", className)}
      onClick={(event) => {
        if (!isActive && !isDisabled) {
          updateCoordinates(event);
          setIsActive(!isActive);
        }

        onClick(event);
      }}
    >
      <div ref={contentRef} className="cursor-pointer flex items-center h-full">
        {typeof children === "function" && children(childrenProps)}
        {typeof children !== "function" && children}
      </div>
      <Portal>
        <div
          data-test-id={`${testID}-portal`}
          style={{ ...coordinates, zIndex: depth }}
          ref={(ref) => {
            if (ref) {
              if (dropdownRef) {
                (
                  dropdownRef as React.MutableRefObject<HTMLDivElement>
                ).current = ref;
              }
              if (forwardRef) {
                (forwardRef as React.MutableRefObject<HTMLDivElement>).current =
                  ref;
              }
            }
          }}
          className={cn("absolute", isActive ? "visible" : "hidden")}
        >
          {typeof content === "function" && isActive && content(childrenProps)}
          {typeof content !== "function" && isActive && content}
        </div>
      </Portal>
    </MenuContainer>
  );
};

Menu.Items = MenuItems;
Menu.Item = MenuItem;

export { Menu };
