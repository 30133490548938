/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateZoneDto,
  UpdateZoneDto,
  Zone,
} from '../models/index';
import {
    CreateZoneDtoFromJSON,
    CreateZoneDtoToJSON,
    UpdateZoneDtoFromJSON,
    UpdateZoneDtoToJSON,
    ZoneFromJSON,
    ZoneToJSON,
} from '../models/index';

export interface CreateZoneRequest {
    createZoneDto: CreateZoneDto;
}

export interface FindOneZoneRequest {
    id: string;
}

export interface RemoveZoneRequest {
    id: string;
}

export interface UpdateZoneRequest {
    id: string;
    updateZoneDto: UpdateZoneDto;
}

/**
 * 
 */
export class ZoneApi extends runtime.BaseAPI {

    /**
     */
    async createZoneRaw(requestParameters: CreateZoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Zone>> {
        if (requestParameters['createZoneDto'] == null) {
            throw new runtime.RequiredError(
                'createZoneDto',
                'Required parameter "createZoneDto" was null or undefined when calling createZone().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/zones`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateZoneDtoToJSON(requestParameters['createZoneDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ZoneFromJSON(jsonValue));
    }

    /**
     */
    async createZone(requestParameters: CreateZoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Zone> {
        const response = await this.createZoneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findAllZonesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Zone>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/zones`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ZoneFromJSON));
    }

    /**
     */
    async findAllZones(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Zone>> {
        const response = await this.findAllZonesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async findOneZoneRaw(requestParameters: FindOneZoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Zone>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling findOneZone().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/zones/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ZoneFromJSON(jsonValue));
    }

    /**
     */
    async findOneZone(requestParameters: FindOneZoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Zone> {
        const response = await this.findOneZoneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removeZoneRaw(requestParameters: RemoveZoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling removeZone().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/zones/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async removeZone(requestParameters: RemoveZoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeZoneRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateZoneRaw(requestParameters: UpdateZoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Zone>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateZone().'
            );
        }

        if (requestParameters['updateZoneDto'] == null) {
            throw new runtime.RequiredError(
                'updateZoneDto',
                'Required parameter "updateZoneDto" was null or undefined when calling updateZone().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/zones/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateZoneDtoToJSON(requestParameters['updateZoneDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ZoneFromJSON(jsonValue));
    }

    /**
     */
    async updateZone(requestParameters: UpdateZoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Zone> {
        const response = await this.updateZoneRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
