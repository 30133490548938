import type { D3SVG } from "data/d3";

import { Color } from "./color";

export enum GradientType {
  TopLeftToBottomRight,
  Opacity,
}

interface GradientParams {
  fromColor: string;
  toColor: string;
}

const GradientTypeMap = {
  [GradientType.Opacity]: (
    grad: D3SVG,
    { fromColor }: Partial<GradientParams>
  ) =>
    grad
      .call((grad) =>
        grad
          .append("stop")
          .attr("offset", "0%")
          .attr("stop-opacity", "0%")
          .attr("stop-color", fromColor)
      )
      .call((grad) =>
        grad
          .append("stop")
          .attr("offset", "10%")
          .attr("stop-color", fromColor)
          .attr("stop-opacity", "100%")
      ),
  [GradientType.TopLeftToBottomRight]: (
    grad: D3SVG,
    { fromColor, toColor }: GradientParams
  ) =>
    grad
      .attr("x1", 0)
      .attr("x2", 1)
      .attr("y1", 0)
      .attr("y2", 1)
      .call((grad) =>
        grad.append("stop").attr("offset", "0%").attr("stop-color", fromColor)
      )
      .call((grad) =>
        grad.append("stop").attr("offset", "100%").attr("stop-color", toColor)
      ),
};

let idSequence = 0;

const createGradient = (
  selection: D3SVG,
  type: GradientType,
  fromColor: string,
  toColor: string
) =>
  selection
    .append("linearGradient")
    .attr("id", `gradient-${++idSequence}`)
    .call((g) => {
      const fn = GradientTypeMap[type];
      fn(g, { fromColor, toColor });
    });

export const D3 = {
  Layout: {
    Line: (selection: D3SVG): D3SVG => {
      return selection.style("color", Color.Layout.Line);
    },
  },
  Gradients: {
    LineGraph: (selection: D3SVG, color?: string): D3SVG =>
      createGradient(selection, GradientType.Opacity, color, null),
    // BarChart: (selection: D3SVG): D3SVG => {},
  },
  GraphElements: {
    Bar: (
      selection: D3SVG,
      color: string = Color.Chart.CornflowerBlue
    ): D3SVG => {
      return selection.attr("rx", "5").attr("fill", color);
    },
  },
};
