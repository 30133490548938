/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PolicyType = {
    Employee: 'employee',
    Plan: 'plan'
} as const;
export type PolicyType = typeof PolicyType[keyof typeof PolicyType];


export function instanceOfPolicyType(value: any): boolean {
    for (const key in PolicyType) {
        if (Object.prototype.hasOwnProperty.call(PolicyType, key)) {
            if (PolicyType[key as keyof typeof PolicyType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function PolicyTypeFromJSON(json: any): PolicyType {
    return PolicyTypeFromJSONTyped(json, false);
}

export function PolicyTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PolicyType {
    return json as PolicyType;
}

export function PolicyTypeToJSON(value?: PolicyType | null): any {
    return value as any;
}

