/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CreateFixedExchangeRateItemDto } from './CreateFixedExchangeRateItemDto';
import {
    CreateFixedExchangeRateItemDtoFromJSON,
    CreateFixedExchangeRateItemDtoFromJSONTyped,
    CreateFixedExchangeRateItemDtoToJSON,
} from './CreateFixedExchangeRateItemDto';

/**
 * 
 * @export
 * @interface CreateFixedExchangeRateDto
 */
export interface CreateFixedExchangeRateDto {
    /**
     * 
     * @type {string}
     * @memberof CreateFixedExchangeRateDto
     */
    from_currency: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFixedExchangeRateDto
     */
    to_currency: string;
    /**
     * 
     * @type {Array<CreateFixedExchangeRateItemDto>}
     * @memberof CreateFixedExchangeRateDto
     */
    rates: Array<CreateFixedExchangeRateItemDto>;
}

/**
 * Check if a given object implements the CreateFixedExchangeRateDto interface.
 */
export function instanceOfCreateFixedExchangeRateDto(value: object): value is CreateFixedExchangeRateDto {
    if (!('from_currency' in value) || value['from_currency'] === undefined) return false;
    if (!('to_currency' in value) || value['to_currency'] === undefined) return false;
    if (!('rates' in value) || value['rates'] === undefined) return false;
    return true;
}

export function CreateFixedExchangeRateDtoFromJSON(json: any): CreateFixedExchangeRateDto {
    return CreateFixedExchangeRateDtoFromJSONTyped(json, false);
}

export function CreateFixedExchangeRateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateFixedExchangeRateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'from_currency': json['from_currency'],
        'to_currency': json['to_currency'],
        'rates': ((json['rates'] as Array<any>).map(CreateFixedExchangeRateItemDtoFromJSON)),
    };
}

export function CreateFixedExchangeRateDtoToJSON(value?: CreateFixedExchangeRateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'from_currency': value['from_currency'],
        'to_currency': value['to_currency'],
        'rates': ((value['rates'] as Array<any>).map(CreateFixedExchangeRateItemDtoToJSON)),
    };
}

