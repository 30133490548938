/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FixedExchangeRateItem
 */
export interface FixedExchangeRateItem {
    /**
     * 
     * @type {string}
     * @memberof FixedExchangeRateItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FixedExchangeRateItem
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof FixedExchangeRateItem
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof FixedExchangeRateItem
     */
    deleted_at: string | null;
    /**
     * 
     * @type {string}
     * @memberof FixedExchangeRateItem
     */
    rate_id: string;
    /**
     * 
     * @type {number}
     * @memberof FixedExchangeRateItem
     */
    rate: number;
    /**
     * 
     * @type {string}
     * @memberof FixedExchangeRateItem
     */
    period_start: string;
    /**
     * 
     * @type {string}
     * @memberof FixedExchangeRateItem
     */
    period_end: string;
}

/**
 * Check if a given object implements the FixedExchangeRateItem interface.
 */
export function instanceOfFixedExchangeRateItem(value: object): value is FixedExchangeRateItem {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('created_at' in value) || value['created_at'] === undefined) return false;
    if (!('updated_at' in value) || value['updated_at'] === undefined) return false;
    if (!('deleted_at' in value) || value['deleted_at'] === undefined) return false;
    if (!('rate_id' in value) || value['rate_id'] === undefined) return false;
    if (!('rate' in value) || value['rate'] === undefined) return false;
    if (!('period_start' in value) || value['period_start'] === undefined) return false;
    if (!('period_end' in value) || value['period_end'] === undefined) return false;
    return true;
}

export function FixedExchangeRateItemFromJSON(json: any): FixedExchangeRateItem {
    return FixedExchangeRateItemFromJSONTyped(json, false);
}

export function FixedExchangeRateItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): FixedExchangeRateItem {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created_at': json['created_at'],
        'updated_at': json['updated_at'],
        'deleted_at': json['deleted_at'],
        'rate_id': json['rate_id'],
        'rate': json['rate'],
        'period_start': json['period_start'],
        'period_end': json['period_end'],
    };
}

export function FixedExchangeRateItemToJSON(value?: FixedExchangeRateItem | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'created_at': value['created_at'],
        'updated_at': value['updated_at'],
        'deleted_at': value['deleted_at'],
        'rate_id': value['rate_id'],
        'rate': value['rate'],
        'period_start': value['period_start'],
        'period_end': value['period_end'],
    };
}

