/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Region } from './Region';
import {
    RegionFromJSON,
    RegionFromJSONTyped,
    RegionToJSON,
} from './Region';

/**
 * 
 * @export
 * @interface RegionMetrics
 */
export interface RegionMetrics {
    /**
     * 
     * @type {Array<Region>}
     * @memberof RegionMetrics
     */
    _9: Array<Region>;
    /**
     * 
     * @type {Array<Region>}
     * @memberof RegionMetrics
     */
    _10: Array<Region>;
    /**
     * 
     * @type {Array<Region>}
     * @memberof RegionMetrics
     */
    _12: Array<Region>;
    /**
     * 
     * @type {Array<Region>}
     * @memberof RegionMetrics
     */
    _17: Array<Region>;
    /**
     * 
     * @type {Array<Region>}
     * @memberof RegionMetrics
     */
    _18: Array<Region>;
    /**
     * 
     * @type {Array<Region>}
     * @memberof RegionMetrics
     */
    _20: Array<Region>;
    /**
     * 
     * @type {Array<Region>}
     * @memberof RegionMetrics
     */
    _33: Array<Region>;
    /**
     * 
     * @type {Array<Region>}
     * @memberof RegionMetrics
     */
    _34: Array<Region>;
    /**
     * 
     * @type {Array<Region>}
     * @memberof RegionMetrics
     */
    _36: Array<Region>;
    /**
     * 
     * @type {Array<Region>}
     * @memberof RegionMetrics
     */
    _65: Array<Region>;
    /**
     * 
     * @type {Array<Region>}
     * @memberof RegionMetrics
     */
    _66: Array<Region>;
    /**
     * 
     * @type {Array<Region>}
     * @memberof RegionMetrics
     */
    _68: Array<Region>;
}

/**
 * Check if a given object implements the RegionMetrics interface.
 */
export function instanceOfRegionMetrics(value: object): value is RegionMetrics {
    if (!('_9' in value) || value['_9'] === undefined) return false;
    if (!('_10' in value) || value['_10'] === undefined) return false;
    if (!('_12' in value) || value['_12'] === undefined) return false;
    if (!('_17' in value) || value['_17'] === undefined) return false;
    if (!('_18' in value) || value['_18'] === undefined) return false;
    if (!('_20' in value) || value['_20'] === undefined) return false;
    if (!('_33' in value) || value['_33'] === undefined) return false;
    if (!('_34' in value) || value['_34'] === undefined) return false;
    if (!('_36' in value) || value['_36'] === undefined) return false;
    if (!('_65' in value) || value['_65'] === undefined) return false;
    if (!('_66' in value) || value['_66'] === undefined) return false;
    if (!('_68' in value) || value['_68'] === undefined) return false;
    return true;
}

export function RegionMetricsFromJSON(json: any): RegionMetrics {
    return RegionMetricsFromJSONTyped(json, false);
}

export function RegionMetricsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegionMetrics {
    if (json == null) {
        return json;
    }
    return {
        
        '_9': ((json['9'] as Array<any>).map(RegionFromJSON)),
        '_10': ((json['10'] as Array<any>).map(RegionFromJSON)),
        '_12': ((json['12'] as Array<any>).map(RegionFromJSON)),
        '_17': ((json['17'] as Array<any>).map(RegionFromJSON)),
        '_18': ((json['18'] as Array<any>).map(RegionFromJSON)),
        '_20': ((json['20'] as Array<any>).map(RegionFromJSON)),
        '_33': ((json['33'] as Array<any>).map(RegionFromJSON)),
        '_34': ((json['34'] as Array<any>).map(RegionFromJSON)),
        '_36': ((json['36'] as Array<any>).map(RegionFromJSON)),
        '_65': ((json['65'] as Array<any>).map(RegionFromJSON)),
        '_66': ((json['66'] as Array<any>).map(RegionFromJSON)),
        '_68': ((json['68'] as Array<any>).map(RegionFromJSON)),
    };
}

export function RegionMetricsToJSON(value?: RegionMetrics | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        '9': ((value['_9'] as Array<any>).map(RegionToJSON)),
        '10': ((value['_10'] as Array<any>).map(RegionToJSON)),
        '12': ((value['_12'] as Array<any>).map(RegionToJSON)),
        '17': ((value['_17'] as Array<any>).map(RegionToJSON)),
        '18': ((value['_18'] as Array<any>).map(RegionToJSON)),
        '20': ((value['_20'] as Array<any>).map(RegionToJSON)),
        '33': ((value['_33'] as Array<any>).map(RegionToJSON)),
        '34': ((value['_34'] as Array<any>).map(RegionToJSON)),
        '36': ((value['_36'] as Array<any>).map(RegionToJSON)),
        '65': ((value['_65'] as Array<any>).map(RegionToJSON)),
        '66': ((value['_66'] as Array<any>).map(RegionToJSON)),
        '68': ((value['_68'] as Array<any>).map(RegionToJSON)),
    };
}

