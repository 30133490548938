import type { BaseButtonProps } from "./Base";

import React from "react";
import styled from "styled-components";

import { BaseButton } from "./Base";
import { DangerButtonStyle, FixedButtonStyle } from "./style";

export const DangerButtonContainer = styled.div`
  display: inline-block;

  > button {
    ${FixedButtonStyle};
    ${DangerButtonStyle};
  }
`;

/**
 * Button used for dangerous actions.
 */
export const DangerButton: React.FC<BaseButtonProps> = (props) => {
  const { isDisabled } = props;

  return (
    <DangerButtonContainer isDisabled={isDisabled}>
      <BaseButton {...props} />
    </DangerButtonContainer>
  );
};
