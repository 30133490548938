import type { SpecializedNotificationDotProps } from "./type";

import React from "react";

import { NotificationDot } from "./NotificationDot";
import { Sentiment } from "../constant";
import { sentimentToPrimaryColor } from "../util";

export const InfoNotificationDot: React.FC<SpecializedNotificationDotProps> = (
  props
) => {
  return (
    <NotificationDot
      color={sentimentToPrimaryColor({ sentiment: Sentiment.Info })}
      {...props}
    />
  );
};
