/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { QueryDTO } from './QueryDTO';
import {
    QueryDTOFromJSON,
    QueryDTOFromJSONTyped,
    QueryDTOToJSON,
} from './QueryDTO';

/**
 * 
 * @export
 * @interface PlanOverviewQueryDto
 */
export interface PlanOverviewQueryDto {
    /**
     * 
     * @type {QueryDTO}
     * @memberof PlanOverviewQueryDto
     */
    query: QueryDTO;
}

/**
 * Check if a given object implements the PlanOverviewQueryDto interface.
 */
export function instanceOfPlanOverviewQueryDto(value: object): value is PlanOverviewQueryDto {
    if (!('query' in value) || value['query'] === undefined) return false;
    return true;
}

export function PlanOverviewQueryDtoFromJSON(json: any): PlanOverviewQueryDto {
    return PlanOverviewQueryDtoFromJSONTyped(json, false);
}

export function PlanOverviewQueryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanOverviewQueryDto {
    if (json == null) {
        return json;
    }
    return {
        
        'query': QueryDTOFromJSON(json['query']),
    };
}

export function PlanOverviewQueryDtoToJSON(value?: PlanOverviewQueryDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'query': QueryDTOToJSON(value['query']),
    };
}

