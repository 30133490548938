import styled from "styled-components";

import * as React from "react";
import * as Style from "style";

export const HorizontalDivider = styled.p`
  border-color: ${Style.Color.Layout.Line};
  border-style: ${Style.Design.Border.Style};
  border-top-width: ${Style.Design.Border.Size};
  margin: 0px;
  height: 1px;
`;

export const PaddedHorizontalDivider = styled(HorizontalDivider)`
  margin: ${Style.Layout.Padding.Small} 0px;
`;

export const SecondaryHorizontalDivider = styled(HorizontalDivider)`
  border-style: dotted;
  border-color: ${Style.Color.Layout.Line};
`;

export interface HorizontalDividerNamespace
  extends React.VoidFunctionComponent {
  Padded: React.VoidFunctionComponent; // TODO: Deprecate.
  Secondary: React.VoidFunctionComponent;
}

HorizontalDivider.Padded = PaddedHorizontalDivider;
HorizontalDivider.Secondary = SecondaryHorizontalDivider;
