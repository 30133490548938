import { css } from "styled-components";

import { Color } from "./color";
import { Layout } from "./layout";

const Rounding = {
  Primary: "15px",
  Secondary: "10px",
  Tertiary: "5px",
};

const Border = {
  Size: "1px",
  Style: "solid",
};

const Depth = {
  BoxShadow: "0px 2px 4px 1px rgba(125,125,125,0.1)",
};

const Bordering = css`
  border-color: ${Color.Layout.Line};
  border-radius: ${Rounding.Secondary};
  border-style: ${Border.Style};
  border-width: ${Border.Size};
`;

const Bounding = css`
  ${Bordering};
  background: ${Color.White};
  padding: ${Layout.Padding.Small};
`;

const Raised = css`
  border-radius: ${Rounding.Primary};
  box-shadow: ${Depth.BoxShadow};
`;

const Definition = {
  Bordering,
  Bounding,
  Raised,
};

export const Design = {
  Border,
  Depth,
  Rounding,
  Definition,
};
