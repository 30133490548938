/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Benchmark,
  CreateBenchmarkDto,
  UpdateBenchmarkDto,
} from '../models/index';
import {
    BenchmarkFromJSON,
    BenchmarkToJSON,
    CreateBenchmarkDtoFromJSON,
    CreateBenchmarkDtoToJSON,
    UpdateBenchmarkDtoFromJSON,
    UpdateBenchmarkDtoToJSON,
} from '../models/index';

export interface CreateBenchmarkRequest {
    createBenchmarkDto: CreateBenchmarkDto;
}

export interface CreateBenchmarkBatchRequest {
    requestBody: Array<string>;
}

export interface FindOneBenchmarkRequest {
    id: string;
}

export interface RemoveBenchmarkRequest {
    id: string;
}

export interface UpdateBenchmarkRequest {
    id: string;
    updateBenchmarkDto: UpdateBenchmarkDto;
}

/**
 * 
 */
export class BenchmarkApi extends runtime.BaseAPI {

    /**
     */
    async createBenchmarkRaw(requestParameters: CreateBenchmarkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Benchmark>> {
        if (requestParameters['createBenchmarkDto'] == null) {
            throw new runtime.RequiredError(
                'createBenchmarkDto',
                'Required parameter "createBenchmarkDto" was null or undefined when calling createBenchmark().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/benchmarks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateBenchmarkDtoToJSON(requestParameters['createBenchmarkDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BenchmarkFromJSON(jsonValue));
    }

    /**
     */
    async createBenchmark(requestParameters: CreateBenchmarkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Benchmark> {
        const response = await this.createBenchmarkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createBenchmarkBatchRaw(requestParameters: CreateBenchmarkBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Benchmark>>> {
        if (requestParameters['requestBody'] == null) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter "requestBody" was null or undefined when calling createBenchmarkBatch().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/benchmarks/batch`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BenchmarkFromJSON));
    }

    /**
     */
    async createBenchmarkBatch(requestParameters: CreateBenchmarkBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Benchmark>> {
        const response = await this.createBenchmarkBatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findAllBenchmarksRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Benchmark>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/benchmarks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BenchmarkFromJSON));
    }

    /**
     */
    async findAllBenchmarks(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Benchmark>> {
        const response = await this.findAllBenchmarksRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async findOneBenchmarkRaw(requestParameters: FindOneBenchmarkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Benchmark>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling findOneBenchmark().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/benchmarks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BenchmarkFromJSON(jsonValue));
    }

    /**
     */
    async findOneBenchmark(requestParameters: FindOneBenchmarkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Benchmark> {
        const response = await this.findOneBenchmarkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removeBenchmarkRaw(requestParameters: RemoveBenchmarkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling removeBenchmark().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/benchmarks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async removeBenchmark(requestParameters: RemoveBenchmarkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeBenchmarkRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateBenchmarkRaw(requestParameters: UpdateBenchmarkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Benchmark>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateBenchmark().'
            );
        }

        if (requestParameters['updateBenchmarkDto'] == null) {
            throw new runtime.RequiredError(
                'updateBenchmarkDto',
                'Required parameter "updateBenchmarkDto" was null or undefined when calling updateBenchmark().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/benchmarks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateBenchmarkDtoToJSON(requestParameters['updateBenchmarkDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BenchmarkFromJSON(jsonValue));
    }

    /**
     */
    async updateBenchmark(requestParameters: UpdateBenchmarkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Benchmark> {
        const response = await this.updateBenchmarkRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
