/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ChatMessage } from './ChatMessage';
import {
    ChatMessageFromJSON,
    ChatMessageFromJSONTyped,
    ChatMessageToJSON,
} from './ChatMessage';
import type { Report } from './Report';
import {
    ReportFromJSON,
    ReportFromJSONTyped,
    ReportToJSON,
} from './Report';

/**
 * 
 * @export
 * @interface ChatConversationDto
 */
export interface ChatConversationDto {
    /**
     * 
     * @type {string}
     * @memberof ChatConversationDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ChatConversationDto
     */
    timestamp: string;
    /**
     * 
     * @type {string}
     * @memberof ChatConversationDto
     */
    title: string;
    /**
     * 
     * @type {Array<ChatMessage>}
     * @memberof ChatConversationDto
     */
    messages: Array<ChatMessage>;
    /**
     * 
     * @type {Array<Report>}
     * @memberof ChatConversationDto
     */
    reports: Array<Report>;
}

/**
 * Check if a given object implements the ChatConversationDto interface.
 */
export function instanceOfChatConversationDto(value: object): value is ChatConversationDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('timestamp' in value) || value['timestamp'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('messages' in value) || value['messages'] === undefined) return false;
    if (!('reports' in value) || value['reports'] === undefined) return false;
    return true;
}

export function ChatConversationDtoFromJSON(json: any): ChatConversationDto {
    return ChatConversationDtoFromJSONTyped(json, false);
}

export function ChatConversationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatConversationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'timestamp': json['timestamp'],
        'title': json['title'],
        'messages': ((json['messages'] as Array<any>).map(ChatMessageFromJSON)),
        'reports': ((json['reports'] as Array<any>).map(ReportFromJSON)),
    };
}

export function ChatConversationDtoToJSON(value?: ChatConversationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'timestamp': value['timestamp'],
        'title': value['title'],
        'messages': ((value['messages'] as Array<any>).map(ChatMessageToJSON)),
        'reports': ((value['reports'] as Array<any>).map(ReportToJSON)),
    };
}

