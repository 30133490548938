import React from "react";
import styled from "styled-components";

import { EditButton } from "./EditButton";
import { EditableMode } from "./constant";
import { useDetectOutsideClick } from "hooks";

export interface EditableChildrenProps {
  isEditing: boolean;
  button: React.ReactNode;
  mode: EditableMode;
  readOnly: boolean;
}

export interface EditableProps {
  children: (props: EditableChildrenProps) => React.ReactNode;
  initial?: EditableMode;
}

export const EditableContainer = styled.div``;

export const Editable: React.FC<EditableProps> = ({
  children,
  initial = EditableMode.Read,
}) => {
  const ref: React.MutableRefObject<HTMLDivElement> = React.useRef();

  const [isActive, setIsActive] = useDetectOutsideClick({
    ref,
    initial: true,
    excludePortal: true,
  });

  const [mode, setMode] = React.useState<EditableMode>(initial);

  const props: EditableChildrenProps = React.useMemo(() => {
    return {
      button: <EditButton mode={mode} onChange={setMode} />,
      isEditing: mode === EditableMode.Write,
      mode,
      readOnly: mode === EditableMode.Read,
    };
  }, [mode, setMode]);

  React.useEffect(() => {
    if (mode === EditableMode.Write) {
      setIsActive(true);
    }
  }, [setIsActive, mode]);

  React.useEffect(() => {
    if (!isActive) {
      setMode(EditableMode.Read);
    }
  }, [isActive]);

  return <EditableContainer ref={ref}>{children(props)}</EditableContainer>;
};
