import type { CheckboxDropdownProps } from "./CheckboxDropdown";
import type { CheckboxListProps } from "./CheckboxList";
import type { CheckboxOptionsProps } from "./CheckboxOptions";
import type { CheckboxProps } from "./Checkbox";

import React from "react";

// TODO: Deprecate options in favor of list.
import { Checkbox as CheckboxComponent } from "./Checkbox";
import { CheckboxDropdown } from "./CheckboxDropdown";
import { CheckboxList } from "./CheckboxList";
import { CheckboxOptions } from "./CheckboxOptions";

export interface CheckboxSpecializations extends React.FC<CheckboxProps> {
  Dropdown: <T>(props: CheckboxDropdownProps<T>) => React.ReactElement;
  List: <T>(props: CheckboxListProps<T>) => React.ReactElement;
  Options: <T>(props: CheckboxOptionsProps<T>) => React.ReactElement;
}

export const Checkbox: CheckboxSpecializations = (props) => {
  return <CheckboxComponent {...props} />;
};

Checkbox.Dropdown = CheckboxDropdown;
Checkbox.List = CheckboxList;
Checkbox.Options = CheckboxOptions;
