import type { FormLayoutComponentProps } from "./type";

import React from "react";
import styled from "styled-components";

import * as Style from "style";

export const FormLayoutListContainer = styled.div`
  display: grid;
  row-gap: ${Style.Layout.Padding.ExtraSmall};
`;

export const FormLayoutList: React.FC<FormLayoutComponentProps> = ({
  children,
}) => {
  return <FormLayoutListContainer>{children}</FormLayoutListContainer>;
};
