import * as R from "ramda";
import React from "react";
import styled from "styled-components";

import {
  CONNECTOR_LENGTH,
  CIRCLE_SIZE,
  STROKE_WIDTH,
  DEFAULT_BACKGROUND,
} from "./constant";

export interface ConnectorProps {
  color?: string;
}

export const ConnectorContainer = styled.div<{ color: string }>`
  background: ${R.prop("color")};
  height: ${CONNECTOR_LENGTH}px;
  width: ${STROKE_WIDTH}px;
  margin-left: ${(CIRCLE_SIZE - STROKE_WIDTH) / 2}px;
`;

export const Connector: React.FC<ConnectorProps> = ({
  color = DEFAULT_BACKGROUND,
}) => {
  return <ConnectorContainer color={color} />;
};
