import * as R from "ramda";

export interface Range<T = number> {
  end: T;
  start: T;
  inclusiveEnd?: boolean;
  inclusiveStart?: boolean;
}

export enum RangePosition {
  Above = "above",
  Below = "below",
  Inside = "inside",
}

export function createRange(start: number, end: number): Range {
  return {
    end,
    start,
  };
}

export function fillRange({ start, end }: Range): number[] {
  return R.times((index: number): number => index + start, end - start + 1);
}

export function midpoint(range: Range): number {
  return (range.start + range.end) / 2;
}

export function clamp(range: Range, value: number): number {
  return Math.min(Math.max(value, range.start), range.end);
}

export function distance(range: Range): number {
  return range.end - range.start;
}

export function percentageOfRange(range: Range, value: number): number {
  return (value - range.start) / distance(range);
}

export function position(range: Range, value: number): RangePosition {
  if (value < range.start) {
    return RangePosition.Below;
  } else if (value > range.end) {
    return RangePosition.Above;
  } else {
    return RangePosition.Inside;
  }
}

export function isInside(range: Range, value: number): boolean {
  const rangePosition: RangePosition = position(range, value);

  return rangePosition === RangePosition.Inside;
}
