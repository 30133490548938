import React from "react";
import styled from "styled-components";

import * as Style from "style";

export interface ListItemProps {
  children: React.ReactNode;
  isLast?: boolean;
  key: string;
}

export const ListItemContainer = styled.li``;

export const ListItem: React.FC<ListItemProps> = ({ key, children }) => {
  return <ListItemContainer key={key}>{children}</ListItemContainer>;
};

export interface ListProps {
  children: React.ReactNode;
}

export const ListContainer = styled.ul``;
export const List: React.FC<ListProps> = ({ children }) => {
  return <ListContainer role="list">{children}</ListContainer>;
};

export const FeedListItemLine = styled.span`
  background: ${Style.Color.Layout.Line};
  width: 1px;
`;

export const FeedListItem: React.FC<ListItemProps> = ({
  isLast,
  key,
  children,
}) => {
  return (
    <li key={key}>
      <div className="relative pb-8">
        {!isLast && (
          <FeedListItemLine className="absolute top-4 left-4 -ml-px h-full" />
        )}
        <div className="relative flex space-x-3">{children}</div>
      </div>
    </li>
  );
};

export const FeedList: React.FC<ListProps> = ({ children }) => {
  return (
    <ListContainer role="list" className="-mb-8">
      {children}
    </ListContainer>
  );
};
