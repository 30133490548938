/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { QueryDTO } from './QueryDTO';
import {
    QueryDTOFromJSON,
    QueryDTOFromJSONTyped,
    QueryDTOToJSON,
} from './QueryDTO';

/**
 * 
 * @export
 * @interface BulkUpdateBenchmarkDto
 */
export interface BulkUpdateBenchmarkDto {
    /**
     * 
     * @type {number}
     * @memberof BulkUpdateBenchmarkDto
     */
    p25: number;
    /**
     * 
     * @type {number}
     * @memberof BulkUpdateBenchmarkDto
     */
    p50: number;
    /**
     * 
     * @type {number}
     * @memberof BulkUpdateBenchmarkDto
     */
    p75: number;
    /**
     * 
     * @type {boolean}
     * @memberof BulkUpdateBenchmarkDto
     */
    applyMultiplier: boolean;
    /**
     * 
     * @type {QueryDTO}
     * @memberof BulkUpdateBenchmarkDto
     */
    cohort: QueryDTO;
}

/**
 * Check if a given object implements the BulkUpdateBenchmarkDto interface.
 */
export function instanceOfBulkUpdateBenchmarkDto(value: object): value is BulkUpdateBenchmarkDto {
    if (!('p25' in value) || value['p25'] === undefined) return false;
    if (!('p50' in value) || value['p50'] === undefined) return false;
    if (!('p75' in value) || value['p75'] === undefined) return false;
    if (!('applyMultiplier' in value) || value['applyMultiplier'] === undefined) return false;
    if (!('cohort' in value) || value['cohort'] === undefined) return false;
    return true;
}

export function BulkUpdateBenchmarkDtoFromJSON(json: any): BulkUpdateBenchmarkDto {
    return BulkUpdateBenchmarkDtoFromJSONTyped(json, false);
}

export function BulkUpdateBenchmarkDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkUpdateBenchmarkDto {
    if (json == null) {
        return json;
    }
    return {
        
        'p25': json['p25'],
        'p50': json['p50'],
        'p75': json['p75'],
        'applyMultiplier': json['applyMultiplier'],
        'cohort': QueryDTOFromJSON(json['cohort']),
    };
}

export function BulkUpdateBenchmarkDtoToJSON(value?: BulkUpdateBenchmarkDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'p25': value['p25'],
        'p50': value['p50'],
        'p75': value['p75'],
        'applyMultiplier': value['applyMultiplier'],
        'cohort': QueryDTOToJSON(value['cohort']),
    };
}

