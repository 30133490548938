import type { AddButtonProps } from "./Add";
import type { BaseButtonProps } from "./Base";
import type { CompactButtonSpecializations } from "./Compact";
import type { IconButtonSpecializations } from "./Icon";
import type { RemoveButtonProps } from "./Remove";

import React from "react";

import { AddButton } from "./Add";
import { BaseButton } from "./Base";
import { CompactButton } from "./Compact";
import { DangerButton } from "./Danger";
import { IconButton } from "./Icon";
import { PrimaryButton } from "./Primary";
import { RemoveButton } from "./Remove";
import { SecondaryButton } from "./Secondary";

export interface ButtonSpecializations extends React.FC<BaseButtonProps> {
  Add: React.FC<AddButtonProps>;
  Compact: CompactButtonSpecializations;
  Danger: React.FC<BaseButtonProps>;
  Icon: IconButtonSpecializations;
  Primary: React.FC<BaseButtonProps>;
  Remove: React.FC<RemoveButtonProps>;
  Secondary: React.FC<BaseButtonProps>;
}

/**
 * Default button.
 *
 * This is the LEAST specialized input and should generally
 * NOT be used directly.
 */
export const Button: ButtonSpecializations = (props) => {
  return <BaseButton {...props} />;
};

Button.Add = AddButton;
Button.Compact = CompactButton;
Button.Danger = DangerButton;
Button.Icon = IconButton;
Button.Primary = PrimaryButton;
Button.Remove = RemoveButton;
Button.Secondary = SecondaryButton;
