import type { StandardComponent } from "components/type";
import type { Step as IStep } from "./type";

import * as R from "ramda";
import React from "react";
import styled from "styled-components";

import * as Style from "style";
import { Circle } from "./Circle";
import {
  DEFAULT_NETURAL,
  DEFAULT_FOREGROUND,
  DEFAULT_BACKGROUND,
} from "./constant";
import { SubTitle } from "../SubTitle";
import { Title } from "../Title";

export interface StepProps extends StandardComponent {
  neutral?: string;
  step: IStep;
}

export const StepContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${Style.Layout.Padding.ExtraSmall};
`;

export const StepInformation = styled.div`
  display: grid;
  position: relative;
`;

export const StepDescription = styled.div`
  position: absolute;
  top: ${Style.Text.Size.Medium};
`;

export const Step: React.FC<StepProps> = ({
  neutral = DEFAULT_NETURAL,
  step,
}) => {
  const {
    Icon,
    background = DEFAULT_BACKGROUND,
    description,
    foreground = DEFAULT_FOREGROUND,
    isActive = false,
    isComplete = false,
    name,
  } = step;

  // Cannot be completed and active.
  const isActiveOverride = isComplete ? false : isActive;

  return (
    <StepContainer>
      <Circle
        background={background}
        foreground={foreground}
        isActive={isActiveOverride}
        isComplete={isComplete}
        neutral={neutral}
        Icon={Icon}
      />
      <StepInformation>
        <Title>{name}</Title>
        {!R.isNil(description) && (
          <StepDescription>
            <SubTitle>{description}</SubTitle>
          </StepDescription>
        )}
      </StepInformation>
    </StepContainer>
  );
};
