import * as R from "ramda";
import styled from "styled-components";

import * as Style from "style";

export const ProgressBarContainer = styled.div<{ color: string }>`
  position: relative;
  overflow: hidden;
  height: 0.5rem;
  display: flex;
  width: 100%;
  border-radius: ${Style.Design.Rounding.Secondary};
  background: ${R.prop("color")};
`;
