import React from "react";

import { ChevronDownIcon } from "@heroicons/react/solid";

export const DropdownChevron: React.FC = () => {
  return <ChevronDownIcon className="h-5 w-5 fill-primary" />;
};

export const RightAlignedDropdownChevron: React.FC = () => {
  return (
    <div className="absolute right-0 top-0 p-2 flex flex-col items-center">
      <DropdownChevron />
    </div>
  );
};
