/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MappedPlan
 */
export interface MappedPlan {
    /**
     * 
     * @type {string}
     * @memberof MappedPlan
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MappedPlan
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof MappedPlan
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof MappedPlan
     */
    deleted_at: string;
    /**
     * 
     * @type {string}
     * @memberof MappedPlan
     */
    created_by: string;
    /**
     * 
     * @type {string}
     * @memberof MappedPlan
     */
    updated_by: string;
    /**
     * 
     * @type {string}
     * @memberof MappedPlan
     */
    deleted_by: string;
    /**
     * 
     * @type {string}
     * @memberof MappedPlan
     */
    plan_group_id: string;
    /**
     * 
     * @type {string}
     * @memberof MappedPlan
     */
    remote_id: string;
    /**
     * 
     * @type {string}
     * @memberof MappedPlan
     */
    job_family_id: string;
    /**
     * 
     * @type {string}
     * @memberof MappedPlan
     */
    job_level_id: string;
    /**
     * 
     * @type {string}
     * @memberof MappedPlan
     */
    title: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MappedPlan
     */
    plan_group_ids: Array<string>;
}

/**
 * Check if a given object implements the MappedPlan interface.
 */
export function instanceOfMappedPlan(value: object): value is MappedPlan {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('created_at' in value) || value['created_at'] === undefined) return false;
    if (!('updated_at' in value) || value['updated_at'] === undefined) return false;
    if (!('deleted_at' in value) || value['deleted_at'] === undefined) return false;
    if (!('created_by' in value) || value['created_by'] === undefined) return false;
    if (!('updated_by' in value) || value['updated_by'] === undefined) return false;
    if (!('deleted_by' in value) || value['deleted_by'] === undefined) return false;
    if (!('plan_group_id' in value) || value['plan_group_id'] === undefined) return false;
    if (!('remote_id' in value) || value['remote_id'] === undefined) return false;
    if (!('job_family_id' in value) || value['job_family_id'] === undefined) return false;
    if (!('job_level_id' in value) || value['job_level_id'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('plan_group_ids' in value) || value['plan_group_ids'] === undefined) return false;
    return true;
}

export function MappedPlanFromJSON(json: any): MappedPlan {
    return MappedPlanFromJSONTyped(json, false);
}

export function MappedPlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): MappedPlan {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created_at': json['created_at'],
        'updated_at': json['updated_at'],
        'deleted_at': json['deleted_at'],
        'created_by': json['created_by'],
        'updated_by': json['updated_by'],
        'deleted_by': json['deleted_by'],
        'plan_group_id': json['plan_group_id'],
        'remote_id': json['remote_id'],
        'job_family_id': json['job_family_id'],
        'job_level_id': json['job_level_id'],
        'title': json['title'],
        'plan_group_ids': json['plan_group_ids'],
    };
}

export function MappedPlanToJSON(value?: MappedPlan | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'created_at': value['created_at'],
        'updated_at': value['updated_at'],
        'deleted_at': value['deleted_at'],
        'created_by': value['created_by'],
        'updated_by': value['updated_by'],
        'deleted_by': value['deleted_by'],
        'plan_group_id': value['plan_group_id'],
        'remote_id': value['remote_id'],
        'job_family_id': value['job_family_id'],
        'job_level_id': value['job_level_id'],
        'title': value['title'],
        'plan_group_ids': value['plan_group_ids'],
    };
}

