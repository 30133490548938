/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Region } from './Region';
import {
    RegionFromJSON,
    RegionFromJSONTyped,
    RegionToJSON,
} from './Region';

/**
 * 
 * @export
 * @interface AllMetrics
 */
export interface AllMetrics {
    /**
     * 
     * @type {Array<Region>}
     * @memberof AllMetrics
     */
    _0: Array<Region>;
}

/**
 * Check if a given object implements the AllMetrics interface.
 */
export function instanceOfAllMetrics(value: object): value is AllMetrics {
    if (!('_0' in value) || value['_0'] === undefined) return false;
    return true;
}

export function AllMetricsFromJSON(json: any): AllMetrics {
    return AllMetricsFromJSONTyped(json, false);
}

export function AllMetricsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AllMetrics {
    if (json == null) {
        return json;
    }
    return {
        
        '_0': ((json['0'] as Array<any>).map(RegionFromJSON)),
    };
}

export function AllMetricsToJSON(value?: AllMetrics | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        '0': ((value['_0'] as Array<any>).map(RegionToJSON)),
    };
}

