import React from "react";
import styled from "styled-components";

import * as Style from "style";
import { Paragraph } from "./Paragraph";

interface UnauthorizedProps {
  message?: string;
}

export const UnauthorizedContainer = styled.div`
  border-color: ${Style.Color.Layout.Line};
  border-style: dotted;
  border-width: 2px;
  padding: ${Style.Layout.Padding.Medium};
  rounding: ${Style.Design.Rounding.Secondary};
`;

export const Unauthorized: React.FC<UnauthorizedProps> = ({
  message = "You are not authorized to view this resource. If you think this is a mistake contact your account administrator.",
}) => {
  return (
    <UnauthorizedContainer className="text-center p-6">
      <Paragraph>{message}</Paragraph>
    </UnauthorizedContainer>
  );
};
