import * as R from "ramda";
import React from "react";
import styled from "styled-components";
import { useMeasure } from "react-use";

import * as Style from "style";
import { createRange, clamp } from "util/range";

export interface SliderProps {
  max?: number;
  min?: number;
  onChange: (next: number) => void;
  step?: number;
  value: number;
}

export const Container = styled.div`
  padding: 0px;
`;

export const Background = styled.div`
  background: ${Style.Color.Layout.Gray};
  border-radius: ${Style.Design.Rounding.Secondary};
`;

export const Progress = styled.div<{ offset: number }>`
  background: ${Style.Color.Brand.Secondary};
  border-radius: ${Style.Design.Rounding.Secondary};
  width: ${R.prop("offset")}px;
`;

export const Knob = styled.div<{ offset: number }>`
  background: ${Style.Color.Brand.Primary};
  cursor: pointer;
  left: ${R.prop("offset")}px;
`;

export const Slider: React.FC<SliderProps> = ({
  onChange,
  value,
  step = 1,
  min = 0,
  max = 100,
}) => {
  const [ref, { width }] = useMeasure<HTMLDivElement>();

  const clamped: number = clamp(createRange(min, max), value);
  const offset: number = (clamped / max) * width;

  return (
    <Container>
      <div className="relative w-full">
        <input
          type="range"
          value={clamped}
          min={min}
          max={max}
          step={step}
          onChange={(event) => {
            const next: number = R.pipe(
              R.pathOr(0, ["target", "value"]),
              Number
            )(event);

            onChange(next);
          }}
          className="absolute z-20 h-2 p-2 w-full appearance-none opacity-0 cursor-pointer"
        />
        <div ref={ref} className="relative z-10 h-2 pointer-events-none">
          <Background className="absolute z-10 left-0 right-0 bottom-0 top-0" />
          <Progress offset={offset} className="absolute z-20 top-0 bottom-0" />
          <Knob
            offset={offset}
            className="absolute z-50 w-6 h-6 top-0 left-0 rounded-full -mt-2 -ml-1"
          />
        </div>
      </div>
    </Container>
  );
};
