import type { HorizontalDividerNamespace } from "./Horizontal";
import type { VerticalDividerNamespace } from "./Vertical";

import React from "react";

import { HorizontalDivider } from "./Horizontal";
import { VerticalDivider } from "./Vertical";

export interface DividerNamespace extends React.VoidFunctionComponent {
  Horizontal: HorizontalDividerNamespace;
  Vertical: VerticalDividerNamespace;
}

export const Divider: DividerNamespace = (props) => {
  return <HorizontalDivider {...props} />;
};

Divider.Vertical = VerticalDivider;
Divider.Horizontal = HorizontalDivider;
