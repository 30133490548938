/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateHealthcheckTemplateDto,
  HealthcheckRule,
  HealthcheckTemplate,
  UpdateHealthcheckTemplateDto,
} from '../models/index';
import {
    CreateHealthcheckTemplateDtoFromJSON,
    CreateHealthcheckTemplateDtoToJSON,
    HealthcheckRuleFromJSON,
    HealthcheckRuleToJSON,
    HealthcheckTemplateFromJSON,
    HealthcheckTemplateToJSON,
    UpdateHealthcheckTemplateDtoFromJSON,
    UpdateHealthcheckTemplateDtoToJSON,
} from '../models/index';

export interface CreateHealthcheckRequest {
    createHealthcheckTemplateDto: CreateHealthcheckTemplateDto;
}

export interface DeleteHealthcheckRequest {
    id: string;
}

export interface FindHealthcheckTemplateRequest {
    id: string;
}

export interface RunHealthcheckRequest {
    id: string;
}

export interface UpdateHealthcheckRequest {
    updateHealthcheckTemplateDto: UpdateHealthcheckTemplateDto;
}

/**
 * 
 */
export class HealthcheckApi extends runtime.BaseAPI {

    /**
     */
    async createHealthcheckRaw(requestParameters: CreateHealthcheckRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HealthcheckTemplate>> {
        if (requestParameters['createHealthcheckTemplateDto'] == null) {
            throw new runtime.RequiredError(
                'createHealthcheckTemplateDto',
                'Required parameter "createHealthcheckTemplateDto" was null or undefined when calling createHealthcheck().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/healthchecks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateHealthcheckTemplateDtoToJSON(requestParameters['createHealthcheckTemplateDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HealthcheckTemplateFromJSON(jsonValue));
    }

    /**
     */
    async createHealthcheck(requestParameters: CreateHealthcheckRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HealthcheckTemplate> {
        const response = await this.createHealthcheckRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteHealthcheckRaw(requestParameters: DeleteHealthcheckRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteHealthcheck().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/healthchecks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteHealthcheck(requestParameters: DeleteHealthcheckRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteHealthcheckRaw(requestParameters, initOverrides);
    }

    /**
     */
    async findAllHealthcheckTemplatesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<HealthcheckTemplate>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/healthchecks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HealthcheckTemplateFromJSON));
    }

    /**
     */
    async findAllHealthcheckTemplates(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<HealthcheckTemplate>> {
        const response = await this.findAllHealthcheckTemplatesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async findHealthcheckTemplateRaw(requestParameters: FindHealthcheckTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HealthcheckTemplate>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling findHealthcheckTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/healthchecks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HealthcheckTemplateFromJSON(jsonValue));
    }

    /**
     */
    async findHealthcheckTemplate(requestParameters: FindHealthcheckTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HealthcheckTemplate> {
        const response = await this.findHealthcheckTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAllRulesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<HealthcheckRule>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/healthchecks/rules`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HealthcheckRuleFromJSON));
    }

    /**
     */
    async getAllRules(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<HealthcheckRule>> {
        const response = await this.getAllRulesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async runHealthcheckRaw(requestParameters: RunHealthcheckRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HealthcheckTemplate>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling runHealthcheck().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/healthchecks/run/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HealthcheckTemplateFromJSON(jsonValue));
    }

    /**
     */
    async runHealthcheck(requestParameters: RunHealthcheckRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HealthcheckTemplate> {
        const response = await this.runHealthcheckRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateHealthcheckRaw(requestParameters: UpdateHealthcheckRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HealthcheckTemplate>> {
        if (requestParameters['updateHealthcheckTemplateDto'] == null) {
            throw new runtime.RequiredError(
                'updateHealthcheckTemplateDto',
                'Required parameter "updateHealthcheckTemplateDto" was null or undefined when calling updateHealthcheck().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/healthchecks`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateHealthcheckTemplateDtoToJSON(requestParameters['updateHealthcheckTemplateDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HealthcheckTemplateFromJSON(jsonValue));
    }

    /**
     */
    async updateHealthcheck(requestParameters: UpdateHealthcheckRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HealthcheckTemplate> {
        const response = await this.updateHealthcheckRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
