import * as R from "ramda";

import React from "react";

import { Tab } from "./Tab";
import { Tabs } from "./Tabs";

export const EmptyTabs: React.VoidFunctionComponent = ({}) => {
  return (
    <Tabs<typeof undefined> defaultActive={undefined}>
      {() => (
        <div className="invisible">
          <Tab onClick={R.always(undefined)} isActive>
            {" "}
          </Tab>
        </div>
      )}
    </Tabs>
  );
};
