import styled from "styled-components";

import * as Style from "style";

export const TableContainer = styled.div`
  overflow-y: auto;
  border-color: ${Style.Color.Layout.Line};
  border-style: ${Style.Design.Border.Style};
  border-width: ${Style.Design.Border.Size};
  background: ${Style.Color.White};
  border-radius: ${Style.Design.Rounding.Secondary};
`;

export const FooterContainer = styled.div`
  overflow: hidden;
`;
