/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ReportData } from './ReportData';
import {
    ReportDataFromJSON,
    ReportDataFromJSONTyped,
    ReportDataToJSON,
} from './ReportData';
import type { ReportType } from './ReportType';
import {
    ReportTypeFromJSON,
    ReportTypeFromJSONTyped,
    ReportTypeToJSON,
} from './ReportType';

/**
 * 
 * @export
 * @interface Report
 */
export interface Report {
    /**
     * 
     * @type {number}
     * @memberof Report
     */
    id: number;
    /**
     * 
     * @type {ReportData}
     * @memberof Report
     */
    data: ReportData;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    deleted_at: string | null;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    created_by: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    updated_by: string;
    /**
     * 
     * @type {ReportType}
     * @memberof Report
     */
    type: ReportType;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    schema: string;
    /**
     * 
     * @type {boolean}
     * @memberof Report
     */
    hidden: boolean;
}



/**
 * Check if a given object implements the Report interface.
 */
export function instanceOfReport(value: object): value is Report {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('created_at' in value) || value['created_at'] === undefined) return false;
    if (!('updated_at' in value) || value['updated_at'] === undefined) return false;
    if (!('deleted_at' in value) || value['deleted_at'] === undefined) return false;
    if (!('created_by' in value) || value['created_by'] === undefined) return false;
    if (!('updated_by' in value) || value['updated_by'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('schema' in value) || value['schema'] === undefined) return false;
    if (!('hidden' in value) || value['hidden'] === undefined) return false;
    return true;
}

export function ReportFromJSON(json: any): Report {
    return ReportFromJSONTyped(json, false);
}

export function ReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): Report {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'data': ReportDataFromJSON(json['data']),
        'created_at': json['created_at'],
        'updated_at': json['updated_at'],
        'deleted_at': json['deleted_at'],
        'created_by': json['created_by'],
        'updated_by': json['updated_by'],
        'type': ReportTypeFromJSON(json['type']),
        'schema': json['schema'],
        'hidden': json['hidden'],
    };
}

export function ReportToJSON(value?: Report | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'data': ReportDataToJSON(value['data']),
        'created_at': value['created_at'],
        'updated_at': value['updated_at'],
        'deleted_at': value['deleted_at'],
        'created_by': value['created_by'],
        'updated_by': value['updated_by'],
        'type': ReportTypeToJSON(value['type']),
        'schema': value['schema'],
        'hidden': value['hidden'],
    };
}

