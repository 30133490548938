import type { StandardComponent } from "components/type";

import * as R from "ramda";
import React from "react";

import { Button } from "components/library";
import { CaretUp, CaretDown } from "components/Icon";

export interface ExpandHandleProps extends StandardComponent {
  isOpen: boolean;
  onClick: (next: boolean) => void;
}

// TODO: Co-locate this with ExpandHandle from GridV2 as Compact / Standard versions.
export const ExpandHandle: React.FC<ExpandHandleProps> = ({
  isOpen = false,
  isDisabled = false,
  onClick = R.always(undefined),
}) => {
  const Icon: React.VoidFunctionComponent = isOpen ? CaretUp : CaretDown;

  return (
    <Button.Icon.Standalone
      Icon={Icon}
      isDisabled={isDisabled}
      onClick={() => onClick(!isOpen)}
    />
  );
};
