// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import * as R from "ramda";
import PropTypes from "prop-types";
import React from "react";
import cn from "lib/cn";
import get from "lodash/get";
import sortBy from "lodash/sortBy";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";

import Pagination from "./Pagination";
import { Paragraph, Input, Title } from "components/library";
import { labelize } from "util/format";
import { toSearchTokens } from "util/search";
import {
  TableContainer,
  BodyCell,
  TableHeader,
  HeaderCell,
  Row,
  Table,
} from "components/library/GridV2";

const sort = (array) => sortBy(array, (x) => x);

const emptyFilterMarker = Symbol("(no value)");
const ascendingSortCompare = (a, b) => {
  // equals
  if (a === b) return 0;

  // no value
  if (!a && !b) return 0;
  if (!a) return -1;
  if (!b) return 1;

  // different types
  // we just sort by the name of the type. The order doesn't matter as long as it's stable
  // no value checks above make sure null and undefined are first
  if (typeof a !== typeof b) return ascendingSortCompare(typeof a, typeof b);

  // standard compare
  if (a < b) return -1;
  return 1;
};

export const SortDirections = {
  asc: {
    compare: ascendingSortCompare,
    icon: ChevronUpIcon,
    next: "desc",
  },
  desc: {
    compare: (a, b) => ascendingSortCompare(b, a),
    icon: ChevronDownIcon,
    next: "asc",
  },
};

const ColumnHeader = ({ index, column, sortDirection, onSort }) => {
  const SortIcon = sortDirection?.icon ? (
    <sortDirection.icon className="ml-1 h-4 w-4" />
  ) : null;

  const { title, sortable = true, action } = column;

  return (
    <HeaderCell column={column} columnIndex={index}>
      <div className={cn("inline-block", sortable && "cursor-pointer")}>
        <div className="flex items-center justify-center">
          <span className="truncate" onClick={(e) => sortable && onSort(e)}>
            <Title>{labelize(title)}</Title>
          </span>
          {sortable && <Paragraph>{SortIcon}</Paragraph>}
          {action && action}
        </div>
      </div>
    </HeaderCell>
  );
};

const DefaultCell = ({ value }) => {
  if (value == null) {
    return null;
  }
  if (typeof value === "boolean") {
    return (
      <div className="text-sm text-gray-800 bg-gray-100">
        {value ? `Active` : "Disabled"}
      </div>
    );
  }

  return <span className="text-sm">{value.toString()}</span>;
};

function getDefaultSortField(columns) {
  const firstSortableColumn = R.find(R.has("field"), columns);

  return firstSortableColumn ? firstSortableColumn.field : null;
}

const Grid = ({
  columns = [],
  data = [],
  onChangeCell = R.always(undefined),
  actions = undefined,
  sticky = false,
  footer = undefined,
  initialPageSize = 20,
  defaultSortField = null,
  defaultSortDirection = SortDirections.asc,
  disableSearch = false,
  isDisabled = false,
  openRowRenderer = R.always(null),
  canExpand = R.always(false),
  disablePagination = false,
  forwardRef = React.createRef(),
  disableSelectColumns = true,
  allowShowAll = true,
  ...rest
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [page, setPage] = useState(0);
  const [sortField, setSortField] = useState(
    defaultSortField || getDefaultSortField(columns)
  );
  const [sortDirection, setSortDirection] = useState(defaultSortDirection);
  const [filter, setFilter] = useState({});
  const [showColumnSelect] = useState(false);
  const [openRows, setOpenRows] = useState(new Set());
  const visibleColumnSelection = columns.map(
    ({ visible }) => visible !== false
  );

  const globalFilters = columns.filter(
    ({ globalFilter }) => globalFilter === true
  );
  // Column selection
  const visibleColumns = columns.filter((col, index) => {
    return visibleColumnSelection[index] === undefined
      ? true
      : visibleColumnSelection[index];
  });

  const unfilteredData = data;

  const filterValues = Object.assign(
    {},
    ...visibleColumns
      .concat(globalFilters)
      .map(({ field, transform = (v) => v }) => {
        const columnFilterValues = data
          .map((item) => transform(get(item, field)))
          .map((v) => v ?? emptyFilterMarker);
        return {
          [field]: sort([...new Set(columnFilterValues)]),
        };
      })
  );

  // Apply Filters
  data = data.filter((row) => {
    return Object.entries(filter)
      .filter(([, value]) => value)
      .every(([key, value]) =>
        value === emptyFilterMarker
          ? get(row, key) == undefined
          : get(row, key) === value
      );
  });

  // Search
  const searchableColumns = columns.filter(
    ({ searchable }) => searchable !== false
  );
  if (searchQuery) {
    const searchTokens = toSearchTokens(searchQuery);

    // Every token should have at least one match, in one value in the object
    data = data.filter((row) => {
      return searchTokens.every((token) =>
        searchableColumns.some(({ field, transform }) => {
          let value = get(row, field);
          if (transform) {
            value = transform(value);
          }
          return token.test(String(value));
        })
      );
    });
  }

  // Sort data
  if (sortField) {
    data = data.sort((a, b) =>
      sortDirection.compare(get(a, sortField), get(b, sortField))
    );
  }

  // Clamp pages
  const pageCount = Math.max(Math.ceil(data.length / pageSize), 1);
  useEffect(() => {
    if (pageSize > 0) {
      if (page < 0) {
        setPage(0);
      } else if (page >= pageCount) {
        setPage(pageCount - 1);
      }
    }
  }, [page, pageSize, data.length]);

  // Page selection
  const totalResultCount = data.length;
  if (pageSize > 0) {
    data = data.slice(page * pageSize, (page + 1) * pageSize);
  }

  return (
    <div className="grid grid-cols-12 gap-y-4">
      {(disableSearch === false || !R.isEmpty(globalFilters)) && (
        <div className="col-span-8 flex items-end">
          {disableSearch === false && (
            <Input
              placeholder="Search..."
              className="max-w-xs mr-4 block w-full px-3 py-2"
              onChange={(next) => setSearchQuery(next)}
            />
          )}
        </div>
      )}

      <div
        className={cn(
          "flex col-span-4 justify-end",
          disableSearch && "col-span-12"
        )}
      >
        {actions && actions}
        {!disableSelectColumns && (
          <div className="relative">
            {/*
						<Button.Secondary onClick={() => setShowColumnSelect(!showColumnSelect)}>
							<div className="flex items-center relative text-left">
								<CheckboxListIcon className="h-4 w-4 mr-2" /> Select Columns
							</div>
						</Button.Secondary>
            */}
            {showColumnSelect && (
              <ul
                className={cn(
                  "absolute top-14 right-0 bg-white border border-gray-300 rounded py-2 flex flex-col space-y-2 z-30 shadow-lg flex-1"
                )}
              >
                {columns.map((column, index) => {
                  return (
                    <li
                      key={`select-${column}-${index}`}
                      className="whitespace-nowrap text-sm hover:bg-gray-100 text-gray-900 select-none"
                    >
                      <label className="py-2 px-4 block">
                        <input
                          type="checkbox"
                          className="mr-2 h-4 w-4 focus:ring-gray-800 text-gray-600 border-gray-300 rounded"
                          name={column.field}
                          checked={visibleColumnSelection[index]}
                          // TODO add function to change visible columns
                          onChange={(e) => {
                            const { checked } = e.currentTarget;
                            if (checked) {
                              setVisibleColumnSelection(
                                R.set(
                                  R.lensIndex(index),
                                  true,
                                  visibleColumnSelection
                                )
                              );
                            } else {
                              setVisibleColumnSelection(
                                R.set(
                                  R.lensIndex(index),
                                  false,
                                  visibleColumnSelection
                                )
                              );
                            }
                          }}
                        />
                        {column.title}
                      </label>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        )}
      </div>

      <TableContainer ref={forwardRef} className="col-span-12 overflow-y-auto">
        <Table sticky={sticky}>
          <TableHeader>
            <Row>
              {visibleColumns.map((column, index) => {
                const columnSortField = column.sortField || column.field;
                return (
                  <ColumnHeader
                    index={index}
                    column={column}
                    sortDirection={
                      columnSortField === sortField ? sortDirection : null
                    }
                    onSort={() => {
                      if (columnSortField === sortField) {
                        setSortDirection(SortDirections[sortDirection.next]);
                      } else {
                        setSortField(columnSortField);
                        setSortDirection(SortDirections.asc);
                      }
                    }}
                    filterValues={filterValues[column.field]}
                    filter={filter[column.field]}
                    onFilter={(value) =>
                      setFilter({ ...filter, [column.field]: value })
                    }
                  />
                );
              })}
            </Row>
          </TableHeader>
          <tbody className="divide-y divide-gray-200">
            {data.map((row, i) => {
              const rows = [];

              rows.push([
                <Row key={i}>
                  {visibleColumns.map((column, index) => {
                    const { field, component, transform = (v) => v } = column;

                    let value = get(row, field);

                    value = transform(value);

                    const Component = component ?? DefaultCell;
                    return (
                      <BodyCell
                        column={column}
                        columnIndex={index}
                        rowIndex={i}
                        row={i}
                        key={`${column.keyField || column.field}.${i}`}
                      >
                        <Component
                          {...R.propOr({}, "props", column)}
                          {...rest}
                          canExpand={canExpand}
                          setOpen={(isOpen) => {
                            const next = new Set(openRows);

                            isOpen ? next.add(i) : next.delete(i);

                            setOpenRows(next);
                          }}
                          isOpen={openRows.has(i)}
                          value={value}
                          index={i}
                          isDisabled={isDisabled}
                          onChange={onChangeCell}
                          row={row}
                          field={field}
                          data={data}
                          unfilteredData={unfilteredData}
                        />
                      </BodyCell>
                    );
                  })}
                </Row>,
              ]);

              if (openRows.has(i)) {
                const subrows = openRowRenderer({
                  row,
                  columns: visibleColumns,
                });

                rows.push(
                  R.map((subrow, subindex) => {
                    return <Row key={`${i}.${subindex}`}>{subrow}</Row>;
                  }, subrows)
                );
              }

              return <>{rows}</>;
            })}
          </tbody>
        </Table>
      </TableContainer>

      {footer && <div className="col-span-12 overflow-hidden">{footer}</div>}

      {disablePagination === false && pageSize > 0 && (
        <div className="col-span-12">
          <Pagination
            {...{ page, pageCount, pageSize, total: totalResultCount }}
            allowShowAll={allowShowAll}
            onChange={(next) => {
              setPageSize(next.pageSize);
              setPage(next.page);
            }}
          />
        </div>
      )}
    </div>
  );
};

Grid.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.exact({
      field: PropTypes.string.isRequired,
      title: PropTypes.string,
      visible: PropTypes.bool,
      sortable: PropTypes.bool,
      actions: PropTypes.func,
      component: PropTypes.elementType,
      transform: PropTypes.func,
      forceExport: PropTypes.bool,
    })
  ),

  data: PropTypes.arrayOf(PropTypes.object),
};
export default Grid;
