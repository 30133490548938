import type { BaseButtonProps } from "./Base";
import type { IconProps } from "components/Icon/type";

import React from "react";
import cn from "lib/cn";
import styled from "styled-components";

import * as Style from "style";
import { BaseButton } from "./Base";
import { PrimaryButtonContainer } from "./Primary";
import { SecondaryButtonContainer } from "./Secondary";

export interface IconButtonProps extends BaseButtonProps {
  Icon: React.FC<IconProps>;
}

export interface StandaloneIconButtonProps extends IconButtonProps {
  isActive?: boolean;
}

export interface IconButtonSpecializations extends React.FC<IconButtonProps> {
  Primary: React.FC<IconButtonProps>;
  Secondary: React.FC<IconButtonProps>;
  Standalone: React.FC<StandaloneIconButtonProps>;
}

/**
 * Base icon button.
 */
export const BaseIconButton: React.FC<IconButtonProps> = ({
  Icon,
  children,
  ...props
}) => {
  return (
    <BaseButton {...props}>
      <div className="flex items-center justify-center">
        <Icon
          height="20"
          width="20"
          className={cn(children ? "mr-2" : "", "w-5 inline-block")}
        />
        {children}
      </div>
    </BaseButton>
  );
};

/**
 * Primary action icon button.
 */
export const PrimaryIconButton: React.FC<IconButtonProps> = (props) => {
  return (
    <PrimaryButtonContainer isDisabled={props.isDisabled}>
      <BaseIconButton {...props} />
    </PrimaryButtonContainer>
  );
};

/**
 * Secondary action icon button.
 */
export const SecondaryIconButton: React.FC<IconButtonProps> = (props) => {
  return (
    <SecondaryButtonContainer isDisabled={props.isDisabled}>
      <BaseIconButton {...props} />
    </SecondaryButtonContainer>
  );
};

function toStandaloneButtonColor({ isActive }): string {
  return isActive
    ? Style.Color.Sentiment.Info.Primary
    : Style.Color.Layout.Gray;
}

function toStandaloneButtonStrokeWidth({ isActive }): string {
  return isActive ? "24px" : "16px";
}

function toStandaloneButtonHoverColor({
  isActive,
  isDisabled = false,
}): string {
  if (isDisabled) {
    return "auto";
  }

  return isActive ? Style.Color.Brand.Secondary : Style.Color.Font.Secondary;
}

export const StandaloneButtonContainer = styled.div<{
  isDisabled?: boolean;
  isActive?: boolean;
}>`
  align-items: center;
  display: flex;
  color: ${toStandaloneButtonColor};
  stroke-width: ${toStandaloneButtonStrokeWidth};

  &:hover {
    color: ${toStandaloneButtonHoverColor};
  }
`;

/**
 * Standalone icon button.
 */
export const StandaloneIconButton: React.FC<StandaloneIconButtonProps> = (
  props
) => {
  const { isActive = false, isDisabled = false } = props;

  return (
    <StandaloneButtonContainer isActive={isActive} isDisabled={isDisabled}>
      <BaseIconButton {...props} />
    </StandaloneButtonContainer>
  );
};

/**
 * Default icon button.
 *
 * This is the LEAST specialized icon button and should generally
 * NOT be used directly.
 */
export const IconButton: IconButtonSpecializations = (props) => {
  return <BaseIconButton {...props} />;
};

IconButton.Primary = PrimaryIconButton;
IconButton.Secondary = SecondaryIconButton;
IconButton.Standalone = StandaloneIconButton;
