import type { FormikContextType } from "formik";

import React from "react";
import * as R from "ramda";

import { useFormikContext } from "formik";

import { formikErrorHelper } from "util/formik";
import { keyToPath } from "@milio/lib/util/string";
import debounce from "just-debounce-it";

export interface FormikComponentProps {
  id: string;
  onChange?: (next: any, id: string) => void;
  className?: string;
  debounceInterval?: number;
}

/**
 * Convert a component into a Formik controlled version.
 */
export function toFormikComponent<T>(
  Component: React.FC<any>
): React.FC<Omit<T, "value"> & FormikComponentProps> {
  return function FormikComponent<C extends object>(
    props: Omit<T, "value"> & FormikComponentProps
  ): React.ReactElement {
    const context: FormikContextType<C> = useFormikContext<C>();

    const { id, onChange, debounceInterval } = props;
    const { setFieldTouched, setFieldValue, values } = context;
    const { getError } = formikErrorHelper<C>(context);

    const debouncedSetFieldValue = React.useCallback(
      debounceInterval
        ? debounce(setFieldValue, debounceInterval)
        : setFieldValue,
      [debounceInterval, setFieldValue]
    );

    const debouncedSetFieldTouched = React.useCallback(
      debounceInterval
        ? debounce(setFieldTouched, debounceInterval)
        : setFieldTouched,
      [debounceInterval, setFieldTouched]
    );

    return (
      <Component
        {...R.omit(["id", "onChange"], props)}
        value={R.path(keyToPath(id), values)}
        onChange={(next: C) => {
          debouncedSetFieldValue(id, next);
          debouncedSetFieldTouched(id, true);

          if (!R.isNil(onChange) && R.is(Function, onChange)) {
            onChange(next, id);
          }
        }}
        error={getError(id)}
      />
    );
  };
}
