/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateJobFamilyDto
 */
export interface CreateJobFamilyDto {
    /**
     * 
     * @type {string}
     * @memberof CreateJobFamilyDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateJobFamilyDto
     */
    job_group_id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateJobFamilyDto
     */
    plan_ids?: Array<string>;
}

/**
 * Check if a given object implements the CreateJobFamilyDto interface.
 */
export function instanceOfCreateJobFamilyDto(value: object): value is CreateJobFamilyDto {
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function CreateJobFamilyDtoFromJSON(json: any): CreateJobFamilyDto {
    return CreateJobFamilyDtoFromJSONTyped(json, false);
}

export function CreateJobFamilyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateJobFamilyDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'job_group_id': json['job_group_id'] == null ? undefined : json['job_group_id'],
        'plan_ids': json['plan_ids'] == null ? undefined : json['plan_ids'],
    };
}

export function CreateJobFamilyDtoToJSON(value?: CreateJobFamilyDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'job_group_id': value['job_group_id'],
        'plan_ids': value['plan_ids'],
    };
}

