import type { IDHandle, ItemRenderer, ItemHandle } from "./type";

import * as R from "ramda";
import React from "react";
import styled from "styled-components";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { Item } from "./Item";
import { List } from "./List";
import { onSwap } from "./util";

export interface DraggableListProps<T> {
  isDisabled?: boolean;
  items: T[];
  onChange?: (items: T[]) => void;
  renderer: ItemRenderer<T>;
  disabled?: Set<string | number>;
}

export const DraggableListContainer = styled.div``;

export function DraggableList<T extends IDHandle>({
  isDisabled = false,
  items,
  onChange = R.always(undefined),
  renderer = R.always(null),
  disabled = new Set(),
}: DraggableListProps<T>) {
  if (R.isEmpty(items)) {
    return null;
  }

  // Fired when a drop event occurs.
  const onDrop = React.useCallback(
    (to: ItemHandle<T>, from: ItemHandle<T>) => {
      const next: T[] | undefined = onSwap({ from, to, items });

      if (!R.isNil(next) && !disabled.has(to.item.id)) {
        onChange(next);
      }
    },
    [items, onChange]
  );

  return (
    <DraggableListContainer>
      <DndProvider backend={HTML5Backend}>
        <List>
          {items.map((item: T, index: number) => (
            <Item
              index={index}
              isDisabled={isDisabled || disabled.has(item.id)}
              item={item}
              key={item.id}
              onDrop={(handle: ItemHandle<T>) =>
                onDrop({ index, item }, handle)
              }
              renderer={renderer}
            />
          ))}
        </List>
      </DndProvider>
    </DraggableListContainer>
  );
}
