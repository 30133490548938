import type { ActionComponent } from "components/type";
import type { MouseEvent } from "react";

import * as R from "ramda";
import React from "react";
import styled from "styled-components";
import { SpinnerCircular } from "spinners-react";

import * as Style from "style";
import { BaseButtonStyle } from "./style";

export interface BaseButtonProps
  extends ActionComponent,
    React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  forwardRef?: React.Ref<HTMLButtonElement>;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  testID?: string;
}

export const BaseButtonContainer = styled.button`
  position: relative;
  ${BaseButtonStyle};
`;

export const LoaderContainer = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoaderIconContainer = styled.div`
  width: 25px;
`;

export const ButtonLoader: React.FC<{ isLoading: boolean }> = ({
  isLoading,
}) => {
  if (!isLoading) {
    return null;
  }

  return (
    <LoaderContainer>
      <LoaderIconContainer>
        <SpinnerCircular size="100%" color={Style.Color.White} />
      </LoaderIconContainer>
    </LoaderContainer>
  );
};

export const BaseButton: React.FC<BaseButtonProps> = ({
  isDisabled = false,
  children,
  isLoading,
  type = "button",
  onClick,
  testID,
  ...props
}) => {
  return (
    <BaseButtonContainer
      type={type}
      data-test-id={testID}
      isDisabled={isDisabled}
      onClick={(event: MouseEvent<HTMLButtonElement>) => {
        if (!isDisabled && !isLoading && R.is(Function, onClick)) {
          onClick(event);
        }
      }}
      ref={R.prop("forwardRef", props)}
      {...props}
    >
      {isLoading && (
        <LoaderContainer>
          <LoaderIconContainer>
            <SpinnerCircular size="100%" color={Style.Color.White} />
          </LoaderIconContainer>
        </LoaderContainer>
      )}
      {!isLoading && children}
    </BaseButtonContainer>
  );
};
