import * as R from "ramda";
import styled from "styled-components";

import * as Style from "style";
import React from "react";

export interface NumberLineContainerProps {
  children: React.ReactNode;
  color: string;
}

export const Outter = styled.div<{ color: string }>`
  background: ${R.prop("color")};
  border-radius: ${Style.Design.Rounding.Secondary};
  display: flex;
  height: 0.5rem;
  width: 100%;
`;

export const Inner = styled.div`
  position: relative;
  margin: 0px 4px;
  width: 100%;
`;

export const NumberLineContainer: React.FC<NumberLineContainerProps> = ({
  children,
  color,
}) => {
  return (
    <Outter color={color}>
      <Inner>{children}</Inner>
    </Outter>
  );
};
