import * as R from "ramda";
import React from "react";
import styled from "styled-components";

import * as Style from "style";
import { Alignment } from "components/library";
import { DataType } from "@milio/lib/data/report/constant";
import { dataTypeToAlignment } from "data/report/util";

export const RightAligned = styled.div`
  ${Style.Text.Definition.Truncate};
  width: 100%;
  text-align: right;
`;

export const LeftAligned = styled.div`
  ${Style.Text.Definition.Truncate};
  width: 100%;
  text-align: left;
`;

export interface AlignmentContainerProps {
  children: React.ReactNode;
  type: DataType | undefined;
}

export const AlignmentContainer: React.FC<AlignmentContainerProps> = ({
  type,
  children,
}) => {
  const Aligner = R.propOr(LeftAligned, dataTypeToAlignment(type), {
    [Alignment.Left]: LeftAligned,
    [Alignment.Right]: RightAligned,
  }) as React.JSXElementConstructor<React.PropsWithChildren>;

  return <Aligner>{children}</Aligner>;
};
