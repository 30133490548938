/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PolicyType } from './PolicyType';
import {
    PolicyTypeFromJSON,
    PolicyTypeFromJSONTyped,
    PolicyTypeToJSON,
} from './PolicyType';
import type { LogicDefinition } from './LogicDefinition';
import {
    LogicDefinitionFromJSON,
    LogicDefinitionFromJSONTyped,
    LogicDefinitionToJSON,
} from './LogicDefinition';

/**
 * 
 * @export
 * @interface Policy
 */
export interface Policy {
    /**
     * 
     * @type {string}
     * @memberof Policy
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Policy
     */
    title: string;
    /**
     * 
     * @type {LogicDefinition}
     * @memberof Policy
     */
    cohort: LogicDefinition;
    /**
     * 
     * @type {PolicyType}
     * @memberof Policy
     */
    type: PolicyType;
    /**
     * 
     * @type {string}
     * @memberof Policy
     */
    created_at?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Policy
     */
    updated_at?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Policy
     */
    created_by?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Policy
     */
    updated_by?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Policy
     */
    deleted_at?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Policy
     */
    deleted_by?: string | null;
}



/**
 * Check if a given object implements the Policy interface.
 */
export function instanceOfPolicy(value: object): value is Policy {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('cohort' in value) || value['cohort'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function PolicyFromJSON(json: any): Policy {
    return PolicyFromJSONTyped(json, false);
}

export function PolicyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Policy {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'cohort': LogicDefinitionFromJSON(json['cohort']),
        'type': PolicyTypeFromJSON(json['type']),
        'created_at': json['created_at'] == null ? undefined : json['created_at'],
        'updated_at': json['updated_at'] == null ? undefined : json['updated_at'],
        'created_by': json['created_by'] == null ? undefined : json['created_by'],
        'updated_by': json['updated_by'] == null ? undefined : json['updated_by'],
        'deleted_at': json['deleted_at'] == null ? undefined : json['deleted_at'],
        'deleted_by': json['deleted_by'] == null ? undefined : json['deleted_by'],
    };
}

export function PolicyToJSON(value?: Policy | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'title': value['title'],
        'cohort': LogicDefinitionToJSON(value['cohort']),
        'type': PolicyTypeToJSON(value['type']),
        'created_at': value['created_at'],
        'updated_at': value['updated_at'],
        'created_by': value['created_by'],
        'updated_by': value['updated_by'],
        'deleted_at': value['deleted_at'],
        'deleted_by': value['deleted_by'],
    };
}

