import type { DateRange } from "util/date";
import type { StandardComponent } from "components/type";

import * as R from "ramda";
import React from "react";
import ReactDatePicker from "react-datepicker";
import styled from "styled-components";
import { DateTime } from "luxon";

import * as Icon from "../Icon";
import * as Style from "style";
import { InputStyle } from "./Input/style";

export const INPUT_FORMAT = "MM/dd/yyyy";

export interface DateRangePicker extends StandardComponent {
  max?: DateTime;
  min?: DateTime;
  onChange?: (next: DateRange) => void;
  value?: DateRange;
}

export interface DatePickerProps extends StandardComponent {
  min?: DateTime;
  max?: DateTime;
  onChange?: (date: DateTime) => void;
  value?: DateTime;
}

export const DatePickerWrapper = styled.div`
  width: 100%;
  position: relative;

  .react-datepicker__current-month {
    ${Style.Text.Definition.Title};
  }

  .react-datepicker__header {
    background: ${Style.Color.Layout.Background.Secondary};
    padding: ${Style.Layout.Padding.ExtraSmall};
    border-top-left-radius: ${Style.Design.Rounding.Secondary};
    border-top-right-radius: ${Style.Design.Rounding.Secondary};
  }

  .react-datepicker__navigation--previous {
    height: 25px;
    width: 25px;
    border-radius: ${Style.Design.Rounding.Secondary};

    &:hover {
      background: ${Style.Color.Layout.Gray};
    }
  }

  .react-datepicker__navigation--next {
    height: 25px;
    width: 25px;
    border-radius: ${Style.Design.Rounding.Secondary};

    &:hover {
      background: ${Style.Color.Layout.Gray};
    }
  }

  .react-datepicker__month-container {
    background: ${Style.Color.White};
    border-color: ${Style.Color.Layout.Line};
    border-radius: ${Style.Design.Rounding.Secondary};
    border-style: ${Style.Design.Border.Style};
    border-width: ${Style.Design.Border.Size};
  }

  .react-datepicker__month {
    padding: ${Style.Layout.Padding.ExtraSmall};
  }

  .react-datepicker__day-names {
    ${Style.Text.Definition.Paragraph};
    margin-top: ${Style.Layout.Padding.ExtraSmall};
    color: ${Style.Color.Font.Secondary};
    display: flex;
    justify-content: space-around;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__input-container input {
    ${InputStyle};
    padding-left: 35px;
  }

  .react-datepicker__day--in-selecting-range {
    background: ${Style.Color.Brand.Secondary};
  }

  .react-datepicker__day--selecting-range-start {
    background: ${Style.Color.White};
    border-color: ${Style.Color.Brand.Primary};
  }

  .react-datepicker__day--selecting-range-end {
    background: ${Style.Color.White};
    border-color: ${Style.Color.Brand.Primary};
  }

  .react-datepicker__day--selected {
    background: ${Style.Color.Brand.Primary};
    color: ${Style.Color.White};
  }

  .react-datepicker__day--range-start {
    background: ${Style.Color.Brand.Primary};
    color: ${Style.Color.White};
  }

  .react-datepicker__day--range-end {
    background: ${Style.Color.Brand.Primary};
    color: ${Style.Color.White};
  }
`;

const IconContainer = styled.div`
  left: 11px;
  margin-bottom: 2px;
  position: absolute;
  top: 11px;
  height: 20px;
  width: 20px;
  z-index: 1;

  > svg {
    color: ${Style.Color.Layout.Gray};
  }
`;

/**
 * Resolve the date value for react date picker.
 */
function resolveValueDate(value: DateTime | Date | null): Date | null {
  if (R.isNil(value)) {
    return null;
  }

  if (DateTime.isDateTime(value)) {
    if (value.isValid) {
      return value.toJSDate();
    }

    const fromTimestamp: DateTime = DateTime.fromMillis(R.prop("ts", value));

    if (fromTimestamp.isValid) {
      return fromTimestamp.toJSDate();
    }
  }

  return null;
}

export const DatePicker: React.FC<DatePickerProps> = ({
  isDisabled = false,
  min = undefined,
  max = undefined,
  onChange = R.always(undefined),
  value = null,
}) => {
  const valueDate: Date = resolveValueDate(value);

  return (
    <DatePickerWrapper isDisabled={isDisabled}>
      <IconContainer>
        <Icon.Calendar />
      </IconContainer>
      <ReactDatePicker
        minDate={min}
        maxDate={max}
        nextMonthButtonLabel=">"
        onChangeRaw={(event) => {
          const { value: nextValue } = event.target;

          if (nextValue) {
            const dateTime: DateTime = DateTime.fromFormat(
              nextValue,
              INPUT_FORMAT
            );

            if (dateTime.isValid) {
              onChange(dateTime);
            }
          }
        }}
        onChange={(next) => {
          onChange(DateTime.fromJSDate(next));
        }}
        previousMonthButtonLabel="<"
        selected={valueDate}
        selectsStart
        startDate={valueDate}
        disabled={isDisabled}
      />
    </DatePickerWrapper>
  );
};

// TODO: Share more with the DatePicker.
export const DateRangePicker: React.FC<DateRangePicker> = ({
  isDisabled = false,
  onChange = R.always(undefined),
  value = undefined,
  min = undefined,
  max = undefined,
  testID,
}) => {
  const startDate: Date = value.start ? value.start.toJSDate() : undefined;
  const endDate: Date =
    value.start && value.end ? value.end.toJSDate() : undefined;
  const minDate: Date = min ? min.toJSDate() : undefined;
  const maxDate: Date = max ? max.toJSDate() : undefined;

  return (
    <DatePickerWrapper isDisabled={isDisabled}>
      <IconContainer>
        <Icon.Calendar />
      </IconContainer>
      <ReactDatePicker
        customInput={<input data-test-id={testID} type="text" />}
        selectsRange={true}
        disabled={isDisabled}
        minDate={minDate}
        maxDate={maxDate}
        nextMonthButtonLabel=">"
        startDate={startDate}
        endDate={endDate}
        selected={startDate}
        onChange={([start, end]: [Date, Date]) => {
          onChange(
            Object.assign(
              {},
              start ? { start: DateTime.fromJSDate(start) } : undefined,
              end ? { end: DateTime.fromJSDate(end) } : undefined
            )
          );
        }}
        previousMonthButtonLabel="<"
      />
    </DatePickerWrapper>
  );
};

interface StringDatePickerProps extends StandardComponent {
  min?: DateTime;
  max?: DateTime;
  onChange: (next: string) => void;
  value: string | null | undefined;
}

/**
 * StringDatePicker. Wrapper for DatePicker that transparently deals with Luxon DateTimes.
 *
 */
export const StringDatePicker: React.FC<StringDatePickerProps> = (props) => {
  const { value, onChange } = props;

  const asDateTime = React.useMemo(() => {
    if (R.isNil(value)) return value;

    const dt = DateTime.fromISO(value);

    return DateTime.fromISO(dt.toLocal().toJSDate().toISOString());
  }, [value]);

  const onChangeTransform = React.useCallback(
    (next: DateTime) => {
      return onChange(next.toJSDate().toISOString());
    },
    [onChange]
  );

  return (
    <DatePicker
      {...props}
      value={asDateTime as unknown as DateTime}
      onChange={onChangeTransform}
    />
  );
};
