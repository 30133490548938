import type { TabsProps } from "./Tabs";

import styled from "styled-components";
import React from "react";

import * as Style from "style";
import { TabContainer } from "./Tab";
import { Tabs, TabsContainer } from "./Tabs";

export const CompactTabsContainer = styled.div`
  ${TabsContainer} {
    margin-bottom: 0px;

    ${TabContainer} {
      margin-right: ${Style.Layout.Padding.Compact};

      :last-child {
        margin-right: none;
      }
    }
  }
`;

export function CompactTabs<T>(props: TabsProps<T>): React.ReactElement {
  return (
    <CompactTabsContainer>
      <Tabs<T> {...props} />
    </CompactTabsContainer>
  );
}
