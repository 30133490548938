import type { Action as ReduxAction } from "redux";

import * as R from "ramda";
import React from "react";
import cn from "lib/cn";
import { v4 as uuid } from "uuid";

import {
  SpecializedToastProps,
  Toast as ToastComponent,
} from "components/library/Toast";
import { removeAt } from "@milio/lib/util/array";
import { Sentiment } from "components/library/constant";

export const DEFAULT_TOAST_TIMEOUT = 3000;
export const ANIMATION_LENGTH = 1000;

export interface UseToastProps {
  namespace?: string;
}

interface AddAction extends ReduxAction<ToastAction.Add> {
  payload: StoredToast;
}

interface SetCurrentNamespaceAction
  extends ReduxAction<ToastAction.SetCurrentNamespace> {
  payload: string;
}

interface HideAction extends ReduxAction<ToastAction.Hide> {
  payload: {
    id: string;
    namespace: string;
  };
}

interface RemoveAction extends ReduxAction<ToastAction.Remove> {
  payload: {
    id: string;
    namespace: string;
  };
}

interface ClearAction extends ReduxAction<ToastAction.Clear> {
  payload: {
    namespace: string;
  };
}

interface ClearCurrentNamespaceAction
  extends ReduxAction<ToastAction.ClearCurrentNamespace> {}
interface KeepCurrentNamespaceAction
  extends ReduxAction<ToastAction.KeepCurrentNamespace> {}

type Action =
  | AddAction
  | ClearAction
  | ClearCurrentNamespaceAction
  | KeepCurrentNamespaceAction
  | HideAction
  | RemoveAction
  | SetCurrentNamespaceAction;

export interface ToastContainerProps {
  namespace: string;
  occupy?: React.ReactNode;
}

export interface Toast {
  message?: string;
  namespace?: string;
  timeout?: number;
  title?: string;
  sentiment?: Sentiment;
}
export type Toasts = Toast[];

export interface StoredToast extends Toast {
  hidden: boolean;
  id: string;
  created_at: number;
}
export type StoredToasts = StoredToast[];
export type StoredToastMap = Map<string, StoredToasts>;
export interface ToastStore {
  currentNamespace: string;
  toasts: StoredToastMap;
}

export interface ToastManager {
  Sentiment: typeof Sentiment;
  add: (payload: Toast) => void;
  clear: (payload?: { namespace: string }) => void;
  clearCurrentNamespace: () => void;
  currentNamespace: string;
  error: (payload: Toast) => void;
  hide: (payload: { namespace: string; id: string }) => void;
  info: (payload: Toast) => void;
  keepCurrentNamespace: () => void;
  remove: (payload: { namespace: string; id: string }) => void;
  setCurrentNamespace: (namespace: string) => void;
  success: (payload: Toast) => void;
  toasts: StoredToastMap;
  warning: (payload: Toast) => void;
}

const initialState = {
  currentNamespace: "toast.global",
  toasts: new Map(),
};

export enum ToastAction {
  Add = "toast.add",
  Clear = "toast.clear",
  ClearCurrentNamespace = "toast.clear-current-namespace",
  Hide = "toast.hide",
  Remove = "toast.remove",
  SetCurrentNamespace = "toast.set-current-namespace",
  KeepCurrentNamespace = "toast.keep-current-namespace",
}

const reducer = (state: ToastStore, action: Action): ToastStore => {
  switch (action.type) {
    case ToastAction.SetCurrentNamespace: {
      return {
        ...state,
        currentNamespace: action.payload,
      };
    }
    case ToastAction.Add: {
      let found: StoredToasts | undefined = state.toasts.get(
        action.payload.namespace
      );

      if (R.isNil(found)) {
        found = [];
      }

      return R.over(
        R.lensProp("toasts"),
        (current: StoredToastMap) => {
          return new Map(
            current.set(
              action.payload.namespace,
              found.concat([action.payload])
            )
          );
        },
        state
      );
    }
    case ToastAction.Hide: {
      if (!state.toasts.has(action.payload.namespace)) {
        return state;
      }

      const found: StoredToasts = state.toasts.get(action.payload.namespace);

      if (R.isEmpty(found)) {
        return state;
      }

      const index: number = R.findIndex(
        R.propEq("id", action.payload.id),
        found
      );

      if (index == -1) {
        return state;
      }

      return R.over(
        R.lensProp("toasts"),
        (current: StoredToastMap) => {
          const next = R.over(
            R.lensIndex(index),
            (toast: StoredToast) => ({ ...toast, hidden: true }),
            found
          );

          return new Map(current.set(action.payload.namespace, next));
        },
        state
      );
    }
    case ToastAction.Remove: {
      if (!state.toasts.has(action.payload.namespace)) {
        return state;
      }

      const found: StoredToasts = state.toasts.get(action.payload.namespace);

      if (R.isEmpty(found)) {
        return state;
      }

      const index: number = R.findIndex(
        R.propEq("id", action.payload.id),
        found
      );

      if (index == -1) {
        return state;
      }

      return R.over(
        R.lensProp("toasts"),
        (current: StoredToastMap) => {
          return new Map(
            current.set(action.payload.namespace, removeAt(index, found))
          );
        },
        state
      );
    }
    case ToastAction.Clear: {
      if (!state.toasts.has(action.payload.namespace)) {
        return state;
      }

      const found: Toasts = state.toasts.get(action.payload.namespace);

      if (R.isEmpty(found)) {
        return state;
      }

      return R.over(
        R.lensProp("toasts"),
        (current: StoredToastMap) => {
          return new Map(current.set(action.payload.namespace, []));
        },
        state
      );
    }
    case ToastAction.KeepCurrentNamespace: {
      if (!state.toasts.has(state.currentNamespace)) {
        return R.set(R.lensProp("toasts"), new Map(), state);
      }

      return R.over(
        R.lensProp("toasts"),
        (current: StoredToastMap) => {
          return new Map([
            [state.currentNamespace, current.get(state.currentNamespace)],
          ]);
        },
        state
      );
    }
    case ToastAction.ClearCurrentNamespace: {
      if (!state.toasts.has(state.currentNamespace)) {
        return state;
      }

      const found: Toasts = state.toasts.get(state.currentNamespace);

      if (R.isEmpty(found)) {
        return state;
      }

      return R.over(
        R.lensProp("toasts"),
        (current: StoredToastMap) => {
          return new Map(current.set(state.currentNamespace, []));
        },
        state
      );
    }
    default: {
      return state;
    }
  }
};

export const ToastContext = React.createContext<{
  dispatch: (action: Action) => void;
  state: ToastStore;
}>({
  dispatch: () => null,
  state: initialState,
});

export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}): React.ReactElement => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const context = { dispatch, state };

  return (
    <ToastContext.Provider value={context}>{children}</ToastContext.Provider>
  );
};

export const useToast = (params: UseToastProps = {}): ToastManager => {
  const { state, dispatch } = React.useContext(ToastContext);

  const defaults = R.mergeRight({ namespace: state.currentNamespace }, params);

  function clear(request: Partial<ClearAction["payload"]> = {}) {
    const payload = R.mergeRight(defaults, request);

    dispatch({ type: ToastAction.Clear, payload });
  }

  function clearCurrentNamespace() {
    dispatch({ type: ToastAction.ClearCurrentNamespace });
  }

  function keepCurrentNamespace() {
    dispatch({ type: ToastAction.KeepCurrentNamespace });
  }

  function remove(request: RemoveAction["payload"]) {
    const payload = R.mergeRight(defaults, request);

    dispatch({ type: ToastAction.Remove, payload });
  }

  function hide(request: HideAction["payload"]) {
    const payload = R.mergeRight(defaults, request);

    dispatch({ type: ToastAction.Hide, payload });
  }

  function add(request: Toast) {
    const payload = R.mergeRight(defaults, request);

    const storedToast: StoredToast = {
      id: uuid(),
      hidden: false,
      created_at: Date.now(),
      ...payload,
      ...(!R.has("timeout", payload) && { timeout: DEFAULT_TOAST_TIMEOUT }),
    };

    if (R.has("timeout", storedToast) && storedToast.timeout !== 0) {
      setTimeout(() => {
        hide({ namespace: storedToast.namespace, id: storedToast.id });

        setTimeout(() => {
          remove({ namespace: storedToast.namespace, id: storedToast.id });
        }, ANIMATION_LENGTH);
      }, storedToast.timeout);
    }

    dispatch({ type: ToastAction.Add, payload: storedToast });
  }

  function setCurrentNamespace(namespace: string) {
    dispatch({ type: ToastAction.SetCurrentNamespace, payload: namespace });
  }

  function info(payload: Toast) {
    add({ ...payload, sentiment: Sentiment.Info });
  }

  function error(payload: Toast) {
    add({ ...payload, sentiment: Sentiment.Error });
  }

  function warning(payload: Toast) {
    add({ ...payload, sentiment: Sentiment.Warning });
  }

  function success(payload: Toast) {
    add({ ...payload, sentiment: Sentiment.Success });
  }

  return {
    Sentiment,
    add,
    clear,
    currentNamespace: state.currentNamespace,
    error,
    hide,
    info,
    remove,
    setCurrentNamespace,
    keepCurrentNamespace,
    success,
    toasts: state.toasts,
    warning,
    clearCurrentNamespace,
  };
};

export const ToastContainer: React.FC<ToastContainerProps> = ({
  occupy,
  namespace,
}) => {
  const { toasts, remove } = useToast();

  const found: StoredToasts = toasts.get(namespace) || [];
  // For now, simply display the most recent toast.
  const toast: StoredToast | undefined = R.last(found);
  const Component = toast
    ? (R.propOr(ToastComponent.Info, toast.sentiment, {
        [Sentiment.Error]: ToastComponent.Error,
        [Sentiment.Info]: ToastComponent.Info,
        [Sentiment.Success]: ToastComponent.Success,
        [Sentiment.Warning]: ToastComponent.Warning,
      }) as React.JSXElementConstructor<SpecializedToastProps>)
    : null;

  return (
    <div className={cn("grid", R.length(found) > 0 ? "gap-y-2" : "hidden")}>
      {occupy && R.length(found) === 0 && (
        <div className="invisible">{occupy}</div>
      )}
      {toast && (
        <div className={cn(toast.hidden ? "fade-out" : "fade-in")}>
          <Component
            title={toast.title}
            onDismiss={() => {
              remove({ namespace, id: toast.id });
            }}
          >
            {toast.message}
          </Component>
        </div>
      )}
    </div>
  );
};
