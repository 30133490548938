import type { Action as ReduxAction } from "redux";
import type { Breadcrumbs } from "components/library/Breadcrumbs";

import React from "react";

import { BreadcrumbBuilder } from "components/library/Breadcrumbs";

export interface BreadcrumbsManager {
  breadcrumbs: Breadcrumbs;
  clear: () => void;
  concat: (breadcrumbs: Breadcrumbs) => void;
  set: (breadcrumbs: Breadcrumbs) => void;
}

interface ClearAction extends ReduxAction<BreadcrumbAction.Clear> {}

interface SetAction extends ReduxAction<BreadcrumbAction.Set> {
  payload: Breadcrumbs;
}

interface ConcatAction extends ReduxAction<BreadcrumbAction.Concat> {
  payload: Breadcrumbs;
}

type Action = ClearAction | SetAction | ConcatAction;

const BreadcrumbsContextInitialState = [];

enum BreadcrumbAction {
  Clear = "breadcrumbs.clear",
  Concat = "breadcrumbs.concat",
  Set = "breadcrumbs.set",
}

const reducer = (state: Breadcrumbs, action: Action): Breadcrumbs => {
  switch (action.type) {
    case BreadcrumbAction.Set: {
      return action.payload;
    }
    case BreadcrumbAction.Concat: {
      return state.concat(action.payload);
    }
    case BreadcrumbAction.Clear: {
      return [];
    }
    default: {
      return state;
    }
  }
};

export const BreadcrumbsContext = React.createContext<{
  dispatch: (action: Action) => void;
  state: Breadcrumbs;
}>({
  dispatch: () => null,
  state: BreadcrumbsContextInitialState,
});

export const BreadcrumbsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}): React.ReactElement => {
  const [state, dispatch] = React.useReducer(
    reducer,
    BreadcrumbsContextInitialState
  );

  const context = { dispatch, state };

  return (
    <BreadcrumbsContext.Provider value={context}>
      {children}
    </BreadcrumbsContext.Provider>
  );
};

export const useBreadcrumbs = (): BreadcrumbsManager => {
  const { state, dispatch } = React.useContext(BreadcrumbsContext);

  function set(breadcrumbs: Breadcrumbs) {
    dispatch({ type: BreadcrumbAction.Set, payload: breadcrumbs });
  }

  function concat(breadcrumbs: Breadcrumbs) {
    dispatch({ type: BreadcrumbAction.Concat, payload: breadcrumbs });
  }

  function clear() {
    dispatch({ type: BreadcrumbAction.Clear });
  }

  return { breadcrumbs: state, clear, set, concat };
};

export const BreadcrumbsContainer: React.FC<Record<string, unknown>> = () => {
  const { breadcrumbs } = useBreadcrumbs();

  return <BreadcrumbBuilder breadcrumbs={breadcrumbs} />;
};
