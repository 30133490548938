/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Region } from './Region';
import {
    RegionFromJSON,
    RegionFromJSONTyped,
    RegionToJSON,
} from './Region';

/**
 * 
 * @export
 * @interface BenchmarkMetrics
 */
export interface BenchmarkMetrics {
    /**
     * 
     * @type {Array<Region>}
     * @memberof BenchmarkMetrics
     */
    _8: Array<Region>;
    /**
     * 
     * @type {Array<Region>}
     * @memberof BenchmarkMetrics
     */
    _16: Array<Region>;
    /**
     * 
     * @type {Array<Region>}
     * @memberof BenchmarkMetrics
     */
    _32: Array<Region>;
    /**
     * 
     * @type {Array<Region>}
     * @memberof BenchmarkMetrics
     */
    _64: Array<Region>;
}

/**
 * Check if a given object implements the BenchmarkMetrics interface.
 */
export function instanceOfBenchmarkMetrics(value: object): value is BenchmarkMetrics {
    if (!('_8' in value) || value['_8'] === undefined) return false;
    if (!('_16' in value) || value['_16'] === undefined) return false;
    if (!('_32' in value) || value['_32'] === undefined) return false;
    if (!('_64' in value) || value['_64'] === undefined) return false;
    return true;
}

export function BenchmarkMetricsFromJSON(json: any): BenchmarkMetrics {
    return BenchmarkMetricsFromJSONTyped(json, false);
}

export function BenchmarkMetricsFromJSONTyped(json: any, ignoreDiscriminator: boolean): BenchmarkMetrics {
    if (json == null) {
        return json;
    }
    return {
        
        '_8': ((json['8'] as Array<any>).map(RegionFromJSON)),
        '_16': ((json['16'] as Array<any>).map(RegionFromJSON)),
        '_32': ((json['32'] as Array<any>).map(RegionFromJSON)),
        '_64': ((json['64'] as Array<any>).map(RegionFromJSON)),
    };
}

export function BenchmarkMetricsToJSON(value?: BenchmarkMetrics | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        '8': ((value['_8'] as Array<any>).map(RegionToJSON)),
        '16': ((value['_16'] as Array<any>).map(RegionToJSON)),
        '32': ((value['_32'] as Array<any>).map(RegionToJSON)),
        '64': ((value['_64'] as Array<any>).map(RegionToJSON)),
    };
}

