import React from "react";
import * as R from "ramda";

import { db } from "database.config";

export const useClientCohortProvider = <Data>() => {
  React.useLayoutEffect(() => {
    db.open();
  }, []);

  const getCohort = React.useCallback(async (id: string) => {
    return db.cohorts.get(id);
  }, []);

  const getSerializedQuery = React.useCallback(async (id: string) => {
    return getCohort(id).then((d) => JSON.parse(d.query));
  }, []);

  const createCohort = React.useCallback(async (...data: Data[]) => {
    const id = crypto.randomUUID();
    await db.cohorts.put({ id, data });
    return id;
  }, []);

  const createSerializedQuery = React.useCallback(async (query: any) => {
    const id = crypto.randomUUID();
    await db.cohorts.put({ id, query: JSON.stringify(query) });
    return id;
  }, []);

  return {
    getCohort,
    createCohort,
    getSerializedQuery,
    createSerializedQuery,
  };
};

export const useClientCohort = <Data>(id: string) => {
  const [resources, setResources] = React.useState<Data[]>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const { getCohort } = useClientCohortProvider();

  React.useLayoutEffect(() => {
    if (id) {
      setLoading(true);
      getCohort(id)
        .then(R.prop("data"))
        .then(setResources)
        .then(() => setLoading(false));
    }
  }, [setResources, getCohort, id]);

  return { loading, resources };
};

export const useClientQuery = (id: string) => {
  const [query, setQuery] = React.useState<Record<string, unknown>>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const { getSerializedQuery } = useClientCohortProvider();

  React.useLayoutEffect(() => {
    if (id) {
      setLoading(true);
      getSerializedQuery(id)
        .then(setQuery)
        .then(() => setLoading(false));
    }
  }, [setQuery, getSerializedQuery, id]);

  return { loading, query };
};
