import React from "react";
import styled from "styled-components";

import * as Style from "style";

export const CodeContainer = styled.pre`
  display: inline-block;
  font-family: ${Style.Text.FontFamily.Monospace};
  font-style: ${Style.Text.Style.Primary};
  font-style: ${Style.Text.Size.Small};
`;

export interface CodeProps {
  children: React.ReactNode;
}

export const Code: React.FC<CodeProps> = ({ children }) => {
  return <CodeContainer>{children}</CodeContainer>;
};
