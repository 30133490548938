import type { LabelProps } from "./type";

import React from "react";
import styled from "styled-components";

import * as Style from "style";
import { DEFAULT_ON_CLICK } from "./constant";
import { Title } from "../Title";
import { toCursor, toColor } from "./style";

export const TinyLabelContainer = styled.label`
  display: block;
  padding: ${Style.Layout.Padding.Tiny} 0px;
  cursor: ${toCursor};

  > span {
    color: ${toColor};
  }
`;
export const TinyLabel: React.FC<LabelProps> = ({
  children,
  error,
  htmlFor,
  isDisabled = false,
  onClick = DEFAULT_ON_CLICK,
}) => {
  return (
    <TinyLabelContainer
      onClick={onClick}
      error={error}
      isDisabled={isDisabled}
      htmlFor={htmlFor}
    >
      <Title>{children}</Title>
    </TinyLabelContainer>
  );
};
