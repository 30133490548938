import * as R from "ramda";
import React from "react";
import cn from "lib/cn";
import styled from "styled-components";
import {
  XIcon,
  InformationCircleIcon,
  ExclamationIcon,
  XCircleIcon,
  CheckCircleIcon,
} from "@heroicons/react/solid";

import * as Style from "style";
import { Paragraph } from "./Paragraph";
import { Sentiment } from "./constant";
import { Title } from "./Title";
import { sentimentToPrimaryColor } from "./util";

export interface SpecializedToastProps {
  children?: React.ReactNode;
  onDismiss?: () => void;
  title?: React.ReactNode;
}

export interface ToastProps extends SpecializedToastProps {
  header?: React.ReactNode;
  icon?: React.ReactNode;
  sentiment: Sentiment;
}

export interface ToastSpecializations extends React.FC<ToastProps> {
  Error: React.FC<SpecializedToastProps>;
  Info: React.FC<SpecializedToastProps>;
  Placeholder: React.FC<Record<string, unknown>>;
  Success: React.FC<SpecializedToastProps>;
  Warning: React.FC<SpecializedToastProps>;
}

export const ToastContainer = styled.div<ToastProps>`
  background: ${sentimentToPrimaryColor};
  border-radius: ${Style.Design.Rounding.Secondary};
  padding: ${Style.Layout.Padding.ExtraSmall} ${Style.Layout.Padding.Small};
`;

const Toast: ToastSpecializations = ({
  children,
  sentiment,
  header,
  icon,
  onDismiss,
}) => {
  return (
    <ToastContainer sentiment={sentiment}>
      <div className="flex justify-content items-center">
        {icon && <div className="flex-shrink-0">{icon}</div>}
        {header && (
          <div className="ml-2 w-full">
            <Title.Light>{header}</Title.Light>
          </div>
        )}
        {onDismiss && (
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button
                type="button"
                onClick={() => onDismiss()}
                className={cn(
                  "inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2 text-white"
                )}
              >
                <XIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        )}
      </div>
      {children && (
        <div className="mt-2 ml-8">
          <Paragraph.Light>{children}</Paragraph.Light>
        </div>
      )}
    </ToastContainer>
  );
};

const PlaceholderToast: React.FC<Record<string, unknown>> = ({}) => {
  return (
    <Toast
      header="Placeholder"
      onDismiss={R.always(undefined)}
      sentiment={Sentiment.Info}
      icon={<XCircleIcon className="h-5 w-5 text-white" />}
    />
  );
};

const ErrorToast: React.FC<SpecializedToastProps> = ({
  children,
  onDismiss,
  title,
}) => {
  const sentiment = Sentiment.Error;

  return (
    <Toast
      header={title}
      onDismiss={onDismiss}
      sentiment={sentiment}
      icon={<XCircleIcon className={cn("h-5 w-5", `text-white`)} />}
    >
      {children}
    </Toast>
  );
};

const InfoToast: React.FC<SpecializedToastProps> = ({
  children,
  onDismiss,
  title,
}) => {
  const sentiment = Sentiment.Info;

  return (
    <Toast
      header={title}
      onDismiss={onDismiss}
      sentiment={sentiment}
      icon={<InformationCircleIcon className={cn("h-5 w-5", `text-white`)} />}
    >
      {children}
    </Toast>
  );
};

const SuccessToast: React.FC<SpecializedToastProps> = ({
  children,
  onDismiss,
  title,
}) => {
  const sentiment = Sentiment.Success;

  return (
    <Toast
      header={title}
      onDismiss={onDismiss}
      sentiment={sentiment}
      icon={<CheckCircleIcon className={cn("h-5 w-5", `text-white`)} />}
    >
      {children}
    </Toast>
  );
};

const WarningToast: React.FC<SpecializedToastProps> = ({
  children,
  onDismiss,
  title,
}) => {
  const sentiment = Sentiment.Warning;

  return (
    <Toast
      header={title}
      sentiment={sentiment}
      onDismiss={onDismiss}
      icon={<ExclamationIcon className={cn("h-5 w-5", `text-white`)} />}
    >
      {children}
    </Toast>
  );
};

Toast.Error = ErrorToast;
Toast.Info = InfoToast;
Toast.Placeholder = PlaceholderToast;
Toast.Success = SuccessToast;
Toast.Warning = WarningToast;

export { Toast };
