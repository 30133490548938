/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Benchmark
 */
export interface Benchmark {
    /**
     * 
     * @type {string}
     * @memberof Benchmark
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Benchmark
     */
    job_title_id: string;
    /**
     * 
     * @type {string}
     * @memberof Benchmark
     */
    zone_id: string;
    /**
     * 
     * @type {string}
     * @memberof Benchmark
     */
    survey_date: string;
    /**
     * 
     * @type {number}
     * @memberof Benchmark
     */
    pct_25: number;
    /**
     * 
     * @type {number}
     * @memberof Benchmark
     */
    pct_50: number;
    /**
     * 
     * @type {number}
     * @memberof Benchmark
     */
    pct_75: number;
    /**
     * 
     * @type {string}
     * @memberof Benchmark
     */
    notes: string;
    /**
     * 
     * @type {number}
     * @memberof Benchmark
     */
    aging_factor: number;
}

/**
 * Check if a given object implements the Benchmark interface.
 */
export function instanceOfBenchmark(value: object): value is Benchmark {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('job_title_id' in value) || value['job_title_id'] === undefined) return false;
    if (!('zone_id' in value) || value['zone_id'] === undefined) return false;
    if (!('survey_date' in value) || value['survey_date'] === undefined) return false;
    if (!('pct_25' in value) || value['pct_25'] === undefined) return false;
    if (!('pct_50' in value) || value['pct_50'] === undefined) return false;
    if (!('pct_75' in value) || value['pct_75'] === undefined) return false;
    if (!('notes' in value) || value['notes'] === undefined) return false;
    if (!('aging_factor' in value) || value['aging_factor'] === undefined) return false;
    return true;
}

export function BenchmarkFromJSON(json: any): Benchmark {
    return BenchmarkFromJSONTyped(json, false);
}

export function BenchmarkFromJSONTyped(json: any, ignoreDiscriminator: boolean): Benchmark {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'job_title_id': json['job_title_id'],
        'zone_id': json['zone_id'],
        'survey_date': json['survey_date'],
        'pct_25': json['pct_25'],
        'pct_50': json['pct_50'],
        'pct_75': json['pct_75'],
        'notes': json['notes'],
        'aging_factor': json['aging_factor'],
    };
}

export function BenchmarkToJSON(value?: Benchmark | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'job_title_id': value['job_title_id'],
        'zone_id': value['zone_id'],
        'survey_date': value['survey_date'],
        'pct_25': value['pct_25'],
        'pct_50': value['pct_50'],
        'pct_75': value['pct_75'],
        'notes': value['notes'],
        'aging_factor': value['aging_factor'],
    };
}

