/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TaskState } from './TaskState';
import {
    TaskStateFromJSON,
    TaskStateFromJSONTyped,
    TaskStateToJSON,
} from './TaskState';

/**
 * 
 * @export
 * @interface UpdateTaskDto
 */
export interface UpdateTaskDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateTaskDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaskDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaskDto
     */
    resource_id?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaskDto
     */
    resource_type?: string;
    /**
     * 
     * @type {TaskState}
     * @memberof UpdateTaskDto
     */
    state?: TaskState;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateTaskDto
     */
    tags?: Array<string>;
}



/**
 * Check if a given object implements the UpdateTaskDto interface.
 */
export function instanceOfUpdateTaskDto(value: object): value is UpdateTaskDto {
    return true;
}

export function UpdateTaskDtoFromJSON(json: any): UpdateTaskDto {
    return UpdateTaskDtoFromJSONTyped(json, false);
}

export function UpdateTaskDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateTaskDto {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'] == null ? undefined : json['title'],
        'description': json['description'] == null ? undefined : json['description'],
        'resource_id': json['resource_id'] == null ? undefined : json['resource_id'],
        'resource_type': json['resource_type'] == null ? undefined : json['resource_type'],
        'state': json['state'] == null ? undefined : TaskStateFromJSON(json['state']),
        'tags': json['tags'] == null ? undefined : json['tags'],
    };
}

export function UpdateTaskDtoToJSON(value?: UpdateTaskDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'description': value['description'],
        'resource_id': value['resource_id'],
        'resource_type': value['resource_type'],
        'state': TaskStateToJSON(value['state']),
        'tags': value['tags'],
    };
}

