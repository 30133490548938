/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportGraphProps
 */
export interface ReportGraphProps {
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportGraphProps
     */
    dimensions: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportGraphProps
     */
    measures: Array<string>;
}

/**
 * Check if a given object implements the ReportGraphProps interface.
 */
export function instanceOfReportGraphProps(value: object): value is ReportGraphProps {
    if (!('dimensions' in value) || value['dimensions'] === undefined) return false;
    if (!('measures' in value) || value['measures'] === undefined) return false;
    return true;
}

export function ReportGraphPropsFromJSON(json: any): ReportGraphProps {
    return ReportGraphPropsFromJSONTyped(json, false);
}

export function ReportGraphPropsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportGraphProps {
    if (json == null) {
        return json;
    }
    return {
        
        'dimensions': json['dimensions'],
        'measures': json['measures'],
    };
}

export function ReportGraphPropsToJSON(value?: ReportGraphProps | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'dimensions': value['dimensions'],
        'measures': value['measures'],
    };
}

