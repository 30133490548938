/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreatePlanGroupDto
 */
export interface CreatePlanGroupDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePlanGroupDto
     */
    job_family_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreatePlanGroupDto
     */
    job_level_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreatePlanGroupDto
     */
    remote_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreatePlanGroupDto
     */
    title: string;
}

/**
 * Check if a given object implements the CreatePlanGroupDto interface.
 */
export function instanceOfCreatePlanGroupDto(value: object): value is CreatePlanGroupDto {
    if (!('title' in value) || value['title'] === undefined) return false;
    return true;
}

export function CreatePlanGroupDtoFromJSON(json: any): CreatePlanGroupDto {
    return CreatePlanGroupDtoFromJSONTyped(json, false);
}

export function CreatePlanGroupDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePlanGroupDto {
    if (json == null) {
        return json;
    }
    return {
        
        'job_family_id': json['job_family_id'] == null ? undefined : json['job_family_id'],
        'job_level_id': json['job_level_id'] == null ? undefined : json['job_level_id'],
        'remote_id': json['remote_id'] == null ? undefined : json['remote_id'],
        'title': json['title'],
    };
}

export function CreatePlanGroupDtoToJSON(value?: CreatePlanGroupDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'job_family_id': value['job_family_id'],
        'job_level_id': value['job_level_id'],
        'remote_id': value['remote_id'],
        'title': value['title'],
    };
}

