import type { SpecializedTooltipProps } from "./type";

import React from "react";
import styled from "styled-components";
import { v4 as uuid } from "uuid";

import * as Style from "style";
import * as Icon from "components/Icon";
import { Tooltip } from "./Tooltip";

export const InfoTooltipContainer = styled.div`
  > svg {
    color: ${Style.Color.Sentiment.Info.Primary};
    height: ${Style.Text.Size.Base};
    width: ${Style.Text.Size.Base};
  }
`;

export const InfoTooltip: React.FC<SpecializedTooltipProps> = ({
  children,
  ...rest
}) => {
  const [id] = React.useState<string>(uuid());

  return (
    <InfoTooltipContainer>
      <Icon.InformationCircle data-for={id} data-tip />
      <Tooltip id={id} {...rest}>
        {children}
      </Tooltip>
    </InfoTooltipContainer>
  );
};
