import type { Column } from "./type";

import * as R from "ramda";
import styled, { css } from "styled-components";

import * as Style from "style";

export interface CellCSSProps {
  column: Column;
}

function toWidth({ column }: CellCSSProps): string {
  return column.fixedWidth ? `${column.fixedWidth / 4}rem` : "auto";
}

function toMaxWidth({ column }: CellCSSProps): string {
  return column.range ? `${column.range.start / 4}rem` : "auto";
}

function toMinWidth({ column }: CellCSSProps): string {
  if (R.isNil(column.fixedWidth)) {
    return column.range ? `${column.range.end / 4}rem` : "auto";
  }

  return "auto";
}

export const CellCSS = css`
  ${Style.Text.Definition.Paragraph};
  color: ${Style.Color.Font.Secondary};
  padding: 0px;
  text-align: left;
`;

export const CellContainer = styled.div<CellCSSProps>`
  min-height: ${Style.Layout.Table.Cell.MinContentHeight};
  align-items: center;
  display: flex;
  max-width: ${toMaxWidth};
  min-width: ${toMinWidth};
  width: ${toWidth};
  padding: ${Style.Layout.Padding.ExtraSmall} ${Style.Layout.Padding.Medium};
`;

export const TruncationWrapper = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;
