/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FixedExchangeRateItem } from './FixedExchangeRateItem';
import {
    FixedExchangeRateItemFromJSON,
    FixedExchangeRateItemFromJSONTyped,
    FixedExchangeRateItemToJSON,
} from './FixedExchangeRateItem';

/**
 * 
 * @export
 * @interface FixedExchangeRate
 */
export interface FixedExchangeRate {
    /**
     * 
     * @type {string}
     * @memberof FixedExchangeRate
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FixedExchangeRate
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof FixedExchangeRate
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof FixedExchangeRate
     */
    deleted_at: string | null;
    /**
     * 
     * @type {string}
     * @memberof FixedExchangeRate
     */
    from_currency: string;
    /**
     * 
     * @type {string}
     * @memberof FixedExchangeRate
     */
    to_currency: string;
    /**
     * 
     * @type {Array<FixedExchangeRateItem>}
     * @memberof FixedExchangeRate
     */
    rates: Array<FixedExchangeRateItem>;
}

/**
 * Check if a given object implements the FixedExchangeRate interface.
 */
export function instanceOfFixedExchangeRate(value: object): value is FixedExchangeRate {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('created_at' in value) || value['created_at'] === undefined) return false;
    if (!('updated_at' in value) || value['updated_at'] === undefined) return false;
    if (!('deleted_at' in value) || value['deleted_at'] === undefined) return false;
    if (!('from_currency' in value) || value['from_currency'] === undefined) return false;
    if (!('to_currency' in value) || value['to_currency'] === undefined) return false;
    if (!('rates' in value) || value['rates'] === undefined) return false;
    return true;
}

export function FixedExchangeRateFromJSON(json: any): FixedExchangeRate {
    return FixedExchangeRateFromJSONTyped(json, false);
}

export function FixedExchangeRateFromJSONTyped(json: any, ignoreDiscriminator: boolean): FixedExchangeRate {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created_at': json['created_at'],
        'updated_at': json['updated_at'],
        'deleted_at': json['deleted_at'],
        'from_currency': json['from_currency'],
        'to_currency': json['to_currency'],
        'rates': ((json['rates'] as Array<any>).map(FixedExchangeRateItemFromJSON)),
    };
}

export function FixedExchangeRateToJSON(value?: FixedExchangeRate | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'created_at': value['created_at'],
        'updated_at': value['updated_at'],
        'deleted_at': value['deleted_at'],
        'from_currency': value['from_currency'],
        'to_currency': value['to_currency'],
        'rates': ((value['rates'] as Array<any>).map(FixedExchangeRateItemToJSON)),
    };
}

