/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Aggregation
 */
export interface Aggregation {
    /**
     * 
     * @type {string}
     * @memberof Aggregation
     */
    aggregation: AggregationAggregationEnum;
    /**
     * 
     * @type {string}
     * @memberof Aggregation
     */
    id: string;
}


/**
 * @export
 */
export const AggregationAggregationEnum = {
    Average: 'average',
    Count: 'count',
    DistinctCount: 'distinct-count',
    Max: 'max',
    Median: 'median',
    Min: 'min',
    Sum: 'sum'
} as const;
export type AggregationAggregationEnum = typeof AggregationAggregationEnum[keyof typeof AggregationAggregationEnum];


/**
 * Check if a given object implements the Aggregation interface.
 */
export function instanceOfAggregation(value: object): value is Aggregation {
    if (!('aggregation' in value) || value['aggregation'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    return true;
}

export function AggregationFromJSON(json: any): Aggregation {
    return AggregationFromJSONTyped(json, false);
}

export function AggregationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Aggregation {
    if (json == null) {
        return json;
    }
    return {
        
        'aggregation': json['aggregation'],
        'id': json['id'],
    };
}

export function AggregationToJSON(value?: Aggregation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'aggregation': value['aggregation'],
        'id': value['id'],
    };
}

