import type { ActionComponent } from "components/type";

import React, { MouseEventHandler } from "react";
import styled from "styled-components";
import { XCircleIcon } from "@heroicons/react/outline";

import * as Style from "style";

export interface RemoveButtonProps extends ActionComponent {
  onClick: MouseEventHandler<HTMLButtonElement>;
}

function toColor({ isDisabled }: RemoveButtonProps): string {
  return isDisabled ? Style.Color.Layout.Line : Style.Color.Layout.Gray;
}

export const RemoveButtonContainer = styled.div<RemoveButtonProps>`
  cursor: pointer;
  color: ${toColor};

  &:hover {
    color ${Style.Color.Chart.Mandy};
  }
`;

export const RemoveButton: React.FC<RemoveButtonProps> = ({
  isDisabled = false,
  onClick,
}) => (
  <RemoveButtonContainer disabled={isDisabled} onClick={onClick} type="button">
    <XCircleIcon className="inline-block w-6 h-6" />
  </RemoveButtonContainer>
);
