import * as R from "ramda";
import React from "react";

export const useDetectOutsideClick = ({
  excludes = [],
  initial,
  ref,
  excludePortal = false,
}: {
  initial: boolean;
  ref: React.RefObject<HTMLElement>;
  excludes?: HTMLElement[];
  excludePortal?: boolean;
}): [boolean, (next: boolean) => void] => {
  const [isActive, setIsActive] = React.useState<boolean>(initial);

  React.useEffect(() => {
    const onClick = (event) => {
      const { target } = event;

      // Handle class of target no longering being mounted in DOM.
      if (!document.contains(target)) {
        return;
      }

      if (excludePortal) {
        const portal = document.getElementById("portal-root");

        if (portal && portal.contains(target)) {
          return;
        }
      }

      // Sometimes you want to exclude specific nodes from counting as being outside.
      if (R.any((ref) => ref.contains(target), excludes)) {
        return;
      }

      if (ref.current !== null && !ref.current.contains(event.target)) {
        setIsActive(!isActive);
      }
    };

    if (isActive) {
      window.addEventListener("click", onClick);
    }

    return () => {
      window.removeEventListener("click", onClick);
    };
  }, [isActive, ref]);

  return [isActive, setIsActive];
};
