import type { SpecializedPillProps } from "./type";

import React from "react";

import { Pill } from "./Pill";
import { Sentiment } from "../constant";

export const InfoPill: React.FC<SpecializedPillProps> = (props) => {
  return <Pill sentiment={Sentiment.Info} {...props} />;
};
