import type { ApplicationContext } from "context";

import React from "react";

import { BlankWhiteLayout } from "./BlankWhiteLayout";
import { SectionHeader } from "./SectionHeader";
import {
  FronteggProvider,
  AuthenticationProvider,
  BreadcrumbsProvider,
  MetadataProvider,
  ModalContainer,
  ModalProvider,
  ToastProvider,
  useApplicationContext,
} from "context";
import { Paragraph } from "components/library";

export interface StandardLayoutProps {
  children: React.ReactNode;
}

export interface StandardLayoutContentProps {
  children: React.ReactNode;
}

export interface StandardContentAreaProps {
  children: React.ReactNode;
}

export const StandardContentArea: React.FC<StandardContentAreaProps> = ({
  children,
}) => {
  return <div className="mt-4">{children}</div>;
};

export const Content: React.FC<StandardLayoutContentProps> = ({ children }) => {
  const context: ApplicationContext = useApplicationContext();

  return (
    <BlankWhiteLayout title={context.metadata.metadata.title}>
      <div className="max-w-6xl">
        <div className="grid grid-cols-2">
          <SectionHeader
            breadcrumbs={context.breadcrumbs.breadcrumbs}
            toastNamespace={context.toast.currentNamespace}
            title={context.metadata.metadata.title}
            subtitle={
              <Paragraph>{context.metadata.metadata.subtitle}</Paragraph>
            }
          />
        </div>
        {children}
      </div>
    </BlankWhiteLayout>
  );
};

export const StandardProvider: React.FC<StandardLayoutProps> = ({
  children,
}) => {
  return (
    <FronteggProvider>
      <AuthenticationProvider>
        <MetadataProvider>
          <BreadcrumbsProvider>
            <ToastProvider>
              <ModalProvider>
                <>
                  <ModalContainer />
                  {children}
                </>
              </ModalProvider>
            </ToastProvider>
          </BreadcrumbsProvider>
        </MetadataProvider>
      </AuthenticationProvider>
    </FronteggProvider>
  );
};

export const StandardLayout: React.FC<StandardLayoutProps> = ({ children }) => {
  return <Content>{children}</Content>;
};
