/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreatePlanGroupDto,
  PlanIdentifier,
  UpdatePlanGroupDto,
} from '../models/index';
import {
    CreatePlanGroupDtoFromJSON,
    CreatePlanGroupDtoToJSON,
    PlanIdentifierFromJSON,
    PlanIdentifierToJSON,
    UpdatePlanGroupDtoFromJSON,
    UpdatePlanGroupDtoToJSON,
} from '../models/index';

export interface RegisterPlanGroupRequest {
    createPlanGroupDto: CreatePlanGroupDto;
}

export interface RegisterPlanGroupBatchRequest {
    createPlanGroupDto: Array<CreatePlanGroupDto>;
}

export interface UpdatePlanGroupMappingRequest {
    id: string;
    updatePlanGroupDto: UpdatePlanGroupDto;
}

/**
 * 
 */
export class PlanGroupApi extends runtime.BaseAPI {

    /**
     */
    async registerPlanGroupRaw(requestParameters: RegisterPlanGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PlanIdentifier>> {
        if (requestParameters['createPlanGroupDto'] == null) {
            throw new runtime.RequiredError(
                'createPlanGroupDto',
                'Required parameter "createPlanGroupDto" was null or undefined when calling registerPlanGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/plan-groups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePlanGroupDtoToJSON(requestParameters['createPlanGroupDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlanIdentifierFromJSON(jsonValue));
    }

    /**
     */
    async registerPlanGroup(requestParameters: RegisterPlanGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PlanIdentifier> {
        const response = await this.registerPlanGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async registerPlanGroupBatchRaw(requestParameters: RegisterPlanGroupBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PlanIdentifier>>> {
        if (requestParameters['createPlanGroupDto'] == null) {
            throw new runtime.RequiredError(
                'createPlanGroupDto',
                'Required parameter "createPlanGroupDto" was null or undefined when calling registerPlanGroupBatch().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/plan-groups/batch`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['createPlanGroupDto']!.map(CreatePlanGroupDtoToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PlanIdentifierFromJSON));
    }

    /**
     */
    async registerPlanGroupBatch(requestParameters: RegisterPlanGroupBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PlanIdentifier>> {
        const response = await this.registerPlanGroupBatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updatePlanGroupMappingRaw(requestParameters: UpdatePlanGroupMappingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updatePlanGroupMapping().'
            );
        }

        if (requestParameters['updatePlanGroupDto'] == null) {
            throw new runtime.RequiredError(
                'updatePlanGroupDto',
                'Required parameter "updatePlanGroupDto" was null or undefined when calling updatePlanGroupMapping().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/plan-groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePlanGroupDtoToJSON(requestParameters['updatePlanGroupDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updatePlanGroupMapping(requestParameters: UpdatePlanGroupMappingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updatePlanGroupMappingRaw(requestParameters, initOverrides);
    }

}
