import type { ColorOverlayProps } from "./Color";
import type { OverlayProps } from "./type";

import React from "react";

import { ColorOverlay } from "./Color";
import { OverlayContainer } from "./Base";

export interface OverlaySpecializations extends React.FC<OverlayProps> {
  Color: React.FC<ColorOverlayProps>;
}

export const Overlay: OverlaySpecializations = (props) => {
  return <OverlayContainer {...props} />;
};

Overlay.Color = ColorOverlay;
