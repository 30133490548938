import styled from "styled-components";

import * as Style from "style";

export const TableHeader = styled.thead`
  background: ${Style.Color.Layout.Background.Primary};
  border-style: ${Style.Design.Border.Style};
  border-bottom-width: ${Style.Design.Border.Size};
  border-color: ${Style.Color.Layout.Line};
  border-top-left-radius: ${Style.Design.Rounding.Secondary};
  border-top-right-radius: ${Style.Design.Rounding.Secondary};
`;
