import type { Auth } from "context";

import * as R from "ramda";
import Head from "next/head";
import React from "react";
import { useRouter } from "next/router";

import Frame from "components/Frame";
import { Icon } from "components/library";
import { removeQueryParams } from "util/url";
import { useApplicationContext } from "context";
import { ClipboardListIcon, SparklesIcon } from "@heroicons/react/outline";
import { IconProps } from "./Icon";
import { PermissionType, RoleType } from ".generated/models";

interface DashboardProps extends React.PropsWithChildren {
  title?: string;
}

export interface MenuItem {
  Icon: React.FunctionComponent<IconProps>;
  href: string;
  label: string;
  testID: string;
  hasNotifications?: boolean;
}
export type MenuItems = MenuItem[];

function findActiveMenuIndex(items: MenuItems, route: string): number {
  return R.findIndex((item: MenuItem) => {
    if (item.href === "/") {
      return route === "/";
    }

    const parts = removeQueryParams(item.href).split("/");
    const first = R.view(R.lensIndex(1), parts);

    return route.startsWith(`/${first}`);
  }, items);
}

function getMenuItems({ auth }: { auth: Auth }): MenuItems {
  const items: MenuItems = [];

  if (auth.authenticate({ roles: [RoleType.Admin] })) {
    items.push({
      label: "AI Copilot",
      Icon: SparklesIcon,
      href: "/copilot",
      testID: "copilot",
    });
  }

  if (auth.authenticate({ roles: [RoleType.Admin, RoleType.ViewOnly] })) {
    items.push({
      label: "Dashboard",
      Icon: Icon.Home,
      href: "/",
      testID: "dashboard",
    });
  }

  if (auth.authenticate({ roles: [RoleType.Admin, RoleType.ViewOnly] })) {
    items.push({
      label: "Plans",
      Icon: Icon.Plans,
      href: "/plan",
      testID: "plan",
    });
  }

  if (auth.authenticate({ roles: [RoleType.Admin, RoleType.ViewOnly] })) {
    items.push({
      label: "Employees",
      Icon: Icon.Employee,
      href: "/compensation-plan",
      testID: "employee",
    });
  }

  if (auth.authenticate({ roles: [RoleType.Admin, RoleType.ViewOnly] })) {
    items.push({
      label: "Reports",
      Icon: Icon.Reports,
      href: "/report",
      testID: "report",
    });
  }

  if (auth.authenticate({ permissions: [PermissionType.HealthchecksView] })) {
    items.push({
      label: "Health Check",
      Icon: Icon.HealthCheck,
      href: "/health-check",
      testID: "health-check",
    });
  }

  if (auth.authenticate({ roles: [RoleType.Admin, RoleType.ViewOnly] })) {
    items.push({
      Icon: ClipboardListIcon,
      href: `/task`,
      label: "Tasks",
      testID: "task",
    });
  }

  if (auth.authenticate({ roles: [RoleType.Admin, RoleType.ViewOnly] })) {
    items.push({
      label: "Settings",
      Icon: Icon.Settings,
      href: "/setting",
      testID: "setting",
    });
  }

  return items;
}

export const DashboardFrame: React.FC<DashboardProps> = ({
  title,
  children,
}) => {
  const { auth, user } = useApplicationContext();
  const { route } = useRouter();

  const menu: MenuItems = getMenuItems({ auth });

  const activeMenuIndex: number = findActiveMenuIndex(menu, route);

  return (
    <>
      <Head>
        <title>Milio - {title}</title>
      </Head>
      <Frame menu={menu} user={user} activeMenuIndex={activeMenuIndex}>
        {children}
      </Frame>
    </>
  );
};
