import * as R from "ramda";
import React from "react";
import styled from "styled-components";

import * as Style from "style";
import { Link } from "../Link";
import { labelize } from "util/format";

export interface TabProps {
  children: React.ReactNode;
  isActive: boolean;
  onClick: () => void;
  testID?: string;
}

interface TabContainerProps {
  isActive: boolean;
}

function toBorderBottom({ isActive }: TabContainerProps): string {
  return isActive
    ? `2px solid ${Style.Color.Brand.Primary}`
    : `2px solid transparent`;
}

function toColor({ isActive }: TabContainerProps): string {
  return isActive ? Style.Color.Brand.Primary : Style.Color.Font.Tertiary;
}

function toHoverColor({ isActive }: TabContainerProps): string {
  return isActive ? Style.Color.Brand.Primary : Style.Color.Brand.Primary;
}

function toCursor({ isActive }: TabContainerProps): string {
  return isActive ? "auto" : "pointer";
}

function toWeight({ isActive }: TabContainerProps): string {
  return isActive ? Style.Text.Weight.Tertiary : Style.Text.Weight.Secondary;
}

export const TabContainer = styled.div`
  background: transparent;
  cursor: ${toCursor};
  padding-bottom: ${Style.Layout.Padding.Compact};
  border-bottom: ${toBorderBottom};

  a {
    font-weight: ${toWeight};

    &:hover {
      color: ${toHoverColor};
    }

    color: ${toColor};
  }
`;

export const Tab: React.FC<TabProps> = ({
  children,
  onClick,
  isActive,
  testID,
}) => {
  return (
    <TabContainer
      className="whitespace-nowrap"
      onClick={() => onClick()}
      isActive={isActive}
    >
      <Link testID={testID}>
        {R.is(String, children) && labelize(children as string)}
        {!R.is(String, children) && children}
      </Link>
    </TabContainer>
  );
};
