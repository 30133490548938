import type { BaseInputProps } from "./type";

import * as R from "ramda";
import React from "react";

import { BaseInput } from "./Base";

export interface RationalNumberInputProps
  extends Omit<BaseInputProps, "onChange" | "value"> {
  onChange?: (next: number, event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: number | undefined;
}

/**
 * Regexp used to test for a potential rational number input.
 */
const POTENTIAL_RATIONAL_NUMBER_INPUT = /^[-]?\d*\.?\d*$/;

/**
 * Convert a passed rational number into its display value.
 */
function valueToDisplay(value: number | undefined): string {
  return R.isNil(value) ? "" : value.toString();
}

/**
 * Determine if an input string represents a potential rational number.
 */
function isPotentialRationalNumber(input: string): boolean {
  return POTENTIAL_RATIONAL_NUMBER_INPUT.test(input);
}

export const RationalNumberInput: React.FC<RationalNumberInputProps> = (
  props
) => {
  const { value } = props;

  const displayValue: string = valueToDisplay(value);
  const [display, setDisplay] = React.useState<string>(displayValue);

  return (
    <BaseInput
      {...R.omit(["value"], props)}
      value={display}
      onChange={(next: string, event: React.ChangeEvent<HTMLInputElement>) => {
        if (isPotentialRationalNumber(next)) {
          setDisplay(next);

          if (displayValue !== next) {
            props.onChange(parseFloat(next), event);
          }
        }
      }}
    />
  );
};
