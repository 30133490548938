import React from "react";

import type { IconProps } from "./type";

export const Settings: React.FC<IconProps> = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="100%"
      height="100%"
      viewBox="0 0 256 256"
      stroke="currentColor"
      fill="none"
    >
      <line
        x1="147.99951"
        y1="171.99353"
        x2="39.99951"
        y2="171.99365"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      ></line>
      <line
        x1="215.99951"
        y1="171.99365"
        x2="187.99951"
        y2="171.99353"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      ></line>
      <circle
        cx="167.99951"
        cy="171.99353"
        r="20"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      ></circle>
      <line
        x1="83.99951"
        y1="83.99353"
        x2="39.99951"
        y2="83.99294"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      ></line>
      <line
        x1="215.99951"
        y1="83.99294"
        x2="123.99951"
        y2="83.99353"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      ></line>
      <circle
        cx="103.99951"
        cy="83.99353"
        r="20"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      ></circle>
    </svg>
  );
};
