/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface AssignUserToPolicyRequest {
    userId: string;
    policyId: string;
}

export interface GetUsersAssignedToPolicyRequest {
    policyId: string;
}

export interface RemoveUserFromPolicyRequest {
    userId: string;
    policyId: string;
}

/**
 * 
 */
export class UserPoliciesApi extends runtime.BaseAPI {

    /**
     */
    async assignUserToPolicyRaw(requestParameters: AssignUserToPolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling assignUserToPolicy().'
            );
        }

        if (requestParameters['policyId'] == null) {
            throw new runtime.RequiredError(
                'policyId',
                'Required parameter "policyId" was null or undefined when calling assignUserToPolicy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user-policies/{userId}/{policyId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))).replace(`{${"policyId"}}`, encodeURIComponent(String(requestParameters['policyId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async assignUserToPolicy(requestParameters: AssignUserToPolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.assignUserToPolicyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getUsersAssignedToPolicyRaw(requestParameters: GetUsersAssignedToPolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters['policyId'] == null) {
            throw new runtime.RequiredError(
                'policyId',
                'Required parameter "policyId" was null or undefined when calling getUsersAssignedToPolicy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user-policies/{policyId}/users`.replace(`{${"policyId"}}`, encodeURIComponent(String(requestParameters['policyId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getUsersAssignedToPolicy(requestParameters: GetUsersAssignedToPolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getUsersAssignedToPolicyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removeUserFromPolicyRaw(requestParameters: RemoveUserFromPolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling removeUserFromPolicy().'
            );
        }

        if (requestParameters['policyId'] == null) {
            throw new runtime.RequiredError(
                'policyId',
                'Required parameter "policyId" was null or undefined when calling removeUserFromPolicy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user-policies/{userId}/{policyId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))).replace(`{${"policyId"}}`, encodeURIComponent(String(requestParameters['policyId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async removeUserFromPolicy(requestParameters: RemoveUserFromPolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeUserFromPolicyRaw(requestParameters, initOverrides);
    }

}
