import type { StandardComponent } from "components/type";

import * as R from "ramda";
import React from "react";
import styled from "styled-components";

import * as Style from "style";
import { SubTitle } from "../SubTitle";
import { Swatch } from "../Swatch";
import { Tooltip } from "../Tooltip";

export interface LegendItemProps extends StandardComponent {
  color: string;
  isDisabled?: boolean;
  key: number;
  label: string;
  onClick?: (label: string) => void;
}

export const LegendItemContainer = styled.div`
  align-items: center;
  column-gap: ${Style.Layout.Padding.ExtraSmall};
  display: flex;
`;

export const LegendItem: React.FC<LegendItemProps> = ({
  color,
  isDisabled = false,
  key,
  label,
  onClick = R.always(undefined),
}) => {
  return (
    <LegendItemContainer onClick={() => onClick(label)}>
      <Swatch.Color color={color} isDisabled={isDisabled} />
      <Tooltip.Truncated tooltip={label}>
        <SubTitle key={key}>{label}</SubTitle>
      </Tooltip.Truncated>
    </LegendItemContainer>
  );
};
