import type { LabelProps } from "./type";

import * as R from "ramda";
import styled from "styled-components";

import * as Style from "style";

import { DEFAULT_ON_CLICK } from "./constant";

export function toColor({ isDisabled, error }): string {
  if (isDisabled) {
    return Style.Color.Layout.Gray;
  }

  if (!R.isNil(error)) {
    return Style.Color.Sentiment.Error.Primary;
  }

  return Style.Color.Font.Primary;
}

export function toCursor({ onClick }): string {
  return onClick === DEFAULT_ON_CLICK ? "default" : "pointer";
}

export const LabelContainer = styled.label<{
  error: LabelProps["error"];
  isDisabled: LabelProps["isDisabled"];
  onClick: LabelProps["onClick"];
}>`
  cursor: ${toCursor};
  display: flex;
  padding-bottom: ${Style.Layout.Padding.ExtraSmall};
  align-items: center;
  column-gap: ${Style.Layout.Padding.ExtraSmall};

  > span {
    color: ${toColor};
  }
`;
