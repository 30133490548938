/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HealthcheckRunResultValue
 */
export interface HealthcheckRunResultValue {
    /**
     * 
     * @type {string}
     * @memberof HealthcheckRunResultValue
     */
    id: HealthcheckRunResultValueIdEnum;
    /**
     * 
     * @type {number}
     * @memberof HealthcheckRunResultValue
     */
    value: number;
    /**
     * 
     * @type {number}
     * @memberof HealthcheckRunResultValue
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof HealthcheckRunResultValue
     */
    delta: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof HealthcheckRunResultValue
     */
    resources: Array<string>;
}


/**
 * @export
 */
export const HealthcheckRunResultValueIdEnum = {
    AboveBand: 'above-band',
    BelowBand: 'below-band',
    LowEmployeeCount: 'low-employee-count',
    StalePay: 'stale-pay',
    StalePayband: 'stale-payband',
    StaleBenchmark: 'stale-benchmark',
    NoPayband: 'no-payband',
    NoBenchmark: 'no-benchmark',
    NoJobFamily: 'no-job-family',
    NoJobLevel: 'no-job-level',
    HighPaybandSpread: 'high-payband-spread',
    AboveBandPct: 'above-band-pct',
    BelowBandPct: 'below-band-pct'
} as const;
export type HealthcheckRunResultValueIdEnum = typeof HealthcheckRunResultValueIdEnum[keyof typeof HealthcheckRunResultValueIdEnum];


/**
 * Check if a given object implements the HealthcheckRunResultValue interface.
 */
export function instanceOfHealthcheckRunResultValue(value: object): value is HealthcheckRunResultValue {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    if (!('total' in value) || value['total'] === undefined) return false;
    if (!('delta' in value) || value['delta'] === undefined) return false;
    if (!('resources' in value) || value['resources'] === undefined) return false;
    return true;
}

export function HealthcheckRunResultValueFromJSON(json: any): HealthcheckRunResultValue {
    return HealthcheckRunResultValueFromJSONTyped(json, false);
}

export function HealthcheckRunResultValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): HealthcheckRunResultValue {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'value': json['value'],
        'total': json['total'],
        'delta': json['delta'],
        'resources': json['resources'],
    };
}

export function HealthcheckRunResultValueToJSON(value?: HealthcheckRunResultValue | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'value': value['value'],
        'total': value['total'],
        'delta': value['delta'],
        'resources': value['resources'],
    };
}

