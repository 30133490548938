/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Me
 */
export interface Me {
    /**
     * 
     * @type {string}
     * @memberof Me
     */
    employee_id: string;
}

/**
 * Check if a given object implements the Me interface.
 */
export function instanceOfMe(value: object): value is Me {
    if (!('employee_id' in value) || value['employee_id'] === undefined) return false;
    return true;
}

export function MeFromJSON(json: any): Me {
    return MeFromJSONTyped(json, false);
}

export function MeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Me {
    if (json == null) {
        return json;
    }
    return {
        
        'employee_id': json['employee_id'],
    };
}

export function MeToJSON(value?: Me | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'employee_id': value['employee_id'],
    };
}

