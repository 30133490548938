import type { MultiProgressBarProps } from "./Multi";
import type { ProgressBarProps } from "./ProgressBar";

import React from "react";

import { MultiProgressBar } from "./Multi";
import { ProgressBar as DefaultProgressBar } from "./ProgressBar";

export interface ProgressBarSpecializations extends React.FC<ProgressBarProps> {
  Multi: React.FC<MultiProgressBarProps>;
}

export const ProgressBar: ProgressBarSpecializations = (props) => {
  return <DefaultProgressBar {...props} />;
};

ProgressBar.Multi = MultiProgressBar;
