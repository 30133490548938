/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TaskState } from './TaskState';
import {
    TaskStateFromJSON,
    TaskStateFromJSONTyped,
    TaskStateToJSON,
} from './TaskState';

/**
 * 
 * @export
 * @interface CreateTaskGroupDto
 */
export interface CreateTaskGroupDto {
    /**
     * 
     * @type {string}
     * @memberof CreateTaskGroupDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTaskGroupDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTaskGroupDto
     */
    resource_type: string;
    /**
     * 
     * @type {TaskState}
     * @memberof CreateTaskGroupDto
     */
    state: TaskState;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateTaskGroupDto
     */
    tags: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateTaskGroupDto
     */
    resources: Array<string>;
}



/**
 * Check if a given object implements the CreateTaskGroupDto interface.
 */
export function instanceOfCreateTaskGroupDto(value: object): value is CreateTaskGroupDto {
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('resource_type' in value) || value['resource_type'] === undefined) return false;
    if (!('state' in value) || value['state'] === undefined) return false;
    if (!('tags' in value) || value['tags'] === undefined) return false;
    if (!('resources' in value) || value['resources'] === undefined) return false;
    return true;
}

export function CreateTaskGroupDtoFromJSON(json: any): CreateTaskGroupDto {
    return CreateTaskGroupDtoFromJSONTyped(json, false);
}

export function CreateTaskGroupDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateTaskGroupDto {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'],
        'description': json['description'],
        'resource_type': json['resource_type'],
        'state': TaskStateFromJSON(json['state']),
        'tags': json['tags'],
        'resources': json['resources'],
    };
}

export function CreateTaskGroupDtoToJSON(value?: CreateTaskGroupDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'description': value['description'],
        'resource_type': value['resource_type'],
        'state': TaskStateToJSON(value['state']),
        'tags': value['tags'],
        'resources': value['resources'],
    };
}

