import type {
  LogicDefinition,
  ReportFilter,
  ReportFilterGroup,
  ReportFilters,
  ReportingSchemaDisplay,
} from "./";

import * as R from "ramda";

import { Alignment } from "components/library";
import {
  Aggregation,
  AggregationType,
  DefaultReport,
  DefaultReportFilterGroup,
  DefaultReportingSchemaDisplay,
  ReportType,
  Operator,
} from "./";
import { ReportData, ReportDataTypeEnum } from ".generated/models";
import { ReportingSchema } from "@milio/lib/data/report/type";
import { DataType } from "@milio/lib/data/report/constant";

export type Counts = { [key: string]: number };

export function getReportingSchemaDisplay(
  schema: ReportingSchema
): ReportingSchemaDisplay {
  return R.mergeRight(
    DefaultReportingSchemaDisplay,
    R.propOr({}, "display", schema)
  );
}

export function usageCounts(filters: ReportFilters): Counts {
  return R.reduce(
    (acc: Counts, filter: ReportFilter): Counts => {
      return R.over(
        R.lensProp(filter.field),
        (count: number | undefined) => R.inc(count || 0),
        acc
      );
    },
    {},
    filters
  );
}

export function fieldCanBeUsed(
  field: string,
  schema: ReportingSchema,
  counts: Counts
): boolean {
  if (!R.propOr(true, "queryable", schema)) {
    return false;
  }

  if (R.has("maxPerGroup", schema)) {
    const used: number = R.propOr(0, field, counts);

    return used < schema.maxPerGroup;
  }

  return true;
}

export function getAvailableProperties(
  schema: { [key: string]: ReportingSchema },
  counts: Counts
): { [key: string]: ReportingSchema } {
  return R.pick(
    R.keys(schema).filter((field: string) => {
      const fieldSchema = R.prop(field, schema);

      return fieldCanBeUsed(fieldSchema.key, fieldSchema, counts);
    }),
    schema
  );
}

function determineDefaultField(
  schema: ReportingSchema,
  filters: ReportFilters = []
): [string, ReportingSchema] {
  const counts: Counts = usageCounts(filters);

  // Determine the subset of properties that are available.
  const availableProperties: { [key: string]: ReportingSchema } =
    getAvailableProperties(schema.properties, counts);

  const fieldSchema = R.find(
    R.whereEq({ key: schema.defaultField }),
    R.values(availableProperties)
  );

  if (!R.isNil(schema.defaultField) && !R.isNil(fieldSchema)) {
    return [schema.defaultField as string, fieldSchema];
  }

  return [null, null];
}

export const addFilterToFilters = R.curry(
  (schema: ReportingSchema, filters: ReportFilters) => {
    const [defaultField, defaultSchema] = determineDefaultField(
      schema,
      filters
    );

    if (!defaultField) return filters;

    const filter: ReportFilter = {
      field: defaultField,
      operator: R.head(defaultSchema.operators),
      value: R.prop("defaultValue", defaultSchema),
    };

    return filters.concat([filter]);
  }
);

export function createDefaultFilterGroup(
  schema: ReportingSchema
): ReportFilterGroup {
  return {
    ...DefaultReportFilterGroup,
    filters: addFilterToFilters(schema, []),
  };
}

export function createDefaultLogicDefinition(
  schema: ReportingSchema
): LogicDefinition {
  return {
    combinators: [],
    groups: [createDefaultFilterGroup(schema)],
    type: R.prop("id", schema) as ReportType,
  };
}

export function createEmptyDefaultLogicDefintion(
  schema: ReportingSchema
): LogicDefinition {
  return {
    ...createDefaultLogicDefinition(schema),
    groups: [
      {
        ...createDefaultFilterGroup(schema),
        filters: [],
      },
    ],
  };
}

export function createDefaultReport(schema: ReportingSchema): ReportData {
  return {
    ...DefaultReport,
    ...createDefaultLogicDefinition(schema),
    type: schema.id as ReportDataTypeEnum,
    id: undefined,
    graphs: [],
    title: "New Report",
  };
}

export function createDefaultAggregation(
  schema: ReportingSchema,
  field: string
): Aggregation {
  const type: AggregationType | undefined = R.head(schema.aggregations || []);

  return {
    types: !R.isNil(type) ? [type] : [],
    field,
  };
}

export function hasCustomComponent(schema: ReportingSchema): boolean {
  return R.has("component", schema);
}

export function filterReportingSchemaProperties(
  properties: ReportingSchema["properties"]
): ReportingSchema["properties"] {
  const omit: string[] = R.reduce(
    (acc: string[], [key, child]: [string, ReportingSchema]) => {
      return R.propOr(true, "selectable", child) ? acc : acc.concat(key);
    },
    [],
    R.toPairs(properties)
  );

  return R.omit(omit, properties);
}

export function sortReportingSchemaProperties(
  properties: ReportingSchema["properties"]
): [string, ReportingSchema][] {
  return R.sortBy(
    R.pipe(R.view(R.lensIndex(1)), R.prop("title")),
    R.toPairs(properties)
  ) as [string, ReportingSchema][];
}

export function isNaturalNumber(value: number): boolean {
  const text = value.toString();

  const absolute = Math.abs(value);
  const integer = parseInt(text, 10);

  return (
    !isNaN(absolute) && integer === absolute && absolute.toString() === text
  );
}

export function logicDefinitionIsEmpty(definition: LogicDefinition): boolean {
  return R.all((group: ReportFilterGroup) => {
    return R.isEmpty(group.filters);
  }, definition.groups);
}

export function getFilterFields(definition: LogicDefinition): Set<string> {
  return R.reduce(
    (acc: Set<string>, group: ReportFilterGroup) => {
      group.filters.forEach((filter: ReportFilter) => {
        acc.add(filter.field);
      });

      return acc;
    },
    new Set<string>(),
    definition.groups
  );
}

// export function toPropertyOptions(
//   schema: ReportingSchema,
//   {
//     disabled = new Set(),
//     omit = new Set(),
//   }: {
//     disabled?: Set<string>;
//     omit?: Set<string>;
//   } = {}
// ): Options<string> {
//   return R.pipe(
//     filterReportingSchemaProperties,
//     sortReportingSchemaProperties,
//     R.reject(([key]: [string]) => omit.has(key)),
//     R.map(([key, child]: [string, ReportingSchema]) => {
//       return {
//         label: child.title,
//         value: key,
//         isDisabled: disabled.has(key),
//       };
//     })
//   )(schema.properties);
// }

export function dataTypeToAlignment(type: DataType): Alignment {
  return R.propOr(Alignment.Left, type, {
    [DataType.Boolean]: Alignment.Left,
    [DataType.Currency]: Alignment.Right,
    [DataType.DateTime]: Alignment.Left,
    [DataType.NaturalNumber]: Alignment.Right,
    [DataType.Percent]: Alignment.Right,
    [DataType.Percentile]: Alignment.Left,
    [DataType.RationalNumber]: Alignment.Right,
    [DataType.String]: Alignment.Left,
  });
}

/**
 * Determine if an operator is still valid given a change in field.
 */
export function operatorIsValidForNextSchema({
  nextSchema,
  operator,
}: {
  nextSchema: ReportingSchema;
  operator: Operator;
}): boolean {
  const operators = R.propOr([], "operators", nextSchema) as Operator[];

  if (R.isNil(operators)) {
    return false;
  }

  return operators.includes(operator);
}
