import { createRange } from "util/range";

export const NAME_CELL_RANGE = createRange(40, 75);

export enum ToastNamespace {
  Analytics = "toast.analytics",
  BudgetCycle = "toast.budget-cycle",
  CompensationPlan = "toast.compensation-plan",
  FixedExchangeRate = "toast.fixed-exchange-rate",
  Global = "toast.global",
  JobFamily = "toast.job-family",
  JobGroup = "toast.job-group",
  JobLevel = "toast.job-level",
  JobTitle = "toast.job-title",
  Model = "toast.model",
  Notification = "toast.notification",
  Plan = "toast.plan",
  Report = "toast.report",
  Settings = "toast.settings",
  Simulation = "toast.simulation",
  Task = "toast.task",
  Team = "toast.team",
  Workflow = "toast.workflow",
  Zone = "toast.zone",
}
