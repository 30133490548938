import React from "react";

import type { IconProps } from "./type";

export const Analytics: React.FC<IconProps> = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="100%"
      height="100%"
      viewBox="-2 0 22 15"
      stroke="currentColor"
      fill="none"
    >
      <path
        d="M2 7.75L2 13"
        stroke="currentcolor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M9 2L9 13"
        stroke="currentcolor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M16 4L16 13"
        stroke="currentcolor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
