import type { CheckboxListProps } from "./CheckboxList";
import type { MenuChildrenProps } from "../Menu";
import type { Option } from "../Select/type";
import type { StandardComponent } from "components/type";

import * as R from "ramda";
import React from "react";
import styled from "styled-components";

import * as Style from "style";
import { Alignment } from "../constant";
import { Checkbox } from "./Checkbox";
import { CheckboxList } from "./CheckboxList";
import { InputStyle } from "../Input/style";
import { Menu } from "../Menu";

export type CheckboxDropdownRenderer = React.FunctionComponent;

export interface CheckboxDropdownProps<T> extends StandardComponent {
  alignment?: Alignment;
  canSearch?: boolean;
  children?: React.ReactNode | CheckboxDropdownRenderer;
  enableToggleAll?: boolean;
  onChange: (next: Set<T>, option?: Option<T>, index?: number) => void;
  options: Option<T>[];
  renderOption?: (option: Option<T>) => React.ReactElement;
  searchPlaceholder?: string;
  value?: Set<T>;
  onSearch?: CheckboxListProps<T>["onSearch"];
}

interface CheckboxDropdownMenuItemProps<T> extends StandardComponent {
  isSelected: boolean;
  onChange: (next: boolean) => void;
  option: Option<T>;
  isIndeterminate: boolean;
}

export const DropdownContainer = styled.div<{ isActive: boolean }>`
  ${InputStyle};
  display: flex;
  height: ${Style.Layout.Input.Height};
  width: 100%;
  overflow: hidden;
  position: relative;
`;

export function CheckboxDropdownMenuItem<T = string>({
  isDisabled = false,
  isIndeterminate = false,
  isSelected,
  onChange,
  option,
}: CheckboxDropdownMenuItemProps<T>): React.ReactElement {
  return (
    <Menu.Item
      isDisabled={isDisabled}
      onClick={() => {
        if (!isDisabled) {
          onChange(!isSelected);
        }
      }}
    >
      <div className="flex items-center">
        <Checkbox
          isIndeterminate={isIndeterminate}
          checked={isSelected}
          isDisabled={isDisabled}
          onChange={onChange}
        />
        <span className="ml-2 mr-2">{option.label}</span>
      </div>
    </Menu.Item>
  );
}

export const CheckboxDropdownContainer = styled.div`
  background: ${Style.Color.White};
  border-color: ${Style.Color.Layout.Line};
  border-radius: ${Style.Design.Rounding.Secondary};
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  padding: ${Style.Layout.Padding.ExtraSmall};
`;

export const CheckboxDropdownSearchContainer = styled.div`
  padding-top: ${Style.Layout.Padding.ExtraSmall};
  margin-bottom: ${Style.Layout.Padding.ExtraSmall};
  width: ${Style.Layout.Input.MinWidth};
  background: inherit;
  position: sticky;
  top: 0;
`;

export function CheckboxDropdown<T = string>({
  canSearch = false,
  children,
  enableToggleAll = false,
  isDisabled = false,
  onChange = R.always(undefined),
  onSearch = R.always(undefined),
  options = [],
  renderOption,
  searchPlaceholder,
  value = new Set(),
  alignment,
}: CheckboxDropdownProps<T>) {
  return (
    <Menu
      alignment={alignment}
      content={() => {
        return (
          <CheckboxDropdownContainer>
            <CheckboxList<T>
              canSearch={canSearch}
              canToggleAll={enableToggleAll}
              isDisabled={isDisabled}
              onChange={onChange}
              options={options}
              renderOption={renderOption}
              searchPlaceholder={searchPlaceholder}
              value={value}
              onSearch={onSearch}
            />
          </CheckboxDropdownContainer>
        );
      }}
    >
      {(props: MenuChildrenProps) => {
        return typeof children === "function" ? children(props) : children;
      }}
    </Menu>
  );
}
