/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateUserSettingDto
 */
export interface UpdateUserSettingDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserSettingDto
     */
    base_currency?: string;
}

/**
 * Check if a given object implements the UpdateUserSettingDto interface.
 */
export function instanceOfUpdateUserSettingDto(value: object): value is UpdateUserSettingDto {
    return true;
}

export function UpdateUserSettingDtoFromJSON(json: any): UpdateUserSettingDto {
    return UpdateUserSettingDtoFromJSONTyped(json, false);
}

export function UpdateUserSettingDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserSettingDto {
    if (json == null) {
        return json;
    }
    return {
        
        'base_currency': json['base_currency'] == null ? undefined : json['base_currency'],
    };
}

export function UpdateUserSettingDtoToJSON(value?: UpdateUserSettingDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'base_currency': value['base_currency'],
    };
}

