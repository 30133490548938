/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ZoneMetadata } from './ZoneMetadata';
import {
    ZoneMetadataFromJSON,
    ZoneMetadataFromJSONTyped,
    ZoneMetadataToJSON,
} from './ZoneMetadata';
import type { BenchmarkMetadata } from './BenchmarkMetadata';
import {
    BenchmarkMetadataFromJSON,
    BenchmarkMetadataFromJSONTyped,
    BenchmarkMetadataToJSON,
} from './BenchmarkMetadata';

/**
 * 
 * @export
 * @interface PlanMetadata
 */
export interface PlanMetadata {
    /**
     * 
     * @type {string}
     * @memberof PlanMetadata
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof PlanMetadata
     */
    plan_id: string;
    /**
     * 
     * @type {number}
     * @memberof PlanMetadata
     */
    pay_band_min: number;
    /**
     * 
     * @type {number}
     * @memberof PlanMetadata
     */
    pay_band_max: number;
    /**
     * 
     * @type {BenchmarkMetadata}
     * @memberof PlanMetadata
     */
    benchmark: BenchmarkMetadata;
    /**
     * 
     * @type {ZoneMetadata}
     * @memberof PlanMetadata
     */
    zone: ZoneMetadata;
    /**
     * 
     * @type {string}
     * @memberof PlanMetadata
     */
    currency: string;
}

/**
 * Check if a given object implements the PlanMetadata interface.
 */
export function instanceOfPlanMetadata(value: object): value is PlanMetadata {
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('plan_id' in value) || value['plan_id'] === undefined) return false;
    if (!('pay_band_min' in value) || value['pay_band_min'] === undefined) return false;
    if (!('pay_band_max' in value) || value['pay_band_max'] === undefined) return false;
    if (!('benchmark' in value) || value['benchmark'] === undefined) return false;
    if (!('zone' in value) || value['zone'] === undefined) return false;
    if (!('currency' in value) || value['currency'] === undefined) return false;
    return true;
}

export function PlanMetadataFromJSON(json: any): PlanMetadata {
    return PlanMetadataFromJSONTyped(json, false);
}

export function PlanMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanMetadata {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'],
        'plan_id': json['plan_id'],
        'pay_band_min': json['pay_band_min'],
        'pay_band_max': json['pay_band_max'],
        'benchmark': BenchmarkMetadataFromJSON(json['benchmark']),
        'zone': ZoneMetadataFromJSON(json['zone']),
        'currency': json['currency'],
    };
}

export function PlanMetadataToJSON(value?: PlanMetadata | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'plan_id': value['plan_id'],
        'pay_band_min': value['pay_band_min'],
        'pay_band_max': value['pay_band_max'],
        'benchmark': BenchmarkMetadataToJSON(value['benchmark']),
        'zone': ZoneMetadataToJSON(value['zone']),
        'currency': value['currency'],
    };
}

