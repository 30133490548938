import styled, { css } from "styled-components";

import * as Style from "style";

const LINE_COLOR = Style.Color.Layout.Gray;

export const LightCrossHatch = css`
  background-image: linear-gradient(
    45deg,
    ${Style.Color.Layout.Background.Secondary} 25%,
    transparent 25%,
    transparent 50%,
    ${Style.Color.Layout.Background.Secondary} 50%,
    ${Style.Color.Layout.Background.Secondary} 75%,
    transparent 75%,
    transparent
  );
  background-size: 6px 6px;
`;

export const CrossHatch = css`
  background-image: linear-gradient(
    45deg,
    ${LINE_COLOR} 25%,
    transparent 25%,
    transparent 50%,
    ${LINE_COLOR} 50%,
    ${LINE_COLOR} 75%,
    transparent 75%,
    transparent
  );
  background-size: 6px 6px;
`;

export const CrossHatchOverlay = styled.div`
  ${CrossHatch};
  bottom: 0;
  left: 0;
  opacity: 0.4;
  position: absolute;
  right: 0;
  top: 0;
`;
