/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Aggregation } from './Aggregation';
import {
    AggregationFromJSON,
    AggregationFromJSONTyped,
    AggregationToJSON,
} from './Aggregation';
import type { ColumnSort } from './ColumnSort';
import {
    ColumnSortFromJSON,
    ColumnSortFromJSONTyped,
    ColumnSortToJSON,
} from './ColumnSort';
import type { FilterGroup } from './FilterGroup';
import {
    FilterGroupFromJSON,
    FilterGroupFromJSONTyped,
    FilterGroupToJSON,
} from './FilterGroup';
import type { ReportGraph } from './ReportGraph';
import {
    ReportGraphFromJSON,
    ReportGraphFromJSONTyped,
    ReportGraphToJSON,
} from './ReportGraph';
import type { FilterValue } from './FilterValue';
import {
    FilterValueFromJSON,
    FilterValueFromJSONTyped,
    FilterValueToJSON,
} from './FilterValue';

/**
 * 
 * @export
 * @interface UpdateReportDto
 */
export interface UpdateReportDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateReportDto
     */
    combinators?: Array<UpdateReportDtoCombinatorsEnum>;
    /**
     * 
     * @type {Array<FilterGroup>}
     * @memberof UpdateReportDto
     */
    groups?: Array<FilterGroup>;
    /**
     * 
     * @type {Array<ReportGraph>}
     * @memberof UpdateReportDto
     */
    graphs?: Array<ReportGraph>;
    /**
     * 
     * @type {Array<ColumnSort>}
     * @memberof UpdateReportDto
     */
    clientSort?: Array<ColumnSort>;
    /**
     * 
     * @type {Array<FilterValue>}
     * @memberof UpdateReportDto
     */
    clientFilters?: Array<FilterValue>;
    /**
     * 
     * @type {Array<Aggregation>}
     * @memberof UpdateReportDto
     */
    clientAggregations?: Array<Aggregation>;
    /**
     * 
     * @type {string}
     * @memberof UpdateReportDto
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateReportDto
     */
    title?: string;
    /**
     * 
     * @type {object}
     * @memberof UpdateReportDto
     */
    systemFilters?: object;
    /**
     * 
     * @type {string}
     * @memberof UpdateReportDto
     */
    schema?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateReportDto
     */
    hidden?: boolean;
}


/**
 * @export
 */
export const UpdateReportDtoCombinatorsEnum = {
    And: 'And',
    Or: 'Or'
} as const;
export type UpdateReportDtoCombinatorsEnum = typeof UpdateReportDtoCombinatorsEnum[keyof typeof UpdateReportDtoCombinatorsEnum];


/**
 * Check if a given object implements the UpdateReportDto interface.
 */
export function instanceOfUpdateReportDto(value: object): value is UpdateReportDto {
    return true;
}

export function UpdateReportDtoFromJSON(json: any): UpdateReportDto {
    return UpdateReportDtoFromJSONTyped(json, false);
}

export function UpdateReportDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateReportDto {
    if (json == null) {
        return json;
    }
    return {
        
        'combinators': json['combinators'] == null ? undefined : json['combinators'],
        'groups': json['groups'] == null ? undefined : ((json['groups'] as Array<any>).map(FilterGroupFromJSON)),
        'graphs': json['graphs'] == null ? undefined : ((json['graphs'] as Array<any>).map(ReportGraphFromJSON)),
        'clientSort': json['clientSort'] == null ? undefined : ((json['clientSort'] as Array<any>).map(ColumnSortFromJSON)),
        'clientFilters': json['clientFilters'] == null ? undefined : ((json['clientFilters'] as Array<any>).map(FilterValueFromJSON)),
        'clientAggregations': json['clientAggregations'] == null ? undefined : ((json['clientAggregations'] as Array<any>).map(AggregationFromJSON)),
        'type': json['type'] == null ? undefined : json['type'],
        'title': json['title'] == null ? undefined : json['title'],
        'systemFilters': json['systemFilters'] == null ? undefined : json['systemFilters'],
        'schema': json['schema'] == null ? undefined : json['schema'],
        'hidden': json['hidden'] == null ? undefined : json['hidden'],
    };
}

export function UpdateReportDtoToJSON(value?: UpdateReportDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'combinators': value['combinators'],
        'groups': value['groups'] == null ? undefined : ((value['groups'] as Array<any>).map(FilterGroupToJSON)),
        'graphs': value['graphs'] == null ? undefined : ((value['graphs'] as Array<any>).map(ReportGraphToJSON)),
        'clientSort': value['clientSort'] == null ? undefined : ((value['clientSort'] as Array<any>).map(ColumnSortToJSON)),
        'clientFilters': value['clientFilters'] == null ? undefined : ((value['clientFilters'] as Array<any>).map(FilterValueToJSON)),
        'clientAggregations': value['clientAggregations'] == null ? undefined : ((value['clientAggregations'] as Array<any>).map(AggregationToJSON)),
        'type': value['type'],
        'title': value['title'],
        'systemFilters': value['systemFilters'],
        'schema': value['schema'],
        'hidden': value['hidden'],
    };
}

