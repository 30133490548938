/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CountryMetadata
 */
export interface CountryMetadata {
    /**
     * 
     * @type {string}
     * @memberof CountryMetadata
     */
    nicename: string;
}

/**
 * Check if a given object implements the CountryMetadata interface.
 */
export function instanceOfCountryMetadata(value: object): value is CountryMetadata {
    if (!('nicename' in value) || value['nicename'] === undefined) return false;
    return true;
}

export function CountryMetadataFromJSON(json: any): CountryMetadata {
    return CountryMetadataFromJSONTyped(json, false);
}

export function CountryMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CountryMetadata {
    if (json == null) {
        return json;
    }
    return {
        
        'nicename': json['nicename'],
    };
}

export function CountryMetadataToJSON(value?: CountryMetadata | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'nicename': value['nicename'],
    };
}

