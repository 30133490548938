/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const KPIStatus = {
    NotReady: 'not-ready',
    Ready: 'ready',
    NeedsInput: 'needs-input'
} as const;
export type KPIStatus = typeof KPIStatus[keyof typeof KPIStatus];


export function instanceOfKPIStatus(value: any): boolean {
    for (const key in KPIStatus) {
        if (Object.prototype.hasOwnProperty.call(KPIStatus, key)) {
            if (KPIStatus[key as keyof typeof KPIStatus] === value) {
                return true;
            }
        }
    }
    return false;
}

export function KPIStatusFromJSON(json: any): KPIStatus {
    return KPIStatusFromJSONTyped(json, false);
}

export function KPIStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): KPIStatus {
    return json as KPIStatus;
}

export function KPIStatusToJSON(value?: KPIStatus | null): any {
    return value as any;
}

