import type { Breadcrumbs } from "components/library/Breadcrumbs";

import * as R from "ramda";
import React from "react";

import { Toast, Heading } from "components/library";
import { BreadcrumbBuilder } from "components/library/Breadcrumbs";
import { ToastContainer } from "context";
import { SearchBarContainer } from "components/Search";

export interface SectionHeaderProps {
  title: string;
  subtitle?: React.ReactNode;
  toastNamespace?: string;
  breadcrumbs?: Breadcrumbs;
}

export const SectionHeader: React.FC<SectionHeaderProps> = ({
  toastNamespace,
  breadcrumbs = [],
  title,
  subtitle = null,
}) => {
  return (
    <div className="col-span-2 flex items-center justify-between mb-10 gap-x-2">
      <div className="flex-shrink-0">
        <div className="mb-2">
          {!R.isEmpty(breadcrumbs) && (
            <BreadcrumbBuilder breadcrumbs={breadcrumbs} />
          )}
          {R.isEmpty(breadcrumbs) && <BreadcrumbBuilder.Placeholder />}
        </div>
        <Heading>{title}</Heading>
        <div>{subtitle}</div>
      </div>
      <div className="flex items-center w-full justify-between">
        <div className="flex flex-grow items-center justify-end gap-x-4">
          <ToastContainer
            namespace={toastNamespace}
            occupy={<Toast.Placeholder />}
          />
          <SearchBarContainer />
        </div>
      </div>
    </div>
  );
};
