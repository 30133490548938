/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Aggregation } from './Aggregation';
import {
    AggregationFromJSON,
    AggregationFromJSONTyped,
    AggregationToJSON,
} from './Aggregation';
import type { ColumnSort } from './ColumnSort';
import {
    ColumnSortFromJSON,
    ColumnSortFromJSONTyped,
    ColumnSortToJSON,
} from './ColumnSort';
import type { FilterGroup } from './FilterGroup';
import {
    FilterGroupFromJSON,
    FilterGroupFromJSONTyped,
    FilterGroupToJSON,
} from './FilterGroup';
import type { ReportGraph } from './ReportGraph';
import {
    ReportGraphFromJSON,
    ReportGraphFromJSONTyped,
    ReportGraphToJSON,
} from './ReportGraph';
import type { FilterValue } from './FilterValue';
import {
    FilterValueFromJSON,
    FilterValueFromJSONTyped,
    FilterValueToJSON,
} from './FilterValue';

/**
 * 
 * @export
 * @interface ReportData
 */
export interface ReportData {
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportData
     */
    combinators: Array<ReportDataCombinatorsEnum>;
    /**
     * 
     * @type {Array<FilterGroup>}
     * @memberof ReportData
     */
    groups: Array<FilterGroup>;
    /**
     * 
     * @type {number}
     * @memberof ReportData
     */
    id: number;
    /**
     * 
     * @type {Array<ReportGraph>}
     * @memberof ReportData
     */
    graphs: Array<ReportGraph>;
    /**
     * 
     * @type {string}
     * @memberof ReportData
     */
    type: ReportDataTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ReportData
     */
    title: string;
    /**
     * 
     * @type {Array<ColumnSort>}
     * @memberof ReportData
     */
    clientSort: Array<ColumnSort>;
    /**
     * 
     * @type {Array<FilterValue>}
     * @memberof ReportData
     */
    clientFilters: Array<FilterValue>;
    /**
     * 
     * @type {Array<Aggregation>}
     * @memberof ReportData
     */
    clientAggregations: Array<Aggregation>;
    /**
     * 
     * @type {object}
     * @memberof ReportData
     */
    systemFilters?: object;
}


/**
 * @export
 */
export const ReportDataCombinatorsEnum = {
    And: 'And',
    Or: 'Or'
} as const;
export type ReportDataCombinatorsEnum = typeof ReportDataCombinatorsEnum[keyof typeof ReportDataCombinatorsEnum];

/**
 * @export
 */
export const ReportDataTypeEnum = {
    Employee: 'employee',
    Plan: 'plan'
} as const;
export type ReportDataTypeEnum = typeof ReportDataTypeEnum[keyof typeof ReportDataTypeEnum];


/**
 * Check if a given object implements the ReportData interface.
 */
export function instanceOfReportData(value: object): value is ReportData {
    if (!('combinators' in value) || value['combinators'] === undefined) return false;
    if (!('groups' in value) || value['groups'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('graphs' in value) || value['graphs'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('clientSort' in value) || value['clientSort'] === undefined) return false;
    if (!('clientFilters' in value) || value['clientFilters'] === undefined) return false;
    if (!('clientAggregations' in value) || value['clientAggregations'] === undefined) return false;
    return true;
}

export function ReportDataFromJSON(json: any): ReportData {
    return ReportDataFromJSONTyped(json, false);
}

export function ReportDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportData {
    if (json == null) {
        return json;
    }
    return {
        
        'combinators': json['combinators'],
        'groups': ((json['groups'] as Array<any>).map(FilterGroupFromJSON)),
        'id': json['id'],
        'graphs': ((json['graphs'] as Array<any>).map(ReportGraphFromJSON)),
        'type': json['type'],
        'title': json['title'],
        'clientSort': ((json['clientSort'] as Array<any>).map(ColumnSortFromJSON)),
        'clientFilters': ((json['clientFilters'] as Array<any>).map(FilterValueFromJSON)),
        'clientAggregations': ((json['clientAggregations'] as Array<any>).map(AggregationFromJSON)),
        'systemFilters': json['systemFilters'] == null ? undefined : json['systemFilters'],
    };
}

export function ReportDataToJSON(value?: ReportData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'combinators': value['combinators'],
        'groups': ((value['groups'] as Array<any>).map(FilterGroupToJSON)),
        'id': value['id'],
        'graphs': ((value['graphs'] as Array<any>).map(ReportGraphToJSON)),
        'type': value['type'],
        'title': value['title'],
        'clientSort': ((value['clientSort'] as Array<any>).map(ColumnSortToJSON)),
        'clientFilters': ((value['clientFilters'] as Array<any>).map(FilterValueToJSON)),
        'clientAggregations': ((value['clientAggregations'] as Array<any>).map(AggregationToJSON)),
        'systemFilters': value['systemFilters'],
    };
}

