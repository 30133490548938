/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmployeeMetric
 */
export interface EmployeeMetric {
    /**
     * 
     * @type {string}
     * @memberof EmployeeMetric
     */
    internal_benchmark: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeMetric
     */
    internal_benchmark_peer_count: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeMetric
     */
    internal_benchmark_percentile: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeMetric
     */
    last_promotion: string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeMetric
     */
    last_promotion_months: number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeMetric
     */
    last_title_change: string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeMetric
     */
    last_title_change_months: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeMetric
     */
    tenure: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeMetric
     */
    role_tenure: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeMetric
     */
    direct_reports: number;
}

/**
 * Check if a given object implements the EmployeeMetric interface.
 */
export function instanceOfEmployeeMetric(value: object): value is EmployeeMetric {
    if (!('internal_benchmark' in value) || value['internal_benchmark'] === undefined) return false;
    if (!('internal_benchmark_peer_count' in value) || value['internal_benchmark_peer_count'] === undefined) return false;
    if (!('internal_benchmark_percentile' in value) || value['internal_benchmark_percentile'] === undefined) return false;
    if (!('last_promotion' in value) || value['last_promotion'] === undefined) return false;
    if (!('last_promotion_months' in value) || value['last_promotion_months'] === undefined) return false;
    if (!('last_title_change' in value) || value['last_title_change'] === undefined) return false;
    if (!('last_title_change_months' in value) || value['last_title_change_months'] === undefined) return false;
    if (!('tenure' in value) || value['tenure'] === undefined) return false;
    if (!('role_tenure' in value) || value['role_tenure'] === undefined) return false;
    if (!('direct_reports' in value) || value['direct_reports'] === undefined) return false;
    return true;
}

export function EmployeeMetricFromJSON(json: any): EmployeeMetric {
    return EmployeeMetricFromJSONTyped(json, false);
}

export function EmployeeMetricFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeMetric {
    if (json == null) {
        return json;
    }
    return {
        
        'internal_benchmark': json['internal_benchmark'],
        'internal_benchmark_peer_count': json['internal_benchmark_peer_count'],
        'internal_benchmark_percentile': json['internal_benchmark_percentile'],
        'last_promotion': json['last_promotion'],
        'last_promotion_months': json['last_promotion_months'],
        'last_title_change': json['last_title_change'],
        'last_title_change_months': json['last_title_change_months'],
        'tenure': json['tenure'],
        'role_tenure': json['role_tenure'],
        'direct_reports': json['direct_reports'],
    };
}

export function EmployeeMetricToJSON(value?: EmployeeMetric | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'internal_benchmark': value['internal_benchmark'],
        'internal_benchmark_peer_count': value['internal_benchmark_peer_count'],
        'internal_benchmark_percentile': value['internal_benchmark_percentile'],
        'last_promotion': value['last_promotion'],
        'last_promotion_months': value['last_promotion_months'],
        'last_title_change': value['last_title_change'],
        'last_title_change_months': value['last_title_change_months'],
        'tenure': value['tenure'],
        'role_tenure': value['role_tenure'],
        'direct_reports': value['direct_reports'],
    };
}

