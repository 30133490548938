/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateZoneDto
 */
export interface CreateZoneDto {
    /**
     * 
     * @type {string}
     * @memberof CreateZoneDto
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof CreateZoneDto
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof CreateZoneDto
     */
    multiplier?: number;
}

/**
 * Check if a given object implements the CreateZoneDto interface.
 */
export function instanceOfCreateZoneDto(value: object): value is CreateZoneDto {
    if (!('country' in value) || value['country'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    return true;
}

export function CreateZoneDtoFromJSON(json: any): CreateZoneDto {
    return CreateZoneDtoFromJSONTyped(json, false);
}

export function CreateZoneDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateZoneDto {
    if (json == null) {
        return json;
    }
    return {
        
        'country': json['country'],
        'description': json['description'],
        'multiplier': json['multiplier'] == null ? undefined : json['multiplier'],
    };
}

export function CreateZoneDtoToJSON(value?: CreateZoneDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'country': value['country'],
        'description': value['description'],
        'multiplier': value['multiplier'],
    };
}

