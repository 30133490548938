/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Context } from './Context';
import {
    ContextFromJSON,
    ContextFromJSONTyped,
    ContextToJSON,
} from './Context';
import type { KPIStatus } from './KPIStatus';
import {
    KPIStatusFromJSON,
    KPIStatusFromJSONTyped,
    KPIStatusToJSON,
} from './KPIStatus';
import type { Timeframe } from './Timeframe';
import {
    TimeframeFromJSON,
    TimeframeFromJSONTyped,
    TimeframeToJSON,
} from './Timeframe';
import type { Sample } from './Sample';
import {
    SampleFromJSON,
    SampleFromJSONTyped,
    SampleToJSON,
} from './Sample';
import type { KPIKind } from './KPIKind';
import {
    KPIKindFromJSON,
    KPIKindFromJSONTyped,
    KPIKindToJSON,
} from './KPIKind';
import type { TrendSentiment } from './TrendSentiment';
import {
    TrendSentimentFromJSON,
    TrendSentimentFromJSONTyped,
    TrendSentimentToJSON,
} from './TrendSentiment';
import type { KPIDataType } from './KPIDataType';
import {
    KPIDataTypeFromJSON,
    KPIDataTypeFromJSONTyped,
    KPIDataTypeToJSON,
} from './KPIDataType';
import type { TrendDirection } from './TrendDirection';
import {
    TrendDirectionFromJSON,
    TrendDirectionFromJSONTyped,
    TrendDirectionToJSON,
} from './TrendDirection';

/**
 * 
 * @export
 * @interface KPI
 */
export interface KPI {
    /**
     * 
     * @type {KPIKind}
     * @memberof KPI
     */
    id: KPIKind;
    /**
     * 
     * @type {number}
     * @memberof KPI
     */
    change: number;
    /**
     * 
     * @type {Context}
     * @memberof KPI
     */
    context: Context;
    /**
     * 
     * @type {TrendDirection}
     * @memberof KPI
     */
    direction: TrendDirection;
    /**
     * 
     * @type {string}
     * @memberof KPI
     */
    name: string;
    /**
     * 
     * @type {Array<Sample>}
     * @memberof KPI
     */
    samples: Array<Sample>;
    /**
     * 
     * @type {TrendSentiment}
     * @memberof KPI
     */
    sentiment: TrendSentiment;
    /**
     * 
     * @type {KPIStatus}
     * @memberof KPI
     */
    status: KPIStatus;
    /**
     * 
     * @type {Timeframe}
     * @memberof KPI
     */
    timeframe: Timeframe;
    /**
     * 
     * @type {KPIDataType}
     * @memberof KPI
     */
    type: KPIDataType;
    /**
     * 
     * @type {number}
     * @memberof KPI
     */
    initial: number;
    /**
     * 
     * @type {number}
     * @memberof KPI
     */
    value: number;
}



/**
 * Check if a given object implements the KPI interface.
 */
export function instanceOfKPI(value: object): value is KPI {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('change' in value) || value['change'] === undefined) return false;
    if (!('context' in value) || value['context'] === undefined) return false;
    if (!('direction' in value) || value['direction'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('samples' in value) || value['samples'] === undefined) return false;
    if (!('sentiment' in value) || value['sentiment'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('timeframe' in value) || value['timeframe'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('initial' in value) || value['initial'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function KPIFromJSON(json: any): KPI {
    return KPIFromJSONTyped(json, false);
}

export function KPIFromJSONTyped(json: any, ignoreDiscriminator: boolean): KPI {
    if (json == null) {
        return json;
    }
    return {
        
        'id': KPIKindFromJSON(json['id']),
        'change': json['change'],
        'context': ContextFromJSON(json['context']),
        'direction': TrendDirectionFromJSON(json['direction']),
        'name': json['name'],
        'samples': ((json['samples'] as Array<any>).map(SampleFromJSON)),
        'sentiment': TrendSentimentFromJSON(json['sentiment']),
        'status': KPIStatusFromJSON(json['status']),
        'timeframe': TimeframeFromJSON(json['timeframe']),
        'type': KPIDataTypeFromJSON(json['type']),
        'initial': json['initial'],
        'value': json['value'],
    };
}

export function KPIToJSON(value?: KPI | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': KPIKindToJSON(value['id']),
        'change': value['change'],
        'context': ContextToJSON(value['context']),
        'direction': TrendDirectionToJSON(value['direction']),
        'name': value['name'],
        'samples': ((value['samples'] as Array<any>).map(SampleToJSON)),
        'sentiment': TrendSentimentToJSON(value['sentiment']),
        'status': KPIStatusToJSON(value['status']),
        'timeframe': TimeframeToJSON(value['timeframe']),
        'type': KPIDataTypeToJSON(value['type']),
        'initial': value['initial'],
        'value': value['value'],
    };
}

