/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SpendByTitleDatum
 */
export interface SpendByTitleDatum {
    /**
     * 
     * @type {string}
     * @memberof SpendByTitleDatum
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SpendByTitleDatum
     */
    count: string;
    /**
     * 
     * @type {number}
     * @memberof SpendByTitleDatum
     */
    total: number;
}

/**
 * Check if a given object implements the SpendByTitleDatum interface.
 */
export function instanceOfSpendByTitleDatum(value: object): value is SpendByTitleDatum {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('count' in value) || value['count'] === undefined) return false;
    if (!('total' in value) || value['total'] === undefined) return false;
    return true;
}

export function SpendByTitleDatumFromJSON(json: any): SpendByTitleDatum {
    return SpendByTitleDatumFromJSONTyped(json, false);
}

export function SpendByTitleDatumFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpendByTitleDatum {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'count': json['count'],
        'total': json['total'],
    };
}

export function SpendByTitleDatumToJSON(value?: SpendByTitleDatum | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'count': value['count'],
        'total': value['total'],
    };
}

