import * as R from "ramda";
import React from "react";

import { Button } from "components/library";
import { CaretCircleDown, CaretCircleUp } from "components/Icon";

export interface ExpandHandleProps {
  isOpen: boolean;
  onClick: (next: boolean) => void;
}

export const ExpandHandle: React.FC<ExpandHandleProps> = ({
  isOpen = false,
  onClick = R.always(undefined),
}) => {
  const Icon: React.VoidFunctionComponent = isOpen
    ? CaretCircleUp
    : CaretCircleDown;

  return (
    <Button.Icon.Standalone Icon={Icon} onClick={() => onClick(!isOpen)} />
  );
};
