import type { User } from "data/user";

import React, { PropsWithChildren } from "react";
import * as R from "ramda";
import * as Frontegg from "@frontegg/nextjs";
import router from "next/router";

export const LOGIN_URL = "/account/login";
export const LOGOUT_URL = "/account/logout";
export const LANDING_URL = "/";

export interface FronteggUser {
  accessToken: string;
  email: string;
  id: string;
  name: string;
  permissions: string[];
  roles: string[];
  tenantId: string;
}

export interface FronteggAuth {
  user: FronteggUser;
}

/**
 * Convert a Frontegg user into the standard user interface.
 */
function fronteggUserToUser(user: FronteggUser): User {
  return {
    customer_id: user.tenantId,
    email: user.email,
    id: user.id,
    name: user.name,
    roles: R.propOr([], "roles", user),
    permissions: R.propOr([], "permissions", user),
    token: user.accessToken,
  };
}

const AuthenticationContext = React.createContext(null);

export const useAuthentication = () => React.useContext(AuthenticationContext);

export const AuthenticationProvider: React.FC<PropsWithChildren<any>> = ({
  children,
}) => {
  const fronteggAuth: FronteggAuth = Frontegg.useAuth();

  if (!fronteggAuth.user) {
    const redirect =
      router?.route !== LOGIN_URL
        ? `${LOGIN_URL}?path=${router.route}`
        : LOGIN_URL;
    router.push(redirect);
    return null;
  }

  const user = React.useMemo(
    () => fronteggUserToUser(fronteggAuth.user),
    [fronteggAuth.user]
  );

  return (
    <AuthenticationContext.Provider value={user}>
      {children}
    </AuthenticationContext.Provider>
  );
};
