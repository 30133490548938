/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateMappedPlanDto
 */
export interface UpdateMappedPlanDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateMappedPlanDto
     */
    job_family_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMappedPlanDto
     */
    job_level_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMappedPlanDto
     */
    remote_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMappedPlanDto
     */
    title?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateMappedPlanDto
     */
    plan_group_ids?: Array<string>;
}

/**
 * Check if a given object implements the UpdateMappedPlanDto interface.
 */
export function instanceOfUpdateMappedPlanDto(value: object): value is UpdateMappedPlanDto {
    return true;
}

export function UpdateMappedPlanDtoFromJSON(json: any): UpdateMappedPlanDto {
    return UpdateMappedPlanDtoFromJSONTyped(json, false);
}

export function UpdateMappedPlanDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateMappedPlanDto {
    if (json == null) {
        return json;
    }
    return {
        
        'job_family_id': json['job_family_id'] == null ? undefined : json['job_family_id'],
        'job_level_id': json['job_level_id'] == null ? undefined : json['job_level_id'],
        'remote_id': json['remote_id'] == null ? undefined : json['remote_id'],
        'title': json['title'] == null ? undefined : json['title'],
        'plan_group_ids': json['plan_group_ids'] == null ? undefined : json['plan_group_ids'],
    };
}

export function UpdateMappedPlanDtoToJSON(value?: UpdateMappedPlanDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'job_family_id': value['job_family_id'],
        'job_level_id': value['job_level_id'],
        'remote_id': value['remote_id'],
        'title': value['title'],
        'plan_group_ids': value['plan_group_ids'],
    };
}

