/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BenchmarkMetadata
 */
export interface BenchmarkMetadata {
    /**
     * 
     * @type {number}
     * @memberof BenchmarkMetadata
     */
    pct_25: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkMetadata
     */
    pct_50: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkMetadata
     */
    pct_75: number;
}

/**
 * Check if a given object implements the BenchmarkMetadata interface.
 */
export function instanceOfBenchmarkMetadata(value: object): value is BenchmarkMetadata {
    if (!('pct_25' in value) || value['pct_25'] === undefined) return false;
    if (!('pct_50' in value) || value['pct_50'] === undefined) return false;
    if (!('pct_75' in value) || value['pct_75'] === undefined) return false;
    return true;
}

export function BenchmarkMetadataFromJSON(json: any): BenchmarkMetadata {
    return BenchmarkMetadataFromJSONTyped(json, false);
}

export function BenchmarkMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): BenchmarkMetadata {
    if (json == null) {
        return json;
    }
    return {
        
        'pct_25': json['pct_25'],
        'pct_50': json['pct_50'],
        'pct_75': json['pct_75'],
    };
}

export function BenchmarkMetadataToJSON(value?: BenchmarkMetadata | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'pct_25': value['pct_25'],
        'pct_50': value['pct_50'],
        'pct_75': value['pct_75'],
    };
}

