/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Aggregation } from './Aggregation';
import {
    AggregationFromJSON,
    AggregationFromJSONTyped,
    AggregationToJSON,
} from './Aggregation';
import type { ColumnSort } from './ColumnSort';
import {
    ColumnSortFromJSON,
    ColumnSortFromJSONTyped,
    ColumnSortToJSON,
} from './ColumnSort';
import type { FilterGroup } from './FilterGroup';
import {
    FilterGroupFromJSON,
    FilterGroupFromJSONTyped,
    FilterGroupToJSON,
} from './FilterGroup';
import type { ReportGraph } from './ReportGraph';
import {
    ReportGraphFromJSON,
    ReportGraphFromJSONTyped,
    ReportGraphToJSON,
} from './ReportGraph';
import type { FilterValue } from './FilterValue';
import {
    FilterValueFromJSON,
    FilterValueFromJSONTyped,
    FilterValueToJSON,
} from './FilterValue';

/**
 * 
 * @export
 * @interface CreateReportDto
 */
export interface CreateReportDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateReportDto
     */
    combinators: Array<CreateReportDtoCombinatorsEnum>;
    /**
     * 
     * @type {Array<FilterGroup>}
     * @memberof CreateReportDto
     */
    groups: Array<FilterGroup>;
    /**
     * 
     * @type {Array<ReportGraph>}
     * @memberof CreateReportDto
     */
    graphs: Array<ReportGraph>;
    /**
     * 
     * @type {Array<ColumnSort>}
     * @memberof CreateReportDto
     */
    clientSort: Array<ColumnSort>;
    /**
     * 
     * @type {Array<FilterValue>}
     * @memberof CreateReportDto
     */
    clientFilters: Array<FilterValue>;
    /**
     * 
     * @type {Array<Aggregation>}
     * @memberof CreateReportDto
     */
    clientAggregations: Array<Aggregation>;
    /**
     * 
     * @type {string}
     * @memberof CreateReportDto
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof CreateReportDto
     */
    title: string;
    /**
     * 
     * @type {object}
     * @memberof CreateReportDto
     */
    systemFilters?: object;
    /**
     * 
     * @type {string}
     * @memberof CreateReportDto
     */
    schema: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateReportDto
     */
    hidden: boolean;
}


/**
 * @export
 */
export const CreateReportDtoCombinatorsEnum = {
    And: 'And',
    Or: 'Or'
} as const;
export type CreateReportDtoCombinatorsEnum = typeof CreateReportDtoCombinatorsEnum[keyof typeof CreateReportDtoCombinatorsEnum];


/**
 * Check if a given object implements the CreateReportDto interface.
 */
export function instanceOfCreateReportDto(value: object): value is CreateReportDto {
    if (!('combinators' in value) || value['combinators'] === undefined) return false;
    if (!('groups' in value) || value['groups'] === undefined) return false;
    if (!('graphs' in value) || value['graphs'] === undefined) return false;
    if (!('clientSort' in value) || value['clientSort'] === undefined) return false;
    if (!('clientFilters' in value) || value['clientFilters'] === undefined) return false;
    if (!('clientAggregations' in value) || value['clientAggregations'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('schema' in value) || value['schema'] === undefined) return false;
    if (!('hidden' in value) || value['hidden'] === undefined) return false;
    return true;
}

export function CreateReportDtoFromJSON(json: any): CreateReportDto {
    return CreateReportDtoFromJSONTyped(json, false);
}

export function CreateReportDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateReportDto {
    if (json == null) {
        return json;
    }
    return {
        
        'combinators': json['combinators'],
        'groups': ((json['groups'] as Array<any>).map(FilterGroupFromJSON)),
        'graphs': ((json['graphs'] as Array<any>).map(ReportGraphFromJSON)),
        'clientSort': ((json['clientSort'] as Array<any>).map(ColumnSortFromJSON)),
        'clientFilters': ((json['clientFilters'] as Array<any>).map(FilterValueFromJSON)),
        'clientAggregations': ((json['clientAggregations'] as Array<any>).map(AggregationFromJSON)),
        'type': json['type'],
        'title': json['title'],
        'systemFilters': json['systemFilters'] == null ? undefined : json['systemFilters'],
        'schema': json['schema'],
        'hidden': json['hidden'],
    };
}

export function CreateReportDtoToJSON(value?: CreateReportDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'combinators': value['combinators'],
        'groups': ((value['groups'] as Array<any>).map(FilterGroupToJSON)),
        'graphs': ((value['graphs'] as Array<any>).map(ReportGraphToJSON)),
        'clientSort': ((value['clientSort'] as Array<any>).map(ColumnSortToJSON)),
        'clientFilters': ((value['clientFilters'] as Array<any>).map(FilterValueToJSON)),
        'clientAggregations': ((value['clientAggregations'] as Array<any>).map(AggregationToJSON)),
        'type': value['type'],
        'title': value['title'],
        'systemFilters': value['systemFilters'],
        'schema': value['schema'],
        'hidden': value['hidden'],
    };
}

