import type { Step } from "./type";

import * as R from "ramda";
import React from "react";
import styled from "styled-components";

import * as Style from "style";

import { CIRCLE_SIZE, ICON_SIZE, DOT_SIZE, STROKE_WIDTH } from "./constant";
import * as Icons from "../../Icon";

export interface CircleProps {
  Icon: Step["Icon"];
  background: Step["background"];
  foreground: Step["foreground"];
  isActive: Step["isActive"];
  isComplete: Step["isComplete"];
  neutral: string;
}

function toBackground({ isComplete, background }: CircleProps): string {
  if (isComplete) {
    return background;
  }

  return Style.Color.White;
}

function toBorderColor({
  isActive,
  isComplete,
  background,
  neutral,
}: CircleProps): string {
  if (isComplete) {
    return "auto";
  }

  return isActive ? background : neutral;
}

function toBorderWidth({ isComplete }): string {
  if (isComplete) {
    return "0px";
  }

  return `${STROKE_WIDTH}px`;
}

export const CircleContainer = styled.div<CircleProps>`
  align-items: center;
  background: ${toBackground};
  border-color: ${toBorderColor};
  border-radius: 50%;
  border-style: solid;
  border-width: ${toBorderWidth};
  color: ${R.prop("foreground")};
  display: flex;
  height: ${CIRCLE_SIZE}px;
  justify-content: center;
  width: ${CIRCLE_SIZE}px;
`;

export const Dot = styled.div<CircleProps>`
  background: ${toBorderColor};
  border-radius: 50%;
  height: ${DOT_SIZE}px;
  width: ${DOT_SIZE}px;
`;

export const IconContainer = styled.div<CircleProps>`
  color: ${R.prop("foreground")};
  height: ${ICON_SIZE}px;
  width: ${ICON_SIZE}px;
`;

export const Circle: React.FC<CircleProps> = (props) => {
  const { isComplete, isActive, Icon = Icons.Check } = props;

  return (
    <CircleContainer {...props}>
      {isComplete && (
        <IconContainer {...props}>
          <Icon />
        </IconContainer>
      )}
      {isActive && <Dot {...props} />}
    </CircleContainer>
  );
};
