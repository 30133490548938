/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PerformanceCategory } from './PerformanceCategory';
import {
    PerformanceCategoryFromJSON,
    PerformanceCategoryFromJSONTyped,
    PerformanceCategoryToJSON,
} from './PerformanceCategory';
import type { QuestionWithAnswer } from './QuestionWithAnswer';
import {
    QuestionWithAnswerFromJSON,
    QuestionWithAnswerFromJSONTyped,
    QuestionWithAnswerToJSON,
} from './QuestionWithAnswer';

/**
 * 
 * @export
 * @interface CreateUserPerformanceRecordDto
 */
export interface CreateUserPerformanceRecordDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUserPerformanceRecordDto
     */
    employee_id: string;
    /**
     * 
     * @type {number}
     * @memberof CreateUserPerformanceRecordDto
     */
    score: number;
    /**
     * 
     * @type {PerformanceCategory}
     * @memberof CreateUserPerformanceRecordDto
     */
    group: PerformanceCategory;
    /**
     * 
     * @type {string}
     * @memberof CreateUserPerformanceRecordDto
     */
    completed_on: string;
    /**
     * 
     * @type {Array<QuestionWithAnswer>}
     * @memberof CreateUserPerformanceRecordDto
     */
    questions: Array<QuestionWithAnswer>;
}



/**
 * Check if a given object implements the CreateUserPerformanceRecordDto interface.
 */
export function instanceOfCreateUserPerformanceRecordDto(value: object): value is CreateUserPerformanceRecordDto {
    if (!('employee_id' in value) || value['employee_id'] === undefined) return false;
    if (!('score' in value) || value['score'] === undefined) return false;
    if (!('group' in value) || value['group'] === undefined) return false;
    if (!('completed_on' in value) || value['completed_on'] === undefined) return false;
    if (!('questions' in value) || value['questions'] === undefined) return false;
    return true;
}

export function CreateUserPerformanceRecordDtoFromJSON(json: any): CreateUserPerformanceRecordDto {
    return CreateUserPerformanceRecordDtoFromJSONTyped(json, false);
}

export function CreateUserPerformanceRecordDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUserPerformanceRecordDto {
    if (json == null) {
        return json;
    }
    return {
        
        'employee_id': json['employee_id'],
        'score': json['score'],
        'group': PerformanceCategoryFromJSON(json['group']),
        'completed_on': json['completed_on'],
        'questions': ((json['questions'] as Array<any>).map(QuestionWithAnswerFromJSON)),
    };
}

export function CreateUserPerformanceRecordDtoToJSON(value?: CreateUserPerformanceRecordDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'employee_id': value['employee_id'],
        'score': value['score'],
        'group': PerformanceCategoryToJSON(value['group']),
        'completed_on': value['completed_on'],
        'questions': ((value['questions'] as Array<any>).map(QuestionWithAnswerToJSON)),
    };
}

