/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const TaskState = {
    InProgress: 'in-progress',
    Complete: 'complete'
} as const;
export type TaskState = typeof TaskState[keyof typeof TaskState];


export function instanceOfTaskState(value: any): boolean {
    for (const key in TaskState) {
        if (Object.prototype.hasOwnProperty.call(TaskState, key)) {
            if (TaskState[key as keyof typeof TaskState] === value) {
                return true;
            }
        }
    }
    return false;
}

export function TaskStateFromJSON(json: any): TaskState {
    return TaskStateFromJSONTyped(json, false);
}

export function TaskStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskState {
    return json as TaskState;
}

export function TaskStateToJSON(value?: TaskState | null): any {
    return value as any;
}

