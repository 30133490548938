import type { Column } from "./type";
import type { CellCSSProps } from "./style";

import React from "react";
import styled from "styled-components";

import * as Style from "style";
import { CellContainer, CellCSS, TruncationWrapper } from "./style";

export interface BodyCellProps {
  children: React.ReactNode;
  column: Column;
}

export const BodyCellContainer = styled.td<CellCSSProps>`
  ${CellCSS};
  background: ${Style.Color.White};
  z-index: 2;
`;

export const BodyCell: React.FC<BodyCellProps> = ({ column, children }) => {
  return (
    <BodyCellContainer>
      <CellContainer column={column}>
        <TruncationWrapper>{children}</TruncationWrapper>
      </CellContainer>
    </BodyCellContainer>
  );
};
