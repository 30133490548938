import type { Progressions, Progression as IProgression } from "./type";

import React from "react";

import * as Style from "style";
import { ProgressBarContainer } from "./Container";
import { Progression } from "./Progression";

export interface MultiProgressBarProps {
  progressions: Progressions;
  background?: string;
}

export const MultiProgressBar: React.FC<MultiProgressBarProps> = ({
  background = Style.Color.Layout.Line,
  progressions,
}) => {
  return (
    <ProgressBarContainer color={background}>
      {progressions.map((progression: IProgression, index: number) => {
        return (
          <Progression key={index} progression={progression} depth={index} />
        );
      })}
    </ProgressBarContainer>
  );
};
