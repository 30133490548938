import type { SpecializedModalProps } from "./Modal";

import React from "react";
import { XCircleIcon } from "@heroicons/react/solid";

import { Modal } from "./Modal";
import { Sentiment } from "../constant";
import { SentimentIcon } from "./SentimentIcon";

export const ErrorModal: React.FC<SpecializedModalProps> = (props) => {
  const sentiment = Sentiment.Error;

  const icon = (
    <SentimentIcon sentiment={sentiment}>
      <XCircleIcon className="h-5 w-5 text-white" />
    </SentimentIcon>
  );

  return <Modal title="Error" {...props} icon={icon} sentiment={sentiment} />;
};
