import type { GridRow } from "./type";

import React from "react";

import { ExpandHandle } from "./ExpandHandle";

export interface ExpandHandleCellProps {
  canExpand: (row: GridRow) => boolean;
  isOpen: boolean;
  row: GridRow;
  setOpen: (next: boolean) => void;
}

export const ExpandHandleCell: React.FC<ExpandHandleCellProps> = ({
  row,
  canExpand,
  isOpen,
  setOpen,
}) => {
  if (!canExpand(row)) {
    return null;
  }

  return <ExpandHandle isOpen={isOpen} onClick={() => setOpen(!isOpen)} />;
};

export const ExpandHandleColumn = {
  component: ExpandHandleCell,
  field: "",
  title: "",
  searchable: false,
  sortable: false,
};
