/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateTaskDto,
  CreateTaskGroupDto,
  Task,
  UpdateTaskDto,
} from '../models/index';
import {
    CreateTaskDtoFromJSON,
    CreateTaskDtoToJSON,
    CreateTaskGroupDtoFromJSON,
    CreateTaskGroupDtoToJSON,
    TaskFromJSON,
    TaskToJSON,
    UpdateTaskDtoFromJSON,
    UpdateTaskDtoToJSON,
} from '../models/index';

export interface CreateFromTaskGroupRequest {
    createTaskGroupDto: CreateTaskGroupDto;
}

export interface CreateTaskRequest {
    createTaskDto: CreateTaskDto;
}

export interface CreateTaskBatchRequest {
    requestBody: Array<string>;
}

export interface FindOneTaskRequest {
    id: string;
}

export interface RemoveTaskRequest {
    id: string;
}

export interface UpdateTaskRequest {
    id: string;
    updateTaskDto: UpdateTaskDto;
}

/**
 * 
 */
export class TaskApi extends runtime.BaseAPI {

    /**
     */
    async createFromTaskGroupRaw(requestParameters: CreateFromTaskGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Task>>> {
        if (requestParameters['createTaskGroupDto'] == null) {
            throw new runtime.RequiredError(
                'createTaskGroupDto',
                'Required parameter "createTaskGroupDto" was null or undefined when calling createFromTaskGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tasks/task-group`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateTaskGroupDtoToJSON(requestParameters['createTaskGroupDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TaskFromJSON));
    }

    /**
     */
    async createFromTaskGroup(requestParameters: CreateFromTaskGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Task>> {
        const response = await this.createFromTaskGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createTaskRaw(requestParameters: CreateTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Task>> {
        if (requestParameters['createTaskDto'] == null) {
            throw new runtime.RequiredError(
                'createTaskDto',
                'Required parameter "createTaskDto" was null or undefined when calling createTask().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tasks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateTaskDtoToJSON(requestParameters['createTaskDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TaskFromJSON(jsonValue));
    }

    /**
     */
    async createTask(requestParameters: CreateTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Task> {
        const response = await this.createTaskRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createTaskBatchRaw(requestParameters: CreateTaskBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Task>>> {
        if (requestParameters['requestBody'] == null) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter "requestBody" was null or undefined when calling createTaskBatch().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tasks/batch`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TaskFromJSON));
    }

    /**
     */
    async createTaskBatch(requestParameters: CreateTaskBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Task>> {
        const response = await this.createTaskBatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findAllTasksRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Task>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tasks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TaskFromJSON));
    }

    /**
     */
    async findAllTasks(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Task>> {
        const response = await this.findAllTasksRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async findOneTaskRaw(requestParameters: FindOneTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Task>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling findOneTask().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tasks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TaskFromJSON(jsonValue));
    }

    /**
     */
    async findOneTask(requestParameters: FindOneTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Task> {
        const response = await this.findOneTaskRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removeTaskRaw(requestParameters: RemoveTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling removeTask().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tasks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async removeTask(requestParameters: RemoveTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeTaskRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateTaskRaw(requestParameters: UpdateTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Task>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateTask().'
            );
        }

        if (requestParameters['updateTaskDto'] == null) {
            throw new runtime.RequiredError(
                'updateTaskDto',
                'Required parameter "updateTaskDto" was null or undefined when calling updateTask().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tasks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateTaskDtoToJSON(requestParameters['updateTaskDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TaskFromJSON(jsonValue));
    }

    /**
     */
    async updateTask(requestParameters: UpdateTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Task> {
        const response = await this.updateTaskRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
