import Dexie from "dexie";

interface TypedDb extends Dexie {
  cohorts: Dexie.Table;
  clusteringParams: Dexie.Table;
}

// Initialize Dexie database
export const db = new Dexie("milio") as TypedDb;
db.version(2).stores({
  clusteringParams: "date",
  cohorts: "id",
});
