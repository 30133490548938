import type { Progression as IProgression } from "./type";

import * as R from "ramda";
import React from "react";
import styled from "styled-components";

import { PercentFormat, percentize } from "util/format";
import { Tooltip } from "../Tooltip";
import { VALID_RANGE } from "./constant";
import { clamp } from "util/range";

export interface ProgressionProps {
  progression: IProgression;
  depth?: number;
}

export const ProgressionContainer = styled.div<{
  depth?: number;
  width: number;
}>`
  background: ${R.prop("color")};
  width: ${R.prop("width")}%;
  z-index: ${R.prop("depth")};
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
`;

export const Progression: React.FC<ProgressionProps> = ({
  progression,
  depth = 0,
}) => {
  const width: number = percentize(clamp(VALID_RANGE, progression.percent), {
    input: PercentFormat.Decimal,
    output: PercentFormat.Percentage,
  });

  return (
    <Tooltip.Inline tooltip={progression.tooltip}>
      <ProgressionContainer
        depth={depth}
        width={width}
        color={progression.color}
        className="shadow-none flex-col text-center whitespace-nowrap text-white justify-center"
      />
    </Tooltip.Inline>
  );
};
