import type { StandardComponent } from "../type";

import * as R from "ramda";
import React from "react";
import cn from "lib/cn";
import styled from "styled-components";

import { BaseInputStyle } from "./Input/style";

export interface TextareaProps extends StandardComponent {
  className?: string;
  defaultValue?: string;
  onChange?: (next: string) => void;
  rows?: number;
  value?: string;
  placeholder?: string;
}

export const TextareaContainer = styled.textarea`
  ${BaseInputStyle};
  margin-right: 0px;
`;

export const Textarea: React.FC<TextareaProps> = ({
  className,
  defaultValue = "",
  isDisabled = false,
  onChange = R.always(undefined),
  rows = 3,
  value = "",
  placeholder,
}) => {
  return (
    <TextareaContainer
      onChange={(event) => {
        onChange(event.target.value);
      }}
      value={value}
      placeholder={placeholder}
      rows={rows}
      disabled={isDisabled}
      className={cn(
        className,
        isDisabled ? "disabled:opacity-50" : "",
        "block w-full"
      )}
      defaultValue={defaultValue}
    />
  );
};
