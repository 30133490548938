import type { BaseInputProps } from "./type";

import * as R from "ramda";
import React from "react";
import styled from "styled-components";

import ReactCurrencyInput from "react-currency-input-field";
import { InputStyle } from "./style";
import { Paragraph } from "../Paragraph";
import { createFormatter } from "lib/numberFormatter";

export interface CurrencyInputProps
  extends Omit<BaseInputProps, "onChange" | "value"> {
  currency?: string;
  hideSymbol?: boolean;
  onChange: (amount: number | undefined) => void;
  value?: number;
  decimalsLimit?: number;
  allowNegativeValue?: boolean;
}

export const CurrencyInputContainer = styled.div`
  display: flex;
  width: 100%;

  input {
    ${InputStyle};
  }
`;

export const CurrencyInput: React.FC<CurrencyInputProps> = ({
  currency = "USD",
  decimalsLimit,
  error,
  hideSymbol = false,
  isDisabled = false,
  onChange = R.always(undefined),
  placeholder = "0",
  value,
  allowNegativeValue = true,
}) => {
  const currencyFormatter = createFormatter({ style: "currency", currency });
  const parts: Intl.NumberFormatPart[] = currencyFormatter.formatToParts(value);
  const [stringValue, setStringValue] = React.useState<string>("");

  React.useEffect(() => {
    setStringValue(R.isNil(value) ? "" : value.toString());
  }, [value]);

  const symbol: string = R.propOr(
    "NA",
    "value",
    R.find(R.propEq("type", "currency"), parts)
  );

  return (
    <CurrencyInputContainer isDisabled={isDisabled} error={error}>
      <div className="relative w-full">
        {!hideSymbol && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Paragraph>{symbol}</Paragraph>
          </div>
        )}
        <ReactCurrencyInput
          className="text-right"
          disabled={isDisabled}
          allowNegativeValue={allowNegativeValue}
          placeholder={placeholder}
          decimalsLimit={decimalsLimit}
          onValueChange={(next: string) => {
            setStringValue(next);

            const parsed: number = parseFloat(next);

            isNaN(parsed) ? onChange(undefined) : onChange(parsed);
          }}
          value={stringValue}
        />
      </div>
    </CurrencyInputContainer>
  );
};
