import type { BaseInputProps } from "./type";

import * as R from "ramda";
import React from "react";
import styled from "styled-components";

import { Alignment } from "../constant";
import { InputStyle } from "./style";
import { InputType } from "./constant";

export const BaseInputContainer = styled.input`
  display: flex;
  ${InputStyle};
  text-align: ${R.prop("alignment")};
`;

function defaultOnParse(
  event: React.ChangeEvent<HTMLInputElement>
): string | number {
  return event.target.value;
}

export const BaseInput: React.FC<BaseInputProps> = ({
  alignment = Alignment.Left,
  autoFocus = false,
  className = "",
  error,
  forwardRef = React.createRef(),
  id,
  isDisabled = false,
  maxLength,
  onClick = R.always(undefined),
  onChange = undefined,
  onKeyDown,
  placeholder = "",
  testID,
  type = InputType.Text,
  value,
  onParse = defaultOnParse,
}) => {
  const [local, setLocal] = React.useState(value);

  React.useEffect(() => {
    setLocal(value);
  }, [value]);

  const optional = {
    ...(!R.isNil(local) && { value: local }),
    ...(onChange && {
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
        const next: string | number = onParse(event);

        setLocal(next);
        onChange(next, event);
      },
    }),
  };

  return (
    <BaseInputContainer
      {...optional}
      autoFocus={autoFocus}
      data-test-id={testID}
      isDisabled={isDisabled}
      disabled={isDisabled}
      onClick={onClick}
      alignment={alignment}
      error={error}
      maxLength={maxLength}
      onKeyDown={onKeyDown}
      ref={forwardRef}
      placeholder={placeholder}
      className={`block w-full ${className}`}
      id={id}
      name={id}
      type={type}
    />
  );
};
