import type { CardHeaderProps } from "./Header";

import * as R from "ramda";
import React from "react";

import { CardContent } from "./Content";
import { CardHeader } from "./Header";
import { ExpandHandle } from "components/library/GridV2";
import { Title } from "components/library/Title";
import { labelize } from "util/format";
import cn from "lib/cn";

export interface CollapsableCardChildrenProps {
  isOpen: boolean;
  setIsOpen: (next: boolean) => void;
}

export interface CollapsableCardProps extends CardHeaderProps {
  initialIsOpen?: boolean;
}

export const CollapsableCard: React.FC<CollapsableCardProps> = ({
  initialIsOpen = true,
  action,
  children,
  title,
  className,
  ...rest
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(initialIsOpen);

  const props: CollapsableCardChildrenProps = React.useMemo(() => {
    return { isOpen, setIsOpen };
  }, [isOpen, setIsOpen]);

  return (
    <div className={cn("rounded-md bg-white shadow", className)} {...rest}>
      <CardHeader
        title={
          <div className="flex items-center gap-2 w-full">
            <ExpandHandle isOpen={isOpen} onClick={setIsOpen} />
            {R.is(String, title) && <Title>{labelize(title as string)}</Title>}
            {R.is(Function, title) && (title as Function)(props)}
            {!R.is(Function, title) && !R.is(String, title) && title}
          </div>
        }
        action={action}
      />
      {isOpen && <CardContent>{children}</CardContent>}
    </div>
  );
};
