/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LogicDefinition } from './LogicDefinition';
import {
    LogicDefinitionFromJSON,
    LogicDefinitionFromJSONTyped,
    LogicDefinitionToJSON,
} from './LogicDefinition';

/**
 * 
 * @export
 * @interface CreatePolicyDto
 */
export interface CreatePolicyDto {
    /**
     * 
     * @type {LogicDefinition}
     * @memberof CreatePolicyDto
     */
    cohort: LogicDefinition;
    /**
     * 
     * @type {string}
     * @memberof CreatePolicyDto
     */
    type: CreatePolicyDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreatePolicyDto
     */
    title: string;
}


/**
 * @export
 */
export const CreatePolicyDtoTypeEnum = {
    Employee: 'employee',
    Plan: 'plan'
} as const;
export type CreatePolicyDtoTypeEnum = typeof CreatePolicyDtoTypeEnum[keyof typeof CreatePolicyDtoTypeEnum];


/**
 * Check if a given object implements the CreatePolicyDto interface.
 */
export function instanceOfCreatePolicyDto(value: object): value is CreatePolicyDto {
    if (!('cohort' in value) || value['cohort'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    return true;
}

export function CreatePolicyDtoFromJSON(json: any): CreatePolicyDto {
    return CreatePolicyDtoFromJSONTyped(json, false);
}

export function CreatePolicyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePolicyDto {
    if (json == null) {
        return json;
    }
    return {
        
        'cohort': LogicDefinitionFromJSON(json['cohort']),
        'type': json['type'],
        'title': json['title'],
    };
}

export function CreatePolicyDtoToJSON(value?: CreatePolicyDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cohort': LogicDefinitionToJSON(value['cohort']),
        'type': value['type'],
        'title': value['title'],
    };
}

