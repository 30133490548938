import type { SpecializedTooltipProps } from "./type";

import * as R from "ramda";
import React from "react";
import { v4 as uuid } from "uuid";

import { Tooltip } from "./Tooltip";
import { isOverflown } from "util/dom";

export interface TruncatedTooltipProps extends SpecializedTooltipProps {
  tooltip: React.ReactNode;
}

/**
 * Used to conditionally show a tooltip if the container's content is overflowing.
 */
export const TruncatedTooltip: React.FC<TruncatedTooltipProps> = ({
  children,
  tooltip,
}) => {
  const ref = React.useRef(null);
  const [id] = React.useState<string>(uuid());
  const [shouldShowTooltip, setShouldShowTooltip] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    const overflown: boolean = R.isNil(ref?.current)
      ? false
      : isOverflown(ref.current);
    if (overflown) {
      return setShouldShowTooltip(true);
    }

    return setShouldShowTooltip(false);
  }, [ref?.current, setShouldShowTooltip]);

  return (
    <span className="truncate">
      {shouldShowTooltip && <Tooltip id={id}>{tooltip}</Tooltip>}
      <div
        ref={(el) => {
          ref.current = el;
        }}
        className="truncate"
        data-for={id}
        data-tip
      >
        {children}
      </div>
    </span>
  );
};
