import type { LabelProps } from "./type";

import React from "react";
import { DEFAULT_ON_CLICK } from "./constant";
import { LabelContainer } from "./style";
import { Title } from "../Title";

export const Label: React.FC<LabelProps> = ({
  children,
  onClick = DEFAULT_ON_CLICK,
  htmlFor,
  isDisabled = false,
  error,
}) => {
  return (
    <LabelContainer
      onClick={onClick}
      error={error}
      isDisabled={isDisabled}
      htmlFor={htmlFor}
    >
      <Title>{children}</Title>
    </LabelContainer>
  );
};
