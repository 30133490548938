import type { ActionComponent } from "components/type";

import React, { MouseEventHandler } from "react";
import styled from "styled-components";
import { PlusCircleIcon } from "@heroicons/react/outline";

import * as Style from "style";

export interface AddButtonProps extends ActionComponent {
  onClick: MouseEventHandler<HTMLButtonElement>;
}

function toColor({ isDisabled }: AddButtonProps): string {
  return isDisabled ? Style.Color.Layout.Line : Style.Color.Layout.Gray;
}

export const AddButtonContainer = styled.div<AddButtonProps>`
  cursor: pointer;
  color: ${toColor};

  &:hover {
    color ${Style.Color.Chart.Fern};
  }
`;

export const AddButton: React.FC<AddButtonProps> = ({
  isDisabled = false,
  onClick,
}) => (
  <AddButtonContainer
    type="button"
    disabled={isDisabled}
    onClick={onClick}
    className="flex-grow-0"
  >
    <PlusCircleIcon className="inline-block w-6 h-6" />
  </AddButtonContainer>
);
