import type { SpecializedTooltipProps } from "./type";

import React from "react";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";

import * as Style from "style";

export interface TooltipProps extends SpecializedTooltipProps {
  id: string;
}

export const ReactStyledTooltip = styled(ReactTooltip)`
  &.place-top {
    opacity: 100% !important;
  }

  &.place-left {
    opacity: 100% !important;
  }

  &.place-right {
    opacity: 100% !important;
  }

  &.place-bottom {
    opacity: 100% !important;
  }

  &.type-dark {
    ${Style.Text.Definition.Paragraph};
    opacity: 100%;
    color: ${Style.Color.Font.Secondary};
    box-shadow: 0 4px 4px -4px ${Style.Color.Font.Secondary};
    background-color: ${Style.Color.Layout.Background.Primary};
    border-radius: ${Style.Design.Rounding.Secondary};
    border-color: ${Style.Color.Layout.Line};
    border-style: ${Style.Design.Border.Style};
    border-width: ${Style.Design.Border.Size};
    padding: ${Style.Layout.Padding.Small};

    &:after {
      display: none;
      border-top-color: ${Style.Color.Layout.Line};
    }
  }
`;

export const Tooltip: React.FC<TooltipProps> = ({ children, id, ...rest }) => {
  return (
    <ReactStyledTooltip id={id} {...rest}>
      {children}
    </ReactStyledTooltip>
  );
};
