import type { StandardComponent } from "components/type";
import type { Option, Options } from "../Select/type";

import * as R from "ramda";
import React from "react";
import styled from "styled-components";

import * as Style from "style";
import { Checkbox } from "./Checkbox";
import { Tooltip } from "components/library/Tooltip";
import { Label } from "components/library/Label";
import { LabelContainer } from "components/library/Label/style";

export interface CheckboxOptionsProps<T> extends StandardComponent {
  value: Set<T>;
  options: Options<T>;
  onChange: (next: Set<T>) => void;
}

export const CheckboxOptionsContainer = styled.div``;

export const CheckboxOptionContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: justify-start;

  ${LabelContainer} {
    cursor: pointer;
    margin-left: ${Style.Layout.Padding.ExtraSmall};
    margin-right: ${Style.Layout.Padding.ExtraSmall};
  }
`;

/**
 * TODO Deprecate in favor of Checkbox.List.
 */
export function CheckboxOptions<T = string>({
  isDisabled = false,
  onChange = R.always(undefined),
  options = [],
  value = new Set(),
}: CheckboxOptionsProps<T>): React.ReactElement {
  return (
    <CheckboxOptionsContainer>
      {R.map((option: Option<T>) => {
        const id: string = R.isNil(option.value) ? "" : option.value.toString();

        return (
          <CheckboxOptionContainer>
            <Checkbox
              id={id}
              onChange={() => {
                const next: Set<T> = new Set(value);

                !value.has(option.value)
                  ? next.add(option.value)
                  : next.delete(option.value);

                onChange(next);
              }}
              checked={value.has(option.value)}
              isDisabled={isDisabled}
            />
            <Label htmlFor={id}>{option.label}</Label>
            {!R.isNil(option.tooltip) && (
              <Tooltip.Info place={Tooltip.Placement.Right}>
                {option.tooltip}
              </Tooltip.Info>
            )}
          </CheckboxOptionContainer>
        );
      }, options)}
    </CheckboxOptionsContainer>
  );
}
