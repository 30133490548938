import React from "react";

import { DesktopNavigationMenu } from "./DesktopNavigationMenu";

const Frame = ({ activeMenuIndex, children, menu, user }) => {
  return (
    <div className="h-screen flex overflow-hidden antialiased bg-layout-background-primary">
      <DesktopNavigationMenu
        menu={menu}
        user={user}
        activeMenuIndex={activeMenuIndex}
      />
      <main className="relative overflow-y-auto flex-1 px-10 py-4">
        {children}
      </main>
    </div>
  );
};

export default Frame;
