export enum ResourceType {
  Account = "account",
  Approval = "approval",
  ApprovalGroup = "approval-group",
  ApprovalRequest = "approval-request",
  Budget = "budget",
  BudgetAllocation = "budget-allocation",
  BudgetCycle = "budget-cycle",
  Comment = "comment",
  Employee = "employee",
  Guideline = "guideline",
  JobLevel = "job-level",
  JobTitle = "job-title",
  Payband = "payband",
  Plan = "plan",
  Workflow = "workflow",
  WorkflowInstance = "workflow-instance",
  WorkflowNodeInstance = "workflow-node-instance",
  Zone = "zone",
}

export const ResourceTypeLabel = {
  [ResourceType.Account]: "Account",
  [ResourceType.Approval]: "Approval",
  [ResourceType.ApprovalGroup]: "Approval Group",
  [ResourceType.ApprovalRequest]: "Approval Request",
  [ResourceType.Budget]: "Budget",
  [ResourceType.BudgetAllocation]: "Allocation",
  [ResourceType.BudgetCycle]: "Budget Cycle",
  [ResourceType.Comment]: "Comment",
  [ResourceType.Employee]: "Employee",
  [ResourceType.Guideline]: "Guideline",
  [ResourceType.JobLevel]: "Job Level",
  [ResourceType.JobTitle]: "Job Title",
  [ResourceType.Plan]: "Plan",
  [ResourceType.Workflow]: "Workflow",
  [ResourceType.WorkflowInstance]: "Workflow Instance",
  [ResourceType.WorkflowNodeInstance]: "Workflow Node",
  [ResourceType.Zone]: "Zone",
};
