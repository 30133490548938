import { InlineTooltip } from "./Inline";
import { Validation } from "util/validation";

import React from "react";

import { ValidationList } from "components/library";

export type ValidationTooltipChildComponent = (
  props: ValidationTooltipChildrenProps
) => React.ReactNode;

export interface ValidationTooltipChildrenProps {
  isDisabled: boolean;
}

export interface ValidationTooltipProps {
  children: React.ReactNode | ValidationTooltipChildComponent;
  validation: Validation;
}

export const ValidationTooltip: React.FC<ValidationTooltipProps> = ({
  children,
  validation,
}) => {
  return (
    <InlineTooltip
      isDisabled={validation.isValid}
      tooltip={<ValidationList validation={validation} />}
    >
      {typeof children === "function"
        ? (children as ValidationTooltipChildComponent)({
            isDisabled: validation.hasErrors,
          })
        : children}
    </InlineTooltip>
  );
};
