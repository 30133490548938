import type { BodyCellProps } from "./BodyCell";

import React from "react";
import styled from "styled-components";

import * as Style from "style";
import { BodyCellContainer } from "./BodyCell";
import { CellContainer, TruncationWrapper } from "./style";

const BodySubCellContainer = styled(BodyCellContainer)`
  background: ${Style.Color.Layout.Background.Primary};
`;

export const BodySubCell: React.FC<BodyCellProps> = ({ column, children }) => {
  return (
    <BodySubCellContainer>
      <CellContainer column={column}>
        <TruncationWrapper>{children}</TruncationWrapper>
      </CellContainer>
    </BodySubCellContainer>
  );
};
