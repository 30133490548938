/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChatMessage
 */
export interface ChatMessage {
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    role: string;
    /**
     * 
     * @type {number}
     * @memberof ChatMessage
     */
    report_id?: number;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    employee_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    plan_id?: string;
}

/**
 * Check if a given object implements the ChatMessage interface.
 */
export function instanceOfChatMessage(value: object): value is ChatMessage {
    if (!('content' in value) || value['content'] === undefined) return false;
    if (!('role' in value) || value['role'] === undefined) return false;
    return true;
}

export function ChatMessageFromJSON(json: any): ChatMessage {
    return ChatMessageFromJSONTyped(json, false);
}

export function ChatMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatMessage {
    if (json == null) {
        return json;
    }
    return {
        
        'content': json['content'],
        'role': json['role'],
        'report_id': json['report_id'] == null ? undefined : json['report_id'],
        'employee_id': json['employee_id'] == null ? undefined : json['employee_id'],
        'plan_id': json['plan_id'] == null ? undefined : json['plan_id'],
    };
}

export function ChatMessageToJSON(value?: ChatMessage | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'content': value['content'],
        'role': value['role'],
        'report_id': value['report_id'],
        'employee_id': value['employee_id'],
        'plan_id': value['plan_id'],
    };
}

