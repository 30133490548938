/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlanHistory
 */
export interface PlanHistory {
    /**
     * 
     * @type {string}
     * @memberof PlanHistory
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PlanHistory
     */
    natural_key: string;
    /**
     * 
     * @type {object}
     * @memberof PlanHistory
     */
    data: object;
    /**
     * 
     * @type {object}
     * @memberof PlanHistory
     */
    previous_data: object;
    /**
     * 
     * @type {string}
     * @memberof PlanHistory
     */
    resource_type: string;
    /**
     * 
     * @type {string}
     * @memberof PlanHistory
     */
    event_type: string;
    /**
     * 
     * @type {string}
     * @memberof PlanHistory
     */
    updated_by: string;
    /**
     * 
     * @type {string}
     * @memberof PlanHistory
     */
    effective_time: string;
}

/**
 * Check if a given object implements the PlanHistory interface.
 */
export function instanceOfPlanHistory(value: object): value is PlanHistory {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('natural_key' in value) || value['natural_key'] === undefined) return false;
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('previous_data' in value) || value['previous_data'] === undefined) return false;
    if (!('resource_type' in value) || value['resource_type'] === undefined) return false;
    if (!('event_type' in value) || value['event_type'] === undefined) return false;
    if (!('updated_by' in value) || value['updated_by'] === undefined) return false;
    if (!('effective_time' in value) || value['effective_time'] === undefined) return false;
    return true;
}

export function PlanHistoryFromJSON(json: any): PlanHistory {
    return PlanHistoryFromJSONTyped(json, false);
}

export function PlanHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanHistory {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'natural_key': json['natural_key'],
        'data': json['data'],
        'previous_data': json['previous_data'],
        'resource_type': json['resource_type'],
        'event_type': json['event_type'],
        'updated_by': json['updated_by'],
        'effective_time': json['effective_time'],
    };
}

export function PlanHistoryToJSON(value?: PlanHistory | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'natural_key': value['natural_key'],
        'data': value['data'],
        'previous_data': value['previous_data'],
        'resource_type': value['resource_type'],
        'event_type': value['event_type'],
        'updated_by': value['updated_by'],
        'effective_time': value['effective_time'],
    };
}

