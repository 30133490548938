import * as R from "ramda";

import * as ReportConfig from "components/Plan/Report/config";
import { PeerGroup } from ".generated/models";
import { ResourceType } from "@milio/lib/data/constant";
import { JobTrack } from "@milio/lib/data/job-level-group/constant";

export const getHydratedEmployeeLink = (
  id: string,
  share_id?: string
): string => {
  const BASE = `/compensation-plan/detail?id=${id}`;
  if (share_id) {
    return BASE + `&share_id=${share_id}`;
  }
  return BASE;
};
export const getExchangeRateEditLink = (id: string): string =>
  `/setting/exchange-rates/edit?id=${id}`;
export const getPlanLink = (id: string): string => {
  return `/plan/detail?id=${id}`;
};
export const getReportEditLink = (id: string, tabIndex?: string): string =>
  `${ReportConfig.BasePath}/edit?id=${id}` +
  (tabIndex ? `&tabIndex=${tabIndex}` : "");
export const getReportViewLink = (shareId: string): string =>
  `${ReportConfig.BasePath}/view?share_id=${shareId}`;
export const getReportShareLink = (reportId: string): string =>
  `${ReportConfig.BasePath}/share?id=${reportId}`;
export const getReportSnapshotLink = (reportId: string): string =>
  `${ReportConfig.BasePath}/snapshots?id=${reportId}`;
export const getReportRevokeLink = (reportId: string): string =>
  `${ReportConfig.BasePath}/revoke?id=${reportId}`;
export const getJobFamilyEditLink = (id: string): string =>
  `/setting/job-family/edit?id=${id}`;
export const getJobFamilyGroupEditLink = (id: string): string =>
  `/setting/job-family-group/edit?id=${id}`;
export const getZoneEditLink = (id: string): string =>
  `/setting/zones/edit?id=${id}`;
export const getTaskEditLink = (id: string): string => `/task/edit?id=${id}`;
export const getTaskNewLink = (): string => `/task/new`;
export const getPolicyEditLink = (id: string): string =>
  `/setting/policy/edit?id=${id}`;
export const getPolicyAssignLink = (id: string): string =>
  `/setting/policy/assign?id=${id}`;
export const getPolicyNewLink = (): string => `/setting/policy/new`;
export const getBenchmarkLink = (pg: PeerGroup): string =>
  `/plan/benchmark?id=${pg.job_title_id}&zone_id=${pg.zone_id}`;

export const getJobLevelEditLink = (id: string): string =>
  `/setting/levels/edit?id=${id}`;
export const getJobLevelGroupEditLink = (id: string): string =>
  `/setting/level-groups/edit?id=${id}`;

export const getCohortLink = (
  resourceType: ResourceType,
  cohortId: string,
  label: string
): string => {
  switch (resourceType) {
    case ResourceType.Employee:
      return `/compensation-plan?cohortId=${cohortId}&view=${label}`;
    case ResourceType.JobTitle:
    case ResourceType.Plan:
      return `/plan?cohortId=${cohortId}&view=${label}`;
    default:
      throw new Error("Unsupported resource for cohort details.");
  }
};

export const getQueryLink = (
  resourceType: ResourceType,
  queryId: string
): string => {
  switch (resourceType) {
    case ResourceType.Employee:
      return `/compensation-plan?queryId=${queryId}&view=Search Results`;
    case ResourceType.JobTitle:
    case ResourceType.Plan:
      return `/plan?queryId=${queryId}&view=Search Results`;
    default:
      throw new Error("Unsupported resource for query details.");
  }
};

export const getMappingPageEditLink = (id: string): string => {
  return `/plan/mapping/edit?id=${id}`;
};

export const getMappingPageLink = (ids: string[] = []): string => {
  const base = "/plan/mapping/new";

  if (R.isEmpty(ids)) return base;

  const url = new URLSearchParams();
  for (const id of R.uniq(ids)) {
    url.append("mapped_titles", id);
  }

  return `${base}?${url.toString()}`;
};

export const getAppLinkWithDataShareContext = (
  reportId: string,
  shareId: string
): string => `/report/view?id=${reportId}&share_id=${shareId}`;

export const getPaybandOptimizerLink = (
  job_family_id: string,
  zone_id: string,
  track: JobTrack
) =>
  `/plan/optimize?job_family_id=${job_family_id}&zone_id=${zone_id}&track=${track}`;
