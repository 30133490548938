/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const TrendSentiment = {
    Negative: 'negative',
    Neutral: 'neutral',
    Positive: 'positive'
} as const;
export type TrendSentiment = typeof TrendSentiment[keyof typeof TrendSentiment];


export function instanceOfTrendSentiment(value: any): boolean {
    for (const key in TrendSentiment) {
        if (Object.prototype.hasOwnProperty.call(TrendSentiment, key)) {
            if (TrendSentiment[key as keyof typeof TrendSentiment] === value) {
                return true;
            }
        }
    }
    return false;
}

export function TrendSentimentFromJSON(json: any): TrendSentiment {
    return TrendSentimentFromJSONTyped(json, false);
}

export function TrendSentimentFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrendSentiment {
    return json as TrendSentiment;
}

export function TrendSentimentToJSON(value?: TrendSentiment | null): any {
    return value as any;
}

