import type { User } from "./";

import * as R from "ramda";

import { arrayize } from "util/array";
import { RoleType } from "@milio/lib/data/user/constant";
import { RoleType as BackendRoleType, PermissionType } from ".generated/models";

export type AuthenticateOptions = {
  roles?: (RoleType | BackendRoleType)[];
  permissions?: PermissionType[];
};
export type Authenticate = (options: AuthenticateOptions) => boolean;

const RolePrecedence = [RoleType.Admin, RoleType.ViewOnly];

/**
 * Determine the default user role type to use.
 */
export function userToDefaultRole(user: User): RoleType {
  const roles: RoleType[] = R.pipe(
    R.map(R.prop("key")),
    R.sortBy((role: RoleType) => {
      return RolePrecedence.indexOf(role);
    })
  )(user.roles);

  return R.head(roles);
}

/**
 * Determine if a user has a role.
 */
const userHasRole = R.curry((user: User, role: any): boolean => {
  const index: number = R.findIndex(R.propEq("key", role), user.roles);

  return index !== -1;
});

/**
 * Determine if a user has a set of roles and permission.
 */
export const isAuthenticated = R.curry(
  (
    user: User,
    { roles: role = [], permissions: permission = [] }: AuthenticateOptions
  ): boolean => {
    const roles = arrayize(role);
    const permissions = arrayize(permission);

    if (!R.isEmpty(permissions)) {
      const userPermissions = R.map(R.prop("key"), user.permissions);

      if (R.isEmpty(userPermissions)) {
        return false;
      }

      return (
        R.isEmpty(permissions) ||
        R.any(R.includes(R.__, userPermissions), permissions)
      );
    }

    return R.isEmpty(roles) || R.any(userHasRole(user), roles);
  }
);
