import * as R from "ramda";
import React from "react";

import { Paragraph } from "./Paragraph";

interface NoDataProps {
  Icon?: React.FC<Record<string, unknown>>;
}

interface NoDataBaseProps extends NoDataProps {
  children: React.ReactNode;
}

interface NoDataMessageProps extends NoDataProps {
  message: string;
}

interface NoDataSpecializations extends React.FC<NoDataBaseProps> {
  Message: React.FC<NoDataMessageProps>;
}

const NoData: NoDataSpecializations = ({ children, Icon }) => {
  return (
    <div className="text-center border-dotted p-6 border-2 border-gray-200 sm:rounded-md">
      {!R.isNil(Icon) && (
        <div className="text-center">
          <Paragraph>
            <Icon className="inline-block h-4 w-4 mb-2" />
          </Paragraph>
        </div>
      )}
      <Paragraph>{children}</Paragraph>
    </div>
  );
};

const NoDataMessage: React.FC<NoDataMessageProps> = ({ message, Icon }) => {
  return <NoData Icon={Icon}>{message}</NoData>;
};

NoData.Message = NoDataMessage;

export { NoData };
