import type { AnySchema } from "yup";
import type { FormikContextType } from "formik";

import * as R from "ramda";
import { yupToFormErrors } from "formik";

import { keyToPath } from "@milio/lib/util/string";

export interface FormikErrorHelper {
  getErrors: (
    options?: Partial<ErrorOptions>
  ) => Record<string, unknown> | undefined;
  getError: <T>(
    fieldName: string,
    options?: Partial<ErrorOptions>
  ) => T | undefined;
  hasError: (filedName: string, options?: Partial<ErrorOptions>) => boolean;
  hasErrors: (options?: Partial<ErrorOptions>) => boolean;
  isTouched: (field: string) => boolean;
  areTouched: (fields: string[]) => boolean;
  wereFilled: (fields: string[]) => boolean;
}

export interface ErrorOptions {
  requireHasBeenSubmitted: boolean;
  requireHasBeenTouched: boolean;
}

export type FormikSetFieldValue = <T>(
  field: string,
  value: T,
  shouldValidate?: boolean
) => void;

const DefaultErrorOptions = {
  requireHasBeenSubmitted: false,
  requireHasBeenTouched: true,
};

export function formikHasError<T extends object>(
  context: FormikContextType<T>,
  defaults: Partial<ErrorOptions> = DefaultErrorOptions
): (fieldName: string) => boolean {
  return (fieldName: string, opts: Partial<ErrorOptions> = {}): boolean => {
    const options: ErrorOptions = R.mergeAll([
      defaults,
      DefaultErrorOptions,
      opts,
    ]) as ErrorOptions;

    const error: string | undefined = formikGetError(context)(
      fieldName,
      options
    );

    return !R.isNil(error);
  };
}

export function formikGetError<T extends object>(
  context: FormikContextType<T>,
  defaults: Partial<ErrorOptions> = DefaultErrorOptions
) {
  return <S>(
    fieldName: string,
    opts: Partial<ErrorOptions> = {}
  ): S | undefined => {
    const options: ErrorOptions = R.mergeAll([
      defaults,
      DefaultErrorOptions,
      opts,
    ]) as ErrorOptions;

    const { errors, touched } = context;
    const path: string[] = keyToPath(fieldName);

    let error = undefined;

    if (R.hasPath(path, errors)) {
      error = R.path(path, errors);

      if (options.requireHasBeenTouched && !R.pathOr(false, path, touched)) {
        error = undefined;
      }

      if (options.requireHasBeenSubmitted && context.submitCount === 0) {
        error = undefined;
      }
    }

    return error;
  };
}

export function formikGetErrors<T extends object>(
  context: FormikContextType<T>,
  defaults: Partial<ErrorOptions> = DefaultErrorOptions
) {
  return (
    opts: Partial<ErrorOptions> = {}
  ): Record<string, unknown> | undefined => {
    const options: ErrorOptions = R.mergeAll([
      defaults,
      DefaultErrorOptions,
      opts,
    ]) as ErrorOptions;

    const { errors, touched } = context;

    if (R.isNil(errors)) {
      return undefined;
    }

    if (R.isEmpty(errors)) {
      return undefined;
    }

    if (options.requireHasBeenSubmitted && context.submitCount === 0) {
      return undefined;
    }

    if (
      options.requireHasBeenTouched &&
      (R.isNil(touched) || R.isEmpty(touched))
    ) {
      return undefined;
    }

    return errors;
  };
}

export function formikHasErrors<T extends object>(
  context: FormikContextType<T>,
  defaults: Partial<ErrorOptions> = DefaultErrorOptions
): () => boolean {
  return (opts: Partial<ErrorOptions> = {}): boolean => {
    const options: ErrorOptions = R.mergeAll([
      defaults,
      DefaultErrorOptions,
      opts,
    ]) as ErrorOptions;

    const errors: Record<string, unknown> | undefined =
      formikGetErrors(context)(options);

    return !R.isNil(errors);
  };
}

/**
 * Determine if a field has been touched.
 */
export function formikIsTouched<T extends object>(
  context: FormikContextType<T>
): (field: string) => boolean {
  return (field: string): boolean => {
    const { touched } = context;

    return R.propOr(false, field, touched) === true;
  };
}

/**
 * Determine if multiple fields have been touched.
 */
export function formikAreTouched<T extends object>(
  context: FormikContextType<T>
): (fields: string[]) => boolean {
  return (fields: string[]): boolean => {
    const { touched } = context;

    return R.all((field: string) => R.prop(field, touched) === true, fields);
  };
}

/**
 * Determine if multiple fields have been touched or are present.
 */
export function formikWereFilled<T extends object>(
  context: FormikContextType<T>
): (fields: string[]) => boolean {
  return (fields: string[]): boolean => {
    const { touched, values } = context;

    const somethingTouched: boolean = R.any((field: string) => {
      return R.prop(field, touched) as boolean;
    }, fields);

    const allTouchedOrSet: boolean = R.all((field: string) => {
      return (
        R.prop(field, touched) === true ||
        (R.has(field, values) && !R.isNil(R.prop(field, values)))
      );
    }, fields);

    return somethingTouched && allTouchedOrSet;
  };
}

export function validate<T extends object>(
  schema: AnySchema,
  instance: T
): Record<string, unknown> {
  try {
    schema.validateSync(instance, { abortEarly: false, recursive: true });
  } catch (error) {
    return yupToFormErrors(error);
  }

  return {};
}

export function formikErrorHelper<T extends object>(
  context: FormikContextType<T>,
  defaults: Partial<ErrorOptions> = DefaultErrorOptions
): FormikErrorHelper {
  return {
    getError: formikGetError<T>(context, defaults),
    getErrors: formikGetErrors<T>(context, defaults),
    hasError: formikHasError<T>(context, defaults),
    hasErrors: formikHasErrors<T>(context, defaults),
    isTouched: formikIsTouched<T>(context),
    areTouched: formikAreTouched<T>(context),
    wereFilled: formikWereFilled<T>(context),
  };
}
