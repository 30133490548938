import type { BaseButtonProps } from "./Base";

import React from "react";
import styled from "styled-components";

import { BaseButton } from "./Base";
import { PrimaryButtonStyle, FixedButtonStyle } from "./style";

export const PrimaryButtonContainer = styled.div<{
  isDisabled: boolean;
}>`
  display: inline-block;

  > button {
    ${FixedButtonStyle};
    ${PrimaryButtonStyle};
  }
`;

/**
 * Button used for primary actions.
 */
export const PrimaryButton: React.FC<BaseButtonProps> = (props) => {
  const { isDisabled } = props;

  return (
    <PrimaryButtonContainer isDisabled={isDisabled}>
      <BaseButton {...props} />
    </PrimaryButtonContainer>
  );
};
