/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MetricValue } from './MetricValue';
import {
    MetricValueFromJSON,
    MetricValueFromJSONTyped,
    MetricValueToJSON,
} from './MetricValue';

/**
 * 
 * @export
 * @interface BreakdownDatum
 */
export interface BreakdownDatum {
    /**
     * 
     * @type {MetricValue}
     * @memberof BreakdownDatum
     */
    avg_pay: MetricValue;
    /**
     * 
     * @type {MetricValue}
     * @memberof BreakdownDatum
     */
    avg_last_promotion_months: MetricValue;
    /**
     * 
     * @type {MetricValue}
     * @memberof BreakdownDatum
     */
    avg_tenure: MetricValue;
    /**
     * 
     * @type {MetricValue}
     * @memberof BreakdownDatum
     */
    min_pay: MetricValue;
    /**
     * 
     * @type {MetricValue}
     * @memberof BreakdownDatum
     */
    max_pay: MetricValue;
    /**
     * 
     * @type {object}
     * @memberof BreakdownDatum
     */
    med_pay: object;
    /**
     * 
     * @type {MetricValue}
     * @memberof BreakdownDatum
     */
    avg_comparatio: MetricValue;
    /**
     * 
     * @type {string}
     * @memberof BreakdownDatum
     */
    key: string;
}

/**
 * Check if a given object implements the BreakdownDatum interface.
 */
export function instanceOfBreakdownDatum(value: object): value is BreakdownDatum {
    if (!('avg_pay' in value) || value['avg_pay'] === undefined) return false;
    if (!('avg_last_promotion_months' in value) || value['avg_last_promotion_months'] === undefined) return false;
    if (!('avg_tenure' in value) || value['avg_tenure'] === undefined) return false;
    if (!('min_pay' in value) || value['min_pay'] === undefined) return false;
    if (!('max_pay' in value) || value['max_pay'] === undefined) return false;
    if (!('med_pay' in value) || value['med_pay'] === undefined) return false;
    if (!('avg_comparatio' in value) || value['avg_comparatio'] === undefined) return false;
    if (!('key' in value) || value['key'] === undefined) return false;
    return true;
}

export function BreakdownDatumFromJSON(json: any): BreakdownDatum {
    return BreakdownDatumFromJSONTyped(json, false);
}

export function BreakdownDatumFromJSONTyped(json: any, ignoreDiscriminator: boolean): BreakdownDatum {
    if (json == null) {
        return json;
    }
    return {
        
        'avg_pay': MetricValueFromJSON(json['avg_pay']),
        'avg_last_promotion_months': MetricValueFromJSON(json['avg_last_promotion_months']),
        'avg_tenure': MetricValueFromJSON(json['avg_tenure']),
        'min_pay': MetricValueFromJSON(json['min_pay']),
        'max_pay': MetricValueFromJSON(json['max_pay']),
        'med_pay': json['med_pay'],
        'avg_comparatio': MetricValueFromJSON(json['avg_comparatio']),
        'key': json['key'],
    };
}

export function BreakdownDatumToJSON(value?: BreakdownDatum | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'avg_pay': MetricValueToJSON(value['avg_pay']),
        'avg_last_promotion_months': MetricValueToJSON(value['avg_last_promotion_months']),
        'avg_tenure': MetricValueToJSON(value['avg_tenure']),
        'min_pay': MetricValueToJSON(value['min_pay']),
        'max_pay': MetricValueToJSON(value['max_pay']),
        'med_pay': value['med_pay'],
        'avg_comparatio': MetricValueToJSON(value['avg_comparatio']),
        'key': value['key'],
    };
}

