import type { StandardComponent } from "components/type";
import type { Step as IStep } from "./type";

import * as R from "ramda";
import React from "react";
import styled from "styled-components";

import { Connector } from "./Connector";
import { DEFAULT_BACKGROUND, DEFAULT_NETURAL } from "./constant";
import { Orientation } from "../constant";
import { Step } from "./Step";

export interface StepsProps extends StandardComponent {
  steps: IStep[];
  neutral?: string;
  orientation?: Orientation;
}

function showConnector({
  length,
  index,
}: {
  length: number;
  index: number;
}): boolean {
  return index !== length - 1;
}

export const StepsContainer = styled.div<{ orientation: Orientation }>`
  display: grid;
`;

export const Steps: React.FC<StepsProps> = ({
  neutral = DEFAULT_NETURAL,
  orientation = Orientation.Horizontal,
  steps,
}) => {
  const length: number = R.length(steps);

  return (
    <StepsContainer orientation={orientation}>
      {R.addIndex(R.map)((step: IStep, index: number) => {
        const { background = DEFAULT_BACKGROUND, isComplete = false } = step;

        const color: string = isComplete ? background : neutral;

        return (
          <>
            <Step step={step} neutral={neutral} />
            {showConnector({ length, index }) && <Connector color={color} />}
          </>
        );
      }, steps)}
    </StepsContainer>
  );
};
