/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateJobGroupDto,
  JobGroup,
  UpdateJobGroupDto,
} from '../models/index';
import {
    CreateJobGroupDtoFromJSON,
    CreateJobGroupDtoToJSON,
    JobGroupFromJSON,
    JobGroupToJSON,
    UpdateJobGroupDtoFromJSON,
    UpdateJobGroupDtoToJSON,
} from '../models/index';

export interface CreateJobGroupRequest {
    createJobGroupDto: CreateJobGroupDto;
}

export interface FindOneJobGroupRequest {
    id: string;
}

export interface RemoveJobGroupRequest {
    id: string;
}

export interface UpdateJobGroupRequest {
    id: string;
    updateJobGroupDto: UpdateJobGroupDto;
}

/**
 * 
 */
export class JobGroupApi extends runtime.BaseAPI {

    /**
     */
    async createJobGroupRaw(requestParameters: CreateJobGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<JobGroup>> {
        if (requestParameters['createJobGroupDto'] == null) {
            throw new runtime.RequiredError(
                'createJobGroupDto',
                'Required parameter "createJobGroupDto" was null or undefined when calling createJobGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/job-groups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateJobGroupDtoToJSON(requestParameters['createJobGroupDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JobGroupFromJSON(jsonValue));
    }

    /**
     */
    async createJobGroup(requestParameters: CreateJobGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<JobGroup> {
        const response = await this.createJobGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findAllJobGroupsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<JobGroup>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/job-groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(JobGroupFromJSON));
    }

    /**
     */
    async findAllJobGroups(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<JobGroup>> {
        const response = await this.findAllJobGroupsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async findOneJobGroupRaw(requestParameters: FindOneJobGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<JobGroup>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling findOneJobGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/job-groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JobGroupFromJSON(jsonValue));
    }

    /**
     */
    async findOneJobGroup(requestParameters: FindOneJobGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<JobGroup> {
        const response = await this.findOneJobGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removeJobGroupRaw(requestParameters: RemoveJobGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling removeJobGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/job-groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async removeJobGroup(requestParameters: RemoveJobGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeJobGroupRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateJobGroupRaw(requestParameters: UpdateJobGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<JobGroup>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateJobGroup().'
            );
        }

        if (requestParameters['updateJobGroupDto'] == null) {
            throw new runtime.RequiredError(
                'updateJobGroupDto',
                'Required parameter "updateJobGroupDto" was null or undefined when calling updateJobGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/job-groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateJobGroupDtoToJSON(requestParameters['updateJobGroupDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JobGroupFromJSON(jsonValue));
    }

    /**
     */
    async updateJobGroup(requestParameters: UpdateJobGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<JobGroup> {
        const response = await this.updateJobGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
