import * as R from "ramda";
import React from "react";

import { ValidationError, Validation } from "util/validation";

export interface ValidationListProps {
  validation: Validation;
}

export const ValidationList: React.FC<ValidationListProps> = ({
  validation,
}) => {
  if (validation.isValid) {
    return null;
  }

  return (
    <ul>
      {R.addIndex(R.map)((error: ValidationError, index: number) => {
        return <li key={`${index}`}>{error.message}</li>;
      }, validation.errors)}
    </ul>
  );
};
