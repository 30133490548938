/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const TrendDirection = {
    Decrease: 'decrease',
    Flat: 'flat',
    Increase: 'increase'
} as const;
export type TrendDirection = typeof TrendDirection[keyof typeof TrendDirection];


export function instanceOfTrendDirection(value: any): boolean {
    for (const key in TrendDirection) {
        if (Object.prototype.hasOwnProperty.call(TrendDirection, key)) {
            if (TrendDirection[key as keyof typeof TrendDirection] === value) {
                return true;
            }
        }
    }
    return false;
}

export function TrendDirectionFromJSON(json: any): TrendDirection {
    return TrendDirectionFromJSONTyped(json, false);
}

export function TrendDirectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrendDirection {
    return json as TrendDirection;
}

export function TrendDirectionToJSON(value?: TrendDirection | null): any {
    return value as any;
}

