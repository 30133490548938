import type { StandardComponent } from "components/type";

import React from "react";
import cn from "lib/cn";
import styled from "styled-components";
import { Switch } from "@headlessui/react";

import * as Style from "style";

export interface ToggleProps extends StandardComponent {
  onChange: (next: boolean) => void;
  value: boolean;
}

function toBackground({ isDisabled, value }: ToggleProps): string {
  if (isDisabled) {
    return Style.Color.Layout.Gray;
  }

  return value ? Style.Color.Brand.Primary : Style.Color.Layout.Line;
}

export const ToggleContainer = styled.div<ToggleProps>`
  display: flex;
  height: ${Style.Layout.Input.Height};
  align-items: center;

  > button {
    background: ${toBackground};
  }
`;

export const Toggle: React.FC<ToggleProps> = ({
  isDisabled = false,
  onChange,
  value,
}) => {
  return (
    <ToggleContainer isDisabled={isDisabled} value={value}>
      <Switch
        checked={value}
        onChange={(...args) => {
          if (!isDisabled) {
            onChange(...args);
          }
        }}
        className="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200"
      >
        <span
          aria-hidden="true"
          className={cn(
            value ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
          )}
        />
      </Switch>
    </ToggleContainer>
  );
};
