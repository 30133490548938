/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { QueryDTO } from './QueryDTO';
import {
    QueryDTOFromJSON,
    QueryDTOFromJSONTyped,
    QueryDTOToJSON,
} from './QueryDTO';

/**
 * 
 * @export
 * @interface BulkUpdatePlanDto
 */
export interface BulkUpdatePlanDto {
    /**
     * 
     * @type {number}
     * @memberof BulkUpdatePlanDto
     */
    payBandMin: number;
    /**
     * 
     * @type {number}
     * @memberof BulkUpdatePlanDto
     */
    payBandMax: number;
    /**
     * 
     * @type {boolean}
     * @memberof BulkUpdatePlanDto
     */
    applyMultiplier: boolean;
    /**
     * 
     * @type {QueryDTO}
     * @memberof BulkUpdatePlanDto
     */
    cohort: QueryDTO;
}

/**
 * Check if a given object implements the BulkUpdatePlanDto interface.
 */
export function instanceOfBulkUpdatePlanDto(value: object): value is BulkUpdatePlanDto {
    if (!('payBandMin' in value) || value['payBandMin'] === undefined) return false;
    if (!('payBandMax' in value) || value['payBandMax'] === undefined) return false;
    if (!('applyMultiplier' in value) || value['applyMultiplier'] === undefined) return false;
    if (!('cohort' in value) || value['cohort'] === undefined) return false;
    return true;
}

export function BulkUpdatePlanDtoFromJSON(json: any): BulkUpdatePlanDto {
    return BulkUpdatePlanDtoFromJSONTyped(json, false);
}

export function BulkUpdatePlanDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkUpdatePlanDto {
    if (json == null) {
        return json;
    }
    return {
        
        'payBandMin': json['payBandMin'],
        'payBandMax': json['payBandMax'],
        'applyMultiplier': json['applyMultiplier'],
        'cohort': QueryDTOFromJSON(json['cohort']),
    };
}

export function BulkUpdatePlanDtoToJSON(value?: BulkUpdatePlanDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'payBandMin': value['payBandMin'],
        'payBandMax': value['payBandMax'],
        'applyMultiplier': value['applyMultiplier'],
        'cohort': QueryDTOToJSON(value['cohort']),
    };
}

