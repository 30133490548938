/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateBenchmarkDto
 */
export interface UpdateBenchmarkDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateBenchmarkDto
     */
    job_title_id?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBenchmarkDto
     */
    zone_id?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBenchmarkDto
     */
    survey_date?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateBenchmarkDto
     */
    pct_25?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateBenchmarkDto
     */
    pct_50?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateBenchmarkDto
     */
    pct_75?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateBenchmarkDto
     */
    notes?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateBenchmarkDto
     */
    aging_factor?: number;
}

/**
 * Check if a given object implements the UpdateBenchmarkDto interface.
 */
export function instanceOfUpdateBenchmarkDto(value: object): value is UpdateBenchmarkDto {
    return true;
}

export function UpdateBenchmarkDtoFromJSON(json: any): UpdateBenchmarkDto {
    return UpdateBenchmarkDtoFromJSONTyped(json, false);
}

export function UpdateBenchmarkDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateBenchmarkDto {
    if (json == null) {
        return json;
    }
    return {
        
        'job_title_id': json['job_title_id'] == null ? undefined : json['job_title_id'],
        'zone_id': json['zone_id'] == null ? undefined : json['zone_id'],
        'survey_date': json['survey_date'] == null ? undefined : json['survey_date'],
        'pct_25': json['pct_25'] == null ? undefined : json['pct_25'],
        'pct_50': json['pct_50'] == null ? undefined : json['pct_50'],
        'pct_75': json['pct_75'] == null ? undefined : json['pct_75'],
        'notes': json['notes'] == null ? undefined : json['notes'],
        'aging_factor': json['aging_factor'] == null ? undefined : json['aging_factor'],
    };
}

export function UpdateBenchmarkDtoToJSON(value?: UpdateBenchmarkDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'job_title_id': value['job_title_id'],
        'zone_id': value['zone_id'],
        'survey_date': value['survey_date'],
        'pct_25': value['pct_25'],
        'pct_50': value['pct_50'],
        'pct_75': value['pct_75'],
        'notes': value['notes'],
        'aging_factor': value['aging_factor'],
    };
}

