import type { SpecializedTooltipProps } from "./type";

import React from "react";
import styled from "styled-components";
import { ExclamationIcon } from "@heroicons/react/outline";
import { v4 as uuid } from "uuid";

import * as Style from "style";
import { Tooltip } from "./Tooltip";

export const WarningTooltipContainer = styled.div`
  > svg {
    color: ${Style.Color.Sentiment.Warning.Primary};
  }
`;

export const WarningTooltip: React.FC<SpecializedTooltipProps> = ({
  children,
  ...rest
}) => {
  const [id] = React.useState(uuid());

  return (
    <WarningTooltipContainer>
      <ExclamationIcon
        data-for={id}
        data-tip
        className="h-4 w-4 text-yellow-300"
      />
      <Tooltip id={id} {...rest}>
        {children}
      </Tooltip>
    </WarningTooltipContainer>
  );
};
