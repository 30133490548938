/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QuestionWithAnswer
 */
export interface QuestionWithAnswer {
    /**
     * 
     * @type {number}
     * @memberof QuestionWithAnswer
     */
    question_id: number;
    /**
     * 
     * @type {string}
     * @memberof QuestionWithAnswer
     */
    text: string;
    /**
     * 
     * @type {number}
     * @memberof QuestionWithAnswer
     */
    answer_value: number;
    /**
     * 
     * @type {string}
     * @memberof QuestionWithAnswer
     */
    answer_label: string;
}

/**
 * Check if a given object implements the QuestionWithAnswer interface.
 */
export function instanceOfQuestionWithAnswer(value: object): value is QuestionWithAnswer {
    if (!('question_id' in value) || value['question_id'] === undefined) return false;
    if (!('text' in value) || value['text'] === undefined) return false;
    if (!('answer_value' in value) || value['answer_value'] === undefined) return false;
    if (!('answer_label' in value) || value['answer_label'] === undefined) return false;
    return true;
}

export function QuestionWithAnswerFromJSON(json: any): QuestionWithAnswer {
    return QuestionWithAnswerFromJSONTyped(json, false);
}

export function QuestionWithAnswerFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionWithAnswer {
    if (json == null) {
        return json;
    }
    return {
        
        'question_id': json['question_id'],
        'text': json['text'],
        'answer_value': json['answer_value'],
        'answer_label': json['answer_label'],
    };
}

export function QuestionWithAnswerToJSON(value?: QuestionWithAnswer | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'question_id': value['question_id'],
        'text': value['text'],
        'answer_value': value['answer_value'],
        'answer_label': value['answer_label'],
    };
}

