/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateImportResultDto
 */
export interface CreateImportResultDto {
    /**
     * 
     * @type {string}
     * @memberof CreateImportResultDto
     */
    job_id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateImportResultDto
     */
    source_id: string;
    /**
     * 
     * @type {number}
     * @memberof CreateImportResultDto
     */
    employees: number;
    /**
     * 
     * @type {number}
     * @memberof CreateImportResultDto
     */
    active_employees: number;
    /**
     * 
     * @type {number}
     * @memberof CreateImportResultDto
     */
    successful: number;
    /**
     * 
     * @type {number}
     * @memberof CreateImportResultDto
     */
    skipped: number;
    /**
     * 
     * @type {number}
     * @memberof CreateImportResultDto
     */
    failed: number;
    /**
     * 
     * @type {object}
     * @memberof CreateImportResultDto
     */
    skip_context: object;
    /**
     * 
     * @type {object}
     * @memberof CreateImportResultDto
     */
    fail_context: object;
}

/**
 * Check if a given object implements the CreateImportResultDto interface.
 */
export function instanceOfCreateImportResultDto(value: object): value is CreateImportResultDto {
    if (!('job_id' in value) || value['job_id'] === undefined) return false;
    if (!('source_id' in value) || value['source_id'] === undefined) return false;
    if (!('employees' in value) || value['employees'] === undefined) return false;
    if (!('active_employees' in value) || value['active_employees'] === undefined) return false;
    if (!('successful' in value) || value['successful'] === undefined) return false;
    if (!('skipped' in value) || value['skipped'] === undefined) return false;
    if (!('failed' in value) || value['failed'] === undefined) return false;
    if (!('skip_context' in value) || value['skip_context'] === undefined) return false;
    if (!('fail_context' in value) || value['fail_context'] === undefined) return false;
    return true;
}

export function CreateImportResultDtoFromJSON(json: any): CreateImportResultDto {
    return CreateImportResultDtoFromJSONTyped(json, false);
}

export function CreateImportResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateImportResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'job_id': json['job_id'],
        'source_id': json['source_id'],
        'employees': json['employees'],
        'active_employees': json['active_employees'],
        'successful': json['successful'],
        'skipped': json['skipped'],
        'failed': json['failed'],
        'skip_context': json['skip_context'],
        'fail_context': json['fail_context'],
    };
}

export function CreateImportResultDtoToJSON(value?: CreateImportResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'job_id': value['job_id'],
        'source_id': value['source_id'],
        'employees': value['employees'],
        'active_employees': value['active_employees'],
        'successful': value['successful'],
        'skipped': value['skipped'],
        'failed': value['failed'],
        'skip_context': value['skip_context'],
        'fail_context': value['fail_context'],
    };
}

