import type { SwitchProps } from "./Switch";

import React from "react";
import styled from "styled-components";

import * as Style from "style";
import { Title } from "../Title";
import { Switch } from "./Switch";

export interface SwitchLabelProps {
  isSelected?: boolean;
  label: string;
  tooltip?: React.ReactNode;
}

export interface LabelledSwitchProps extends SwitchProps {
  left: SwitchLabelProps;
  right: SwitchLabelProps;
}

export const LabelledSwitchContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export interface LabelContainerProps {
  isSelected: SwitchLabelProps["isSelected"];
}

function toLabelContainerColor({
  isSelected = true,
}: LabelContainerProps): string {
  return isSelected ? "auto" : Style.Color.Layout.Gray;
}

export const LabelContainer = styled.div<LabelContainerProps>`
  span {
    color: ${toLabelContainerColor};
  }
`;

export const SwitchContainer = styled.div`
  margin: 0px ${Style.Layout.Padding.ExtraSmall};

  div {
    display: flex;
  }
`;

export const LabelledSwitch: React.FC<LabelledSwitchProps> = ({
  left,
  right,
  ...rest
}) => {
  return (
    <LabelledSwitchContainer>
      <LabelContainer isSelected={left.isSelected}>
        <Title.Tooltip tooltip={left.tooltip}>{left.label}</Title.Tooltip>
      </LabelContainer>

      <SwitchContainer>
        <Switch {...rest} />
      </SwitchContainer>

      <LabelContainer isSelected={right.isSelected}>
        <Title.Tooltip tooltip={right.tooltip}>{right.label}</Title.Tooltip>
      </LabelContainer>
    </LabelledSwitchContainer>
  );
};
