/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CountryMetadata } from './CountryMetadata';
import {
    CountryMetadataFromJSON,
    CountryMetadataFromJSONTyped,
    CountryMetadataToJSON,
} from './CountryMetadata';

/**
 * 
 * @export
 * @interface ZoneMetadata
 */
export interface ZoneMetadata {
    /**
     * 
     * @type {CountryMetadata}
     * @memberof ZoneMetadata
     */
    country_details: CountryMetadata;
    /**
     * 
     * @type {string}
     * @memberof ZoneMetadata
     */
    description: string;
}

/**
 * Check if a given object implements the ZoneMetadata interface.
 */
export function instanceOfZoneMetadata(value: object): value is ZoneMetadata {
    if (!('country_details' in value) || value['country_details'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    return true;
}

export function ZoneMetadataFromJSON(json: any): ZoneMetadata {
    return ZoneMetadataFromJSONTyped(json, false);
}

export function ZoneMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZoneMetadata {
    if (json == null) {
        return json;
    }
    return {
        
        'country_details': CountryMetadataFromJSON(json['country_details']),
        'description': json['description'],
    };
}

export function ZoneMetadataToJSON(value?: ZoneMetadata | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'country_details': CountryMetadataToJSON(value['country_details']),
        'description': value['description'],
    };
}

