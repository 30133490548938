import type { SpecializedAnchorProps, AnchorProps } from "./type";

import React from "react";

import { TopRightAnchor } from "./TopRight";
import { Anchor as BaseAnchor } from "./Anchor";

export interface AnchorNamespace extends React.FC<AnchorProps> {
  TopRight: React.FC<SpecializedAnchorProps>;
}

export const Anchor: AnchorNamespace = (props) => {
  return <BaseAnchor {...props} />;
};

Anchor.TopRight = TopRightAnchor;
