import type { StandardComponent } from "components/type";

import * as R from "ramda";
import React from "react";
import styled from "styled-components";

import { SwatchContainer } from "./Base";

export interface ColorSwatchProps extends StandardComponent {
  color: string;
  onClick?: () => void;
}

export const Fill = styled.div<{ color: string }>`
  background: ${R.prop("color")};
  border-radius: 50%;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

export const DisabledSwatch = styled(SwatchContainer)`
  border-radius: 50%;
  border-color: ${R.prop("color")};
  border-style: solid;
  border-width: 2px;
`;

export const ColorSwatch: React.FC<ColorSwatchProps> = ({
  color,
  isDisabled = false,
  onClick = R.always(undefined),
}) => {
  if (isDisabled) {
    return <DisabledSwatch color={color} />;
  }

  return (
    <SwatchContainer onClick={onClick}>
      <Fill color={color} />
    </SwatchContainer>
  );
};
