import * as R from "ramda";

/**
 * Create an object that when called gives the next element in the array
 * and cycles back to the start when the end is reached.
 */
export interface Cycler<T> {
  next: () => T;
}
export function cycler<T>(list: T[]): Cycler<T> {
  const size = list.length;

  let current = 0;
  const next = (): T => {
    const index: number = current++ % size;

    return R.view(R.lensIndex(index), list);
  };

  return { next };
}

/**
 * Add a value to the front of an array.
 */
export function unshift<T>(value: T, list: T[]): T[] {
  return [value, ...list];
}

/**
 * Insert a value at a given index.
 */
export function insertAt<T>(index: number, value: T, list: T[]): T[] {
  return [...list.slice(0, index), value, ...list.slice(index)];
}

/**
 * Generate an array that alternates values between two input arrays.
 */
export function interleave<T>(left: T[], right: T[]): T[] {
  const pairs = left.map((value: T, index: number): T[] => {
    if (right[index] === undefined) {
      return [value];
    }

    return [value, right[index]];
  });
  return R.flatten(pairs) as unknown as T[];
}

/**
 * Append one array to another.
 */
export function concatAt<T>(index: number, values: T[], list: T[]): T[] {
  return [...list.slice(0, index), ...values, ...list.slice(index)];
}

/**
 * Replace a value in an array.
 */
export function replaceAt<T>(index: number, value: T, list: T[]): T[] {
  return [...list.slice(0, index), value, ...list.slice(index + 1)];
}

/**
 * Remove a value at an index.
 */
export function removeAt<T>(index: number, list: T[]): T[] {
  return [...list.slice(0, index), ...list.slice(index + 1)];
}

/**
 * Swap two values at two indexes.
 */
export function swapAt<T>(left: number, right: number, list: T[]): T[] {
  const next: T[] = [...list];

  const temp: T = next[left];
  next[left] = next[right];
  next[right] = temp;

  return next;
}

/**
 * Ensure a value is an array.
 */
export function arrayize<T = unknown>(input: T | T[]): T[] {
  return R.is(Array, input) ? (input as T[]) : [input as T];
}

/**
 * Convert an array of strings into a single string.
 */
export function arrayToString<T = unknown>(value: T[]): string {
  return `(${value.join(", ")})`;
}

/**
 * Group by a unique prop getter.
 */
export function groupByUnique<T = unknown>(
  toKey: (item: T) => string,
  list: T[]
): { [key: string]: T } {
  return R.indexBy(toKey, list);
}

/**
 * Return the longest of two arrays otherwise the first.
 */
export function getLonger<T = unknown, S = unknown>(
  left: T[],
  right: S[]
): T[] | S[] {
  if (R.length(left) > R.length(right)) {
    return left;
  } else if (R.length(right) > R.length(left)) {
    return right;
  }

  return left;
}

/**
 * Zip two arrays without truncation.
 */
export function zip<T = unknown, S = unknown>(
  left: T[],
  right: S[]
): [T | undefined, S | undefined][] {
  const longest: T[] | S[] = getLonger<T, S>(left, right);

  return R.addIndex(R.reduce)(
    (acc: [T | undefined, S | undefined][], element: T | S, index: number) => {
      const first: T | undefined = R.view(R.lensIndex(index), left);
      const second: S | undefined = R.view(R.lensIndex(index), right);

      const tuple: [T | undefined, S | undefined] = [undefined, undefined];

      if (!R.isNil(first)) {
        tuple[0] = first;
      }

      if (!R.isNil(second)) {
        tuple[1] = second;
      }

      acc.push(tuple);

      return acc;
    },
    [],
    longest
  );
}

export const makeArray = R.pipe(R.of, R.flatten);
