import type { SliderProps } from "./Base";

import React from "react";
import styled from "styled-components";

import * as R from "ramda";
import * as Style from "style";
import { Slider } from "./Base";

export type SliderLabel = (
  props: SliderProps
) => React.ReactNode | React.ReactNode;

export interface LabelledSliderProps extends SliderProps {
  left: SliderLabel;
  right: SliderLabel;
}

const LabelContainer = styled.div`
  margin-top: ${Style.Layout.Padding.ExtraSmall};
`;

export const LabelledSlider: React.FC<LabelledSliderProps> = ({
  left,
  right,
  ...props
}) => {
  return (
    <div>
      <Slider {...props} />
      <div className="flex justify-between">
        <LabelContainer className="flex-shrink flex flex-col items-center">
          {R.is(Function, left) ? left(props) : left}
        </LabelContainer>
        <LabelContainer className="flex-shrink flex flex-col items-center">
          {R.is(Function, right) ? right(props) : left}
        </LabelContainer>
      </div>
    </div>
  );
};
