import type { Options, Option } from "./Select/type";
import type { StandardComponent } from "components/type";

import * as R from "ramda";
import React from "react";
import styled from "styled-components";

import * as Style from "style";
import { Label } from "./Label";

export interface RadioButtonProps extends StandardComponent {
  onChange: (next: boolean) => void;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  value: boolean;
}

function toBackgroundColor({ value }: RadioButtonProps): string {
  return value ? Style.Color.Brand.Primary : Style.Color.White;
}

function toBorderColor({ value }: RadioButtonProps): string {
  return value ? Style.Color.Brand.Primary : Style.Color.Layout.Line;
}

export const RadioButtonContainer = styled.span`
  background: ${toBackgroundColor};
  border-color: ${toBorderColor};
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CentralDot = styled.div`
  background: ${Style.Color.White};
  border-radius: 50%;
  height: 5px;
  width: 5px;
`;

export const RadioButton: React.FC<RadioButtonProps> = ({
  onClick = R.always(undefined),
  onChange,
  value,
}) => {
  return (
    <div className="inline-block flex items-center">
      <RadioButtonContainer
        className="h-4 w-4 rounded-full border flex items-center justify-center"
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          onChange(!value);
          onClick(event);
        }}
        value={value}
      >
        <CentralDot />
      </RadioButtonContainer>
    </div>
  );
};

export interface SelectRadioProps<T> extends StandardComponent {
  options: Options<T>;
  value: T;
  onChange: (next: T) => void;
}

export function SelectRadio<T = string>(
  props: SelectRadioProps<T>
): React.ReactElement {
  const { options, value, onChange } = props;

  const current: Option<T> | undefined = R.pipe(
    R.find(R.propEq("value", value)),
    (found: Option<T> | undefined) => {
      return R.isNil(found) ? R.head(options) : found;
    }
  )(options);

  return (
    <div>
      {options.map((option: Option<T>, i) => {
        const handleChange = () => onChange(option.value);

        return (
          <div className="flex items-center gap-x-2" key={i}>
            <RadioButton
              value={current.value === option.value}
              onChange={handleChange}
            />
            <Label onClick={handleChange}>{option.label}</Label>
          </div>
        );
      })}
    </div>
  );
}
