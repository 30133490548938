import type { ModalProps, SpecializedModalProps } from "./Modal";
import type { ConfirmationModalProps } from "./Confirmation";
import type { NoticeModalProps } from "./Notice";

import React from "react";

import { ConfirmationModal } from "./Confirmation";
import { ErrorModal } from "./Error";
import { InfoModal } from "./Info";
import { Modal as BaseModal } from "./Modal";
import { NoticeModal } from "./Notice";
import { Sentiment } from "../constant";
import { SuccessModal } from "./Success";
import { WarningModal } from "./Warning";

export interface ModalSpecializations extends React.FC<ModalProps> {
  Confirmation: React.FC<ConfirmationModalProps>;
  Error: React.FC<SpecializedModalProps>;
  Info: React.FC<SpecializedModalProps>;
  Notice: React.FC<NoticeModalProps>;
  Sentiment: typeof Sentiment;
  Success: React.FC<SpecializedModalProps>;
  Warning: React.FC<SpecializedModalProps>;
}

export const Modal: ModalSpecializations = (props) => {
  return <BaseModal {...props} />;
};

Modal.Confirmation = ConfirmationModal;
Modal.Error = ErrorModal;
Modal.Info = InfoModal;
Modal.Notice = NoticeModal;
Modal.Sentiment = Sentiment;
Modal.Success = SuccessModal;
Modal.Warning = WarningModal;
