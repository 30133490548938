/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CountryDetails
 */
export interface CountryDetails {
    /**
     * 
     * @type {string}
     * @memberof CountryDetails
     */
    iso: string;
    /**
     * 
     * @type {string}
     * @memberof CountryDetails
     */
    nicename: string;
}

/**
 * Check if a given object implements the CountryDetails interface.
 */
export function instanceOfCountryDetails(value: object): value is CountryDetails {
    if (!('iso' in value) || value['iso'] === undefined) return false;
    if (!('nicename' in value) || value['nicename'] === undefined) return false;
    return true;
}

export function CountryDetailsFromJSON(json: any): CountryDetails {
    return CountryDetailsFromJSONTyped(json, false);
}

export function CountryDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CountryDetails {
    if (json == null) {
        return json;
    }
    return {
        
        'iso': json['iso'],
        'nicename': json['nicename'],
    };
}

export function CountryDetailsToJSON(value?: CountryDetails | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'iso': value['iso'],
        'nicename': value['nicename'],
    };
}

