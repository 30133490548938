import cn from "lib/cn";

import * as R from "ramda";
import * as Style from "style";
import React from "react";
import styled from "styled-components";

import { CardPadding } from "./constant";
import { Title } from "../Title";
import { labelize } from "util/format";

export interface CardHeaderProps<P = unknown>
  extends Omit<React.HTMLProps<HTMLDivElement>, "action" | "title"> {
  action?: React.ReactNode;
  title: React.ReactNode | ((props: P) => React.ReactNode);
}

export interface CardHeaderTitleProps {
  children: React.ReactNode;
}

export const CardHeaderContainer = styled.div`
  align-items: center;
  border-top-left-radius: ${Style.Design.Rounding.Primary};
  border-top-right-radius: ${Style.Design.Rounding.Primary};
  box-sizing: border-box;
  display: flex;
  padding: ${CardPadding} ${CardPadding} 0px ${CardPadding};
  background: ${Style.Color.White};
`;

export const CardHeader: React.FC<CardHeaderProps> = ({ title, action }) => {
  return (
    <div
      className={cn(
        "flex items-center rounded-md bg-white p-4",
        action ? "justify-between" : ""
      )}
    >
      <>
        {R.is(String, title) && <Title>{labelize(title as string)}</Title>}
        {!R.is(String, title) && title}
        {action}
      </>
    </div>
  );
};
