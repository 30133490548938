import type { AnimationComponentProps } from "./type";

import styled from "styled-components";
import {
  ENTERING,
  ENTERED,
  EXITING,
  EXITED,
} from "react-transition-group/Transition";

import { createAnimationContainer } from "./Container";

function toOpacity({ state }: AnimationComponentProps): string {
  switch (state) {
    case ENTERING:
      return "0";
    case ENTERED:
      return "1";
    case EXITING:
      return "1";
    case EXITED:
      return "0";
    default:
      return "0";
  }
}

function toDisplay({ state }: AnimationComponentProps): string {
  switch (state) {
    case EXITED:
      return "none";
    case ENTERING:
    case ENTERED:
    case EXITING:
    default:
      return "auto";
  }
}

const FadeAnimation = styled.div`
  transition: 0.5s;
  opacity: ${toOpacity};
  display: ${toDisplay};
`;

export const Fade = createAnimationContainer(FadeAnimation);
