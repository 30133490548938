/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateJobFamilyDto
 */
export interface UpdateJobFamilyDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateJobFamilyDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateJobFamilyDto
     */
    job_group_id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateJobFamilyDto
     */
    plan_ids?: Array<string>;
}

/**
 * Check if a given object implements the UpdateJobFamilyDto interface.
 */
export function instanceOfUpdateJobFamilyDto(value: object): value is UpdateJobFamilyDto {
    return true;
}

export function UpdateJobFamilyDtoFromJSON(json: any): UpdateJobFamilyDto {
    return UpdateJobFamilyDtoFromJSONTyped(json, false);
}

export function UpdateJobFamilyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateJobFamilyDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'job_group_id': json['job_group_id'] == null ? undefined : json['job_group_id'],
        'plan_ids': json['plan_ids'] == null ? undefined : json['plan_ids'],
    };
}

export function UpdateJobFamilyDtoToJSON(value?: UpdateJobFamilyDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'job_group_id': value['job_group_id'],
        'plan_ids': value['plan_ids'],
    };
}

