import React from "react";

import type { IconProps } from "./type";

export const Eye: React.FC<IconProps> = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="100%"
      height="100%"
      viewBox="0 0 256 256"
      stroke="currentColor"
      fill="none"
    >
      <path
        d="M128,56C48,56,16,128,16,128s32,72,112,72,112-72,112-72S208,56,128,56Z"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <circle
        cx="128"
        cy="128"
        r="40"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></circle>
    </svg>
  );
};
