import * as R from "ramda";

/**
 * Find the intersection of two sets.
 */
export function intersection<T>(left: Set<T>, right: Set<T>): Set<T> {
  return new Set(Array.from(left).filter((x: T) => right.has(x)));
}

/**
 * Find the union of two sets.
 */
export function union<T>(left: Set<T>, right: Set<T>): Set<T> {
  return new Set(Array.from(left).concat(Array.from(right)));
}

/**
 * Find the difference of two sets.
 */
export function difference<T>(left: Set<T>, right: Set<T>): Set<T> {
  return new Set(Array.from(left).filter((x: T) => !right.has(x)));
}

/**
 * Determine if two sets are equal.
 */
export function areEqual<T>(left: Set<T>, right: Set<T>) {
  return (
    left.size === right.size &&
    R.all((value: T) => right.has(value), Array.from(left))
  );
}
