import React from "react";
import ReactDOM from "react-dom";

export interface PortalProps {
  children: React.ReactNode;
}

export const Portal: React.FC<PortalProps> = ({ children }) => {
  const [mount] = React.useState(document.getElementById("portal-root"));
  const [element] = React.useState(document.createElement("div"));

  React.useEffect(() => {
    mount.appendChild(element);

    return () => {
      mount.removeChild(element);
    };
  }, [element, mount]);

  return ReactDOM.createPortal(children, element);
};
