import roundTo from "floor-to-precision";

import { PercentFormat } from "./constant";

/**
 * Translate a percentage between formats.
 */
export function percentize(
  value: number,
  {
    input,
    output,
    precision = 4,
  }: {
    input: PercentFormat;
    output: PercentFormat;
    precision?: number;
  }
): number {
  switch (input) {
    case PercentFormat.Percentage:
      switch (output) {
        case PercentFormat.Decimal:
          return value / 100;
        case PercentFormat.Percentage:
        default:
          return value;
      }
    case PercentFormat.Decimal:
    default:
      switch (output) {
        case PercentFormat.Decimal:
          return value;
        case PercentFormat.Percentage:
        default:
          return roundTo(value * 100, precision);
      }
  }
}
