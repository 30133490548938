/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DatumWithBreakdowns } from './DatumWithBreakdowns';
import {
    DatumWithBreakdownsFromJSON,
    DatumWithBreakdownsFromJSONTyped,
    DatumWithBreakdownsToJSON,
} from './DatumWithBreakdowns';
import type { PlanOverviewKey } from './PlanOverviewKey';
import {
    PlanOverviewKeyFromJSON,
    PlanOverviewKeyFromJSONTyped,
    PlanOverviewKeyToJSON,
} from './PlanOverviewKey';

/**
 * 
 * @export
 * @interface PlanOverviewDatum
 */
export interface PlanOverviewDatum {
    /**
     * 
     * @type {PlanOverviewKey}
     * @memberof PlanOverviewDatum
     */
    key: PlanOverviewKey;
    /**
     * 
     * @type {number}
     * @memberof PlanOverviewDatum
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof PlanOverviewDatum
     */
    employee_count: number;
    /**
     * 
     * @type {DatumWithBreakdowns}
     * @memberof PlanOverviewDatum
     */
    stats: DatumWithBreakdowns;
}

/**
 * Check if a given object implements the PlanOverviewDatum interface.
 */
export function instanceOfPlanOverviewDatum(value: object): value is PlanOverviewDatum {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('count' in value) || value['count'] === undefined) return false;
    if (!('employee_count' in value) || value['employee_count'] === undefined) return false;
    if (!('stats' in value) || value['stats'] === undefined) return false;
    return true;
}

export function PlanOverviewDatumFromJSON(json: any): PlanOverviewDatum {
    return PlanOverviewDatumFromJSONTyped(json, false);
}

export function PlanOverviewDatumFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanOverviewDatum {
    if (json == null) {
        return json;
    }
    return {
        
        'key': PlanOverviewKeyFromJSON(json['key']),
        'count': json['count'],
        'employee_count': json['employee_count'],
        'stats': DatumWithBreakdownsFromJSON(json['stats']),
    };
}

export function PlanOverviewDatumToJSON(value?: PlanOverviewDatum | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'key': PlanOverviewKeyToJSON(value['key']),
        'count': value['count'],
        'employee_count': value['employee_count'],
        'stats': DatumWithBreakdownsToJSON(value['stats']),
    };
}

