import type { AnimationProps, AnimationComponent } from "./type";
import type { TransitionStatus } from "react-transition-group";

import * as R from "ramda";
import React from "react";
import { Transition } from "react-transition-group";

/**
 * Create an animated container given an animation component.
 */
export const createAnimationContainer = (Animation: AnimationComponent) => {
  return ({
    children,
    animate,
    timeout = 0,
    onDone = R.always(undefined),
  }: AnimationProps) => {
    return (
      <Transition
        in={animate}
        addEndListener={
          ((node, done) => {
            node.addEventListener(
              "transitionend",
              (event) => {
                onDone();
                done(event);
              },
              false
            );
          }) as any
        }
        timeout={timeout}
      >
        {(state: TransitionStatus) => (
          <Animation state={state}>{children}</Animation>
        )}
      </Transition>
    );
  };
};
