import * as R from "ramda";
import React from "react";
import styled from "styled-components";

import * as Style from "style";

export interface ErrorMessageProps {
  error: string | undefined;
}

const ErrorMessageContainer = styled.span`
  ${Style.Text.Definition.Paragraph};
  color: ${Style.Color.Sentiment.Error.Primary};
`;

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ error }) => {
  if (R.isNil(error)) {
    return null;
  }

  return <ErrorMessageContainer>{error}</ErrorMessageContainer>;
};
