/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ChatConversationDto,
} from '../models/index';
import {
    ChatConversationDtoFromJSON,
    ChatConversationDtoToJSON,
} from '../models/index';

/**
 * 
 */
export class AiApi extends runtime.BaseAPI {

    /**
     */
    async getConversationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ChatConversationDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ai/conversations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ChatConversationDtoFromJSON));
    }

    /**
     */
    async getConversations(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ChatConversationDto>> {
        const response = await this.getConversationsRaw(initOverrides);
        return await response.value();
    }

}
