/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RecentHireDto
 */
export interface RecentHireDto {
    /**
     * 
     * @type {number}
     * @memberof RecentHireDto
     */
    last3: number;
    /**
     * 
     * @type {number}
     * @memberof RecentHireDto
     */
    last3Count: number;
    /**
     * 
     * @type {number}
     * @memberof RecentHireDto
     */
    last6: number;
    /**
     * 
     * @type {number}
     * @memberof RecentHireDto
     */
    last6Count: number;
    /**
     * 
     * @type {number}
     * @memberof RecentHireDto
     */
    last9: number;
    /**
     * 
     * @type {number}
     * @memberof RecentHireDto
     */
    last9Count: number;
    /**
     * 
     * @type {number}
     * @memberof RecentHireDto
     */
    last12: number;
    /**
     * 
     * @type {number}
     * @memberof RecentHireDto
     */
    last12Count: number;
}

/**
 * Check if a given object implements the RecentHireDto interface.
 */
export function instanceOfRecentHireDto(value: object): value is RecentHireDto {
    if (!('last3' in value) || value['last3'] === undefined) return false;
    if (!('last3Count' in value) || value['last3Count'] === undefined) return false;
    if (!('last6' in value) || value['last6'] === undefined) return false;
    if (!('last6Count' in value) || value['last6Count'] === undefined) return false;
    if (!('last9' in value) || value['last9'] === undefined) return false;
    if (!('last9Count' in value) || value['last9Count'] === undefined) return false;
    if (!('last12' in value) || value['last12'] === undefined) return false;
    if (!('last12Count' in value) || value['last12Count'] === undefined) return false;
    return true;
}

export function RecentHireDtoFromJSON(json: any): RecentHireDto {
    return RecentHireDtoFromJSONTyped(json, false);
}

export function RecentHireDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecentHireDto {
    if (json == null) {
        return json;
    }
    return {
        
        'last3': json['last3'],
        'last3Count': json['last3Count'],
        'last6': json['last6'],
        'last6Count': json['last6Count'],
        'last9': json['last9'],
        'last9Count': json['last9Count'],
        'last12': json['last12'],
        'last12Count': json['last12Count'],
    };
}

export function RecentHireDtoToJSON(value?: RecentHireDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'last3': value['last3'],
        'last3Count': value['last3Count'],
        'last6': value['last6'],
        'last6Count': value['last6Count'],
        'last9': value['last9'],
        'last9Count': value['last9Count'],
        'last12': value['last12'],
        'last12Count': value['last12Count'],
    };
}

