import type { MenuChildrenProps } from "components/library/Menu";
import type { Option as IOption, Options } from "./type";
import type { StandardComponent } from "components/type";
import type { UICursor } from "./useUICursor";

import * as R from "ramda";
import React from "react";

import { CurrentSelection } from "./CurrentSelection";
import { Option } from "./Option";
import { OptionMenu } from "./OptionMenu";
import { SubTitle } from "../SubTitle";
import { stringsToOptions } from "./util";
import { useUICursor } from "./useUICursor";

export interface SelectBuilderProps<T> extends StandardComponent {
  children?: React.ReactNode;
  closeOnClick?: boolean;
  onChange: (next: T) => void;
  options: Options<T>;
  value: T;
  placeholder?: React.ReactNode;
}

export interface SelectBuilderWrapperProps<T>
  extends Omit<SelectBuilderProps<T>, "options"> {}

export function SelectBuilder<T = string>(
  props: SelectBuilderProps<T>
): React.ReactElement {
  const {
    options,
    value,
    onChange,
    children,
    testID,
    placeholder,
    readOnly = false,
    isDisabled = false,
    closeOnClick = true,
  } = props;

  const currentIndex: number = R.findIndex(R.propEq("value", value), options);
  const current: IOption<T> | undefined =
    currentIndex === -1
      ? undefined
      : R.view(R.lensIndex(currentIndex), options);

  const optionMenuRef = React.useRef(null);
  const cursor: UICursor = useUICursor({
    ref: optionMenuRef,
    length: options.length,
    onSelect: (pointer: number) => {
      const option: IOption<T> = R.view(R.lensIndex(pointer), options);

      if (R.isNil(option.isDisabled) || option.isDisabled === false) {
        onChange(option.value);
      }
    },
  });

  if (readOnly) {
    return <SubTitle>{current?.label}</SubTitle>;
  }

  return (
    <CurrentSelection
      testID={testID}
      isDisabled={isDisabled}
      placeholder={placeholder}
      label={R.isNil(current) ? undefined : current.label}
      onKeyDown={cursor.onKeyDown}
      onToggle={(isOpen: boolean) => {
        isOpen ? cursor.focusSelected() : cursor.set(currentIndex);
      }}
    >
      {({ close }: MenuChildrenProps) => {
        return (
          <OptionMenu ref={optionMenuRef}>
            {options.map((option: IOption<T>, index: number) => {
              return (
                <Option
                  testID={option.testID}
                  isDisabled={option.isDisabled}
                  error={option.error}
                  readOnly={option.readOnly}
                  isActive={cursor.pointer === index}
                  key={
                    !R.isNil(option.value)
                      ? option.value.toString()
                      : index.toString()
                  }
                  onClick={() => {
                    onChange(option.value);

                    if (closeOnClick) {
                      close();
                    }
                  }}
                  isSelected={current?.value === option.value}
                >
                  {option.label}
                </Option>
              );
            })}
            {children && children}
          </OptionMenu>
        );
      }}
    </CurrentSelection>
  );
}

SelectBuilder.stringsToOptions = stringsToOptions;
