import type { LabelProps } from "./type";

import * as R from "ramda";
import React from "react";

import { LabelContainer } from "./style";
import { Title } from "../Title";
import { Tooltip } from "../Tooltip";

export interface TooltipLabelProps extends LabelProps {
  tooltip: React.ReactNode;
}

export const TooltipLabel: React.FC<TooltipLabelProps> = ({
  tooltip,
  children,
  ...props
}) => {
  return (
    <LabelContainer {...props}>
      <Title>{children}</Title>
      {!R.isNil(tooltip) && <Tooltip.Info>{tooltip}</Tooltip.Info>}
    </LabelContainer>
  );
};
