/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HealthcheckRule } from './HealthcheckRule';
import {
    HealthcheckRuleFromJSON,
    HealthcheckRuleFromJSONTyped,
    HealthcheckRuleToJSON,
} from './HealthcheckRule';
import type { LogicDefinition } from './LogicDefinition';
import {
    LogicDefinitionFromJSON,
    LogicDefinitionFromJSONTyped,
    LogicDefinitionToJSON,
} from './LogicDefinition';

/**
 * 
 * @export
 * @interface CreateHealthcheckTemplateDto
 */
export interface CreateHealthcheckTemplateDto {
    /**
     * 
     * @type {string}
     * @memberof CreateHealthcheckTemplateDto
     */
    title: string;
    /**
     * 
     * @type {LogicDefinition}
     * @memberof CreateHealthcheckTemplateDto
     */
    cohort: LogicDefinition;
    /**
     * 
     * @type {string}
     * @memberof CreateHealthcheckTemplateDto
     */
    type: CreateHealthcheckTemplateDtoTypeEnum;
    /**
     * 
     * @type {Array<HealthcheckRule>}
     * @memberof CreateHealthcheckTemplateDto
     */
    rules: Array<HealthcheckRule>;
}


/**
 * @export
 */
export const CreateHealthcheckTemplateDtoTypeEnum = {
    Employee: 'employee',
    Plan: 'plan'
} as const;
export type CreateHealthcheckTemplateDtoTypeEnum = typeof CreateHealthcheckTemplateDtoTypeEnum[keyof typeof CreateHealthcheckTemplateDtoTypeEnum];


/**
 * Check if a given object implements the CreateHealthcheckTemplateDto interface.
 */
export function instanceOfCreateHealthcheckTemplateDto(value: object): value is CreateHealthcheckTemplateDto {
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('cohort' in value) || value['cohort'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('rules' in value) || value['rules'] === undefined) return false;
    return true;
}

export function CreateHealthcheckTemplateDtoFromJSON(json: any): CreateHealthcheckTemplateDto {
    return CreateHealthcheckTemplateDtoFromJSONTyped(json, false);
}

export function CreateHealthcheckTemplateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateHealthcheckTemplateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'],
        'cohort': LogicDefinitionFromJSON(json['cohort']),
        'type': json['type'],
        'rules': ((json['rules'] as Array<any>).map(HealthcheckRuleFromJSON)),
    };
}

export function CreateHealthcheckTemplateDtoToJSON(value?: CreateHealthcheckTemplateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'cohort': LogicDefinitionToJSON(value['cohort']),
        'type': value['type'],
        'rules': ((value['rules'] as Array<any>).map(HealthcheckRuleToJSON)),
    };
}

