import type { D3SVG } from "data/d3";

import { Color } from "./color";

export const Layout = {
  NavigationMenu: {
    Width: "165px",
  },

  Padding: {
    ExtraLarge: "50px",
    Large: "40px",
    Medium: "20px",
    Small: "15px",
    ExtraSmall: "10px",
    Compact: "4px",
    Tiny: "1px",
  },

  Input: {
    Height: "40px",
    MinWidth: "163px",
  },

  Select: {
    MinHeight: "100px",
    MaxHeight: "300px",
  },

  Dropdown: {
    MaxHeight: "300px",
  },

  Table: {
    Cell: {
      MinContentHeight: "50px",
    },
  },

  Card: {
    Header: {
      Height: "50px",
    },
  },
};

export const D3Layout = {
  Line: (svg: D3SVG): D3SVG => {
    return svg.style("color", Color.Layout.Line);
  },
};
