import type { Option } from "components/library/Select";
import type { StandardComponent } from "components/type";

import * as R from "ramda";
import React from "react";
import styled from "styled-components";
import { FixedSizeList } from "react-window";

import * as Style from "style";
import { Checkbox } from "./Checkbox";
import { Input } from "../Input";
import { Menu } from "../Menu";
import { Tooltip } from "../Tooltip";
import { difference } from "@milio/lib/util/set";
import { displayNumber } from "util/format";
import { queryHasMatch } from "util/search";
import { Title } from "..";

export interface CheckboxListProps<T> extends StandardComponent {
  canSearch?: boolean;
  canToggleAll?: boolean;
  maxHeight?: string;
  onChange: (next: Set<T>, option?: Option<T>, index?: number) => void;
  onSearch?: (subset: Option<T>[], search: string) => void;
  options: Option<T>[];
  renderOption?: (option: Option<T>, index?: number) => React.ReactElement;
  renderOptionBelow?: (option: Option<T>, index?: number) => React.ReactElement;
  searchPlaceholder?: string;
  value?: Set<T>;
  autoFocusSearch?: boolean;
  virtualize?: boolean;
}

interface CheckboxDropdownMenuItemProps<T> extends StandardComponent {
  index: number;
  isSelected: boolean;
  onChange: (next: boolean) => void;
  option: Option<T>;
  isIndeterminate?: boolean;
  renderOption?: CheckboxListProps<T>["renderOption"];
  renderOptionBelow?: CheckboxListProps<T>["renderOption"];
}

export const CheckboxDropdownMenuItemContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const CheckboxDropdownMenuItemContainerLeft = styled.div`
  align-items: center;
  display: flex;
`;

export const CheckboxDropdownMenuItemContainerRight = styled.div``;
export const CheckboxDropdownMenuItemContainerBelow = styled.div``;

export function CheckboxDropdownMenuItem<T = string>({
  index,
  isDisabled = false,
  isIndeterminate = false,
  isSelected,
  onChange,
  option,
  renderOption,
  renderOptionBelow,
}: CheckboxDropdownMenuItemProps<T>): React.ReactElement {
  return (
    <Menu.Item
      isDisabled={isDisabled}
      onClick={() => {
        if (!isDisabled) {
          onChange(!isSelected);
        }
      }}
    >
      <CheckboxDropdownMenuItemContainer>
        <CheckboxDropdownMenuItemContainerLeft>
          <Checkbox
            isIndeterminate={isIndeterminate}
            checked={isSelected}
            isDisabled={isDisabled}
            onChange={onChange}
          />
          <span className="ml-2 mr-2">
            <Title>{option.label}</Title>
          </span>
          {!R.isNil(option.tooltip) && (
            <Tooltip.Info place={Tooltip.Placement.Right}>
              {option.tooltip}
            </Tooltip.Info>
          )}
        </CheckboxDropdownMenuItemContainerLeft>
        <CheckboxDropdownMenuItemContainerRight>
          {R.is(Function, renderOption) && renderOption(option, index)}
        </CheckboxDropdownMenuItemContainerRight>
      </CheckboxDropdownMenuItemContainer>
      <CheckboxDropdownMenuItemContainerBelow>
        {R.is(Function, renderOptionBelow) && renderOptionBelow(option, index)}
      </CheckboxDropdownMenuItemContainerBelow>
    </Menu.Item>
  );
}

export const CheckboxListContainer = styled.div`
  background: ${Style.Color.White};
`;

export const CheckboxListSearchContainer = styled.div`
  margin-bottom: ${Style.Layout.Padding.ExtraSmall};
`;

export const CheckboxListOptionsContainer = styled.div<{ maxHeight: number }>`
  background: ${Style.Color.White};
  max-height: ${R.prop("maxHeight")};
  overflow-y: auto;
`;

export function CheckboxList<T>({
  canSearch = false,
  canToggleAll = false,
  isDisabled = false,
  onChange,
  onSearch,
  options = [],
  renderOption,
  renderOptionBelow,
  searchPlaceholder = "e.g. Job Title",
  value = new Set(),
  autoFocusSearch = false,
  virtualize = true,
}: CheckboxListProps<T>) {
  const [search, setSearch] = React.useState<string>("");

  const {
    allSelected,
    isIndeterminate,
    all,
    disabled,
  }: {
    allSelected: boolean;
    isIndeterminate: boolean;
    noneSelected: boolean;
    disabled: Set<T>;
    possible: Set<T>;
    all: Set<T>;
  } = React.useMemo(() => {
    const set: Set<T> = new Set(R.map(R.prop("value"), options));
    const disabledSet = new Set(
      R.pipe(
        R.filter(R.propOr(false, "isDisabled")),
        R.map(R.prop("value"))
      )(options)
    ) as Set<T>;
    const possibleSet: Set<T> = difference(set, disabledSet);

    return {
      allSelected: value.size === set.size,
      isIndeterminate: value.size !== 0 && value.size !== options.length,
      noneSelected: value.size === 0,
      all: set,
      disabled: disabledSet,
      possible: possibleSet,
    };
  }, [value, options]);

  const filteredOptions: Option<T>[] = React.useMemo(() => {
    if (R.isEmpty(search)) {
      return options;
    }

    return R.filter((option: Option<T>): boolean => {
      return queryHasMatch(search, option.label);
    }, options);
  }, [search, options]);

  const selectAllLabel: string = React.useMemo(() => {
    return `Select All (${displayNumber(value.size)} / ${displayNumber(
      all.size
    )})`;
  }, [value, all]);

  React.useEffect(() => {
    if (!R.isNil(search) && !R.isNil(onSearch)) {
      onSearch(filteredOptions, search);
    }
  }, [search]);

  const RenderOption = React.useCallback(
    ({ index, style }) => {
      const option = filteredOptions[index];
      return (
        <div style={style}>
          <CheckboxDropdownMenuItem<T>
            index={index}
            isDisabled={isDisabled || option.isDisabled}
            renderOption={renderOption}
            renderOptionBelow={renderOptionBelow}
            isSelected={value.has(option.value)}
            option={option}
            onChange={(isSelected: boolean) => {
              const next: Set<T> = new Set(value);
              const index: number = R.findIndex(
                R.propEq("value", option.value),
                options
              );

              isSelected ? next.add(option.value) : next.delete(option.value);

              onChange(next, option, index);
            }}
          />
        </div>
      );
    },
    [filteredOptions, allSelected, disabled, all, isIndeterminate]
  );

  return (
    <div>
      {canSearch && (
        <CheckboxListSearchContainer>
          <Input
            autoFocus={autoFocusSearch}
            isDisabled={isDisabled}
            onChange={(next: string) => setSearch(next)}
            placeholder={searchPlaceholder}
            value={search}
          />
        </CheckboxListSearchContainer>
      )}
      <div>
        {canToggleAll && (
          <CheckboxDropdownMenuItem
            index={-1}
            isDisabled={isDisabled}
            isSelected={allSelected}
            isIndeterminate={isIndeterminate}
            option={{
              value: "",
              label: selectAllLabel,
            }}
            onChange={() => {
              const next: Set<T> = allSelected ? disabled : all;

              onChange(next);
            }}
          />
        )}
        {virtualize ? (
          <FixedSizeList
            itemCount={filteredOptions.length}
            itemSize={50}
            height={canToggleAll ? 192 - 29 : 192}
            width={224}
          >
            {RenderOption}
          </FixedSizeList>
        ) : (
          filteredOptions.map((_o, index) => RenderOption({ index, style: {} }))
        )}
      </div>
    </div>
  );
}
