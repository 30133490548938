import * as R from "ramda";
import React from "react";

import styled from "styled-components";

import * as Style from "style";
import { Icon } from "components/library";

export interface GrabHandleProps {
  isActive?: boolean;
  onClick?: () => void;
}

export const GrabHandleContainer = styled.div<{ isActive: boolean }>`
  display: inline-block;
  height: ${Style.Text.Size.Base};
  width: ${Style.Text.Size.Base};
  color: ${Style.Color.Brand.Primary};
`;

export const GrabHandle: React.FC<GrabHandleProps> = ({
  isActive = false,
  onClick = R.always(undefined),
}) => {
  return (
    <GrabHandleContainer onClick={onClick} isActive={isActive}>
      <Icon.GrabHandle />
    </GrabHandleContainer>
  );
};
