import type { SpecializedNotificationDotProps } from "./type";

import React from "react";

import { NotificationDot } from "./NotificationDot";
import { Sentiment } from "../constant";
import { sentimentToPrimaryColor } from "../util";

export const NeutralNotificationDot: React.FC<
  SpecializedNotificationDotProps
> = (props) => {
  return (
    <NotificationDot
      color={sentimentToPrimaryColor({ sentiment: Sentiment.Neutral })}
      {...props}
    />
  );
};
