/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreatePlanDto
 */
export interface CreatePlanDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePlanDto
     */
    plan_group_id: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePlanDto
     */
    zone_id: string;
    /**
     * 
     * @type {number}
     * @memberof CreatePlanDto
     */
    pay_band_min?: number;
    /**
     * 
     * @type {number}
     * @memberof CreatePlanDto
     */
    pay_band_max?: number;
    /**
     * 
     * @type {string}
     * @memberof CreatePlanDto
     */
    currency: string;
}

/**
 * Check if a given object implements the CreatePlanDto interface.
 */
export function instanceOfCreatePlanDto(value: object): value is CreatePlanDto {
    if (!('plan_group_id' in value) || value['plan_group_id'] === undefined) return false;
    if (!('zone_id' in value) || value['zone_id'] === undefined) return false;
    if (!('currency' in value) || value['currency'] === undefined) return false;
    return true;
}

export function CreatePlanDtoFromJSON(json: any): CreatePlanDto {
    return CreatePlanDtoFromJSONTyped(json, false);
}

export function CreatePlanDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePlanDto {
    if (json == null) {
        return json;
    }
    return {
        
        'plan_group_id': json['plan_group_id'],
        'zone_id': json['zone_id'],
        'pay_band_min': json['pay_band_min'] == null ? undefined : json['pay_band_min'],
        'pay_band_max': json['pay_band_max'] == null ? undefined : json['pay_band_max'],
        'currency': json['currency'],
    };
}

export function CreatePlanDtoToJSON(value?: CreatePlanDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'plan_group_id': value['plan_group_id'],
        'zone_id': value['zone_id'],
        'pay_band_min': value['pay_band_min'],
        'pay_band_max': value['pay_band_max'],
        'currency': value['currency'],
    };
}

