import type { StandardComponent } from "../../type";

import * as L from "lodash";
import * as R from "ramda";
import React from "react";

import { Input } from "../Input";
import { Loader } from "../Loader";
import { SubTitle } from "../SubTitle";
import { Menu } from "../Menu";
import { NoResults } from "./NoResults";
import { RightAlignedDropdownChevron } from "../Select/DropdownChevron";
import { isPromise } from "util/promise";

export interface DropdownChildrenProps<T> {
  cursor: number;
  data: T[];
  onSelect: (S) => void;
  close: () => void;
}

// TODO: Deprecate in favor of library/constant
enum KeyEvent {
  ArrowDown = "ArrowDown",
  ArrowUp = "ArrowUp",
  Enter = "Enter",
}
const KeyEvents = R.values(KeyEvent);

export interface DropdownProps<T> extends StandardComponent {
  children: (props: DropdownChildrenProps<T>) => React.ReactElement;
  debounce?: number;
  depth?: number;
  isExact?: boolean;
  key?: string;
  onChange?: (next: T) => void;
  onEnter?: (next: string) => void;
  onSearch: (search: string) => Promise<T[]> | T[];
  placeholder?: string;
  value?: string | undefined;
  noResults?: React.ReactNode;
}

function increment(cursor: number, length: number): number {
  return cursor === length - 1 ? cursor : cursor + 1;
}

function decrement(cursor: number): number {
  return cursor === 0 ? 0 : cursor - 1;
}

/**
 * Sometimes a single Dropdown component will be used whos content is
 * dependent on another component. When this is the case you can give
 * the Dropdown a unique key based on the dependent component. When this key
 * changes it will induce a fresh search and repopulate the options.
 */
export function Dropdown<T>({
  children,
  debounce = 0,
  depth,
  isDisabled = false,
  isExact = true,
  readOnly = false,
  key,
  onChange,
  onEnter,
  onSearch,
  placeholder,
  testID,
  value,
  noResults = <NoResults />,
}: DropdownProps<T>): React.ReactElement {
  const inputRef: React.RefObject<HTMLInputElement> = React.useRef();
  const contentRef: React.RefObject<HTMLInputElement> = React.useRef();

  const [data, setData] = React.useState<T[]>(undefined);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState<string>("");
  const [selected, setSelected] = React.useState<string>("");
  const [cursor, setCursor] = React.useState<number>(0);
  const [isSearching, setIsSearching] = React.useState<boolean>(false);

  const debouncedOnSearch = React.useMemo(
    () =>
      L.throttle((next: string) => {
        const potentialPromise = onSearch(next);

        if (isPromise(potentialPromise as unknown as Record<string, unknown>)) {
          setIsLoading(true);

          return (potentialPromise as Promise<T[]>).then((result: T[]) => {
            setCursor(0);
            setData(result);
            setIsLoading(false);
          });
        }

        setCursor(0);
        setData(potentialPromise as T[]);
      }, debounce),
    [onSearch]
  );

  const changeSearch = React.useCallback(
    (next: string) => {
      setIsSearching(true);
      setSearch(next);
    },
    [setIsSearching, setSearch]
  );

  const onSelect = React.useCallback(
    (next: string) => {
      onEnter(next);
    },
    [onEnter]
  );

  const onKeyDown = React.useCallback(
    (close) => (event) => {
      const { key } = event;

      if (KeyEvents.includes(key)) {
        event.preventDefault();

        const scrollIntoView = () => {
          const active = contentRef.current.querySelector(".active");

          if (active) {
            active.scrollIntoView({
              behavior: "auto",
              block: "center",
              inline: "center",
            });
          }
        };

        switch (key) {
          case KeyEvent.ArrowUp:
            scrollIntoView();
            return setCursor(decrement(cursor));
          case KeyEvent.ArrowDown:
            scrollIntoView();
            return setCursor(increment(cursor, data.length));
          case KeyEvent.Enter:
          default:
            const selection: T = R.view(R.lensIndex(cursor), data);

            onChange(selection);

            return close();
        }
      }
    },
    [cursor, data, contentRef]
  );

  React.useEffect(() => {
    if (!R.isNil(value)) {
      setIsSearching(false);
      setSelected(value);
    } else {
      setSelected("");
    }
  }, [value, setIsSearching, setSelected]);

  React.useEffect(() => {
    debouncedOnSearch(search);
  }, [search, debouncedOnSearch]);

  React.useEffect(() => {
    onSearch(search);
  }, [key]);

  if (readOnly) {
    return (
      <div className="w-full">
        <SubTitle>{selected}</SubTitle>
      </div>
    );
  }

  return (
    <Menu
      isDisabled={isDisabled}
      testID={testID}
      depth={depth}
      content={({ close }) => {
        if (R.isNil(data) || R.isEmpty(data)) {
          return (
            <Menu.Items className="max-h-60 overflow-auto">
              {noResults}
            </Menu.Items>
          );
        }

        return children({ data, onSelect, cursor, close });
      }}
      forwardRef={contentRef}
      onToggle={(isOpen: boolean) => {
        if (!isOpen) {
          setIsSearching(false);
        }

        if (isExact) {
          setSearch("");
        } else {
          onSelect(search);
        }

        if (isOpen) {
          if (inputRef.current) {
            inputRef.current.select();
          }
        }
      }}
    >
      {({ close }) => {
        return (
          <div className="w-full">
            {isLoading && (
              <div className="absolute h-5 w-5 right-0 my-auto mx-0 flex items-center">
                <Loader />
              </div>
            )}
            <RightAlignedDropdownChevron />
            <Input
              onKeyDown={onKeyDown(close)}
              className="pr-8"
              placeholder={placeholder}
              isDisabled={isDisabled}
              forwardRef={inputRef}
              onChange={changeSearch}
              value={isSearching ? search : selected}
            />
          </div>
        );
      }}
    </Menu>
  );
}
