import type { Enumeration } from "../../data/report/type";

import * as R from "ramda";
import { Country } from "country-data";
import { HydratedEmployee } from "@api/milio";

export interface CurrencyCodeDatum {
  code: string;
  countries: string[];
  currency: string;
  digits: string;
  number: string;
}
export type CurrencyCodeData = { [key: string]: CurrencyCodeDatum };

export interface CountryDatum {
  alpha2: string;
  name: string;
}
export type CountryData = {
  [key: string]: CountryDatum;
};

export function toCurrencyEnumerations(
  data: CurrencyCodeData
): Enumeration<string>[] {
  return R.pipe(
    R.mapObjIndexed((datum: CurrencyCodeDatum): Enumeration<string> => {
      return {
        label: `${datum.code} (${datum.currency})`,
        value: datum.code,
      };
    }),
    R.values,
    R.reject(R.propEq("value", undefined))
  )(data);
}

export function toCountryEnumerations(
  data: readonly Country[]
): Enumeration<string>[] {
  // Data from package has country without a name.
  const filtered = R.filter(
    R.pipe(R.prop("name"), R.complement(R.isNil)),
    data
  );

  return R.sortBy(
    R.prop("label"),
    R.map((datum: CountryDatum): Enumeration<string> => {
      return {
        label: datum.name,
        value: datum.alpha2,
      };
    }, filtered)
  );
}

export const getZoneLabel = (d: HydratedEmployee) =>
  R.path(["zone", "country_details", "nicename"], d) +
  " - " +
  R.path(["zone", "description"], d);
