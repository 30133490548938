import type { OverlayProps } from "./type";

import * as R from "ramda";
import styled from "styled-components";

import { OverlayContainer } from "./Base";

export interface ColorOverlayProps extends OverlayProps {
  color: string;
}

export const ColorOverlay = styled(OverlayContainer)<ColorOverlayProps>`
  background: ${R.prop("color")};
`;
