/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateJobLevelDto,
  HydratedJobLevel,
  UpdateJobLevelDto,
} from '../models/index';
import {
    CreateJobLevelDtoFromJSON,
    CreateJobLevelDtoToJSON,
    HydratedJobLevelFromJSON,
    HydratedJobLevelToJSON,
    UpdateJobLevelDtoFromJSON,
    UpdateJobLevelDtoToJSON,
} from '../models/index';

export interface CreateJobLevelRequest {
    createJobLevelDto: CreateJobLevelDto;
}

export interface FindOneJobLevelRequest {
    id: string;
}

export interface RemoveJobLevelRequest {
    id: string;
}

export interface UpdateJobLevelRequest {
    id: string;
    updateJobLevelDto: UpdateJobLevelDto;
}

/**
 * 
 */
export class JobLevelApi extends runtime.BaseAPI {

    /**
     */
    async createJobLevelRaw(requestParameters: CreateJobLevelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HydratedJobLevel>> {
        if (requestParameters['createJobLevelDto'] == null) {
            throw new runtime.RequiredError(
                'createJobLevelDto',
                'Required parameter "createJobLevelDto" was null or undefined when calling createJobLevel().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/job-levels`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateJobLevelDtoToJSON(requestParameters['createJobLevelDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HydratedJobLevelFromJSON(jsonValue));
    }

    /**
     */
    async createJobLevel(requestParameters: CreateJobLevelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HydratedJobLevel> {
        const response = await this.createJobLevelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findAllJobLevelsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<HydratedJobLevel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/job-levels`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HydratedJobLevelFromJSON));
    }

    /**
     */
    async findAllJobLevels(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<HydratedJobLevel>> {
        const response = await this.findAllJobLevelsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async findOneJobLevelRaw(requestParameters: FindOneJobLevelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HydratedJobLevel>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling findOneJobLevel().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/job-levels/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HydratedJobLevelFromJSON(jsonValue));
    }

    /**
     */
    async findOneJobLevel(requestParameters: FindOneJobLevelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HydratedJobLevel> {
        const response = await this.findOneJobLevelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removeJobLevelRaw(requestParameters: RemoveJobLevelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling removeJobLevel().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/job-levels/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async removeJobLevel(requestParameters: RemoveJobLevelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeJobLevelRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateJobLevelRaw(requestParameters: UpdateJobLevelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HydratedJobLevel>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateJobLevel().'
            );
        }

        if (requestParameters['updateJobLevelDto'] == null) {
            throw new runtime.RequiredError(
                'updateJobLevelDto',
                'Required parameter "updateJobLevelDto" was null or undefined when calling updateJobLevel().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/job-levels/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateJobLevelDtoToJSON(requestParameters['updateJobLevelDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HydratedJobLevelFromJSON(jsonValue));
    }

    /**
     */
    async updateJobLevel(requestParameters: UpdateJobLevelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HydratedJobLevel> {
        const response = await this.updateJobLevelRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
