/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const HistoryCategory = {
    BaseCompensationDecrease: 'base-compensation-decrease',
    BaseCompensationIncrease: 'base-compensation-increase',
    BecameExempt: 'became-exempt',
    BecameInactive: 'became-inactive',
    BecameNonExempt: 'became-non-exempt',
    DepartmentChange: 'department-change',
    EquityGranted: 'equity-granted',
    Hired: 'hired',
    HourlyBaseCompensationDecrease: 'hourly-base-compensation-decrease',
    HourlyBaseCompensationIncrease: 'hourly-base-compensation-increase',
    JobTitleChange: 'job-title-change',
    ManagerChange: 'manager-change',
    Performance: 'performance',
    WentOnLeave: 'went-on-leave',
    ReturnedFromLeave: 'returned-from-leave',
    PlanTitleChange: 'plan-title-change',
    JobFamilyChange: 'job-family-change',
    JobLevelChange: 'job-level-change',
    PayBandMinChange: 'pay-band-min-change',
    PayBandMaxChange: 'pay-band-max-change',
    Unknown: 'unknown'
} as const;
export type HistoryCategory = typeof HistoryCategory[keyof typeof HistoryCategory];


export function instanceOfHistoryCategory(value: any): boolean {
    for (const key in HistoryCategory) {
        if (Object.prototype.hasOwnProperty.call(HistoryCategory, key)) {
            if (HistoryCategory[key as keyof typeof HistoryCategory] === value) {
                return true;
            }
        }
    }
    return false;
}

export function HistoryCategoryFromJSON(json: any): HistoryCategory {
    return HistoryCategoryFromJSONTyped(json, false);
}

export function HistoryCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): HistoryCategory {
    return json as HistoryCategory;
}

export function HistoryCategoryToJSON(value?: HistoryCategory | null): any {
    return value as any;
}

