/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Timeframe
 */
export interface Timeframe {
    /**
     * 
     * @type {string}
     * @memberof Timeframe
     */
    end: string;
    /**
     * 
     * @type {string}
     * @memberof Timeframe
     */
    start: string;
}

/**
 * Check if a given object implements the Timeframe interface.
 */
export function instanceOfTimeframe(value: object): value is Timeframe {
    if (!('end' in value) || value['end'] === undefined) return false;
    if (!('start' in value) || value['start'] === undefined) return false;
    return true;
}

export function TimeframeFromJSON(json: any): Timeframe {
    return TimeframeFromJSONTyped(json, false);
}

export function TimeframeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Timeframe {
    if (json == null) {
        return json;
    }
    return {
        
        'end': json['end'],
        'start': json['start'],
    };
}

export function TimeframeToJSON(value?: Timeframe | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'end': value['end'],
        'start': value['start'],
    };
}

