/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HealthcheckRule } from './HealthcheckRule';
import {
    HealthcheckRuleFromJSON,
    HealthcheckRuleFromJSONTyped,
    HealthcheckRuleToJSON,
} from './HealthcheckRule';
import type { LogicDefinition } from './LogicDefinition';
import {
    LogicDefinitionFromJSON,
    LogicDefinitionFromJSONTyped,
    LogicDefinitionToJSON,
} from './LogicDefinition';

/**
 * 
 * @export
 * @interface UpdateHealthcheckTemplateDto
 */
export interface UpdateHealthcheckTemplateDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateHealthcheckTemplateDto
     */
    title?: string;
    /**
     * 
     * @type {LogicDefinition}
     * @memberof UpdateHealthcheckTemplateDto
     */
    cohort?: LogicDefinition;
    /**
     * 
     * @type {string}
     * @memberof UpdateHealthcheckTemplateDto
     */
    type?: UpdateHealthcheckTemplateDtoTypeEnum;
    /**
     * 
     * @type {Array<HealthcheckRule>}
     * @memberof UpdateHealthcheckTemplateDto
     */
    rules?: Array<HealthcheckRule>;
    /**
     * 
     * @type {string}
     * @memberof UpdateHealthcheckTemplateDto
     */
    id: string;
}


/**
 * @export
 */
export const UpdateHealthcheckTemplateDtoTypeEnum = {
    Employee: 'employee',
    Plan: 'plan'
} as const;
export type UpdateHealthcheckTemplateDtoTypeEnum = typeof UpdateHealthcheckTemplateDtoTypeEnum[keyof typeof UpdateHealthcheckTemplateDtoTypeEnum];


/**
 * Check if a given object implements the UpdateHealthcheckTemplateDto interface.
 */
export function instanceOfUpdateHealthcheckTemplateDto(value: object): value is UpdateHealthcheckTemplateDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    return true;
}

export function UpdateHealthcheckTemplateDtoFromJSON(json: any): UpdateHealthcheckTemplateDto {
    return UpdateHealthcheckTemplateDtoFromJSONTyped(json, false);
}

export function UpdateHealthcheckTemplateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateHealthcheckTemplateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'] == null ? undefined : json['title'],
        'cohort': json['cohort'] == null ? undefined : LogicDefinitionFromJSON(json['cohort']),
        'type': json['type'] == null ? undefined : json['type'],
        'rules': json['rules'] == null ? undefined : ((json['rules'] as Array<any>).map(HealthcheckRuleFromJSON)),
        'id': json['id'],
    };
}

export function UpdateHealthcheckTemplateDtoToJSON(value?: UpdateHealthcheckTemplateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'cohort': LogicDefinitionToJSON(value['cohort']),
        'type': value['type'],
        'rules': value['rules'] == null ? undefined : ((value['rules'] as Array<any>).map(HealthcheckRuleToJSON)),
        'id': value['id'],
    };
}

