import styled from "styled-components";

import * as Style from "style";

export const VerticalDivider = styled.p`
  border-color: ${Style.Color.Layout.Line};
  border-right-width: ${Style.Design.Border.Size};
  border-style: ${Style.Design.Border.Style};
  margin: 0px;
  width: 1px;
  height: 100%;
`;

export const SecondaryVerticalDivider = styled(VerticalDivider)`
  border-style: dotted;
  border-color: ${Style.Color.Layout.Line};
`;

export interface VerticalDividerNamespace extends React.VoidFunctionComponent {
  Secondary: React.VoidFunctionComponent;
}

VerticalDivider.Secondary = SecondaryVerticalDivider;
