import type { ApplicationContext } from "context";
import type {
  StandardLayoutProps,
  StandardLayoutContentProps,
} from "./StandardLayout";

import React from "react";

import { BlankWhiteLayout } from "./BlankWhiteLayout";
import { SectionHeader } from "./SectionHeader";
import { StandardProvider } from "./StandardLayout";
import { useApplicationContext } from "context";
import { Paragraph } from "components/library";

export const WorkspaceContent: React.FC<StandardLayoutContentProps> = ({
  children,
}) => {
  const context: ApplicationContext = useApplicationContext();

  return (
    <BlankWhiteLayout title={context.metadata.metadata.title} paddless>
      <>
        <div className="grid grid-cols-2">
          <SectionHeader
            breadcrumbs={context.breadcrumbs.breadcrumbs}
            toastNamespace={context.toast.currentNamespace}
            title={context.metadata.metadata.title}
            subtitle={
              <Paragraph>{context.metadata.metadata.subtitle}</Paragraph>
            }
          />
        </div>
        {children}
      </>
    </BlankWhiteLayout>
  );
};

export const WorkspaceLayout: React.FC<StandardLayoutProps> = ({
  children,
}) => {
  return <WorkspaceContent>{children}</WorkspaceContent>;
};
