import type { Cycler } from "@milio/lib/util/array";
import type { IconProps } from "components/Icon/type";

import * as Icon from "components/Icon";
import * as R from "ramda";
import React from "react";

import { cycler } from "@milio/lib/util/array";

import * as Style from "style";
import { Sentiment } from "./constant";

/**
 * Convert a sentiment into its primary color.
 */
export function sentimentToPrimaryColor({
  sentiment,
}: {
  sentiment: Sentiment;
}): string {
  return R.propOr(Style.Color.Sentiment.Success.Primary, sentiment, {
    [Sentiment.Error]: Style.Color.Sentiment.Error.Primary,
    [Sentiment.Info]: Style.Color.Sentiment.Info.Primary,
    [Sentiment.Neutral]: Style.Color.Sentiment.Neutral.Primary,
    [Sentiment.Success]: Style.Color.Sentiment.Success.Primary,
    [Sentiment.Warning]: Style.Color.Sentiment.Warning.Primary,
  });
}

/**
 * Convert a sign into a color.
 */
export function signToColor(sign: number): string {
  switch (sign) {
    case 1:
      return Style.Color.Chart.Fern;
    case -1:
      return Style.Color.Chart.Mandy;
    default:
    case 0:
      return Style.Color.Font.Secondary;
  }
}

/**
 * Convert a sentiment into its icon.
 */
export function sentimentToIcon({
  sentiment,
}: {
  sentiment: Sentiment;
}): React.FC<IconProps> {
  return R.propOr(R.always(null), sentiment, {
    [Sentiment.Error]: Icon.WarningTriangle,
    [Sentiment.Info]: Icon.InformationCircle,
    [Sentiment.Neutral]: R.always(null),
    [Sentiment.Success]: Icon.CheckCircle,
    [Sentiment.Warning]: Icon.WarningCircle,
  });
}

/**
 * Convert a number into a sentiment.
 */
export function numberToSentiment(value: number): Sentiment {
  if (value > 0) {
    return Sentiment.Success;
  } else if (value < 0) {
    return Sentiment.Error;
  }

  return Sentiment.Neutral;
}

/**
 * Create a chart color cycler.
 */
export function chartColorCycler(): Cycler<string> {
  return cycler(Style.ChartColors);
}

/**
 * Create a gray scale color cycler.
 */
export function grayScaleColorCycler(): Cycler<string> {
  return cycler(Style.GrayScale);
}
