/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PerformanceDetails
 */
export interface PerformanceDetails {
    /**
     * 
     * @type {number}
     * @memberof PerformanceDetails
     */
    score: number;
    /**
     * 
     * @type {string}
     * @memberof PerformanceDetails
     */
    completed_on: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PerformanceDetails
     */
    questions: Array<string>;
}

/**
 * Check if a given object implements the PerformanceDetails interface.
 */
export function instanceOfPerformanceDetails(value: object): value is PerformanceDetails {
    if (!('score' in value) || value['score'] === undefined) return false;
    if (!('completed_on' in value) || value['completed_on'] === undefined) return false;
    if (!('questions' in value) || value['questions'] === undefined) return false;
    return true;
}

export function PerformanceDetailsFromJSON(json: any): PerformanceDetails {
    return PerformanceDetailsFromJSONTyped(json, false);
}

export function PerformanceDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PerformanceDetails {
    if (json == null) {
        return json;
    }
    return {
        
        'score': json['score'],
        'completed_on': json['completed_on'],
        'questions': json['questions'],
    };
}

export function PerformanceDetailsToJSON(value?: PerformanceDetails | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'score': value['score'],
        'completed_on': value['completed_on'],
        'questions': value['questions'],
    };
}

