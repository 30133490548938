import type { OverlayProps } from "./type";

import * as R from "ramda";
import styled from "styled-components";

export const OverlayContainer = styled.div<OverlayProps>`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  align-items: center;
  display: flex;
  justify-content: center;
  opacity: ${R.propOr(1, "opacity")};
`;
