import type { UseCursorProps, Cursor } from "./useCursor";

import { useCursor } from "./useCursor";

import * as R from "ramda";

export enum KeyEvent {
  ArrowDown = "ArrowDown",
  ArrowUp = "ArrowUp",
  Enter = "Enter",
}
const KeyEvents = R.values(KeyEvent);

export interface UseUICursorProps extends UseCursorProps {
  ref: React.RefObject<HTMLElement>;
  onSelect?: (pointer: number) => void;
}

export interface UICursor extends Cursor {
  focusActive: () => void;
  focusSelected: () => void;
  onKeyDown: (event: KeyboardEvent) => void;
}

const DATA_ACTIVE_KEY = "data-active";
const DATA_SELECTED_KEY = "data-selected";

export function getDataActiveProp(isActive: boolean): {
  "data-active": boolean;
} {
  return { [DATA_ACTIVE_KEY]: isActive };
}

export function getDataSelectedProp(isSelected: boolean): {
  "data-selected": boolean;
} {
  return { [DATA_SELECTED_KEY]: isSelected };
}

export function getUICursorDataProps({
  isActive,
  isSelected,
}: {
  isSelected: boolean;
  isActive: boolean;
}): {
  "data-active": boolean;
  "data-selected": boolean;
} {
  return {
    ...getDataActiveProp(isActive),
    ...getDataSelectedProp(isSelected),
  };
}

export function getDataActiveSelector(isActive: boolean): string {
  return `[${DATA_ACTIVE_KEY}=${isActive.toString()}]`;
}

export function getDataSelectedSelector(isSelected: boolean): string {
  return `[${DATA_SELECTED_KEY}=${isSelected.toString()}]`;
}

function scrollTo(ref: React.RefObject<HTMLElement>, selector: string): void {
  if (!R.isNil(ref) && !R.isNil(ref.current)) {
    const found = ref.current.querySelector(selector);

    if (found) {
      found.scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "center",
      });
    }
  }
}

/**
 * UI cursor is used to keep track of a location within a list and complex
 * it with specific UI interactions such as key presses.
 */
export function useUICursor({
  initial,
  length,
  onSelect,
  ref,
}: UseUICursorProps): UICursor {
  const cursor: Cursor = useCursor({ length, initial });

  const focusSelected = () => scrollTo(ref, getDataSelectedSelector(true));
  const focusActive = () => scrollTo(ref, getDataActiveSelector(true));

  const onKeyDown = (event) => {
    const { key } = event;

    if (KeyEvents.includes(key)) {
      event.stopPropagation();
      event.preventDefault();

      switch (key) {
        case KeyEvent.ArrowUp:
          focusActive();
          return cursor.decrement();
        case KeyEvent.ArrowDown:
          focusActive();
          return cursor.increment();
        case KeyEvent.Enter:
          onSelect(cursor.pointer);
          return;
        default:
          return;
      }
    }
  };

  return {
    ...cursor,
    focusActive,
    focusSelected,
    onKeyDown,
  };
}
