import * as R from "ramda";
import React from "react";
import { Dialog, Transition } from "@headlessui/react";

import { Paragraph } from "../Paragraph";
import { Sentiment } from "../constant";
import { Title } from "../Title";

export interface ModalChildrenProps {
  close: () => void;
  focusRef: React.Ref<HTMLButtonElement>;
}

export interface SpecializedModalProps {
  children: (props: ModalChildrenProps) => React.ReactNode;
  isOpen?: boolean;
  title?: string;
}

export interface ModalProps extends SpecializedModalProps {
  icon?: React.ReactNode;
  onClose?: () => void;
  sentiment: Sentiment;
}

export const Modal: React.FC<ModalProps> = ({
  children,
  icon,
  isOpen = false,
  onClose,
  title,
}) => {
  const focusRef = React.useRef(null);

  return (
    <Transition.Root show={isOpen} as={React.Fragment}>
      <Dialog
        as="div"
        auto-reopen="true"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={onClose}
        initialFocus={focusRef}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                {icon && icon}
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                  <Title>{title}</Title>
                  <Paragraph>
                    <div className="mt-2">
                      {R.is(Function, children) &&
                        children({ close: onClose, focusRef })}
                    </div>
                  </Paragraph>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
