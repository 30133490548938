import type { Range } from "util/range";

import React from "react";

import * as Style from "style";
import { NumberLineContainer } from "./Container";
import { NumberLinePoint } from "./NumberLinePoint";

export interface NumberLineProps {
  background?: string;
  foreground?: string;
  range: Range;
  value: number;
}

export const NumberLine: React.FC<NumberLineProps> = ({
  background = Style.Color.Layout.Line,
  foreground = Style.Color.Chart.CornflowerBlue,
  range,
  value,
}) => {
  return (
    <NumberLineContainer color={background}>
      <NumberLinePoint
        range={range}
        point={{ value: value, color: foreground }}
      />
    </NumberLineContainer>
  );
};
