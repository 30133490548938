/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateEmployeeDto
 */
export interface CreateEmployeeDto {
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDto
     */
    employeeId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDto
     */
    email: string;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeDto
     */
    baseCompLocal: number;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeDto
     */
    baseCompLocalHourly?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDto
     */
    currency: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDto
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDto
     */
    location: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeDto
     */
    is_active: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDto
     */
    gender: CreateEmployeeDtoGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDto
     */
    birth_date: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDto
     */
    join_date: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDto
     */
    termination_date?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDto
     */
    image_url: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDto
     */
    job_title_id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDto
     */
    zone_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDto
     */
    manager_id: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDto
     */
    department: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDto
     */
    department_id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDto
     */
    exemption_status?: CreateEmployeeDtoExemptionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDto
     */
    source_id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDto
     */
    pay_rate: CreateEmployeeDtoPayRateEnum;
    /**
     * 
     * @type {object}
     * @memberof CreateEmployeeDto
     */
    metadata?: object | null;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDto
     */
    effective_from: string;
}


/**
 * @export
 */
export const CreateEmployeeDtoGenderEnum = {
    Female: 'female',
    Male: 'male',
    NotSpecified: 'not-specified',
    Other: 'other'
} as const;
export type CreateEmployeeDtoGenderEnum = typeof CreateEmployeeDtoGenderEnum[keyof typeof CreateEmployeeDtoGenderEnum];

/**
 * @export
 */
export const CreateEmployeeDtoExemptionStatusEnum = {
    Exempt: 'exempt',
    NonExempt: 'non-exempt'
} as const;
export type CreateEmployeeDtoExemptionStatusEnum = typeof CreateEmployeeDtoExemptionStatusEnum[keyof typeof CreateEmployeeDtoExemptionStatusEnum];

/**
 * @export
 */
export const CreateEmployeeDtoPayRateEnum = {
    Salary: 'salary',
    Hourly: 'hourly',
    Daily: 'daily'
} as const;
export type CreateEmployeeDtoPayRateEnum = typeof CreateEmployeeDtoPayRateEnum[keyof typeof CreateEmployeeDtoPayRateEnum];


/**
 * Check if a given object implements the CreateEmployeeDto interface.
 */
export function instanceOfCreateEmployeeDto(value: object): value is CreateEmployeeDto {
    if (!('employeeId' in value) || value['employeeId'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('baseCompLocal' in value) || value['baseCompLocal'] === undefined) return false;
    if (!('currency' in value) || value['currency'] === undefined) return false;
    if (!('country' in value) || value['country'] === undefined) return false;
    if (!('location' in value) || value['location'] === undefined) return false;
    if (!('is_active' in value) || value['is_active'] === undefined) return false;
    if (!('gender' in value) || value['gender'] === undefined) return false;
    if (!('birth_date' in value) || value['birth_date'] === undefined) return false;
    if (!('join_date' in value) || value['join_date'] === undefined) return false;
    if (!('image_url' in value) || value['image_url'] === undefined) return false;
    if (!('job_title_id' in value) || value['job_title_id'] === undefined) return false;
    if (!('manager_id' in value) || value['manager_id'] === undefined) return false;
    if (!('department' in value) || value['department'] === undefined) return false;
    if (!('department_id' in value) || value['department_id'] === undefined) return false;
    if (!('source_id' in value) || value['source_id'] === undefined) return false;
    if (!('pay_rate' in value) || value['pay_rate'] === undefined) return false;
    if (!('effective_from' in value) || value['effective_from'] === undefined) return false;
    return true;
}

export function CreateEmployeeDtoFromJSON(json: any): CreateEmployeeDto {
    return CreateEmployeeDtoFromJSONTyped(json, false);
}

export function CreateEmployeeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateEmployeeDto {
    if (json == null) {
        return json;
    }
    return {
        
        'employeeId': json['employeeId'],
        'name': json['name'],
        'email': json['email'],
        'baseCompLocal': json['baseCompLocal'],
        'baseCompLocalHourly': json['baseCompLocalHourly'] == null ? undefined : json['baseCompLocalHourly'],
        'currency': json['currency'],
        'country': json['country'],
        'location': json['location'],
        'is_active': json['is_active'],
        'gender': json['gender'],
        'birth_date': json['birth_date'],
        'join_date': json['join_date'],
        'termination_date': json['termination_date'] == null ? undefined : json['termination_date'],
        'image_url': json['image_url'],
        'job_title_id': json['job_title_id'],
        'zone_id': json['zone_id'] == null ? undefined : json['zone_id'],
        'manager_id': json['manager_id'],
        'department': json['department'],
        'department_id': json['department_id'],
        'exemption_status': json['exemption_status'] == null ? undefined : json['exemption_status'],
        'source_id': json['source_id'],
        'pay_rate': json['pay_rate'],
        'metadata': json['metadata'] == null ? undefined : json['metadata'],
        'effective_from': json['effective_from'],
    };
}

export function CreateEmployeeDtoToJSON(value?: CreateEmployeeDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'employeeId': value['employeeId'],
        'name': value['name'],
        'email': value['email'],
        'baseCompLocal': value['baseCompLocal'],
        'baseCompLocalHourly': value['baseCompLocalHourly'],
        'currency': value['currency'],
        'country': value['country'],
        'location': value['location'],
        'is_active': value['is_active'],
        'gender': value['gender'],
        'birth_date': value['birth_date'],
        'join_date': value['join_date'],
        'termination_date': value['termination_date'],
        'image_url': value['image_url'],
        'job_title_id': value['job_title_id'],
        'zone_id': value['zone_id'],
        'manager_id': value['manager_id'],
        'department': value['department'],
        'department_id': value['department_id'],
        'exemption_status': value['exemption_status'],
        'source_id': value['source_id'],
        'pay_rate': value['pay_rate'],
        'metadata': value['metadata'],
        'effective_from': value['effective_from'],
    };
}

