import type { BaseButtonProps } from "./Base";
import type { IconButtonProps } from "./Icon";

import * as React from "react";
import styled, { css } from "styled-components";

import * as Style from "style";
import { BaseIconButton } from "./Icon";
import { ButtonLoader } from "./Base";
import {
  SecondaryButtonStyle,
  PrimaryButtonStyle,
  BaseButtonStyle,
  DangerButtonStyle,
} from "./style";

export interface CompactButtonSpecializations
  extends React.FC<BaseButtonProps> {
  Danger: React.FC<BaseButtonProps>;
  Icon: CompactIconButtonSpecializations;
  Primary: React.FC<BaseButtonProps>;
  Secondary: React.FC<BaseButtonProps>;
}

export interface CompactIconButtonSpecializations
  extends React.FC<IconButtonProps> {
  Primary: React.FC<IconButtonProps>;
  Secondary: React.FC<IconButtonProps>;
}

const CompactButtonStyle = css`
  padding: ${Style.Layout.Padding.ExtraSmall} ${Style.Layout.Padding.Small};
`;

export const CompactButtonContainer = styled.button`
  position: relative;
  ${BaseButtonStyle};
  ${CompactButtonStyle};
`;

const PrimaryContainer = styled.div`
  > button {
    ${PrimaryButtonStyle};
    ${CompactButtonStyle};
  }
`;

const SecondaryContainer = styled.div`
  > button {
    ${SecondaryButtonStyle};
    ${CompactButtonStyle};
  }
`;

const DangerContainer = styled.div`
  > button {
    ${DangerButtonStyle};
    ${CompactButtonStyle};
  }
`;

const CompactButtonContentShield = styled.div<{ isLoading: boolean }>`
  > span {
    visibility: ${(props) => (props.isLoading ? "hidden" : "auto")};
  }
`;

export const CompactButton: CompactButtonSpecializations = ({
  children,
  ...props
}: BaseButtonProps) => {
  const { isLoading } = props;

  return (
    <CompactButtonContainer {...props}>
      <ButtonLoader isLoading={isLoading} />
      <CompactButtonContentShield isLoading={isLoading}>
        <span>{children}</span>
      </CompactButtonContentShield>
    </CompactButtonContainer>
  );
};

const CompactPrimaryButton: React.FC<BaseButtonProps> = (props) => {
  return (
    <PrimaryContainer isDisabled={props.isDisabled}>
      <CompactButton {...props} />
    </PrimaryContainer>
  );
};

const CompactSecondaryButton: React.FC<BaseButtonProps> = (props) => {
  return (
    <SecondaryContainer isDisabled={props.isDisabled}>
      <CompactButton {...props} />
    </SecondaryContainer>
  );
};

const CompactDangerButton: React.FC<BaseButtonProps> = (props) => {
  return (
    <DangerContainer isDisabled={props.isDisabled}>
      <CompactButton {...props} />
    </DangerContainer>
  );
};

export const CompactIconButton: CompactIconButtonSpecializations = ({
  children,
  ...props
}: BaseButtonProps) => {
  return <CompactButtonContainer {...props}>{children}</CompactButtonContainer>;
};

export const PrimaryCompactIconButton: React.FC<IconButtonProps> = (props) => {
  return (
    <PrimaryContainer {...props}>
      <BaseIconButton {...props} />
    </PrimaryContainer>
  );
};

export const SecondaryCompactIconButton: React.FC<IconButtonProps> = (
  props
) => {
  return (
    <SecondaryContainer {...props}>
      <BaseIconButton {...props} />
    </SecondaryContainer>
  );
};

CompactIconButton.Primary = PrimaryCompactIconButton;
CompactIconButton.Secondary = SecondaryCompactIconButton;

CompactButton.Danger = CompactDangerButton;
CompactButton.Icon = CompactIconButton;
CompactButton.Primary = CompactPrimaryButton;
CompactButton.Secondary = CompactSecondaryButton;
