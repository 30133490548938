/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JobFamily
 */
export interface JobFamily {
    /**
     * 
     * @type {string}
     * @memberof JobFamily
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof JobFamily
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof JobFamily
     */
    deleted_at: string;
    /**
     * 
     * @type {string}
     * @memberof JobFamily
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof JobFamily
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof JobFamily
     */
    job_group_id: string;
}

/**
 * Check if a given object implements the JobFamily interface.
 */
export function instanceOfJobFamily(value: object): value is JobFamily {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('created_at' in value) || value['created_at'] === undefined) return false;
    if (!('deleted_at' in value) || value['deleted_at'] === undefined) return false;
    if (!('updated_at' in value) || value['updated_at'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('job_group_id' in value) || value['job_group_id'] === undefined) return false;
    return true;
}

export function JobFamilyFromJSON(json: any): JobFamily {
    return JobFamilyFromJSONTyped(json, false);
}

export function JobFamilyFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobFamily {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created_at': json['created_at'],
        'deleted_at': json['deleted_at'],
        'updated_at': json['updated_at'],
        'name': json['name'],
        'job_group_id': json['job_group_id'],
    };
}

export function JobFamilyToJSON(value?: JobFamily | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'created_at': value['created_at'],
        'deleted_at': value['deleted_at'],
        'updated_at': value['updated_at'],
        'name': value['name'],
        'job_group_id': value['job_group_id'],
    };
}

