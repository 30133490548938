import type { StandardComponent } from "components/type";

import React from "react";

import * as Style from "style";
import { CrossHatchOverlay } from "../CrossHatchOverlay";
import { ProgressBarContainer } from "./Container";
import { Progression } from "./Progression";

export interface ProgressBarProps extends StandardComponent {
  background?: string;
  foreground?: string;
  percent?: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  background = Style.Color.Layout.Line,
  foreground = Style.Color.Chart.CornflowerBlue,
  percent = 0,
  isDisabled = false,
}) => {
  return (
    <ProgressBarContainer color={background}>
      {isDisabled && <CrossHatchOverlay />}
      <Progression progression={{ percent: percent, color: foreground }} />
    </ProgressBarContainer>
  );
};
