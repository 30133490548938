import styled from "styled-components";

import * as Style from "style";

export interface TableProps {
  sticky: boolean;
}

function toFirstChildBoxShadow({ sticky }: TableProps): string {
  return sticky ? `inset -8px 0 1px -7px ${Style.Color.Layout.Line}` : "none";
}

function toFirstChildPosition({ sticky }: TableProps): string {
  return sticky ? "sticky" : "auto";
}

export const Table = styled.table<TableProps>`
  min-width: 100%;
  border-radius: ${Style.Design.Rounding.Secondary};
  border-collapse: collapse;

  thead,
  tbody,
  tfoot {
    th:first-child,
    td:first-child {
      left: 0;
      position: ${toFirstChildPosition};
    }
  }

  thead,
  tbody {
    th:first-child,
    td:first-child {
      box-shadow: ${toFirstChildBoxShadow};
    }
  }
`;
