import type { AnimationComponentProps } from "./type";

import styled from "styled-components";
import {
  ENTERING,
  ENTERED,
  EXITING,
  EXITED,
} from "react-transition-group/Transition";

import { createAnimationContainer } from "./Container";

function toWidth({ state }: AnimationComponentProps): string {
  switch (state) {
    case ENTERING:
      return "0%";
    case ENTERED:
      return "100%";
    case EXITING:
      return "100%";
    case EXITED:
      return "0%";
    default:
      return "0%";
  }
}

const SlideInRightAnimation = styled.div`
  transition: width 0.5s;
  width: ${toWidth};
`;

export const SlideInRight = createAnimationContainer(SlideInRightAnimation);
