import * as R from "ramda";
import * as yup from "yup";
import { ReportingSchema as _ReportingSchema } from "@milio/lib/data/report/type";
import { GraphType } from "@milio/lib/data/report/constant";
import { ResourceType } from "@milio/lib/data/constant";

export enum ReportType {
  Employee = "employee",
  Plan = "plan",
}

export enum DataType {
  Boolean = "boolean",
  Currency = "currency",
  DateTime = "date-time",
  NaturalNumber = "natural-number",
  Percentile = "percentile",
  Percent = "percent",
  RationalNumber = "rational-number",
  String = "string",
}

export enum DataContainer {
  Array = "array",
}

export enum Operator {
  Equals = "Equals",
  Excludes = "Excludes",
  GreaterThan = "GreaterThan",
  GreaterThanOrEqualTo = "GreaterThanOrEqualTo",
  Includes = "Includes",
  LessThan = "LessThan",
  LessThanOrEqualTo = "LessThanOrEqualTo",
  NotEquals = "NotEquals",
  Contains = "Contains",
  DoesNotContain = "DoesNotContain",
}

export enum AggregationType {
  Average = "average",
  Count = "count",
  DistinctCount = "distinct-count",
  Max = "max",
  Median = "median",
  Min = "min",
  Sum = "sum",
}
export type AggregationTypes = AggregationType[];

export interface LabelledOperator {
  operator: Operator;
  label: string;
}

export type Operators = Operator[];

export enum Combinator {
  And = "And",
  Or = "Or",
}
export type Combinators = Combinator[];

export const ReportFilterSchema = yup.object({
  field: yup.string().required(),
  operator: yup.mixed().required(),
  value: yup
    .string()
    .test("must-have-value", "Filter requires a value.", (current, context) => {
      return (
        R.has("categories", context.parent) ||
        (!R.isNil(current) && !R.isEmpty(current))
      );
    }),
});

export type ReportFilter = yup.InferType<typeof ReportFilterSchema>;
export type ReportFilters = ReportFilter[];

export const DefaultReportFilter = {
  id: undefined,
  field: undefined,
  operator: undefined,
  value: undefined,
};

export const DefaultReportFilterGroup = {
  combinator: Combinator.And,
  filters: [],
};

export const AggregationSchema = yup.object({
  field: yup.string().required(),
  types: yup
    .array()
    .of(yup.mixed<AggregationType>().oneOf(Object.values(AggregationType))),
});
export type Aggregation = yup.InferType<typeof AggregationSchema>;
export type Aggregations = Aggregation[];

export interface AggregationResult {
  field: string;
  results: number[];
  types: AggregationTypes;
}
export type AggregationResults = AggregationResult[];

export const ReportFilterGroupSchema = yup.object({
  combinator: yup
    .mixed<Combinator>()
    .oneOf(Object.values(Combinator))
    .required(),
  filters: yup.array().of(ReportFilterSchema).required(),
});
export type ReportFilterGroup = yup.InferType<typeof ReportFilterGroupSchema>;
export type ReportFilterGroups = ReportFilterGroup[];

export const LogicDefinitionSchema = yup.object({
  groups: yup.array().of(ReportFilterGroupSchema),
  combinators: yup
    .array()
    .of(yup.mixed<Combinator>().oneOf(Object.values(Combinator))),
  type: yup.mixed<ReportType>().oneOf(Object.values(ReportType)).required(),
});

export type LogicDefinition = yup.InferType<typeof LogicDefinitionSchema>;
export type LogicDefinitions = LogicDefinition[];

export const ReportGraphMeasureSchema = yup.object({
  label: yup.string(),
  value: yup.string(),
  aggregations: yup.array().of(yup.string()),
  selected: yup.mixed(),
});

export const ReportGraphPropsSchema = yup.object({
  dimensions: yup.array().of(yup.string()),
  measures: yup.array().of(ReportGraphMeasureSchema),
  tooltipContext: yup.array().of(ReportGraphMeasureSchema),
});

export type ReportGraphProps = yup.InferType<typeof ReportGraphPropsSchema>;

export const ReportGraphSchema = yup.object({
  title: yup.string(),
  type: yup.string().oneOf(Object.values(GraphType)),
  props: yup.mixed<ReportGraphProps>(),
});

export type ReportGraph = yup.InferType<typeof ReportGraphSchema>;

export const ReportSchema = LogicDefinitionSchema.shape({
  id: yup.mixed(),
  title: yup.string().required("Report must have a title."),
  type: yup.mixed().required(),
  graphs: yup.array().of(ReportGraphSchema),
  clientSort: yup.array(),
  clientFilters: yup.array(),
  clientAggregations: yup.array(),
});

export const DefaultReport = {
  fields: [],
  groups: [],
  type: ReportType.Employee,
  title: "",
  combinators: [],
  aggregations: [],
  clientAggregations: [],
  clientSort: [],
  clientFilters: [],
};

export interface Enumeration<T> {
  description?: string;
  label?: string;
  value: T;
}
export type Enumerations<T> = Enumeration<T>[];

export interface ReportingSchemaRelationship {
  resource: ResourceType;
}

export interface ReportingSchemaDisplay {
  width?: number;
}

export type ReportingSchema = _ReportingSchema;
export type ReportingSchemas = ReportingSchema[];
export type ReportingSchemaMap = { [key: string]: ReportingSchema };
export type ReportingSchemaPair = [string, ReportingSchema];
export type ReportingSchemaPairs = ReportingSchemaPair[];

export interface FilterResult {
  description: string;
  result: boolean;
}
export type FilterResults = FilterResult[];

export interface FilterGroupResult {
  filters: FilterResults;
  result: boolean;
}
export type FilterGroupResults = FilterGroupResult[];

export interface Explanation {
  groups: FilterGroupResults;
  result: boolean;
}

export const DefaultReportingSchemaDisplay: ReportingSchemaDisplay = {
  width: 45,
};
