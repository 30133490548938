/* tslint:disable */
/* eslint-disable */
/**
 * Milio API
 * API for accessing data within Milio
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RegionStats } from './RegionStats';
import {
    RegionStatsFromJSON,
    RegionStatsFromJSONTyped,
    RegionStatsToJSON,
} from './RegionStats';
import type { AllMetrics } from './AllMetrics';
import {
    AllMetricsFromJSON,
    AllMetricsFromJSONTyped,
    AllMetricsToJSON,
} from './AllMetrics';
import type { PlanMetadataContainer } from './PlanMetadataContainer';
import {
    PlanMetadataContainerFromJSON,
    PlanMetadataContainerFromJSONTyped,
    PlanMetadataContainerToJSON,
} from './PlanMetadataContainer';
import type { PaybandMetrics } from './PaybandMetrics';
import {
    PaybandMetricsFromJSON,
    PaybandMetricsFromJSONTyped,
    PaybandMetricsToJSON,
} from './PaybandMetrics';
import type { BenchmarkMetrics } from './BenchmarkMetrics';
import {
    BenchmarkMetricsFromJSON,
    BenchmarkMetricsFromJSONTyped,
    BenchmarkMetricsToJSON,
} from './BenchmarkMetrics';
import type { RegionMetrics } from './RegionMetrics';
import {
    RegionMetricsFromJSON,
    RegionMetricsFromJSONTyped,
    RegionMetricsToJSON,
} from './RegionMetrics';

/**
 * 
 * @export
 * @interface PlanMetricsDto
 */
export interface PlanMetricsDto {
    /**
     * 
     * @type {string}
     * @memberof PlanMetricsDto
     */
    key: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PlanMetricsDto
     */
    sort_key: Array<string>;
    /**
     * 
     * @type {PlanMetadataContainer}
     * @memberof PlanMetricsDto
     */
    metadata: PlanMetadataContainer;
    /**
     * 
     * @type {RegionMetrics}
     * @memberof PlanMetricsDto
     */
    regions: RegionMetrics;
    /**
     * 
     * @type {PaybandMetrics}
     * @memberof PlanMetricsDto
     */
    payband_regions: PaybandMetrics;
    /**
     * 
     * @type {BenchmarkMetrics}
     * @memberof PlanMetricsDto
     */
    benchmark_regions: BenchmarkMetrics;
    /**
     * 
     * @type {AllMetrics}
     * @memberof PlanMetricsDto
     */
    all_regions: AllMetrics;
    /**
     * 
     * @type {RegionStats}
     * @memberof PlanMetricsDto
     */
    region_stats: RegionStats;
}

/**
 * Check if a given object implements the PlanMetricsDto interface.
 */
export function instanceOfPlanMetricsDto(value: object): value is PlanMetricsDto {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('sort_key' in value) || value['sort_key'] === undefined) return false;
    if (!('metadata' in value) || value['metadata'] === undefined) return false;
    if (!('regions' in value) || value['regions'] === undefined) return false;
    if (!('payband_regions' in value) || value['payband_regions'] === undefined) return false;
    if (!('benchmark_regions' in value) || value['benchmark_regions'] === undefined) return false;
    if (!('all_regions' in value) || value['all_regions'] === undefined) return false;
    if (!('region_stats' in value) || value['region_stats'] === undefined) return false;
    return true;
}

export function PlanMetricsDtoFromJSON(json: any): PlanMetricsDto {
    return PlanMetricsDtoFromJSONTyped(json, false);
}

export function PlanMetricsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanMetricsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'sort_key': json['sort_key'],
        'metadata': PlanMetadataContainerFromJSON(json['metadata']),
        'regions': RegionMetricsFromJSON(json['regions']),
        'payband_regions': PaybandMetricsFromJSON(json['payband_regions']),
        'benchmark_regions': BenchmarkMetricsFromJSON(json['benchmark_regions']),
        'all_regions': AllMetricsFromJSON(json['all_regions']),
        'region_stats': RegionStatsFromJSON(json['region_stats']),
    };
}

export function PlanMetricsDtoToJSON(value?: PlanMetricsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'key': value['key'],
        'sort_key': value['sort_key'],
        'metadata': PlanMetadataContainerToJSON(value['metadata']),
        'regions': RegionMetricsToJSON(value['regions']),
        'payband_regions': PaybandMetricsToJSON(value['payband_regions']),
        'benchmark_regions': BenchmarkMetricsToJSON(value['benchmark_regions']),
        'all_regions': AllMetricsToJSON(value['all_regions']),
        'region_stats': RegionStatsToJSON(value['region_stats']),
    };
}

